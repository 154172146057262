export enum DriverStatus {
  //کلا برنامه بسته است
  OutOfService = 1004,
  // برنامه باز است ولی افلاین است
  Off = 1005,
  // لاگین کرده و انلاین هم در دسترس هم شده ولی در ایستگاه ثبت نوبت نکرده است
  Station100 = 1006,
  //در ایستگاه در صف انتظار قرار دارد
  WaitingOnStation = 1007,
  Traveling = 1008,
  Blocked = 1009,
  Vacation = 1010,
  ProposedService = 1011,
  // به دلیل لاگین در دستگاه دیگری از برنامه خارج می گردد
  LogoutForConcurrency = 1012,
  Locked = 1013,

}
 
export class IDriverStatusMeta {
  text: string;
  color: string;
  state: DriverStatus;
  static getStatus(code: DriverStatus): IDriverStatusMeta {
    //console.log('code is ' + code);
    const statusMap = {
      [DriverStatus.Blocked]: {
        text: 'مسدود',
        color: '#f00',
        state: DriverStatus.Blocked
      },
      [DriverStatus.Off]: {
        text: 'خارج از سرویس',
        color: '#888',
        state: DriverStatus.Off
      },
      [DriverStatus.WaitingOnStation]: {
        text: 'در صف ایستگاه',
        color: '#ff0',
        state: DriverStatus.WaitingOnStation
      },
      [DriverStatus.Station100]: {
        text: ' آزاد',
        color: '#eee',
        state: DriverStatus.Station100
      },
      [DriverStatus.Traveling]: {
        text: 'در حال سفر',
        color: '#0f0',
        state: DriverStatus.Traveling
      },
      [DriverStatus.Vacation]: {
        text: 'مرخصی',
        color: '#f0f',
        state: DriverStatus.Vacation
      },
      [DriverStatus.OutOfService]: {
        text: 'خارج از برنامه',
        color: '#fff',
        state: DriverStatus.OutOfService
      },
      [DriverStatus.Locked]: {
        text: 'قفل شده توسط راهبر',
        color: '#f00',
        state: DriverStatus.Locked
      }
    }[code];
    return statusMap ? statusMap : statusMap[DriverStatus.Off];
  }

  isInStation(): boolean {
    return this.state == DriverStatus.WaitingOnStation;
  }
}

import { NumericLiteral } from "typescript";

export class Passanger {
  id: number;
  fName: string;
  lName: string;
  mobile: string;
  nationalCode: string;
  gender: boolean;
  fixedComment: string;
  lockDriver: string;
  serviceCount: number;
  mobiles: string;
  
}
 

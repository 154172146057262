import { DriverStatus, IDriverStatusMeta } from "../lib/enums/driver-status.enum";
import { Car } from "./car";
import { ErrorOutput } from "./enumtype";

export class Driver {
  selected: boolean;
  id: number;
  nationalCode: string;
  mobile: string;
  fName: string;
  lName: string;
  currentCarID: number;
  currentStatus: number;
  guid: string;
  confirmInfoByDriver: boolean;
  avatar: string;
  confirmInfoByOperator: boolean;
  currentDriverStatus: IDriverStatusMeta;
  driverCode: string;
  workingMobile: string;
  currentStation = 0;
  lockComment: string;
  lat: any;
  lng: any;
  lastActivity: any;
  driverStatusInfo: DriverStatusInfo;
  typeCar: number;
  color: number;
  pelaqueIR: string;
  pelaqueLetter: string;
  pelaque2 : number;
  pelaque3: number;
  username: string;
  password: string;
  commission: number;
  abonman: number
  mande: number;
  sumPrice: number;
  sumEtebari: number;
  commissionPercent: number;
  commissionValue: number;
  debitMande: number;
  this_Month_SumPrice: number;
  this_Month_SumEtebari: number;
  this_Month_CountTotal: number;
  this_Month_CountEtebari: number;
  this_Month_CalculatedCommission: number;

  shiftName: string;





  constructor() {
    this.currentDriverStatus = IDriverStatusMeta.getStatus(DriverStatus.OutOfService);
    this.driverStatusInfo = new DriverStatusInfo();

    this.typeCar=0;
    this.color = 0;
    this.pelaqueIR='';
    this.pelaqueLetter = '';
    this.pelaque2 = 0;
    this.pelaque3 = 0;


  }
}
export class getDriverInfo {
  info: Driver;
  car: Car;
  errorCode: ErrorOutput;
  tk: string;
}
export class DriverPositionHist {
  lat: any;
  lng: any;
  bearing: any;
  speed: any;
  driverID: number;
  createDateTime: string;
  relatedServiceID: number;
  relatedToken: string;
  currentStatus: number;
  currentStation: number;
  fName: string;
  lName: string;
  driverCode: number;
  mobile: string;
  shamsiCreateDateTime: string;
}
export class DriverStatusInfo {
  currentStatus: number;
  stationID: number;
  priority: number;
  guid: string;
  lat: any;
  lng: any;

  lastGPSRecieveDateTime: Date;
  lastGPSRecieveDistanceSecond: number;
  mande: number;
  mandeString: string;
                       


}
export class NationalCodeCheckResult {
  driverGUID: string;
  errorCode: number;
  errorMessage: string;
  tempGuid: string;
}

export class DriverLog {
  id: number;
  logType: number;
  logTypeName: string;
  logMessage: string;
  tag: string;
  createDateTime: string;
  persianDate: string;
  eventTime: string;

}

import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-inputtext',
  templateUrl: './inputtext.component.html',
  styleUrls: ['./inputtext.component.css']
})
export class InputtextComponent implements OnInit {

  constructor(
    private matSnackbar: MatSnackBar,

    public dialogRef: MatDialogRef<InputtextComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      msg: string,
      fieldName: string,
      value: number,
      maxValue:number

    },) { }


  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
    if (this.data.maxValue > 0) {
      if (this.data.value < this.data.maxValue) {
          this.matSnackbar.open('مبلغ صحیح نمی باشد', null, { duration: 2000 });

        return;

      }
      else
        this.dialogRef.close(this.data.value);

    }
    this.dialogRef.close(this.data.value);
  }

}

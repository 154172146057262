import { Component, Input, OnInit } from '@angular/core';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { Driver, getDriverInfo } from '../../model/driver';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-summaryinfo',
  templateUrl: './summaryinfo.component.html',
  styleUrls: ['./summaryinfo.component.css']
})
export class SummaryinfoComponent implements OnInit {
  @Input() driverID = '';

  constructor(private drvSservice: DriverserviceService,
    private route: ActivatedRoute,
  ) { }
  driverInfo: Driver;
  ngOnInit(): void {
    

    this.report();
  }
  report() {
    this.drvSservice.getDriverWithoutFBSToken(this.driverID).subscribe(t => {
      this.driverInfo = t.info;
      console.log(t);
    });
  }

}

import {Component, Inject, OnInit} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceState } from '../../globalVariable';
import { WebSocketService } from '../../lib/web-socket.service';
import { Service } from '../../model/service';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';

@Component({
  selector: 'app-finishservice',
  templateUrl: './finishservice.component.html',
  styleUrls: ['./finishservice.component.scss']
})
export class FinishserviceComponent implements OnInit {
  passengersCount = 1;
  satisfaction = 3;
  totalCollected = 1234;
  //price = 0;
  inputGuid=''
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private requestSerivce: ServiceRequestService,
    private serviceService: ServiceserviceService,
    private socketService: WebSocketService,
    public dialogRef: MatDialogRef<FinishserviceComponent>,
    private matSnackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {
      guid: string, destination: string,
      price: number,
      comment:string
}
  ) {
    this.inputGuid = data.guid;
    
    if (data.price > 0)
      this.price.setValue(data.price);
    
    this.comment.setValue(data.comment);
    this.destination.setValue(data.destination);
  }
 price = new FormControl('', [Validators.required, Validators.min(40000)]);
  
  anam = new FormControl('', []);
  comment = new FormControl('', []);
  destination = new FormControl('', []);
  ngOnInit() {
     
      this.loadData(this.inputGuid);
     
  }
  currentService = new Service()
  guidService=''
  loadData(guid : any) {
    this.guidService = guid;
    this.serviceService.getService(this.guidService).subscribe(_res => {
      this.currentService = _res;
    });

  }
 

  setFinish() {

    if (!this.price.value) {
      this.matSnackbar.open('مبلغ کرایه را وارد نمایید', null, { duration: 1500 });
      return;

    }


    if (parseInt(this.price.value) < 40000) {
      this.matSnackbar.open('مبلغ کرایه صحیح نمی باشد', null, { duration: 1500 });
      return;
    }

    //console.log('setFinish' + this.price.value)
    this.serviceService.UpdateFinishWithDestination(this.currentService.serviceGUID, this.price.value, this.anam.value, this.destination.value, this.comment.value).subscribe(
      _res => {
      this.currentService.serviceState = ServiceState.Finished;
      this.dialogRef.close(this.price.value);
    },
      error => {
        this.matSnackbar.open('اطلاعات وارد شده صحیح نمی باشد', null, { duration: 1500 });

      }
    );
  }
}

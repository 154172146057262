export const environment = {
  production: true,
  cur_u_name: 'implet_gu',
  cur_u_tkn: 'implet_tkn',
  cur_fbsToken: 'g_fbs',
  old_fbsToken: 'o_fbs',
  currentversion: 'current_versionInfo',
  cur_url: 'cur_url',
  is_created: 'is_created'


};

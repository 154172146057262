
import { Injectable } from '@angular/core';
import { HttpClient,  HttpParams} from '@angular/common/http';

import { GlobalVariable } from './../../globalVariable';
import { Observable, Subscriber } from 'rxjs';
import { ManualServiceRequest, PropsoedReport, ServiceRequest } from '../../model/ServiceRequest';
import { ResevedService } from '../../model/reservedService';

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestService {
  public moke = GlobalVariable.mokeFLAG;
  constructor(private httpClient: HttpClient) { }
  private RequestServiceBaseServiceURL = GlobalVariable.RequestServiceBaseServiceURL;
  private getMockServiceRequest() {
    var res = new ServiceRequest();
    res.destination2Addr = 'مقصد ستی';
    res.destinationAddr = 'مقصد اول تستی';
    res.destinationLat = 36.314077;
    res.destinationLng = 59.561900;
    res.destinationLat2 = 36.310619
    res.destinationLng2 = 59.646701
    res.originAddr = 'مب دا';
    res.originLat = 36.321882;
    res.originLng = 59.545666;
    res.result = 0;
    res.daleyTime = 10;
    res.distanceKM = 10;
    res.distanceMinute = 30;

    return res;
  }
  public getRequestByPropsedCode(guidRequest: any): Observable<ServiceRequest> {
    if (this.moke) {
      return new Observable<ServiceRequest>((subscriber: Subscriber<ServiceRequest>) => subscriber.next(this.getMockServiceRequest()));
    }
    let params = new HttpParams().set('guidRequest', guidRequest);
    //console.log(this.RequestServiceBaseServiceURL + "/getRequestByPropsedCode");
    return this.httpClient.post<ServiceRequest>(this.RequestServiceBaseServiceURL + "/getRequestByPropsedCode", params);
  }
  public getPropsedByDriver_Service(guidRequest: any, guidDriver: any) {
    
    let params = new HttpParams().set('guidRequest', guidRequest).set('guidDriver', guidDriver);
    return this.httpClient.post<string>(this.RequestServiceBaseServiceURL + "/getPropsedByDriver_Service", params,{ responseType: 'text' as 'json' });
  }
  public peygiri(requestID: any) {

    let params = new HttpParams().set('requestID', requestID);
    return this.httpClient.post<string>(this.RequestServiceBaseServiceURL + "/peygiri", params,{ responseType: 'text' as 'json' });
  }
  public proposedDialogClosed(guidRequest: any): Observable<boolean> {
    let params = new HttpParams().set('guidRequest', guidRequest);
    return this.httpClient.post<boolean>(this.RequestServiceBaseServiceURL + "/ProposedDialogClosed", params);
  }
  public checkActive(guidRequest: any): Observable<boolean> {
    let params = new HttpParams().set('guidRequest', guidRequest);
    return this.httpClient.post<boolean>(this.RequestServiceBaseServiceURL + "/CheckActive", params);
  }
  public getRequestService(guidRequest: any): Observable<ServiceRequest> {
    if (this.moke) {
      return new Observable<ServiceRequest>((subscriber: Subscriber<ServiceRequest>) => subscriber.next(this.getMockServiceRequest()));
    }
    let params = new HttpParams().set('guidRequest', guidRequest);
    return this.httpClient.post<ServiceRequest>(this.RequestServiceBaseServiceURL + "/getRequestService", params);
  }
  public getLastRequest(passangerID: any): Observable<ServiceRequest> {
    
    let params = new HttpParams().set('passangerID', passangerID);
    return this.httpClient.post<ServiceRequest>(this.RequestServiceBaseServiceURL + "/getLastRequest", params);
  }
  public getCurrentReserved(passangerID: any): Observable<ResevedService> {

    let params = new HttpParams().set('passangerID', passangerID);
    return this.httpClient.post<ResevedService>(this.RequestServiceBaseServiceURL + "/getCurrentReserved", params);
  }
  public getRequestServiceByID(serviceID: any): Observable<ServiceRequest> {
    let params = new HttpParams().set('serviceID', serviceID);
    return this.httpClient.post<ServiceRequest>(this.RequestServiceBaseServiceURL + "/getRequestServiceByID", params);
  }
  public saveServiceReuest(req: ServiceRequest) {
    let params = new HttpParams().set('originlat', req.originLat.toString()).set('originlng', req.originLng.toString()).set('destinationLat', req.destinationLat.toString()).set('destinationLng', req.destinationLng.toString()).set('destinationLat2', req.destinationLat2.toString()).set('destinationLng2', req.destinationLng2.toString()).set('passangerID', req.passangerID.toString()).set('destination2Addr', req.destination2Addr.toString()).set('destinationAddr', req.destinationAddr.toString()).set('originAddr', req.originAddr.toString());
 
    return this.httpClient.post<string>(this.RequestServiceBaseServiceURL + "/saverequest", params,{ responseType: 'text' as 'json' });

  }
  public getFreeService(guidDriver: any): Observable<ServiceRequest[]>  {
    //console.log('guidDriver params' + guidDriver);
    let params = new HttpParams().set('guidDriver', guidDriver);
    return this.httpClient.post<ServiceRequest[]>(this.RequestServiceBaseServiceURL + "/getFreeService",   params  );
  }

  public getListDynamic(whereCondition: string, orderByExpression: string): Observable<ServiceRequest[]> {
    let params = new HttpParams().set('whereCondition', whereCondition).set('orderByExpression', orderByExpression);
    return this.httpClient.post<ServiceRequest[]>(this.RequestServiceBaseServiceURL + "/getListDynamic", params);
  }

  public saveManualService(req: ManualServiceRequest, driverID: any, stationID: any) {
    let params = new HttpParams().set('originlat', req.originLat.toString()).set('originlng', req.originLng.toString()).set('destinationLat', req.destinationLat.toString()).set('destinationLng', req.destinationLng.toString()).set('destinationLat2', req.destinationLat2.toString()).set('destinationLng2', req.destinationLng2.toString()).set('passangerID', req.passangerID.toString()).set('destination2Addr', req.destination2Addr.toString()).set('destinationAddr', req.destinationAddr.toString()).set('originAddr', req.originAddr.toString()).set('DriverID', driverID).set('StationID', stationID);
    params = params.set('passangerName', req.passangerName);
    params = params.set('passagnerMobile', req.passangerMobile);
    params = params.set('comment', req.comment);
    params = params.set('DarEkhtiar', req.darEkhtiar.toString());

    return this.httpClient.post<string>(this.RequestServiceBaseServiceURL + "/savemanualrequest", params,{ responseType: 'text' as 'json' });

  }

  public getPropsoedReport(requestID: any): Observable<PropsoedReport[]> {
    
    let params = new HttpParams().set('RequestID', requestID);
    return this.httpClient.post<PropsoedReport[]>(this.RequestServiceBaseServiceURL + "/GetPropsoedReport", params);
  }
  public Delete(requestID: number) {
    let params = new HttpParams().set('RequestID', requestID.toString());
    return this.httpClient.post(this.RequestServiceBaseServiceURL + "/Delete", params);

  }

}

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { Map, map as LeafletMap, tileLayer, CRS, icon, LeafletMouseEvent, Icon, Marker, LatLng } from 'leaflet';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OperatorTable } from '../../model/operatorTable';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { ServiceInfo } from '../../model/serviceInfo';
import { searchResult } from '../../model/routingInfo';
import { SavedPlaceService } from '../../lib/serviceLib/savedplace.service';

import { Passanger } from '../../model/passanger';

import { ServiceRequest } from '../../model/ServiceRequest';
import { SaveServiceResult, saveservicresultType } from '../../model/saveserviceresult';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatSidenav } from '@angular/material/sidenav';
import { LastCallInfo } from '../../model/lastCallInfo';
import { Station } from '../../model/station';
import { StationQueueInfo } from '../../model/stationQueueInfo';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';

import { ShowcreatedserviceComponent } from '../../driver/showcreatedservice/showcreatedservice.component';
import { TaximapComponent } from '../../taximap/taximap.component';
import { WebSocketService } from '../../lib/web-socket.service';
import { NotificationModel, SocketMessageType } from '../../model/socketmodels';
import { MessageboxComponent } from '../../messagebox/messagebox.component';
import { DomSanitizer } from '@angular/platform-browser';

import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { InpDateTimeComponent } from '../inp-date-time/inp-date-time.component';
import { StationdetailComponent } from '../../call-center/stationdetail/stationdetail.component';
import { NotifalertserviceService } from '../../shared/notifalertservice.service';
import { CallLog } from '../../model/callLog';
import { ResevedService } from '../../model/reservedService';
import { OkmessageComponent } from '../../shared/okmessage/okmessage.component';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-new-web-call',
  templateUrl: './new-call-web.component.html',
  styleUrls: ['./new-call-web.component.scss']
})
export class NewCallWebComponent implements OnInit {
  @ViewChild("map", { static: false })
  public map: TaximapComponent = new TaximapComponent;

  dialogProposedServiceRef!: MatDialogRef<InpDateTimeComponent, any>;
  dialogStationDetailRef!: MatDialogRef<StationdetailComponent, any>;

  @ViewChild("drawer", { static: false })
  public drawer!: MatSidenav;

  operatorInfo!: OperatorTable;

  constructor(
    private callCenterService: CallCenterService,
    private taxiService: TaxiserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private currentServiceService: ServiceserviceService,
    private placeService: SavedPlaceService,
    private matSnackbar: MatSnackBar,
    private stationSerivce: StationserviceService,
    private dialog: MatDialog,
    private socketService: WebSocketService,
    private sanitizer: DomSanitizer,
    private renderer2: Renderer2,
    private requestService: ServiceRequestService,
    private notifAlert: NotifalertserviceService


  ) {
    // هر جا مثه این با سوکت کار کرد اضافه است
    this.socketService.meesageList$.subscribe(msg => {
      if (msg) {
        this.processMessage(msg);
      }
    });
  }
  // متد مربوط به زمان دریافت یه پیام سوکت بوده
  processMessage(messageBox: NotificationModel) {
    switch (messageBox.msgType) {
      case SocketMessageType.GetClientInfo:
        break;
    }

  }
  callInfo!: LastCallInfo;
  stationready: Station[] = [];

  passangerFormGroup!: FormGroup;
  callFormInsertMode = false;

  logs: ServiceInfo[] = [];
  driverStation: StationQueueInfo[] = [];
  selectedDriver!: StationQueueInfo;
  fName = new FormControl();

  telIDCaller = new FormControl('', [Validators.required]);
  stationCode = new FormControl('', [Validators.required]);
  passangerMobile = new FormControl();
  passangerFullName = new FormControl('', [Validators.required]);
  reservedUntilMinute = new FormControl();

  stationCapacity = '';
  passangerReservedCount = '';
  passangerComment = new FormControl();
  lockDriver = '';
  fNameMoshtarek = new FormControl();
  originAC = new FormControl('', [Validators.required]);
  destAC = new FormControl('');
  firstdriverCode = new FormControl();
  //firstdriverFullName = new FormControl();
  firstdriverMobile = new FormControl();
  firstdriverPelaque = new FormControl();
  serviceRequestComment = new FormControl();
  TypeCarOfCurrentDriver = new FormControl();
  workingmobilecontrol = new FormControl();
  personalMobile = new FormControl();
  posStatusControl = new FormControl();

  serviceCount = new FormControl('1');
  originMarker: Marker;
  destMarker: Marker;
  originOptions!: Observable<searchResult[]>;
  destinationOptions!: Observable<searchResult[]>;

  selectedOrigins: string[] = [];

  //این دو تا که تهش فیلتر داره برای لیست مبدا و مقصد ها هست - بعد از وارد کردن بخشی از حرف باید فیلتر بشه
  selectedDestination_Filter: string[] = [];
  selectedOrigins_Filter: string[] = [];


  value = 0;
  //این مارکر ها هم اضافه است. یکی مال مبدا بوده یکی مال مقصد الان دیگه نقشه ای نیست و مبدا و مقصد هم نیس
  originMarkerIcon: Icon = icon({
    iconSize: [40, 60],
    iconAnchor: [20, 60],
    iconUrl: 'assets/marker-origin.png',
    shadowUrl: 'assets/marker-shadow.png'
  });

  destMarkerIcon: Icon = icon({
    iconSize: [40, 60],
    iconAnchor: [20, 60],
    iconUrl: 'assets/marker-dest.png',
    shadowUrl: 'assets/marker-shadow.png'
  });

  operatorguid!: string;

// بار گزاری کردن آدرس های ذخیره شده کاربر
  loadSavedPlace(passangerID : number) {

    this.placeService.getSavedPlace(passangerID).subscribe(res => {
      if (res) {
        this.selectedOrigins = res;
        this.selectedDestination_Filter = JSON.parse(JSON.stringify(res.slice(0, 5)));
        this.selectedOrigins_Filter = JSON.parse(JSON.stringify(res.slice(0, 5)));
      }
      else {
        this.selectedOrigins = null as any;
        this.selectedDestination_Filter = null as any;
        this.selectedOrigins_Filter = null as any;
      }


    });
  }
// وقتی از بین گزینه های مبدا ئو مقضصد انتخابی روی یکی کلیک می کنه این صدا زده می شه - برای مبدا
  gotoPlace(p : any) {
    this.setOrigin(0, 0, p);
  }
// مثه بالایی برای مقصد
  gotoPlacedest(p : any) {
    this.setDestination(0, 0, p);
  }


// بعد از لاگین اپراتور کدش رو میگیره و اطلاعاتش رو لود می کنه
  loadOperatorInfo(guidCode : any): Observable<any> {
    return new Observable((observer) => {

      this.callCenterService.getOperatorInfo(guidCode).subscribe(res => {
        if (res) {
          this.operatorInfo = res;
          observer.next(res);
        } else {

          this.router.navigate(['login'], { queryParams: {} });
        }
      });
    });
  }
  stationMarker: any;
  driverMarker: Marker[] = [];


  loadStationList(stationID : any) {
    if (stationID) {
// قبلا این شکلی بود که یه ایستگاه کهوارد می شد لیست ایستگاه های همجوارش رو نشون میداد این 99 درصد الان کلا استفاده نمیشه
      this.stationSerivce.getNeighbors(stationID).subscribe(_res => {
        this.stationready = _res;

        //console.log('stationready');
        //console.log(this.stationready);
      });
      // با انتخاب شدن یه ایستگاه ما لیست کسانی که توی صف هستند رو زیرش نشون میدیم این اگر صدا زده میشه که لازمه
      this.stationSerivce.getStationQueue(stationID).subscribe(_res => {

        this.driverStation = _res;
        this.selectedDriverIndex = 0;
        if (this.driverStation) {
          this.driverStation.forEach((data) => {
            data.safeAvatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + data.avatar);
          })

          this.loadDriverByIndex();
        }
      });
      // نمیش جزییات یه ایستگاه بعد از انتخاب آن
      this.stationSerivce.getStation(stationID).subscribe(_res => {
        this.currentStation = _res;

        //console.log('currentStation' );
        //console.log( this.currentStation);
        if (_res) {
          this.stationCapacity = this.currentStation.capacity.toString();
          this.passangerReservedCount = this.currentStation.reservedCount.toString();
          if (this.stationMarker) {
            this.map.removemarker(this.stationMarker);
          }
          this.stationMarker = this.map.addStationMarker(_res);
          this.map.setView(_res.lat, _res.lng);
          //console.log(this.stationMarker);
          if (this.driverMarker && this.driverMarker.length > 0) {
            this.driverMarker.forEach((marker) => {
              this.map.removemarker(marker);
            })
          }
          this.driverMarker = [];
          this.stationSerivce.getStationQueue(_res.id).subscribe(_res => {
            let queuelist = _res;
            //console.log(queuelist);

            queuelist.forEach((data) => {
              //console.log(data);
              var marker = this.map.addDriverMarker(data.lat, data.lng, data.driverCode);
              this.driverMarker.push(marker);
            })
          });


        }
        else {
          this.matSnackbar.open('ایستگاه وارد شده صحیح نمی باشد', null, { duration: 250 });
        }


      });
    }

  }
  resetStationDetail() {
    this.driverStation = null;
    this.selectedDriverIndex = -1;
    this.selectDriver(null);
    this.stationready = null;
    this.currentStation = null;
    this.stationCapacity = '';
    this.passangerReservedCount = '';
  }
  currentStation = new Station();
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
  BackMode = '';
  ngOnInit() {

    this.gpsTest();
    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.mode = 'insert';
      this.currentIDForUpdate = -1;

      this.loadOperatorInfo(this.operatorguid).subscribe(res => {
        const mode = params['valp'];
        //console.log('mode' + mode);
        if (mode) {
          // ازین پارامتر برای زمانی که طرف میاد فرم رو برای ویرایش باز می کنه استفاده مییشه
          // توی فرم بی سیم چک کنی ازونجا مقدار میگیره
          this.BackMode = 'manage';
          //this.getCall();
          this.gotoupdate(mode);
        }

      });


    });
    this.passangerFormGroup = new FormGroup({});

    this.originLatLng = new LatLng(36.297182, 59.604767);
    //this.originOptions = this.originAC.valueChanges.pipe(
    //  // startWith(''),
    //  debounceTime(500),
    //  distinctUntilChanged(),
    //  switchMap(_val => this._getPlaces(_val))
    //);
    //this.destinationOptions = this.destAC.valueChanges.pipe(
    //  // startWith(''),
    //  debounceTime(500),
    //  distinctUntilChanged(),
    //  switchMap(_val => this._getPlaces(_val))
    //);
    this.stationCode.valueChanges.subscribe(val => {
      this.loadStationList(val);
      this.loadStationStatics(val);
    }
    );

    this._initMap();
    //this.map.setView(36.329616, 59.56543);
    //this.socketService.startSocket2();

    this.originAC.valueChanges.subscribe((value: string) => {
      // با تایپ یه حرف توی مبدا و مقصد بایستی فیلتر بشه لیست مناطق ذخیره شده
      if (!value)
        this.selectedOrigins_Filter = JSON.parse(JSON.stringify(this.selectedOrigins.slice(0, 5)));
      else {
        this.selectedOrigins_Filter = [];
        var count = 0;
        this.selectedOrigins.forEach((data) => {
          if (data.includes(value)) {
            if (this.selectedOrigins_Filter.length < 5)
              this.selectedOrigins_Filter.push(data);

          }
        })
      }

    });
    this.destAC.valueChanges.subscribe((value: string) => {
      // مثه بالایی
      if (!value) {
        this.selectedDestination_Filter = JSON.parse(JSON.stringify(this.selectedOrigins.slice(0, 5)));
      }
      else {
        this.selectedDestination_Filter = [];
        this.selectedOrigins.forEach((data) => {
          if (this.selectedOrigins_Filter.length < 5) {
            if (data.includes(value))
              this.selectedDestination_Filter.push(data);
          }
        })
      }
    });


  }
  loadStationStatics(stationID: any) {

  }

  loadPassangerReport(passangerID: number) {
    this.currentServiceService.report(passangerID).subscribe(res => {

      this.logs = res;
    });
  }
  CurrentReserved: ResevedService = null;
  // زمانی که مسافر دوباره تماس میگیره اگر سفر رزرو شده ای داشته باشه باید بهش هشدار بدیم
  // چون ممکنه قبلا رزرو کرده باشه بعد دوباره تماس میگیره اپراتور نفهمه و دوباره ثبت کنه وتکراری بشه
  // این تابع چک می کنه اگر سفر رزرو شده ای داره هشدار بده
  checkLastReserved(passangerID: any) {
    this.requestService.getCurrentReserved(passangerID).subscribe(res => {
      //console.log('reserved service is');
      //console.log(res);
      if (res) {
        this.CurrentReserved = res;
      } else
        this.CurrentReserved = null;



    });
  }

  // زمانی که تماس گرفته میشه و اپراتور وصل میشه در صورتی که قبلا سفر یا درخواست بازی داشته باشه هنوز ، یعنی سفری که هنوز راننده نگرفته یا تمام نشده
  // یا نیم ساعت ازش نگذشته همون ابتدا به اپراتور پیام داده میشه و بهش هشدار داده میشه
  checkLastService(passangerID : any) {
    this.requestService.getLastRequest(passangerID).subscribe(res => {
      if (res) {


        const confirmRef = this.dialog.open(ConfirmDialogComponent, {
          hasBackdrop: true,
          data: {
            message: 'آیا مایل به ویرایش سفر اخیر می باشید؟',
            buttonText: {
              ok: '(1) بله',
              cancel: '(2) خیر ثبت سفر جدید',
              extraButtonText: '(3) ثبت پیگیری'
            }
          }
        });

        confirmRef.afterClosed().subscribe(_confirmed => {
          if (_confirmed === 'OK') {
            this.gotoupdate(res.id);
          }
          else if (_confirmed === 'btn3') {
            //console.log(res);
            //console.log('btn3');
            this.peygiri(res.id);

          }
          else {
            //this._reset();
          }
        });

      }



    });
  }

// بعد از انتخاب آخرین تماس و برای لود شدن اطالعات مسافر
  loadPassangerInfo(passangerInfo: Passanger) {
    //console.warn(passangerInfo);

    this.checkLastService(passangerInfo.id);
    this.checkLastReserved(passangerInfo.id);

    this.loadPassangerReport(passangerInfo.id);
    /*
     * inja
    this.callFormGroup.patchValue(passangerInfo);
    */

    //this.callFormGroup.disable();
    this.loadSavedPlace(passangerInfo.id);
    this.passangerFullName.setValue(passangerInfo.fName);
    if (passangerInfo.mobile && passangerInfo.mobile.length > 9 && !passangerInfo.mobile.startsWith('051') && !passangerInfo.mobile.startsWith('51'))
      this.passangerMobile.setValue(passangerInfo.mobile);
    this.passangerComment.setValue(passangerInfo.fixedComment);
    this.lockDriver = passangerInfo.lockDriver;
    //console.warn(passangerInfo.serviceCount);
    this.passangerServiceCount = passangerInfo.serviceCount;

  }
  selectedCars = new FormControl();
  CarList = [{ value: '3', name: 'سمند' }, { name: 'پژو', value: 2 }, { value: '4', name: 'آریو' }, { name: 'برلیانس', value: 1 }, { value: '5', name: 'ون' }];

  // خواندن آخرین شماره تماس گیرنده از آی دی کالر
  getIDCaller() {
    this.passangerMobile.setValue('');
    this.fillCall(this.telIDCaller.value);
  }
//شماره مشخص شده و می باید اطلاعات کاربر از روی شماره پر بشه - ممکنه شماره دستی وارد بشه یا با زدن آخرین تماس از ای دی کالر گرفته بشه
  fillCall(mobile: any) {
    this.mode = 'insert';
    this.currentIDForUpdate = -1;
    this.callCenterService.getCallerInfoByMobile(mobile).subscribe(_res => {
      this.fillPassangerInfoFromModel(_res);

    });
  }
  getCallClick() {
    this._reset();
    //console.log('getCallClick');
    this.getCall();
  }
  passangerServiceCount = 0

  fillPassangerInfoFromModel(_res: LastCallInfo) {

    if (_res) {
      //console.warn('استعلام تماس خروجی :');
      //console.warn(_res);
      this.callInfo = _res;
      if (this.callInfo.callInfo) {
        this.telIDCaller.setValue(_res.callInfo.tel);
        //debugger
        if (_res.callInfo.tel.length > 9 && !_res.callInfo.tel.startsWith('051') && !_res.callInfo.tel.startsWith('51')) {
          this.passangerMobile.setValue(_res.callInfo.tel);
        }
        else {

          var passMobile = _res.passangerInfo ? _res.passangerInfo.mobile : '';
          if (passMobile.length >= 9 &&!passMobile.startsWith('051') && !passMobile.startsWith('51')
            )
            this.passangerMobile.setValue(passMobile);
        }
        this.passangerServiceCount = _res.passangerInfo.serviceCount;
        this.callFormInsertMode = !_res.passangerInfo;
        if (!this.callFormInsertMode && this.BackMode != 'manage') {
          this.loadPassangerInfo(_res.passangerInfo);
        }

      } else {
        this.matSnackbar.open('در حال حاضر شما تماسی ندارید.', null, { duration: 3000 });
        this.callInfo = new LastCallInfo();
        this.callInfo.callInfo = new CallLog();
        this.callInfo.passangerInfo = new Passanger();

      }
    }


  }
  getCall() {
    this.callCenterService.getCurrentCallInfo(this.operatorInfo.id).subscribe(_res => {
      this.fillPassangerInfoFromModel(_res)


    });
  }

  submitCallForm() {

  }

  //displayFn(_searchResult: searchResult): string {
  //  return _searchResult && `${_searchResult.title} - ${_searchResult.address}`;
  //}

  originLatLng: LatLng;
  destLatLng: LatLng;
// دیگه استفاده نمیشه 99 درصد
  setDestination(x: any, y: any, title: any) {
    this.destAC.setValue(title);
    this.destLatLng = new LatLng(x, y);

    //if (this.destMarker) {
    //  this.destMarker.setLatLng(this.destLatLng);
    //  this.map.setView(this.destLatLng.lat, this.destLatLng.lng);
    //} else {
    //  this.destMarker = this.map.addMarker(this.destLatLng.lat, this.destLatLng.lng, MarkerType.destination, '', true);
    //  this.map.setView(this.destLatLng.lat, this.destLatLng.lng);
    //}

  }

  setOrigin(lat: any, lng: any, title: any) {
    this.originAC.setValue(title);
    this.originLatLng = new LatLng(lat, lng);
    //////console.log('originLatLng : ');
    //console.log(this.originLatLng);


    //if (this.originMarker) {
    //  //console.log('this.originMarker is not null  ');
    //  this.originMarker.setLatLng(this.originLatLng);
    //  //console.log('then set view');
    //  this.map.setView(lat, lng);
    //} else {
    //  //console.log('else this.originMarker is null  ');
    //  this.originMarker = this.map.addMarker(this.originLatLng.lat, this.originLatLng.lng, MarkerType.origin, '', true);
    //  //console.log('else set view');
    //  this.map.setView(lat, lng);
    //}
  }

  optionSelected(ev: any) {
    //console.log(ev);
    const result: searchResult = ev.option.value;
    this.setOrigin(result.y, result.x, result.title);
    this.renderer2.selectRootElement('#passangerComment').focus();
  }

  optionSelecteddest(ev: any) {
    //console.log(ev);
    const result: searchResult = ev.option.value;
    this.setDestination(result.y, result.x, result.title);
    this.renderer2.selectRootElement('#selectedCars').focus();

  }

  private _getPlaces(value: string): Observable<searchResult[]> {
    ////console.log(value);
    return this.taxiService.searchMap(value, this.originLatLng.lat, this.originLatLng.lng);
  }

  private _getPlacesdes(value: string): Observable<searchResult[]> {
    ////console.log(value);
    return this.taxiService.searchMap(value, this.destLatLng.lat, this.destLatLng.lng);
  }

  private _initMap(): void {
    //this.map = new TaximapComponent();
    //this.map = LeafletMap('map', {
    //  crs: CRS.EPSG4326
    //});

    //tileLayer('https://bpms.mashhad.ir/favageoserver/gwc/service/tms/1.0.0/osm%3Aosm@EPSG:4326@png/{z}/{x}/{y}.png', {
    //  tms: true,
    //}).addTo(this.map);

    //this.map.setView([36.329616, 59.56543], 12);

    // add marker on click
    // this.map.on('click', (ev: LeafletMouseEvent) => {
    //   //console.log(ev);
    //   marker(ev.latlng, {
    //     icon: this.markerIcon
    //   }).addTo(this.map);
    // });
  }
  amanati = false;
  pos = false;
  maeloolin = false;
  khadamat = false;
  darEkhtiar = false;
  maledriver = true;
  femaledriver = true;


  currentIDForUpdate = -1;

  //این تابع وظیفه اعتبار سنجی قبل از ذخیره رو داره
  isValid(): boolean {
    if (!this.currentStation || !this.currentStation.id || this.currentStation.id <= 0) {
      this.matSnackbar.open('ایستگاه وارد شده صحیح نمی باشد', null, { duration: 3000 });
      return false;
    }

    if (!this.originAC.value) {
      this.matSnackbar.open('مبدا وارد نشده است', null, { duration: 3000 });
      return false;
    }
    if (!this.stationCode.value) {
      this.matSnackbar.open('کد ایستگاه صحیح نیست', null, { duration: 3000 });
      return false;
    }
    if (!this.passangerFullName.value) {
      this.matSnackbar.open('نام مسافر وارد نشده است', null, { duration: 3000 });
      return false;
    }

    if (!this.telIDCaller.value) {
      this.matSnackbar.open('استعلام تماس یا کلید ذره بین انتخاب نشده است', null, { duration: 3000 });
      return false;
    }

    var serviceCount: number = +this.serviceCount.value;
    if (serviceCount > 9) {
      this.matSnackbar.open('تعداد سرویس وارد شده صحیح نمی باشد', null, { duration: 3000 });
      return false;
    }
    return true;
  }

  date: Date = null;

  saveRequest(): Observable<SaveServiceResult> {



    //console.log('saveRequest');

    if (!this.isValid())
      return null;
    var result: Observable<SaveServiceResult>;
    var serviceCount: number = +this.serviceCount.value;




    let req = new ServiceRequest();
    if (this.mode == 'update')
      req.id = this.currentIDForUpdate;
    else
      req.id = 1;
    if (this.destLatLng) {
      req.destinationLat = this.destLatLng.lat;
      req.destinationLng = this.destLatLng.lng;
    }
    if (this.originLatLng) {
      req.originLat = this.originLatLng.lat;
      req.originLng = this.originLatLng.lng;
    }
    req.destinationAddr = this.destAC.value;
    req.originAddr = this.originAC.value;
    req.telIDCaller = this.callInfo.callInfo.tel;

    req.destination2Addr = '';
    req.destinationLat2 = 0;
    req.destinationLng2 = 0;
    req.amanati = this.amanati;
    req.pos = this.pos;
    req.maeloolin = this.maeloolin;
    req.khadamat = this.khadamat;
    req.darEkhtiar = this.darEkhtiar;
    //console.log('darEkhtiar in save method' + req.darEkhtiar);
    req.fixedComment = this.passangerComment.value;
    req.femaleDriver = this.femaledriver;
    req.maleDriver = this.maledriver;
    req.requestComment = this.serviceRequestComment.value;
    req.passangerFName = this.passangerFullName.value;
    req.passangerMobile = this.passangerMobile.value;
    req.operatorID = this.operatorInfo.id;
    if (this.selectedCars)
      req.typeCars = this.selectedCars.value;
    else
      req.typeCars = '';
    if (this.selectedDriver) {
      req.driverCode = this.selectedDriver.driverCode;
    }

    const passenger: Passanger = {
      id: this.callInfo && this.callInfo.passangerInfo && this.callInfo.passangerInfo.mobile == this.telIDCaller.value ? this.callInfo.passangerInfo.id : -1,
      fName: req.passangerFName,
      lName: req.passangerFName,
      mobile: this.passangerMobile.value,
      nationalCode: '',
      gender: false,
      fixedComment: this.passangerComment.value,
      lockDriver: '',
      serviceCount: 0,
      mobiles : ''
    };

    req.telIDCaller = this.telIDCaller.value;
    //console.log(passenger);
    //console.warn(this.callInfo);
    //console.warn(passenger);

    return this.currentServiceService.saveService(req, passenger, +this.stationCode.value, this.mode, serviceCount, this.transactionID);
  }
  transactionID = ''
  private _reset(): void {
    this.transactionID = this.makeid(36);
    //console.log(this.transactionID);
    //this.nosave = false;
    this.mode = 'insert';
    /*
     * inja
    this.callFormGroup.reset();
    */
    this.callFormInsertMode = false;
    this.stationready = [];
    this.logs = [];

    this.stationCode.reset();
    this.originAC.reset();
    this.destAC.reset();

    if (this.originMarker) {
      this.originMarker.remove();
      this.originMarker = null;
    }
    if (this.destMarker) {
      this.destMarker.remove();
      this.destMarker = null;
    }
    this.originOptions = null;
    this.destinationOptions = null;

    this.selectedOrigins = [];
    this.selectedDestination_Filter = [];
    this.selectedOrigins_Filter = [];
    this.passangerComment.setValue('');
    this.serviceRequestComment.setValue('');
    this.passangerFullName.setValue('');
    this.passangerMobile.setValue('');
    this.lockDriver = '';
    this.passangerServiceCount = 0;
    this.pos = false;
    this.maeloolin = false;
    this.darEkhtiar = false;
    this.amanati = false;
    this.khadamat = false;
    this.maledriver = true;
    this.femaledriver = true;

    this.resetStationDetail();
    //this.map.setView(36.329616, 59.56543);
    this.checkForBackToCaller(false);
    this.CurrentReserved = null;
    this.serviceCount.setValue('1');
    //console.log(this.pos);
  }
  // با زدن بعضی تیک ها مثه دراختیار و اینا باید به کامنت اضافه یا کم بشن این تابع ها برای اون کاره
  addTerm(mainvalue: any, addvalue: any) {
    if (!mainvalue)
      return addvalue;
    else {
      if (!mainvalue.includes(addvalue))
        return mainvalue + ' ' + addvalue;
      else
        return mainvalue;
    }
  }
  subTerm(mainvalue: any, addvalue: any) {
    if (mainvalue)
      return mainvalue.replace(addvalue, '');
    else
      return '';
  }

  // مرتبط با دو تا تابع بالا
  // با انتخاب گزینه ها و تیک های دراختیار و ... می بایست توی توضیحات سفر نیز متن مرتبط اضافه یا کم بشه
  valueChange(changedItem: any, $event: any) {
    //console.log('dar ekhtiar before ' + this.darEkhtiar);
    if (changedItem == 'pos')
      this.pos = $event.checked;
    else if (changedItem == 'maeloolin')
      this.maeloolin = $event.checked;
    else if (changedItem == 'darEkhtiar') {
      this.darEkhtiar = $event.checked;
      if (this.darEkhtiar) {
        this.serviceRequestComment.setValue(this.addTerm(this.serviceRequestComment.value, ' در اختیار '));
      }
      else {
        this.serviceRequestComment.setValue(this.subTerm(this.serviceRequestComment.value, ' در اختیار '));
      }
    }
    else if (changedItem == 'amanati') {
      this.amanati = $event.checked;
      if (this.amanati) {
        this.serviceRequestComment.setValue(this.addTerm(this.serviceRequestComment.value, ' امانتی '));
      }
      else {
        this.serviceRequestComment.setValue(this.subTerm(this.serviceRequestComment.value, ' امانتی '));

      }
    }
    else if (changedItem == 'femaledriver') {
      this.femaledriver = $event.checked;
    }
    else if (changedItem == 'maledriver') {
      {
        this.maledriver = $event.checked;
      }

    }
    else if (changedItem == 'khadamat') {
      this.khadamat = $event.checked;
      if (this.khadamat) {
        this.serviceRequestComment.setValue(this.addTerm(this.serviceRequestComment.value, ' خدمات '));
      }
      else {
        this.serviceRequestComment.setValue(this.subTerm(this.serviceRequestComment.value, ' خدمات '));

      }

    }
    //console.log('dar ekhtiar after ' + this.darEkhtiar);
  }

  //valueChange(changedItem, $event) {

  //  changedItem= $event.checked;
  //}
  dialogCreatedServiceRef!: MatDialogRef<ShowcreatedserviceComponent, any>;
  private showCreatedService(info: SaveServiceResult) {
    //console.warn(info.serviceGUID);

    this.dialogCreatedServiceRef = this.dialog.open(ShowcreatedserviceComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        info: info
      }
    });

  }
  lstStation!: Station[];
  loadStation() {
    this.stationSerivce.getAllStationList().subscribe(_res => {

      this.lstStation = _res;
      _res.forEach(station => {
        this.map.addStationMarker(station);

      });
    });
  }
  private setStationItemResult(stationID: any, result: saveservicresultType) {
    this.stationready.forEach(async function (station) {
      if (station.id == stationID) {
        station.serviceResult = result;
      }
    })
  }
  //nosave = false;
  saveRequestForQueue(): void {
    //console.log('saveRequestForQueue');



    //if (this.nosave) {

    //  this.matSnackbar.open('ثبت تکراری', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center" });
    //  setTimeout(() => { this.nosave=false }, 25000);
    //  return;
    //}

    if (!this.isValid())
      return;
    if (this.date) {
      var diffMs = this.date.getTime() - new Date().getTime();
      var diff = Math.abs(diffMs) < 6000
      //console.log('diff')
      //console.log(diffMs)
      //console.log(diff)

      if (diff) {
        //console.log('duplicate')

        return;
      }
    }
    this.date = new Date();
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'در حال ثبت',
        buttonText: {
          ok: '(1) متوجه شدم',
          cancel: ' '
        }
      }
    });



    //this.nosave = true;
    this.saveRequest().subscribe(res => {
      if (!res) {
          // چون هر کار می کردیم باز هم این اپراتور ها یه جوری کلیک می کردن پشت سر هم که فرم تکراری ثبت می شد
          // سمت سرور هم یه شرط هایی گذاشتیم و ممکنه ذخیره به علت تکراری بودن انجام نشه
        confirmRef.close('CancelRequest');
        const dupRef = this.dialog.open(ConfirmDialogComponent, {
          hasBackdrop: true,
          data: {
            message: 'فرم تکراری است - در فرم بی سیم چک شود',
            buttonText: {
              ok: '(1) متوجه شدم',
              cancel: ' '
            }
          }
        });
        return;
      }
      confirmRef.close('CancelRequest');

      //this.nosave = false; 
      //console.warn(res);
      this.notifAlert.setParam('savenew');
      if (this.mode == 'update') {
        this.socketService.sendMessage(res.serviceGUID, SocketMessageType.RequestServiceUpdate);
      }
      else {
        this.socketService.sendMessage(res.serviceGUID, SocketMessageType.RequestServiceCreate);
      }

      // این حالت هایی که در ادامه امده اینا برداشته شده و دیگه نیازی نیست 
      // چون بهمحض اینکه فرم ثبت میشه فرم بسته باید بشه و بره برای ثبت بعدی = قبلا در حالت انتظار می موند مثه نسخه مسافر اسنپ
      if (res.result == saveservicresultType.driverFound) {
        //console.warn('saveservicresultType.driverFound');
        this.matSnackbar.open('راننده پیدا شده و به سفر اختصاص داده شد', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
        this.showCreatedService(res);
      }
      else if (res.result == saveservicresultType.driverNotFound) {
        this.matSnackbar.open('متاسفانه راننده ای یافت نشد', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      else if (res.result == saveservicresultType.inSearchingDriver) {
        const confirmRef = this.dialog.open(ConfirmDialogComponent, {
          hasBackdrop: true,
          data: {
            message: 'با موفقیت ثبت شد',
            buttonText: {
              ok: '(1) متوجه شدم',
              cancel: ' '
            }
          }
        });



        this.matSnackbar.open('در حال پذیرش از سوی راننده', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      else if (res.result == saveservicresultType.update_lastServiceIsCancel) {
        this.matSnackbar.open('سرویس قبلی کنسل شده و سرویس جدید ثبت گردید', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      else if (res.result == saveservicresultType.update_NoChange) {
        this.matSnackbar.open('تغییرات اعمال گردید', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      else if (res.result == saveservicresultType.update_SentAlarmForDriver) {
        this.matSnackbar.open('تغییرات اعمال گردیده و در انتظار تایید راننده می باشد', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      //console.warn('station:' + station + ' -> res.result' + res.result)
      //this.setStationItemResult(station.id, res.result);

      this.checkForBackToCaller(true);
    },
      (err) => {
        confirmRef.close('CancelRequest');
        //console.log(err)
      }
    );

  }
  // وقتی از فرم بی سیم یا جای دیگه فرم رو باز می کنی برای ویرایش بعد از ذخیره باید برگردی به همون آدرس . این تابع همین رو چک می کنه
  checkForBackToCaller(resetElse: any) {
    if (this.BackMode == 'manage') {
      this.router.navigate(['cc/manage'], { queryParams: { guid: this.operatorguid } });
    }
    else if (resetElse)
      this._reset();


  }

  // با انتخاب رزرو این تابع صدا زده می شود
  // با زدن رزرو ابتدا فرآیند ذخیره صدا زده میشه
  private reservedRequestForQueue(date: any, hour: any, minute: any): void {
    //console.log('reservedRequestForQueue is called');
    this.saveRequest().subscribe(res => {
      //console.log('saveRequest result is ');
      //console.log(res);
      this.currentServiceService.reservedService(res.requestID, this.stationCode.value, date, hour).subscribe(reservedres => {
        //console.log(reservedres)
        if (reservedres > 0) {
          this.matSnackbar.open('درخواست رزرو شما با موفقیت ثبت گردید', null, { duration: 3000 });
          this._reset();
        }
      });

    });
  }
  dialogmsgBoxRef!: MatDialogRef<MessageboxComponent, any>;
  //گزینه ارسال مجدد - این گزینه برای وقتیه که شما یه درخواست زدی ویه نتجیه ای داشته اما می خای دوباره بره تو فرآیند ارسال و دوباره ارسال بشه
  resend(trip: any) {
    //console.log('resend')
    //console.log(trip)
    this.currentServiceService.ReProposedRequest(trip.requestID).subscribe(res => {
      this.socketService.sendMessage(res.serviceGUID, SocketMessageType.RequestServiceCreate);
      this.matSnackbar.open('فرم دوباره ثبت گردید', null, { duration: 3000 });
      //console.log(res);
    });




  }

  cancelTrip(requestID: any, trip: any) {

    this.dialogmsgBoxRef = this.dialog.open(MessageboxComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        msgText: 'آیا از کنسل کردن سفر اطمینان دارید؟',
        msgTitle: 'انصراف'
      }
    });
    this.dialogmsgBoxRef.afterClosed().subscribe((result : any) => {
      if (result == 'ok') {
        this.currentServiceService.cancelServiceByRequest(requestID).subscribe(res => {

        });
        if (trip)
          trip.serviceStateName = 'کنسل شده';

      }
      this.checkForBackToCaller(true);
    });

    //console.warn(trip);
  }
  peygiri(requestID: any) {
    this.requestService.peygiri(requestID).subscribe(res => {
      this.matSnackbar.open('پیام پیگیری برای راننده صادر گردید', null, { duration: 2000 });
    }
    );
  }
  selectDriver(drv: any) {
    //console.log(drv);
    this.selectedDriver = drv;
    if (this.selectedDriver) {

      this.firstdriverCode.setValue(this.selectedDriver.driverCode);
      //this.firstdriverFullName.setValue(this.selectedDriver.fName);
      this.firstdriverPelaque.setValue(this.selectedDriver.pelaque2 + this.selectedDriver.pelaqueLetter + this.selectedDriver.pelaque3 + '- ایران ' + this.selectedDriver.pelaqueIR);
      this.posStatusControl.setValue(this.selectedDriver.pos);
      if (this.selectedDriver.pos == true)
        this.posStatusControl.setValue('دارد');
      else
        this.posStatusControl.setValue('ندارد');
      this.personalMobile.setValue(this.selectedDriver.mobile);
      this.workingmobilecontrol.setValue(this.selectedDriver.workingMobile);
      this.TypeCarOfCurrentDriver.setValue(this.selectedDriver.carName + this.selectedDriver.colorName);

    }
    else {
      this.firstdriverCode.setValue('');
      //this.firstdriverFullName.setValue('');
      this.firstdriverPelaque.setValue('');
      this.posStatusControl.setValue('');
      this.personalMobile.setValue('');
      this.workingmobilecontrol.setValue('');
      this.TypeCarOfCurrentDriver.setValue('');
    }
  }
  loadDriverByIndex() {
    this.selectDriver(this.driverStation[this.selectedDriverIndex]);
  }
  selectedDriverIndex = 0;

  // مطابق متد پایینی
  nextDriver() {
    //console.log(this.driverStation.length);
    this.selectedDriverIndex = this.selectedDriverIndex + 1;
    if (this.selectedDriverIndex > this.driverStation.length)
      this.selectedDriverIndex = 0;
    this.loadDriverByIndex();
  }

  // لیست راننده های ایستگاه گزینه بعد و قبل داره این برای قبلیه
  predDriver() {
    //console.log(this.driverStation.length);
    this.selectedDriverIndex = this.selectedDriverIndex - 1;
    if (this.selectedDriverIndex < 0)
      this.selectedDriverIndex = 0;
    this.loadDriverByIndex();
  }

  handleShortCut(event: KeyboardEvent) {

    //console.log(' event key is :');

    //console.log(event);


    if ((event.which == 13 || event.keyCode == 13)) {
      //console.log('enter pressed');
      this.forwardNext(event.srcElement);
      event.preventDefault();
    }
    else if (event.key === 'F1') {
      this.getCall();
      event.preventDefault();
    }
    else if (event.key === 'F2') {
      this.renderer2.selectRootElement('#txttellIDCaller').focus();
      event.preventDefault();
    }
    else if (event.key === 'Escape') {
      //console.log('ESC');
      event.preventDefault();
    }
    else if (event.key === 'F8') {
      //console.log('F8');
      event.preventDefault();
    }
    else if (event.key === 'F5') {
      this.renderer2.selectRootElement('#passangerMobile').focus();
      event.preventDefault();
    }

    else if (event.key === 'F3') {
      this.renderer2.selectRootElement('#originAC').focus();
      event.preventDefault();
    }
    else if (event.key === 'F7') {
      this.renderer2.selectRootElement('#firstdriverCode').focus();
      event.preventDefault();
    }
    else if (event.key === 'F6') {
      this.renderer2.selectRootElement('#stationCode').focus();
      event.preventDefault();
    }
    else if (event.altKey) { // alt

      //console.log('alt key');
      if (event.key === 'r') {
        event.preventDefault();
        this.showReserved();
      }
    }
    else if (event.ctrlKey) { // ctrl



      if (event.key === '1') {
        //console.log('ctrl+1');
        event.preventDefault();
      }
      else if (event.key === '5') {
        //console.log('ctrl+5');
        event.preventDefault();
      }
      else if (event.key === '6') {
        //console.log('ctrl+6');
        event.preventDefault();
      }
      else if ((['h', 'H', 'ا'].indexOf(event.key) > -1)) {
        //console.log('ctrl+H');
        event.preventDefault();
      }
      else if (event.key === 'x') {
        //console.log('ctrl+X');
        event.preventDefault();
      }
      else if (event.key === 'q') {
        //console.log('ctrl+Q');
        event.preventDefault();
      }
      else if ((['e', 'E', 'ث'].indexOf(event.key) > -1)) {
        //console.log('ctrl+E');
        event.preventDefault();
        //this.saveRequestClickDebounce();


      }
    }
    else {
      //console.log(event);
    }
  }


  okdialogRef!: MatDialogRef<OkmessageComponent, any>;

  showStationDetails() {
    this.dialogStationDetailRef = this.dialog.open(StationdetailComponent, {
      width: '1200px',
      hasBackdrop: true,
      data: {
        stationID: this.stationCode.value
      },
      autoFocus: true,
    });

    //this.stationdet.loadData(this.stationCode.value);
    //this.drawer.toggle()

  }
  gpsTest() {
    //console.log('gpstest');

    navigator.geolocation.getCurrentPosition((position) => {
      //console.log(position);
      //console.log(`position in watch getCurrentPosition lat is ${position.coords.latitude} , longitude is ${position.coords.longitude}`);
    });
    navigator.geolocation.watchPosition((position) => {
      //console.log(`position in watch position lat is ${position.coords.latitude} , longitude is ${position.coords.longitude}`);
    });
  }

  onStationKeydown(event: any) {
    //زمانی که در تکست باکس کد ایتسگاه اسپیس می زته لیست جزییات ایستگاه نمایش داده میشه
    if (event.key === " ") {
      event.preventDefault();
      if (this.stationCode.value)
        this.showStationDetails();
    }
    //console.log('onStationKeydown');
    //console.log(event);
  }
  // وقتی رزرو رو انتخاب می کنه یه مودال باز میشه که توش ساعت و تاریخ میگیره . این تابع این کارو می کنه ولی کلا باید عوض بشه
  showReserved() {
    if (!this.isValid())
      return;
    this.dialogProposedServiceRef = this.dialog.open(InpDateTimeComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        guidRequest: ''
      }
    });
    this.dialogProposedServiceRef.afterClosed().subscribe((result : any) => {
      //console.log('result.date' + result.date);
      //console.log(result);
      //console.log(result.date);

      if (result.Action == 'Save') {
        //console.log('saved call');
        this.reservedRequestForQueue(result.date.value.toString(), result.hour, result.minute);
      } else if (result.Action == 'Cancel') {

      }
    });
  }

// برای وقتی که اینتر می زنه میخاد بره کنترل بعدی استفاده شده
  forwardNext(element: any) {

    var nextControlDic: { [id: string]: string; } = {};
    nextControlDic["txttellIDCaller"] = "passangerFullName";
    nextControlDic["passangerFullName"] = "passangerMobile";
    nextControlDic["passangerMobile"] = "originAC";
    nextControlDic["originAC"] = "passangerComment";
    nextControlDic["passangerComment"] = "serviceRequestComment";
    nextControlDic["serviceRequestComment"] = "destAC";
    nextControlDic["destAC"] = "selectedCars";
    nextControlDic["selectedCars"] = "stationCode";
    nextControlDic["stationCode"] = "firstdriverCode";
    nextControlDic["firstdriverCode"] = "TypeCarOfCurrentDriver";
    nextControlDic["TypeCarOfCurrentDriver"] = "workingmobilecontrol";
    nextControlDic["workingmobilecontrol"] = "personalMobile";
    nextControlDic["personalMobile"] = "posStatusControl";
    nextControlDic["posStatusControl"] = "firstdriverPelaque";
    nextControlDic["firstdriverPelaque"] = "serviceCount";
    nextControlDic["serviceCount"] = "txttellIDCaller";



    var related = nextControlDic[element.id];
    if (related) {
      //console.log('realted is ' + related)
      this.renderer2.selectRootElement('#' + related).focus();
    }
    else {
      //console.log('relates is null');
      this.renderer2.selectRootElement('#txttellIDCaller').focus();
    }
  }
  mode = '';
  makeid(length: any) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

// یعنی این آی دی رو برای اپدیت باز کن
  gotoupdate(requestID: any) {


    //console.log(requestID);
    this.requestService.getRequestServiceByID(requestID).subscribe(res => {
      //console.log('getRequestServiceByID out');
      //console.log(res);
      this.mode = 'update';
      this.callInfo = new LastCallInfo();
      this.callInfo.callInfo = new CallLog();
      this.callInfo.passangerInfo = new Passanger();
      this.callInfo.callInfo.tel = res.telIDCaller;
      this.callInfo.callInfo.tel = res.passangerMobile;



      this.currentIDForUpdate = requestID;
      this.passangerFullName.setValue(res.passangerFName);
      this.telIDCaller.setValue(res.telIDCaller);
      this.passangerMobile.setValue(res.passangerMobile);
      this.lockDriver = '';
      this.passangerServiceCount = (res.passangerServiceCount);
      this.stationCode.setValue(res.selectedStationID.toString());
      this.stationCapacity = '';
      this.passangerReservedCount = '';
      this.originAC.setValue(res.originAddr);
      this.destAC.setValue(res.destinationAddr);
      this.passangerComment.setValue(res.fixedComment);
      this.serviceRequestComment.setValue(res.requestComment);
      this.pos = res.pos;
      this.maeloolin = res.maeloolin;
      this.darEkhtiar = res.darEkhtiar;
      this.amanati = res.amanati;
      this.khadamat = res.khadamat;
      this.maledriver = res.maleDriver;
      this.femaledriver = res.femaleDriver;
      if (res.typeCars)
        this.selectedCars.setValue(res.typeCars.split(','));
      else
        this.selectedCars.setValue('');

    });


   
  }
  // ازین برای این استفاده شد که یارو مثه خر روی گزینه ذخیره کلیک می کرد پشت سر هم این میگه توی سیصد میلی ثانیه فقط یه بار فایر بشه
   debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  };
  counter = 0;
  saverequestLog() {
    this.counter++
    this.saveRequestForQueue();
  }
  saveRequestClickDebounce = this.debounce(this.saverequestLog, 1000);
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';
import { TripsComponent } from '../trips/trips.component';
import { DriverStatus, IDriverStatusMeta } from '../../lib/enums/driver-status.enum';

import { Driver } from '../../model/driver';
import { Station } from '../../model/station';
import { StationQueueInfo } from '../../model/stationQueueInfo';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { StationMemberComponent } from '../station-member/station-member.component';
import { MessageboxComponent } from '../../messagebox/messagebox.component';

@Component({
  selector: 'app-bottomsheetinfo',
  templateUrl: './bottomsheetinfo.component.html',
  styleUrls: ['./bottomsheetinfo.component.scss']
})
export class BottomsheetinfoComponent implements OnInit {
  currentStation = new Station();
  dialogStationMemberRef: MatDialogRef<StationMemberComponent, any>;
  dialogStationTripRef: MatDialogRef<TripsComponent, any>;
  
  currentPriority = '---';
  driverInfo = new Driver();
  InRange = true;
  constructor(private sanitizer: DomSanitizer,private stationSerivce: StationserviceService,private dialog: MatDialog,private _bottomSheetRef: MatBottomSheetRef<BottomsheetinfoComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    //console.warn(data);
    this.currentStation = data.stationInfo;
    this.driverInfo = data.driverInfo;
    this.InRange = data.InRange;
    this.stationSerivce.getStation(this.currentStation.id).subscribe(_res => {
      this.currentStation = _res;
    });


  }
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  ngOnInit() {
    //console.log('this.driverInfo.currentStation at bottom ->  ' + this.driverInfo.currentStation);
  }
  queuelist: StationQueueInfo[];
  showStationMember() {
    //console.log('this.currentStation.id');
    //console.log(this.currentStation.id);
    this.stationSerivce.getStationQueue(this.currentStation.id).subscribe(_res => {
      this.queuelist = _res;
      this.queuelist.forEach((data) => {
        data.safeAvatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + data.avatar);
      })
      this.dialogStationMemberRef = this.dialog.open(StationMemberComponent, {
        width: '400px',
        hasBackdrop: true,
        data: {
          stationID: this.currentStation.id,
          queue: this.queuelist

        }
      });
      this.dialogStationMemberRef.afterClosed().subscribe((result : any) => {
        if (result == 'removequeue') {
          this.removeQueue();
        }
      });


    });

    
  }
  showStationTrip() {

    this.dialogStationTripRef = this.dialog.open(TripsComponent, {
        width: '400px',
        hasBackdrop: true,
        data: {
          stationID: this.currentStation.id

        }
      });

 
  }
  addToQueue() {
    if (this.InRange) {
      if (this.driverInfo.currentStation > 0 && this.driverInfo.currentStation != 100 && this.driverInfo.currentStation != this.currentStation.id) {
        this.dialogmsgBoxRef = this.dialog.open(MessageboxComponent, {
          width: '400px',
          hasBackdrop: true,
          data: {
            msgText: 'در صورت پیوستن به این صف نوبت شما در صف قبلی از بین خواهد رفت - آیا مایل به ادامه می باشید؟',
            msgTitle: 'خروج از صف'
          }
        });
        this.dialogmsgBoxRef.afterClosed().subscribe((result : any) => {
          if (result == 'ok') {
            this.addQeueMethod();
          }
        });
      }
      else {
        this.addQeueMethod();
      }

    }
    else {
      this._bottomSheetRef.dismiss('addToQueue');
    }
  }
  addQeueMethod() {
    {
      //console.log('addQeueMethod bottomsheet');
      //this.stationSerivce.addToQueue(this.currentStation.id, this.driverInfo.guid).subscribe(_res => {
      //  console.warn('priority :' + _res);
      //  this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(DriverStatus.WaitingOnStation);
      //  this.currentPriority = _res;
      //  this.driverInfo.currentStation = this.currentStation.id;
        this._bottomSheetRef.dismiss('addToQueue');

      //});
    }
    
    }
  dialogmsgBoxRef: MatDialogRef<MessageboxComponent, any>;
  exitQueue() {
    this.dialogmsgBoxRef = this.dialog.open(MessageboxComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        msgText: 'درصورت خروج از صف نوبت شما از بین خواهد رفت. آیا مایل به خروج می باشید؟',
        msgTitle: 'خروج از صف'
      }
    });
    this.dialogmsgBoxRef.afterClosed().subscribe((result : any) => {
      if (result == 'ok') {
        this.removeQueue();
      }
    });

   
    

  }
  removeQueue() {
    //console.log('removeQueue on bottom');
    this.stationSerivce.removeFromQueue(this.currentStation.id, this.driverInfo.guid).subscribe(_res => {
      this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(DriverStatus.Station100);
      this.currentPriority = '---';
      this._bottomSheetRef.dismiss('exitQueue');
    });
  }

}

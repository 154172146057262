import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalVariable } from '../../globalVariable';
import { Observable } from 'rxjs';
import { Dashboard } from '../../model/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private httpClient: HttpClient

  ) { }


  public getDashboard(operatorGUID: string): Observable<Dashboard> {
  
    let params = new HttpParams().set('operatorGUID', operatorGUID);

    return this.httpClient.post<Dashboard>(GlobalVariable.DashboardBaseServiceURL + "/GetDashboard", params);
  }



  public writeLog(log: string): Observable<boolean> {

    let params = new HttpParams().set('log', log);
    

      return this.httpClient.post<boolean>(GlobalVariable.DashboardBaseServiceURL + "/LogToFile", params);


  }


}


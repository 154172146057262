import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from '@circlon/angular-tree-component';
import { AccServiceService } from '../../lib/serviceLib/accService.service';
import { Acc_Acc } from '../../model/accModels';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormMode } from '../../lib/enums/form-mode.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-manage-acc',
  templateUrl: './manage-acc.component.html',
  styleUrls: ['./manage-acc.component.css']
})
export class ManageAccComponent implements OnInit {

  @ViewChild('operatorDialog', { static: false }) operatorDialogTemplateRef: TemplateRef<any>;
  operatorDialogRef: MatDialogRef<any>;

  pageTitle = 'هزینه';
  currentFormMode: FormMode;
  operatorFromGroup: FormGroup;
  currentEditingOperator: Partial<Acc_Acc>;

  columns = [];
  options: ITreeOptions = {
    displayField: 'accName',
    rtl: true,

    idField: 'id',
    hasChildrenField: 'hasChildren',
    actionMapping: {
      mouse: {
        dblClick: (tree, node, $event) => {
          this.selectNode(tree, node, $event);
        }
      },
      keys: {
        [KEYS.ENTER]: (tree, node, $event) => {
          node.expandAll();
        }
      }
    },
    nodeHeight: 23,
    allowDrag: (node) => {
      return true;
    },
    allowDrop: (node) => {
      return true;
    },
    allowDragoverStyling: true,
    levelPadding: 150,
    useVirtualScroll: true,
    animateExpand: true,
    scrollOnActivate: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    scrollContainer: document.documentElement // HTML
  }

  constructor(
    private accService: AccServiceService,
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private dialog: MatDialog,

  ) { }
  nodes: Partial<Acc_Acc>[];
  ngOnInit(): void {
    this.nodes = [
      {
        id: 0,
        accName: '---',
        hasChildren: false,

        children: [],
        parentID: 0,
        comment: '',
        sysRecord: false,
        value1: '',
        value2: ''
      }
    ]
    this.accService.getAccList(-1).subscribe(res => {
      this.nodes = res;
    })

    this.operatorFromGroup = new FormGroup({
      accName: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
      
    });

  }
  get FormMode() {
    return FormMode;
  }
  selectedData: Acc_Acc;
  selectNode(tree, node, $event) {
    //console.log(tree);
    console.log(node.data);
    this.selectedData = node.data;

    if (node.data.id <= 0) {
      this.openOperatorDialog(FormMode.Add)
    }
    else {
      this.openOperatorDialog(FormMode.Edit, this.selectedData)

    }
    
    //console.log($event);
  }
  openOperatorDialog(_mode: FormMode, _data?: Partial<Acc_Acc>) {
    console.log(_data);
    this.currentFormMode = _mode;
    this.operatorFromGroup.reset();

    if (_mode === FormMode.Edit && _data) {

      this.currentEditingOperator = _data;
      this.operatorFromGroup.patchValue(this.currentEditingOperator);
    }

    this.operatorDialogRef = this.matDialog.open(this.operatorDialogTemplateRef, {
      hasBackdrop: true,
      width: '360px'
    });
  }
  submit() {
    if (this.operatorFromGroup.valid) {
      const formValues: Partial<Acc_Acc> = this.operatorFromGroup.value;

      if (this.currentFormMode === FormMode.Add) {
        formValues.parentID = this.selectedData.parentID;
        this.accService.insert(formValues).subscribe(_id => {
          formValues.id = _id;
          this.nodes.push(formValues);
          this.matSnackbar.open(' درج با موفقیت افزوده شد.', null, { duration: 5000 });
        });

      } else if (this.currentFormMode === FormMode.Edit) {
        
        formValues.id = this.selectedData.id;
       

        this.accService.update(formValues).subscribe(_id => {
          this.matSnackbar.open('ویرایش  با موفقیت انجام شد.', null, { duration: 5000 });
        });  
      }

      this.operatorDialogRef.close();
    }
  }
  update(node) {
    this.selectedData = node.data;
    this.openOperatorDialog(FormMode.Edit, node.data)
  }
  delete(node) {
    console.log(node)
    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید  «' + node.data['accName'] + '» حذف شود؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });

    confirmRef.afterClosed().subscribe(_confirmed => {
      //if (_confirmed) {
      //  this.baseIService.delete(_data.id).subscribe(_res => {
      //    const index = this.operators.findIndex(_item => _item.id === _data.id);
      //    if (index > -1) {
      //      this.operators.splice(index, 1);
      //    }
      //    this.matSnackbar.open('رکورد با شناسه ' + _data.id + ' حذف شد.', null, { duration: 5000 });
      //  });
      //}
    });
  }
  /*
  deleteNode(node, tree) {
    const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
    parentNode.data.children = parentNode.data.children.filter(child => {
      return child !== node.data;
    });

    tree.treeModel.update();
    if (node && node.parent && node.parent.data && node.parent.data.children.length === 0) {
      node.parent.data.hasChildren = false;
    }

    if (this.selectedNode?.id === node.data.id) {
      this.selectedNode = null;
    }
  }
  */
}

import { CallLog } from "./callLog";
import { ErrorOutput } from "./enumtype";
import { Passanger } from "./passanger";

export class LastCallInfo {
  callInfo: CallLog;
  passangerInfo: Passanger;
  error: ErrorOutput  ;
}
 

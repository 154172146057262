import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceInfo } from '../../model/serviceInfo';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { FinishserviceComponent } from '../finishservice/finishservice.component';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-mytrips',
  templateUrl: './mytrips.component.html',
  styleUrls: ['./mytrips.component.scss']
})
export class MytripsComponent implements OnInit {
  driverID = 0;
  list: ServiceInfo[] = [];

  constructor(
    private dialog: MatDialog,
    private matSnackbar: MatSnackBar,

    public serviceService: ServiceserviceService,
    public dialogRef: MatDialogRef<MytripsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { driverID: number }
  ) {
    this.driverID = data.driverID;
    this.serviceService.reportDriver(this.driverID).subscribe(res => {
      this.list = res;
      //this.list.forEach((data) => {
      //  data.safeAvatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + data.avatar);
      //})

    });
  }
  close() {
    this.dialogRef.close('closse');

  }
  updatePrice(row, price): void {
    console.log(row?.id);
    if (!row?.updatablePrice) {
      this.matSnackbar.open('مهلت ویرایش مبلغ به اتمام رسیده است. ', null, { duration: 2000 });

      return;
    }
    const dialogRef = this.dialog.open(InputtextComponent, {
      data: { title: 'ویرایش کرایه', msg: 'کرایه به تومان', fieldName: 'کرایه', value: price , maxValue:40000},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result && parseInt(result)>=40000)
        this.serviceService.UpdatePrice(row.id, result).subscribe(_res => {
          row.price = result;
        });
      console.log('The dialog was closed');
      console.log(result);
    });
  }

  finishedService(q: ServiceInfo) {
    this.updatePrice(q, q.price);
    
    /*
    var dialogfinishRef = this.dialog.open(FinishserviceComponent, {
      width: '400px',
      data: {
        guid: q.serviceGuid
      }
    });
    dialogfinishRef.afterClosed().subscribe((newprice) => {
      console.log('newprice'+newprice);
      if (newprice && newprice>0)
        q.price = newprice;
      else
        return 0
    });
     */

  }
  ngOnInit() {
  }
  checkForPrice(q: ServiceInfo) {
    console.log(q);
    if (!q.price && parseInt(q.price) <= 0) {
      this.finishedService(q);
     
    
    }

  }
}

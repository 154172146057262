import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IKarkardReport, IServiceReport } from '../../lib/interfaces/iServiceReport';
import { ReportService } from '../../lib/serviceLib/report.service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';
import { InputselectItemComponent } from '../../shared/inputselectItem/inputselectItem.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { Driver } from '../../model/driver';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { SummaryinfoComponent } from '../../driver/summaryinfo/summaryinfo.component';

@Component({
  selector: 'app-report-karkard',
  templateUrl: './report-karkard.component.html',
  styleUrls: ['./report-karkard.component.css']
})
export class ReportKarKardComponent implements OnInit {
  @ViewChild(SummaryinfoComponent) child: SummaryinfoComponent;

  totalprice = 0;
  totalcommission = 0;
  totalbedehkari = 0;
  totalbestankari = 0;
  driverID = 1;
  mande = 0;
  driveracc: { [id: number]: number; } = {};
  driverInfo: Driver;
  count = 0;
  selectedDriver = new FormControl('');
  options: string[] = [];
  driverList: Driver[] = [];
  filteredOptions: Observable<string[]>;

  operatorguid = '';
  constructor(private repSrv: ReportService, public dialog: MatDialog,
    private serviceService: ServiceserviceService,
    private drvSservice: DriverserviceService,
    private route: ActivatedRoute,
    private router: Router,

  ) {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.driverID = params['gid'];

    });
  }
  reports: IKarkardReport[];
  ngOnInit(
  ): void {
    if(this.driverID > 0)
      this.report();

  
 this.options = [];
    this.drvSservice.getAllDriver().subscribe(res => {
  this.driverList = res;
  res.forEach(t => {
    this.options.push(this.displayDriver(t));
  });
});

this.filteredOptions = this.selectedDriver.valueChanges.pipe(
  startWith(''),
  map(value => this._filter(value.toString() || '')),
);

  }
  displayDriver(drv: Driver) {
    this.driverID = drv.id;
  return drv.driverCode + ' - ' + drv.fName;
}
  private _filter(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.options.filter(option => option.toLowerCase().includes(filterValue));
}
selectOption(e: MatAutocompleteSelectedEvent) {
  console.log(e);
}



calctotal() {
  this.totalprice = 0;
  this.totalcommission = 0;
  this.totalbedehkari = 0;
  this.totalbestankari = 0;

  this.count = this.reports?.length ?? 0;
  var isFirst = 0;
  var nextMande = 0;
  this.reports.forEach(t => {

    if (isFirst == 0) {
      isFirst = 1;
      t.mande = this.driverInfo.mande;
      nextMande = t.mande;
    }
    else {
      t.mande = nextMande;

    }
    if (!this.driveracc[t.driverId]) {
      this.driveracc[t.driverId] = 0;
    }

    nextMande = nextMande + t.bedehkari;
    nextMande = nextMande - t.bestankari;

    //this.driveracc[t.driverId] = this.driveracc[t.driverId] + t.bestankari;
    //t.mande = this.driveracc[t.driverId];


    if (!t.anam) {
      t.anam = 0;
    }


    if (t.price) {
      let prc: number = parseInt(t.price);
      if (prc > 0)
        this.totalprice += prc;

      this.totalcommission += t.commission;
      this.totalbedehkari += t.bedehkari;
      this.totalbestankari += t.bestankari;


    }
    else
      t.price = '0';
    if (!t.typePardakhtName)
      t.typePardakhtName = '---';

  });
  this.mande = this.driverInfo.mande;
}

  report() {
    if (this.selectedDriver.value) {
      var driverObject: Driver;
      this.driverList.forEach(t => {
        console.log(this.displayDriver(t));
        if (this.selectedDriver.value == this.displayDriver(t)) {
          driverObject = t;
        }
      }
      );
      this.driverID = driverObject?.id;
    }
  this.drvSservice.getDriverWithoutFBSToken(this.driverID).subscribe(t => {
    this.child.report();
    console.log(t);
    this.driverInfo = t.info;
  });

  this.repSrv.KarkardReport(this.driverID).subscribe(t => {
    this.reports = t;
    console.log(t);
    this.calctotal();
  });
}
openDialog(row, defValue, mode): void {
  console.log(row?.serviceID);
  if(mode == 'destination') {
  const dialogRef = this.dialog.open(InputtextComponent, {
    data: { title: 'ویرایش مقصد', msg: 'مقصد  را وارد نمایید', fieldName: 'مقصد', value: defValue },
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
    if (result)
      this.serviceService.UpdateDestination(row.serviceID, result).subscribe(_res => {
        row.destinationAddr = result;
      });
    console.log('The dialog was closed');
    console.log(result);
  });
}
    else if (mode == 'requestComment') {
  const dialogRef = this.dialog.open(InputtextComponent, {
    data: { title: 'ویرایش توضیحات', msg: 'توضیحات  را وارد نمایید', fieldName: 'توضیحات', value: defValue },
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
    if (result)
      this.serviceService.UpdateRequestComment(row.serviceID, result).subscribe(_res => {
        row.requestComment = result;
      });
    console.log('The dialog was closed');
    console.log(result);
  });
}
else if (mode == 'typePardakhtName') {
  const dialogRef = this.dialog.open(InputselectItemComponent, {
    data: {
      title: 'نوع پرداخت', msg: 'نوع پرداخت را انتخاب نمایید', fieldName: 'نوع پرداخت', value: defValue,
      lst: [
        { id: 1019, name: 'نقدی' },
        { id: 1020, name: 'پیش پرداخت' },
        { id: 1021, name: 'اعتباری' }
      ]
    },
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
    if (result)
      this.serviceService.UpdateTypePardakht(row.serviceID, result).subscribe(_res => {
        if (result == '1019')
          row.typePardakhtName = 'نقدی';
        else if (result == '1020')
          row.typePardakhtName = 'پیش پرداخت';
        else if (result == '1021')
          row.typePardakhtName = 'اعتباری';

      });
    console.log('The dialog was closed');
    console.log(result);
  });
}
else if (mode == 'anam') {
  const dialogRef = this.dialog.open(InputtextComponent, {
    data: { title: 'ویرایش انعام', msg: 'مقدار انعام را وارد نمایید', fieldName: 'انعام', value: defValue },
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
    if (result)
      this.serviceService.UpdateAnam(row.serviceID, result).subscribe(_res => {
        row.anam = result;

        this.report();
      });
    console.log('The dialog was closed');
    console.log(result);
  });
}
else {
  const dialogRef = this.dialog.open(InputtextComponent, {
    data: { title: 'ویرایش کرایه', msg: 'مقدار کرایه را وارد نمایید', fieldName: 'کرایه', value: defValue, maxValue: 40000 },
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
    if (result)
      this.serviceService.UpdatePrice(row.serviceID, result).subscribe(_res => {
        row.price = result;

        this.report();
      });
    console.log('The dialog was closed');
    console.log(result);
  });
}



    


  }
updateDebit(drvID) {
  this.router.navigate(['cc/debits'], { queryParams: { guid: this.operatorguid, refID: drvID } });
}
}

//import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';

@Component({
  selector: 'app-login-shahreman',
  templateUrl: './login-shahreman.component.html',
  styleUrls: ['./login-shahreman.component.scss']
})

export class LoginShahremanComponent implements OnInit {
  checkForUpdat() {
    this.swUpdate.available.subscribe(event => {

      
      location.reload();


    });
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private driverService: DriverserviceService,
    private swUpdate: SwUpdate
  ) { }

  ngOnInit() {
    this.checkForUpdat();
    this.route.queryParams.subscribe(params => {
      this.loadData(params['uid'],1);
    });
    this.url = this.router.url;


  }
  errorShow = false;
  checkagain() {
    this.loadData(this.currentuid,1);
  }

  url = '';
  currentuid = '';
  schedulJon: any;
  loadData(uid: any, counter: any) {
    this.currentuid = uid;
    this.driverService.handleShahremanToken(uid).subscribe(res => {
      if (res) {
        this.errorShow = false;
        if (res.errorCode == 0)
          // this.router.navigate(['/driver/verify'], { queryParams: { guidS: res.tempGuid, guidD: res.driverGUID } });
          this.router.navigate(['/driver/confirm'], { queryParams: { guid: res.tempGuid } });
        else
          this.router.navigate(['/driver/new'], { queryParams: {} });
      }
      else if (counter < 5) {
        this.errorShow = true;
        this.schedulJon = setTimeout(() => {
          this.loadData(uid, counter+1)
        }, 10 * 1000);
      }
    }
      );
  

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageboxComponent } from '../../messagebox/messagebox.component';
import { ServiceRequest } from '../../model/ServiceRequest';
import { StationQueueInfo } from '../../model/stationQueueInfo';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';


@Component({
  selector: 'app-station-member',
  templateUrl: './station-member.component.html',
  styleUrls: ['./station-member.component.scss']
})
export class StationMemberComponent implements OnInit {
  queueList: StationQueueInfo[];
  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<StationMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { stationID: number, queue:StationQueueInfo[]}
  ) {
    this.queueList = data.queue;
    //console.warn(this.queueList);
  }


  ngOnInit() {

  }

  

 
  dialogmsgBoxRef: MatDialogRef<MessageboxComponent, any>;
  removeqyeye() {
    this.dialogmsgBoxRef = this.dialog.open(MessageboxComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        msgText: 'در صورت پیوستن به این صف نوبت شما در صف قبلی از بین خواهد رفت - آیا مایل به ادامه می باشید؟',
        msgTitle: 'خروج از صف'
      }
    });
    this.dialogmsgBoxRef.afterClosed().subscribe((result : any) => {
      if (result == 'ok') {
        this.dialogRef.close('removequeue');
      }
    });
  }

  close() {
    this.dialogRef.close('close');
  }
}

import { Component,  Inject, OnInit, Renderer2} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { saveservicresultType } from '../../model/saveserviceresult';
import { ServiceRequest } from '../../model/ServiceRequest';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';


@Component({
  selector: 'app-setmanualdriver',
  templateUrl: './setmanualdriver.component.html',
  styleUrls: ['./setmanualdriver.component.scss']
})
export class SetmanualdriverComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<SetmanualdriverComponent>,
    private route: ActivatedRoute,
    private renderer2: Renderer2,
    private taxiSerivce: ServiceRequestService,
    @Inject(MAT_DIALOG_DATA) public data: { guidRequest: string, actionType:number },
    private currentServiceService: ServiceserviceService,
    private drvService: DriverserviceService,
    private matSnackbar: MatSnackBar,
  ) {
    this.actionType = this.data.actionType;
    if (this.actionType === 2)
      this.actionTypeName = 'ارسال پیشنهاد'
    else
      this.actionTypeName = 'تخصیص'

  }

  ngOnInit() {
    //console.log(this.data);
    //this.renderer2.selectRootElement('#driverCode').focus();
    this.loadRequestInfo(this.data.guidRequest);
    
    //if (this.driverCodeInput)
    //  this.driverCodeInput.focus();
  }
  currentRequest: ServiceRequest = new ServiceRequest();
  loadRequestInfo(guid: any) {
    this.taxiSerivce.getRequestService(guid).subscribe(_res => {
      this.currentRequest = _res;
      //console.log(_res);
    });
  }
  driverCode = '';
  driverName = '';
  actionType = 1
  actionTypeName='تخصیص'
  accept() {
    //console.log('accept');
    this.currentServiceService.saveServiceManualByAdmin(this.data.guidRequest, this.driverCode, this.data.actionType).subscribe(res => {
      if (res.result == saveservicresultType.driverNotFound) {
        this.matSnackbar.open('سفر دیگری دارد.', "", { duration: 3000 });
      }
      else
      this.dialogRef.close('Accept');


      //console.log('accept finish'+res);
    });
    
  }

  reject() {
    this.dialogRef.close('Reject');
  }
  onBlurDriverCode(event: KeyboardEvent) {

    if (this.driverCode && this.driverCode != '') {
        this.drvService.getDriverByCode(this.driverCode).subscribe(res => { this.driverName = res; })
    }
    else {
      this.driverName = '';
    }
    
  }
  firstEnter = true;

  handleShortCut(event: KeyboardEvent) {
    if ((event.which == 13 || event.keyCode == 13)) {
      //console.log('enter pressed');
      if (this.firstEnter) {
        this.onBlurDriverCode(event);
        this.firstEnter = false;
      }
      else {
        this.accept();
        this.firstEnter = true;

      }
      event.preventDefault();
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import {
  MatExpansionModule,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { UiLoaderComponent } from "./shared/materials/ui-loader.component";
import { CheckboxComponent } from "./shared/materials/checkbox.component";
import { DataTableComponent } from "./shared/materials/data-table.component";
import { PelqueComponent } from "./shared/materials/plaque.component";

import { DropdownComponent } from "./shared/materials/dropdown.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { DateAdapter } from '@angular/material/core';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

import { NewComponent } from './driver/new/new.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { ConfirmInfoComponent } from './driver/confirm-info/confirm-info.component';
import { VerifysmsComponent } from './driver/verifysms/verifysms.component';
import { TestapiComponent } from './testapi/testapi.component';
import { WebSocketService } from './lib/web-socket.service';
import { TestsocketComponent } from './testsocket/testsocket.component';
import { TaximapComponent } from './taximap/taximap.component';
import { NewrequestComponent } from './newrequest/newrequest.component';
import { QueuesetComponent } from './test/queueset/queueset.component';
import { ProposedRequestComponent } from './driver/proposed-request/proposed-request.component';
import { StationComponent } from './driver/station/station.component';
import { ViewstationComponent } from './station/viewstation/viewstation.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';


import { ServiceruningComponent } from './passanger/serviceruning/serviceruning.component';
import { WaitforRequestComponent } from './passanger/waitfor-request/waitfor-request.component';
import { DrivernotfoundComponent } from './passanger/drivernotfound/drivernotfound.component';
import { DrivermismmatchComponent } from './driver/drivermismmatch/drivermismmatch.component';
import { GooriginComponent } from './driver/goorigin/goorigin.component';
// import { GodestinationComponent } from './driver/godestination/godestination.component';
import { FinishserviceComponent } from './driver/finishservice/finishservice.component';
import { NgxSplitInputModule } from 'ngx-splitinput';
import { NewCallComponent } from './call-center/new-call/new-call.component';
import { NewCallVanComponent } from './call-center/new-call/new-call-van.component';

import { NewCallSimpleComponent } from './call-center/new-call/new-call-simple.component';

import { NewCallWebComponent } from './call-center/new-call/new-call-web.component';
import { FoundedObjectsComponent } from './call-center/new-call/founded-objects.component';
import { RegisterFoundedObjectComponent } from './call-center/new-call/register-founded-object.component';
import { RegisterComplaintComponent } from './call-center/new-call/register-complaint.component';
import { ComplaintsComponent } from './call-center/new-call/complaints.component';
import { LogsComponent } from './call-center/new-call/logs.component';

import { RegisterPassangerComponent } from './call-center/new-call/register-passanger.component';
import { PassangersComponent } from './call-center/new-call/passangers.component';
import { RegisterStationComponent } from './call-center/new-call/register-station.component';
import { StationsComponent } from './call-center/new-call/stations.component';
import { DrivermonitorComponent } from './call-center/new-call/driver-monitor.component';
import { TrafficmonitorComponent } from './call-center/new-call/traffic-monitor.component';
import { LoginComponent } from './call-center/login/login.component';
import { OperatorsComponent } from './call-center/operators/operators.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { DriverProfileComponent } from './call-center/driver-profile/driver-profile.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { WaitforconfirmComponent } from './driver/waitforconfirm/waitforconfirm.component';
import { StationMemberComponent } from './station/station-member/station-member.component';
import { StationMemberSelectComponent } from './station/station-member-select/station-member-select.component';
import { TestComponent } from './test/test/test.component';
import { BottomsheetinfoComponent } from './station/bottomsheetinfo/bottomsheetinfo.component';
import { TripsComponent } from './station/trips/trips.component';
import { MytripsComponent } from './driver/mytrips/mytrips.component';

import { ShowcreatedserviceComponent } from './driver/showcreatedservice/showcreatedservice.component';
import { MessageboxComponent } from './messagebox/messagebox.component';
import { PlayfileComponent } from './driver/playfile/playfile.component';
import { LogcallComponent } from './call-center/logcall/logcall.component';
import { PushComponent } from './test/push/push.component';
import { LogoutComponent } from './driver/logout/logout.component';
import { FreeserviceComponent } from './driver/freeservice/freeservice.component';
import { LoginShahremanComponent } from './driver/login-shahreman/login-shahreman.component';
import { UnlocknotificationComponent } from './driver/unlocknotification/unlocknotification.component';
import { MasterCcComponent } from './call-center/master-cc/master-cc.component';
import { MasterCcWebComponent } from './call-center/master-cc-web/master-cc-web.component';
import { TestSocketComponent } from './driver/test-socket/test-socket.component';
import { TestMapComponent } from './driver/test-map/test-map.component';
import { PeygirialertComponent } from './driver/peygirialert/peygirialert.component';
import { CancelbydriverComponent } from './driver/cancelbydriver/cancelbydriver.component';
import { ConfirmmessageComponent } from './shared/confirmmessage/confirmmessage.component';
import { SaveDriverInfoComponent } from './call-center/save-driver-info/save-driver-info.component';
import { InpDateTimeComponent } from './call-center/inp-date-time/inp-date-time.component';
import { CallmanagerComponent } from './call-center/callmanager/callmanager.component';
import { LoginAppNewComponent } from './driver/login-app-new/login-app-new.component';
import { StationdetailComponent } from './call-center/stationdetail/stationdetail.component';
import { SetmanualdriverComponent } from './call-center/setmanualdriver/setmanualdriver.component';
import { MasterDriverComponent } from './driver/master-driver/master-driver.component';
import { MenuDriverComponent } from './driver/menu-driver/menu-driver.component';
import { ShowfreeservicelistComponent } from './driver/showfreeservicelist/showfreeservicelist.component';
import { DriverGuardGuard } from './driver-guard.guard';
//import { OperatorGuardGuard } from './operator-guard.guard';
import { ActlanuncherComponent } from './shared/actlanuncher/actlanuncher.component';
import { MaterialPersianDateAdapter, PERSIAN_DATE_FORMATS } from './shared/persiandateadapter.service';
import { SnackbarexitdriverComponent } from './driver/snackbarexitdriver/snackbarexitdriver.component';
import { ExitalarmComponent } from './driver/exitalarm/exitalarm.component';
import { MessgaenotifComponent } from './driver/messgaenotif/messgaenotif.component';
import { TripmanageComponent } from './manage/tripmanage/tripmanage.component';
import { CallInfoComponent } from './driver/call-info/call-info.component';
import { SetpriorityComponent } from './manage/setpriority/setpriority.component';
import { ManageDriverComponent } from './manage/manage-driver/manage-driver.component';
import { ManageDebitComponent } from './manage/manage-debit/manage-debit.component';
import { OkmessageComponent } from './shared/okmessage/okmessage.component';
import { ChangestationComponent } from './manage/changestation/changestation.component';

import { DriverLogComponent } from './driver/driver-log/driver-log.component';
import { PropsoedListComponent } from './driver/propsoed-list/propsoed-list.component';
import { TextFieldComponent } from './shared/materials/text-field.component';
import { TextAreaComponent } from './shared/materials/text-area.component';
import { FormsListComponent } from './call-center/new-call/forms-list.component';
import { SearchFormsComponent } from './call-center/new-call/search-forms.component';
import { DashboardComponent } from './call-center/new-call/dashboard.component';
import { ProfileComponent } from './driver/profile/profile.component';
import { DatePickerComponent } from "./shared/materials/date-picker.component";
import { TimePickerComponent } from "./shared/materials/time-picker.component";
import { ButtonComponent } from "./shared/materials/button.component";
import { InputTextModule } from "primeng/inputtext";
import { CalendarModule } from "primeng/calendar";
import { TooltipModule } from "primeng/tooltip";
import { InputNumberModule } from "primeng/inputnumber";
import { InputMaskModule } from "primeng/inputmask";
import { DropdownModule } from "primeng/dropdown";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TableModule } from "primeng/table";
import { ToolbarModule } from "primeng/toolbar";
import { DialogModule } from "primeng/dialog";
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartModule } from 'primeng/chart';

import { ErrorIntercept } from './lib/serviceLib/error.interceptor';
import { NewtestComponent } from './newui/newtest/newtest.component';
import { NewNavbarComponent } from './newui/new-navbar/new-navbar.component';
import { NewMenuComponent } from './newui/new-menu/new-menu.component';
import { NewFooterComponent } from './newui/new-footer/new-footer.component';
import { NewUserinfoComponent } from './newui/new-userinfo/new-userinfo.component';
import { NewCcComponent } from './call-center/cc/new-cc/new-cc.component';
import { NewuiccComponent } from './call-center/cc/newuicc/newuicc.component';
import { SetorigindesComponent } from './shared/setorigindes/setorigindes.component';
import { SearchplaceComponent } from './shared/searchplace/searchplace.component';
import { DrvMasterComponent } from './drv/drv-master/drv-master.component';
import { BaseInfoComponent } from './call-center/baseinfo/baseinfo.component';
import { ReportTripComponent } from './report/report-trip/report-trip.component';
import { ReportBydriverComponent } from './report/report-bydriver/report-bydriver.component';
import { ReportKarKardComponent } from './report/report-karkard/report-karkard.component';

import { ReportByoperatorComponent } from './report/report-byoperator/report-byoperator.component';
import { ReportCommissionComponent } from './report/report-commission/report-commission.component';

import { TestviewComponent } from './driver/testview/testview.component';
import { DriverMenuComponent } from './driver/driver-menu/driver-menu.component';
import { StationListComponent } from './station/station-list/station-list.component';
import { DriverCardComponent } from './shared/driver-card/driver-card.component';
import { DriverQueueComponent } from './shared/driver-queue/driver-queue.component';
import { InputtextComponent } from './shared/inputtext/inputtext.component';
import { InputselectItemComponent } from './shared/inputselectItem/inputselectItem.component';
import { SettingComponent } from './admin/setting/setting.component';
import { BulkabonmanComponent } from './admin/bulkabonman/bulkabonman.component';
import { AbonmanviewComponent } from './admin/abonmanview/abonmanview.component';
import { MonthbymonthComponent } from './report/monthbymonth/monthbymonth.component';
import { SummaryinfoComponent } from './driver/summaryinfo/summaryinfo.component';
import { ManageAccComponent } from './acc/manage-acc/manage-acc.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AddDebitComponent } from './acc/add-debit/add-debit.component';
import { MasterAccComponent } from './acc/master-acc/master-acc.component';
import { ShiftlistingComponent } from './manage/shiftlisting/shiftlisting.component';
import { MyshiftComponent } from './manage/myshift/myshift.component';
import { ShiftSchedulerComponent } from './manage/shift-scheduler/shift-scheduler.component'

 


const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  declarations: [
    AppComponent,
    UiLoaderComponent,
    NewComponent,
    ConfirmInfoComponent,
    VerifysmsComponent,
    TestapiComponent,
    TestsocketComponent,
    TaximapComponent,
    NewrequestComponent,
    QueuesetComponent,
    ProposedRequestComponent,
    SetpriorityComponent,
    StationComponent,
    ViewstationComponent,
    ServiceruningComponent,
    WaitforRequestComponent,
    DrivernotfoundComponent,
    DrivermismmatchComponent,
    GooriginComponent,
    FinishserviceComponent,
    NewCallComponent,
    NewCallVanComponent,
    NewCallSimpleComponent,

    NewCallWebComponent,
    FoundedObjectsComponent,
    RegisterFoundedObjectComponent,
    RegisterComplaintComponent,
    ComplaintsComponent,
    LogsComponent,
    RegisterPassangerComponent,
    PassangersComponent,
    RegisterStationComponent,
    StationsComponent,
    DrivermonitorComponent,
    TrafficmonitorComponent,
    LoginComponent,
    TestapiComponent,
    OperatorsComponent,
    ConfirmDialogComponent,
    DriverProfileComponent,
    WaitforconfirmComponent,
    StationMemberComponent,
    StationMemberSelectComponent,
    TestComponent,
    BottomsheetinfoComponent,
    TripsComponent,
    MytripsComponent,
    ShowcreatedserviceComponent,
    MessageboxComponent,
    PlayfileComponent,
    LogcallComponent,
    PushComponent,
    LogoutComponent,
    FreeserviceComponent,
    LoginShahremanComponent,
    UnlocknotificationComponent,
    MasterCcComponent,
    MasterCcWebComponent,
    TestSocketComponent,
    TestMapComponent,
    PeygirialertComponent,
    CancelbydriverComponent,
    ConfirmmessageComponent,
    SaveDriverInfoComponent,
    InpDateTimeComponent,
    CallmanagerComponent,
    LoginAppNewComponent,
    StationdetailComponent,
    SetmanualdriverComponent,
    MasterDriverComponent,
    MenuDriverComponent,
    ShowfreeservicelistComponent,
    ActlanuncherComponent,
    SnackbarexitdriverComponent,
    ExitalarmComponent,
    MessgaenotifComponent,
    TripmanageComponent,
    CallInfoComponent,
    SetpriorityComponent,
    ManageDriverComponent,
    ManageDebitComponent,
    AbonmanviewComponent,
    OkmessageComponent,
    ChangestationComponent,
    DriverLogComponent,
    PropsoedListComponent,
    TextFieldComponent,
    TextAreaComponent,
    FormsListComponent,
    SearchFormsComponent,
    DashboardComponent,
    ProfileComponent,
    DatePickerComponent,
    TimePickerComponent,
    ButtonComponent,
    CheckboxComponent,
    DataTableComponent,

    DropdownComponent,
    NewtestComponent,
    NewNavbarComponent,
    NewMenuComponent,
    NewFooterComponent,
    NewUserinfoComponent,
    NewCcComponent,
    NewuiccComponent,
    SetorigindesComponent,
    SearchplaceComponent,
    DrvMasterComponent,
    BaseInfoComponent,
    ReportTripComponent,
    ReportBydriverComponent,
    ReportKarKardComponent,
    ReportByoperatorComponent,
    ReportCommissionComponent,
    TestviewComponent,
    DriverMenuComponent,
    StationListComponent,
    DriverCardComponent,
    DriverQueueComponent,
    InputtextComponent,
    InputselectItemComponent,
    SettingComponent,
    BulkabonmanComponent,
    AbonmanviewComponent,
    MonthbymonthComponent,
    SummaryinfoComponent,
    ManageAccComponent,
    AddDebitComponent,
    MasterAccComponent,
    ShiftlistingComponent,
    ShiftSchedulerComponent,
    MyshiftComponent

  ],
  entryComponents: [
    StationdetailComponent,
    DriverLogComponent,
    ProposedRequestComponent,
    SetpriorityComponent,
    WaitforRequestComponent,
    DrivernotfoundComponent,
    ConfirmDialogComponent,
    StationMemberComponent,
    
    BottomsheetinfoComponent,
    TripsComponent,
    MytripsComponent,
    ShiftlistingComponent,
    MyshiftComponent ,
    FinishserviceComponent,
    ShowcreatedserviceComponent,
    MessageboxComponent,
    FreeserviceComponent,
    CancelbydriverComponent,
    ConfirmmessageComponent,
    InpDateTimeComponent,
    SetmanualdriverComponent,
    SnackbarexitdriverComponent,
    OkmessageComponent,
    ChangestationComponent,
    PropsoedListComponent,
    SetorigindesComponent    ,
    SearchplaceComponent
  ],
  imports: [
    TreeModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    //MatExpansionPanel,
    //MatExpansionPanelHeader,
    //MatExpansionPanelTitle,
    MatExpansionModule,
    MatBottomSheetModule,
    CommonModule,
    HttpClientModule,
    MatSliderModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCardModule,
    MatTabsModule,
    MatToolbarModule,
    MultiSelectModule,
    MatIconModule,
    MatBottomSheetModule,
    MatTooltipModule,
    NgxSplitInputModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatTableModule,
    MatSortModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatRadioModule,
    MatListModule,
    MatBadgeModule,
    MatMenuModule,
    MatDividerModule,
    MatSelectModule,
    MatDatepickerModule,
    InputTextModule,
    CalendarModule,
    TooltipModule,
    InputNumberModule,
    InputMaskModule,
    DropdownModule,
    InputTextareaModule,
    TableModule,
    MatProgressSpinnerModule,
    ToolbarModule,
    DialogModule,
    ChartModule,
    NgxMaskModule.forRoot(maskConfig),

    RouterModule.forRoot([
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    //    { path: '', redirectTo: 'acc', pathMatch: 'full' },
      {
        path: 'acc', component: MasterAccComponent

        ,
      children: [
        { path: 'adddebit', component: AddDebitComponent},  
        { path: 'manage', component: ManageAccComponent},  

        ]},
      
      //{ path: '', component: TestviewComponent, pathMatch: 'full' },
      { path: 'bi', component: BaseInfoComponent, pathMatch: 'full' },
      {
          path: 'driver', component: DrvMasterComponent, children: [
          { path: 'test', component: TestviewComponent }, //login
          { path: 'new', redirectTo: '/login' /*component: NewComponent */}, //login
          { path: 'verify', component: VerifysmsComponent }, //verify sms
          { path: 'station2', component: TestviewComponent, canActivate: [DriverGuardGuard] },
          { path: 'confirm', component: ConfirmInfoComponent },
          { path: 'mismatch', component: DrivermismmatchComponent },
          { path: 'profile', component: ProfileComponent, canActivate: [DriverGuardGuard] },
          { path: 'waitconfirm', component: WaitforconfirmComponent },
          { path: 'sound', component: PlayfileComponent },
          { path: 'logout', component: LogoutComponent },
          { path: 'saveInfo', component: SaveDriverInfoComponent },
        ]
      }, //login
      {
        path: 'driver', component: MasterDriverComponent, canActivate: [DriverGuardGuard], children: [
          {
            path: 'freelist',
            component: ShowfreeservicelistComponent,
          }
        ]
      },

      {
        path: 'drv', component: DrvMasterComponent, children: [
          { path: 'new', component: NewComponent }, //login
          { path: 'verify', component: VerifysmsComponent }, //verify sms
          { path: 'mismatch', component: DrivermismmatchComponent }, //login fialed with miss authentication info
          { path: 'waitconfirm', component: WaitforconfirmComponent }, //wait for un allowd driver
          { path: 'confirm', component: ConfirmInfoComponent }, //confirm info at first login
        ]
      },


      { path: 'testApi', component: TestapiComponent },
      { path: 'socket', component: TestsocketComponent },
      { path: 'map', component: TaximapComponent },
      { path: 'new', component: NewrequestComponent },
      { path: 'test/q', component: QueuesetComponent },
      { path: 'message/exitq', component: ExitalarmComponent },
      { path: 'message/notifshow', component: MessgaenotifComponent },


      { path: 'station/view', component: ViewstationComponent, canActivate: [DriverGuardGuard] },
      { path: 'passanger/service', component: ServiceruningComponent },

      { path: 'service/origin', component: GooriginComponent },
      { path: 'service/finish', component: FinishserviceComponent, canActivate: [DriverGuardGuard] },
      //{ path: 'cc/new', component: NewCallComponent },
      { path: 'login', component: LoginComponent },

      { path: 'cc/setpr', component: SetpriorityComponent },

      { path: 'cc/driver-profile', component: DriverProfileComponent },
      { path: 'test/test', component: TestComponent },
      { path: 'CallCenter/LogCall', component: LogcallComponent },
      { path: 'test/push', component: PushComponent },
      { path: 'sso/user', component: LoginShahremanComponent },
      { path: 'shahreman/pushnotif1', component: UnlocknotificationComponent },
      { path: 'shahreman/pushnotif2', component: UnlocknotificationComponent },
      { path: 'shahreman/pushnotif4', component: UnlocknotificationComponent },
      { path: 'shahreman/pushnotif3', component: UnlocknotificationComponent },
      { path: 'lauchercall/setact', component: ActlanuncherComponent },
      { path: 'newui', component: NewtestComponent },

      { path: 'shahreman/pushnotif', component: UnlocknotificationComponent },
      { path: 'shahreman/peygiri', component: PeygirialertComponent },
      { path: 'service/cancel', component: CancelbydriverComponent },
      { path: 'shahreman/test', component: TestSocketComponent },
      { path: 'test/map', component: TestMapComponent },
      { path: 'station/det', component: StationdetailComponent },

      { path: 'DirectLogin', component: LoginAppNewComponent },
      { path: 'testqu', component: DriverQueueComponent },
      
      {
        path: 'origin',
        component: SetorigindesComponent,

      },
      {
        path: 'places',
        component: SearchplaceComponent,

      },
      
      {
        path: 'report', component: ReportBydriverComponent, children: [
        ]
      },
      {
        path: 'cc', component: MasterCcComponent, children: [
          {
            path: 'places',
            component: SearchplaceComponent,

          },
          {
            path: 'reportcommission',
            component: ReportCommissionComponent,

          },
          
          {
            path: 'report', component: ReportBydriverComponent
          },
          {
            path: 'karkard', component: ReportKarKardComponent
          }
          ,
          {
            path: 'mbym', component: MonthbymonthComponent
          },
          
          {
            path: 'reportoperator', component: ReportByoperatorComponent
          },

          {
            path: 'new',
            component: NewCallComponent,
          },
          {
            path: 'newvan',
            component: NewCallVanComponent,
          },
          {
            path: 'newsimple',
            component: NewCallSimpleComponent,
          },
          {
            path: 'baseinfo',
            component: BaseInfoComponent,
          },
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'pelaq',
            component: PelqueComponent,
          },
          {
            path: 'p',
            component: PelqueComponent,
          },


          {
            path: 'newui',
            component: NewCcComponent,
          },
          {
            path: 'newweb',
            component: NewCallWebComponent,
          },
          {
            path: 'dashboard',
            component: DashboardComponent,
          },
          {
            path: 'formslist',
            component: FormsListComponent,
          },
          {
            path: 'searchforms',
            component: SearchFormsComponent,
          },
          {
            path: 'foundeds',
            component: FoundedObjectsComponent,
          },
          {
            path: 'regFounded',
            component: RegisterFoundedObjectComponent,
          },
          {
            path: 'regComplaint',
            component: RegisterComplaintComponent,
          },
          {
            path: 'logs',
            component: LogsComponent,
          },
          {
            path: 'complaints',
            component: ComplaintsComponent,
          },
          {
            path: 'regPassanger',
            component: RegisterPassangerComponent,
          },
          {
            path: 'passangers',
            component: PassangersComponent,
          },
          {
            path: 'regStation',
            component: RegisterStationComponent,
          },
          {
            path: 'stations',
            component: StationsComponent,
          },
          {
            path: 'manage',
            component: CallmanagerComponent
          },
          { path: 'operators', component: OperatorsComponent },
          { path: 'drivers', component: ManageDriverComponent },
          { path: 'shift', component: ShiftSchedulerComponent },
          
          { path: 'debits', component: ManageDebitComponent },
          { path: 'abonman', component: AbonmanviewComponent },
          {
            path: 'manageall',
            component: TripmanageComponent
          },
          {
            path: 'drivermonitor',
            component: DrivermonitorComponent
          },
          {
            path: 'trafficmonitor',
            component: TrafficmonitorComponent
          }
          ,
          {
            path: 'setting',
            component: SettingComponent
          }


        ],
      },



    ]),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    //WebSocketService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    //{ provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorIntercept, multi: true },
    { provide: DateAdapter, useClass: MaterialPersianDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: PERSIAN_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

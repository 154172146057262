import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GlobalVariable} from './../../globalVariable';
import {Driver, getDriverInfo} from './../../model/driver';
import {Observable, Subscriber} from 'rxjs';
import {ErrorOutput} from '../../model/enumtype';
import {Car} from '../../model/car';
import { LastCallInfo } from '../../model/lastCallInfo';
import { OperatorTable } from '../../model/operatorTable';
import { IBaseInfo, IBaseInfoMaster } from '../interfaces/Ibaseinfo';


export enum BaseInfoEnum {
  None = 0,
  TypePardakht = 9,
  TypeService= 10,
}
@Injectable({
  providedIn: 'root'
})
export class BaseInfoService {
  public moke = GlobalVariable.mokeFLAG;
  private BaseServiceURL= GlobalVariable.BaseInfoBaseServiceURL ;

  constructor(private httpClient: HttpClient) {

  }
  
  public getList(masterID: any): Observable<IBaseInfo[]> {
    let params = new HttpParams().set('MasterID', masterID);
    return this.httpClient.post<IBaseInfo[]>(this.BaseServiceURL + '/GetList', params);
  }
  public getMasterInfo(masterID: any): Observable<IBaseInfoMaster> {
    let params = new HttpParams().set('MasterID', masterID);
    return this.httpClient.post<IBaseInfoMaster>(this.BaseServiceURL + '/GetMasterInfo', params);
  }

  public insert(value: Partial<IBaseInfo>): Observable<number> {
    let params = new HttpParams().set('Name', value.name);
    params = params.set('GroupId', value.groupId.toString());
    params = params.set('Comment', value.comment);
    return this.httpClient.post<number>(this.BaseServiceURL + '/InsertNew', params);
  }

  public update(value: Partial<IBaseInfo>): Observable<number> {
    const params = new HttpParams()
      .set('Name', value.name)
      .set('GroupId', value.groupId.toString())
      .set('Comment', value.comment)
      .set('ID', value.id.toString());
    return this.httpClient.post<number>(`${this.BaseServiceURL}/Update`, params);
  }

  public delete(opID: any): Observable<any> {
    const params = new HttpParams().set('ID', opID);
    return this.httpClient.post(`${this.BaseServiceURL}/Delete`, params);
  }
  


   
}

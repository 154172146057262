import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'jalali-moment';

@Component({
  selector: 'app-inp-date-time',
  templateUrl: './inp-date-time.component.html',
  styleUrls: ['./inp-date-time.component.scss']
})
export class InpDateTimeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InpDateTimeComponent>,
    private matSnackbar: MatSnackBar,
    private renderer2: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: { guidRequest: string })
  {

  }
  selectedhour: FormControl;
  selectedminute: FormControl;
  date: FormControl; 
  


  save() {
    if ((!this.selectedhour.value) || +this.selectedhour.value > 23) {
      this.matSnackbar.open('ساعت وارد شده صحیح نمی باشد', null, { duration: 1500 });
      return;
    }
    

    if ((!this.selectedminute.value) || +this.selectedminute.value > 59) {
      this.matSnackbar.open('دقیقه وارد شده صحیح نمی باشد', null, { duration: 1500 });
      return;
    }
    if (!this.date.value) {
      this.matSnackbar.open('تاریخ وارد شده صحیح نمی باشد', null, { duration: 1500 });
      return;
    }


    this.dialogRef.close({ 'Action': 'Save', 'date': this.date, 'hour': this.selectedhour.value, 'minute': this.selectedminute.value });
  }
  cancel() {
    this.dialogRef.close({ 'Action': 'Cancel', 'date': this.date, 'hour': this.selectedhour.value, 'minute': this.selectedminute.value });
  }
  ngOnInit() {

    this.selectedhour = new FormControl('', [Validators.required, Validators.min(0), Validators.max(23)]);
    this.selectedminute = new FormControl('', [Validators.required, Validators.min(0), Validators.max(59)]);
   this.date = new FormControl(new Date(), [Validators.required]);
  }
  
}

import { Map, map as LeafletMap, tileLayer, CRS, marker, icon, LeafletMouseEvent, Icon, DivIcon, Marker, LatLng, circleMarker, circle } from 'leaflet';

export enum MarkerType {
  initLocation,
  currentGPS,
  origin,
  destination,
  station,
  taxi,
  grayDriver,
  greenDriver,
  yellowDriver,
  redDriver
  
}
export class markerHelper {

  public static getMarker(markertype?: MarkerType): Icon {

    

    switch (markertype) {
      case MarkerType.initLocation:
        return  icon({
          iconUrl: 'assets/markersnapp.png',
          iconSize: [34, 48],
          
        });

      case MarkerType.origin:
        return icon({
          iconUrl: 'assets/originmarkersnapp.png',
          iconSize: [110, 43]
        });
        
      case MarkerType.destination:
        return icon({
          iconUrl: 'assets/destinationsnapp.png',
          iconSize: [108, 42]
        });
       
      case MarkerType.station:
        return icon({
          iconUrl: 'assets/station.png',
          iconSize: [43, 36]
        });
      case MarkerType.taxi:
        return icon({
          iconUrl: 'assets/carIcon.png',
          iconSize: [32, 62]
        });
      case MarkerType.grayDriver:
        return icon({
          iconUrl: 'assets/car-gray.png',
          iconSize: [10, 20]
        });
      case MarkerType.greenDriver:
        return icon({
          iconUrl: 'assets/car-green.png',
          iconSize: [10, 20]
        });
      case MarkerType.yellowDriver:
        return icon({
          iconUrl: 'assets/car-yellow.png',
          iconSize: [10, 20]
        });
      case MarkerType.redDriver:
        return icon({
          iconUrl: 'assets/car-red.png',
          iconSize: [10, 20]
        });
      default:
        return icon({
          iconUrl: 'assets/normalMarker.png',
          iconSize: [24, 24]
        });


       
    }
    
   
  }
  public static getMarkerText(txt:string , markertype?: MarkerType): string {
    return txt;
  }
}

import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import { Direction } from "@angular/cdk/bidi";
import {Subject} from "rxjs";

@Component({
  selector: 'custom-text-field',
  template: `
    <div class="d-flex float-input-container mt-2 w-100" [style.height]="height">
      <span class="p-float-label">
        <input
          *ngIf="inputType !== 'number' && inputType !== 'phone'"
          [(ngModel)]="model"
          [disabled]="isDisabled"
          (ngModelChange)="onChangeListener.next(model)" type="text" pInputText>
        <p-inputMask
          *ngIf="inputType === 'phone'"
          [(ngModel)]="model"
          [disabled]="isDisabled"
          (ngModelChange)="onChangeListener.next(model)" [autoClear]="false" mask="99999999999" >
        </p-inputMask>
        <p-inputNumber
          *ngIf="inputType === 'number'"
          style="direction: ltr"
          class="full-width"
          [(ngModel)]="model"
          mode="decimal"
          [useGrouping]="useGrouping"
          [disabled]="isDisabled"
          [maxlength]="maxLength"
          (ngModelChange)="onChangeListener.next(model)"
          (onKeyDown)="onKeyDown($event)"
          (keyup)="onChangeListener.next(model)"
          [showButtons]="showButtons"
          [min]="0"
          [max]="max">
        </p-inputNumber>
        <label for="float-input">{{label}}</label>
    </span>
    </div>
  `,
  styles: [`
    ::ng-deep input {
      //font-size: 1.3rem;
height: 5vh;

    }

    label {
      font-family: IranYekan;
    }

    ::ng-deep p-inputnumber .p-inputnumber-button {
      border-radius: 0px !important;
height: 5vh;
    }

    ::ng-deep p-inputnumber .p-inputnumber-button-group {
      flex-direction: row !important;
height: 5vh;

    }

    ::ng-deep p-inputnumber .p-inputnumber-input {
      flex-direction: row !important;
height: 5vh;

    }

  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextFieldComponent),
    multi: true,
  }]
})
export class TextFieldComponent implements ControlValueAccessor {

  @Input() label: any = '';
  @Input() textAlign: CanvasTextAlign = 'left';
  @Input() inputDirection: Direction = 'rtl';
  @Input() inputType: 'phone' | 'number' | 'text' | 'password' = 'text';
  @Input() maxLength: number = 25;
  @Input() max: number = 1000;
  @Input() showButtons: boolean = false;
  @Input() useGrouping: boolean = false;
  @Output() keyDown = new EventEmitter();
  @Input() height: string = '5vh';
  onChangeListener = new Subject();

  model: string = '';
  isDisabled: boolean = false;


  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChangeListener.subscribe(x => {
      fn(x);
    })
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(val: any): void {
    this.model = val;
  }

  onKeyDown($event: any) {
    this.keyDown.emit($event)
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-new-menu',
  templateUrl: './new-menu.component.html',
  styleUrls: ['./new-menu.component.css']
})
export class NewMenuComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private swUpdate: SwUpdate
  ) { }
  operatorguid: string;
  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      /*this.loadOperatorInfo(this.operatorguid);*/
    });

  }
  showMenu(item) {
    if (item == 'dashboard') {
      this.router.navigate(['cc/dashboard'], { queryParams: { guid: this.operatorguid } });

    }
    if (item == 'newForm') {
      this.router.navigate(['cc/new'], { queryParams: { guid: this.operatorguid } });
    }
    if (item == 'newVanForm') {
      this.router.navigate(['cc/newvan'], { queryParams: { guid: this.operatorguid } });
    }
    
    if (item == 'newFormSimple') {
      this.router.navigate(['cc/newsimple'], { queryParams: { guid: this.operatorguid } });
    }
    
    else if (item == 'searchForms') {
      this.router.navigate(['cc/searchforms'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'formsList') {
      this.router.navigate(['cc/formslist'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'regComplaint') {
      this.router.navigate(['cc/regComplaint'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'complaints') {
      this.router.navigate(['cc/complaints'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'regFounded') {
      this.router.navigate(['cc/regFounded'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'foundeds') {
      this.router.navigate(['cc/foundeds'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'regPassanger') {
      this.router.navigate(['cc/regPassanger'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'passangers') {
      this.router.navigate(['cc/passangers'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'regStation') {
      this.router.navigate(['cc/regStation'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'stations') {
      this.router.navigate(['cc/stations'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'bisim') {
      this.router.navigate(['cc/manage'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'operatosmanage') {
      this.router.navigate(['cc/operators'], { queryParams: { guid: this.operatorguid } });

    }
    else if (item == 'manage') {
      this.router.navigate(['cc/manageall'], { queryParams: { guid: this.operatorguid } });
    }
    
    
    else if (item == 'managedrivers') {
      this.router.navigate(['cc/drivers'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'shift') {
      this.router.navigate(['cc/shift'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'newWebForm') {
      this.router.navigate(['cc/newweb'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'logs') {
      this.router.navigate(['cc/logs'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'drivermonitor') {
      this.router.navigate(['cc/drivermonitor'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'trafficmonitor') {
      this.router.navigate(['cc/trafficmonitor'], { queryParams: { guid: this.operatorguid } });
    }
    else if (item == 'colors') {
      this.router.navigate(['cc/baseinfo'], { queryParams: { guid: this.operatorguid , gid:3 } });
    }
    else if (item == 'carTypes') {
      this.router.navigate(['cc/baseinfo'], { queryParams: { guid: this.operatorguid, gid: 2 } });
    }
    else if (item == 'serviceType') {
      this.router.navigate(['cc/baseinfo'], { queryParams: { guid: this.operatorguid, gid: 10 } });
    }

    else if (item == 'report_driver') {
      this.router.navigate(['cc/report'], { queryParams: { guid: this.operatorguid, gid: 2 } });
    }
    else if (item == 'report_karkard') {
      this.router.navigate(['cc/karkard'], { queryParams: { guid: this.operatorguid, gid: 2 } });
    }
    else if (item == 'report_operator') {
      this.router.navigate(['cc/reportoperator'], { queryParams: { guid: this.operatorguid, gid: 2 } });
    }
    else if (item == 'config') {
      this.router.navigate(['cc/setting'], { queryParams: { guid: this.operatorguid, gid: 2 } });
    }
    else if (item == 'report_commission') {
      this.router.navigate(['cc/reportcommission'], { queryParams: { guid: this.operatorguid, gid: 2 } });
    } else if (item == 'abonman') {
      this.router.navigate(['cc/abonman'], { queryParams: { guid: this.operatorguid, gid: 2 } });
    }
    else if (item == 'updateversion') {

      this.swUpdate.available.subscribe(() => {
        this.swUpdate.activateUpdate().then(() => {
          window.location.reload();
        });
      });
}
    
    
    console.log(item);
  }

}

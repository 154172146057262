

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { FormMode } from '../../lib/enums/form-mode.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { keyvalueobject } from '../../model/keyvalueobject';
import { Driver } from '../../model/driver';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { DriverLogComponent } from '../../driver/driver-log/driver-log.component';
import { MyTel } from '../../shared/materials/plaque.component';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';

@Component({
  selector: 'app-operators',
  templateUrl: './manage-driver.component.html',
  styleUrls: ['./manage-driver.component.scss']
})
export class ManageDriverComponent implements OnInit {
  @ViewChild('operatorDialog', { static: false }) operatorDialogTemplateRef: TemplateRef<any>;
  operatorDialogRef: MatDialogRef<any>;


  @ViewChild('lockDialog', { static: false }) lockDialogTemplateRef: TemplateRef<any>;
  lockDialogRef: MatDialogRef<any>;



  currentFormMode: FormMode;
  operatorFromGroup: FormGroup;
  lockFromGroup: FormGroup;
  operators: Partial<Driver>[];
  currentEditingOperator: Partial<Driver>;
  mobileSearch = '';
  nationalCodeSearch = '';
  driverFNameSearch = '';
  driverCodeSearch = '';
  stationCode = '';
  recordCount = 0;
  operatorguid = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,

    private drivService: DriverserviceService,
    private taxiservice: TaxiserviceService,

    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private dialog: MatDialog,
    private taxiService: TaxiserviceService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];

    });
  }
  filter() {
    console.log('filter');
    this.drivService.getAll(this.mobileSearch, this.driverFNameSearch, this.driverCodeSearch, this.nationalCodeSearch, '').subscribe(_res => {
      console.log(_res);
      this.operators = _res;
    });


    this.taxiservice.getListKeyValue(2).subscribe(_res => {
      this.CarList = _res;
      console.log(this.CarList);
    });
    this.taxiservice.getListKeyValue(3).subscribe(_res => {
      this.ColorList = _res;
      console.log(this.ColorList);
    });


  }
  ngOnInit() {
    this.filter();

    this.operatorFromGroup = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
      fName: new FormControl('', [Validators.required]),
      workingMobile: new FormControl('', [Validators.required]),
      driverCode: new FormControl('', [Validators.required]),
      typeCar: new FormControl('', [Validators.required]),
      color: new FormControl('', [Validators.required]),
      pelaqueIR: new FormControl('', [Validators.required]),
      pelaqueLetter: new FormControl('', [Validators.required]),
      pelaque2: new FormControl('', [Validators.required]),
      pelaque3: new FormControl('', [Validators.required]),
      nationalCode: new FormControl('', []),
      username: new FormControl('', []),
      password: new FormControl('', []),
    });

    this.lockFromGroup = new FormGroup({
      lockComment: new FormControl('', [Validators.required]),
    });
  }
  CarList: keyvalueobject[] = [];
  ColorList: keyvalueobject[] = [];


  get FormMode() {
    return FormMode;
  }
  openlockDialog(_data?: Partial<Driver>) {
    this.lockFromGroup.reset();
    if (_data) {
      this.currentEditingOperator = _data;
      this.lockFromGroup.patchValue(this.currentEditingOperator);
    }

    this.lockDialogRef = this.matDialog.open(this.lockDialogTemplateRef, {
      hasBackdrop: true,
      width: '360px'
    });

  }
  openOperatorDialog(_mode: FormMode, _data?: Partial<Driver>) {
    console.log(_data);
    this.currentFormMode = _mode;
    this.operatorFromGroup.reset();

    if (_mode === FormMode.Edit && _data) {

      this.currentEditingOperator = _data;
      this.operatorFromGroup.patchValue(this.currentEditingOperator);
    }

    this.operatorDialogRef = this.matDialog.open(this.operatorDialogTemplateRef, {
      hasBackdrop: true,
      width: '360px'
    });
  }

  delete(_data: Partial<Driver>) {
    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید راننده «' + _data.fName + _data.driverCode + '» حذف شود؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });

    confirmRef.afterClosed().subscribe(_confirmed => {
      if (_confirmed) {
        this.drivService.delete(_data.id).subscribe(_res => {
          const index = this.operators.findIndex(_item => _item.id === _data.id);
          if (index > -1) {
            this.operators.splice(index, 1);
          }
          this.matSnackbar.open('راننده با شناسه ' + _data.id + ' حذف شد.', null, { duration: 5000 });
        });
      }
    });
  }
  selectedhour = new FormControl('', [Validators.required, Validators.min(0), Validators.max(23)]);
  selectedminute = new FormControl('', [Validators.required, Validators.min(0), Validators.max(59)]);
  date = new FormControl('', [Validators.required]);
  submitLock() {
    if (this.lockFromGroup.valid) {
      const formValues: Partial<Driver> = this.lockFromGroup.value;
      this.drivService.lock(this.currentEditingOperator.id, formValues.lockComment, this.date.value, this.selectedhour.value, this.selectedminute.value
      ).subscribe(_id => {
        this.currentEditingOperator.lockComment = formValues.lockComment;
        this.matSnackbar.open('کد' + this.currentEditingOperator.driverCode + 'قفل گردید', null, { duration: 5000 });
      });
      this.lockDialogRef.close();
    }

  }
  unlock(operator: Driver) {
    this.drivService.unlock(operator.id).subscribe(res => {
      operator.lockComment = '';
      this.matSnackbar.open('قفل کد ' + operator.driverCode + 'باز گردید', null, { duration: 5000 });

    }
    );
  }
  submit() {
    if (this.operatorFromGroup.valid) {
      const formValues: Partial<Driver> = this.operatorFromGroup.value;

      if (this.currentFormMode === FormMode.Add) {

        this.drivService.insert(formValues).subscribe(_id => {
          formValues.id = _id;
          this.operators.push(formValues);
          this.matSnackbar.open('راننده جدید با موفقیت افزوده شد.', null, { duration: 5000 });
        });

      } else if (this.currentFormMode === FormMode.Edit) {

        this.currentEditingOperator.mobile = formValues.mobile;
        this.currentEditingOperator.fName = formValues.fName;
        this.currentEditingOperator.workingMobile = formValues.workingMobile;
        this.currentEditingOperator.driverCode = formValues.driverCode;
        this.currentEditingOperator.pelaque2 = formValues.pelaque2;
        this.currentEditingOperator.pelaque3 = formValues.pelaque3;
        this.currentEditingOperator.pelaqueIR = formValues.pelaqueIR;
        this.currentEditingOperator.pelaqueLetter = formValues.pelaqueLetter;
        this.currentEditingOperator.typeCar = formValues.typeCar;
        this.currentEditingOperator.color = formValues.color;
        this.currentEditingOperator.nationalCode = formValues.nationalCode;
        this.currentEditingOperator.username = formValues.username;
        this.currentEditingOperator.password = formValues.password;

        this.drivService.update(this.currentEditingOperator).subscribe(_id => {
          this.matSnackbar.open('ویرایش راننده با موفقیت انجام شد.', null, { duration: 5000 });
        });
      }

      this.operatorDialogRef.close();
    }
  }
  dialogProposedServiceRef: MatDialogRef<DriverLogComponent, any>;
  lockDrv(driver: Driver) {

  }
  showLog(driverIDParam: any) {
    this.dialogProposedServiceRef = this.dialog.open(DriverLogComponent, {
      width: '800px',
      hasBackdrop: true,
      data: {
        driverID: driverIDParam
      },
      autoFocus: true,
    });
  }
  checkAll($event: any) {
    this.operators.forEach(res => {
      res.selected = $event.checked;
    });
  }
  valueChange(checkedItem: Driver, $event: any) {
    if (checkedItem) {
      checkedItem.selected = $event.checked;
      console.log(checkedItem);
    }
  }
  updatePriceDefault() {
    this.router.navigate(['cc/setting'], { queryParams: { guid: this.operatorguid } });
  }
  fileToUpload: File | null = null;
  handleFileInput(files: FileList, driverID) {
    
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
    this.uploadFileToActivity(driverID);
    
  }
  uploadFileToActivity(driverID) {
    this.drivService.postFile(this.fileToUpload, driverID).subscribe(data => {

      // do something, if upload success
    }, error => {
      console.log(error);
    });
  }

  


  updatePrice(): void {

    this.taxiService.readConfigValue().subscribe(res => {
      console.log(res);


      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش کمیسیون', msg: 'درصد  کمیسیون را وارد نمایید', fieldName: 'کمیسیون', value: res },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.operators.forEach(item => {
            if (item.selected)
              this.drivService.updateComission(item.id, result).subscribe(_id => {
                item.commission = result;
              });
          })


        console.log('The dialog was closed');
        console.log(result);
      });
    })
  }


  updateAbonman(): void {

    this.taxiService.readConfigValue().subscribe(res => {
      console.log(res);


      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش آبونمان', msg: '  آبونمان را وارد نمایید', fieldName: 'آبونمان', value: res },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.operators.forEach(item => {
            if (item.selected)
              this.drivService.updateComission(item.id, result).subscribe(_id => {
                item.commission = result;
              });
          })


        console.log('The dialog was closed');
        console.log(result);
      });
    })
  }
  updateDebit(drv) {
    this.router.navigate(['cc/debits'], { queryParams: { guid: this.operatorguid , refID : drv.id} });
  }
  
  showMbyM(drv) {
    this.router.navigate(['cc/mbym'], { queryParams: { guid: this.operatorguid, did: drv.id } });
  }
  showKarkard(drv) {
    this.router.navigate(['cc/karkard'], { queryParams: { guid: this.operatorguid, gid: drv.id } });
  }
  loginWith(drv) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/DirectLogin?uid=${drv.fbsToken}`])
    );
    console.log(url);
    /*this.router.navigate([]).then(result => { */window.open(`/DirectLogin?uid=${drv.fbsToken}`, '_blank'); //});

    //window.open(url, '_blank');
   // this.router.navigate(['cc/karkard'], { queryParams: { guid: this.operatorguid, gid: drv.id } });
  }
  setstation(drv) {
    this.drivService.readDefaultStation(drv.id).subscribe(res => {
      console.log(res);
      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ایستگاه پیش فرض', msg: 'کد ایستگاه را وارد کنید', fieldName: 'ایستگاه پیش فرض', value: res },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          this.drivService.updateDefaultStation(drv.id, result).subscribe(_id => {
            //item.commission = result;
          });

        }


      });
    })
  }


  updateSelectedCommission(drv) {

    this.taxiService.readConfigValue().subscribe(res => {
      console.log(res);

      var defaultCommission = drv.commission;
      if (drv.commission && drv.commission > 0) {
        defaultCommission = drv.commission;
      }
      else
        defaultCommission = res;
      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش کمیسیون', msg: 'درصد  کمیسیون را وارد نمایید', fieldName: 'کمیسیون', value: defaultCommission },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.drivService.updateComission(drv.id, result).subscribe(_id => {
            drv.commission = result;
          });
        console.log('The dialog was closed');
        console.log(result);
      });
    })
  }
  updateSelectedAbonman(drv) {
    console.log(drv);
    console.log('updateSelectedAbonman');
    this.taxiService.readConfigValue('MonthlyAbonman').subscribe(res => {
      console.log(res);

      var defaultAbonman = drv.abonman;
      if (drv.abonman && drv.abonman > 0) {
        defaultAbonman = drv.abonman;
      }
      else
        defaultAbonman = res;
      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش آبونمان', msg: 'مقدار  آبونمان را وارد نمایید', fieldName: 'آبونمان تومان', value: defaultAbonman },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.drivService.updateAbonman(drv.id, result).subscribe(_id => {
            drv.abonman = result;
          });
        console.log('The dialog was closed');
        console.log(result);
      });
    })
  }
  resetVanList() {
    this.drivService.resetVanQueue().subscribe(_id => {
      
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatTableDataSource } from '@angular/material/table';
import { DriverLog } from '../../model/driver';
import { PropsoedReport } from '../../model/ServiceRequest';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';

@Component({
  selector: 'app-driver-propsoedlist',
  templateUrl: './propsoed-list.component.html',
  styleUrls: ['./propsoed-list.component.scss']
})
export class PropsoedListComponent implements OnInit {

  filteredList: PropsoedReport[] = [];
  dataSource = new MatTableDataSource(this.filteredList);
  requestID = 0;

  constructor(
    private driverService: DriverserviceService,
    private requestService: ServiceRequestService,
    
    public dialogRef: MatDialogRef<PropsoedListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { requestID: number },
  ) {
    this.requestID = this.data.requestID;
    this.requestService.getPropsoedReport(this.requestID).subscribe(res => {
      //console.log(res);
      //console.log(this.requestID);
      this.filteredList = res;
      this.dataSource = new MatTableDataSource(this.filteredList);
    });
  }

  ngOnInit() {

  }
 
  stationCode: string;

  displayedColumns: string[] = ['position', 'driverCode', 'timeText', 'typeName', 'stationCode','resultName'];
  selectedRowIndex = -1;
  selectedElement: DriverLog = null;
  highlight(row: any) {
    //console.log('highlight' + row.id);
    this.selectedRowIndex = row.id;
    this.selectedElement = row;

  }


}

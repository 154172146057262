import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-driver-menu',
  templateUrl: './driver-menu.component.html',
  styleUrls: ['./driver-menu.component.css']
})
export class DriverMenuComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<DriverMenuComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    console.log(data);
    this.Mande = data.mande;
    this.MandeString = data.mandeString;
  }
 
  ngOnInit(): void {
  }
  Mande = 0;
  MandeString = '';
  itemClick(itemName) {
    this._bottomSheetRef.dismiss(itemName);
  }


}

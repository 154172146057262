import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ServiceRequest} from '../../model/ServiceRequest';
import {DriverserviceService} from '../../lib/serviceLib/driverservice.service';
import {ServiceRequestService} from '../../lib/serviceLib/servicerequestservice.service';

@Component({
  selector: 'app-proposed-request',
  templateUrl: './proposed-request.component.html',
  styleUrls: ['./proposed-request.component.css']
})
export class ProposedRequestComponent implements OnInit {
  @ViewChild("audioOption", { static: false })
  public audioPlayerRef: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<ProposedRequestComponent>,
    private route: ActivatedRoute,
    private router: Router,
    private taxiSerivce: ServiceRequestService,
    @Inject(MAT_DIALOG_DATA) public data: { guidRequest: string }
  ) {
  }

  currentRequest: ServiceRequest;

  ngOnInit() {
    this.currentRequest = new ServiceRequest();
    //var guid = '';
    //this.route.queryParams.subscribe(params => {
    //  guid = params['guid'];
    //  this.loadRequestInfo(guid);
    //});
    this.loadRequestInfo(this.data.guidRequest);
  }

  getRequestInfo(req: ServiceRequest) {
    if (!req) {
      this.dialogRef.close('NULL');
    }
    else {
      this.currentRequest = req;
      //console.warn('this.currentRequest ->');
      //console.warn(this.currentRequest);
    }
    //this.onAudioPlay();
  }

  loadRequestInfo(guid: any) {
    this.taxiSerivce.getRequestByPropsedCode(guid).subscribe(_res => {
      //console.warn('loadRequestInfo(guid)');
      //console.warn(_res);
      this.getRequestInfo(_res);
    });
  }

  accept() {
    this.dialogRef.close('Accept');
    
  }

  reject() {
    this.dialogRef.close('Reject');
  }
}

export class SocketMessage {
  MessageType: string;
  Payload: any
}
export class NotificationModel {
  guidCode: string;
  msgType: SocketMessageType;
}
export class SquareChangeRequest {
  Id: number;
  Color: string;
  Name: string;
}
export class Square {
  Id: number;
  Color: string;
}

export enum SocketMessageType {
  GetClientInfo,
  /// <summary>
  /// در صورتی که کلاینت مسافر باشد در زمان اعلام مشخصات خود از این نوع پیام استفاده می کند
  /// </summary>
  SetPassangerInfo,
  /// <summary>
  /// اعلام مشخصات راننده در اپلیکیشن راننده در زمان معرفی خود
  /// </summary>
  SetDriverInfo,
  /// <summary>
  /// ارسال اطلاعات اپراتور در زمان معرفی خود بعد از باز کردن سوکت
  /// </summary>
  SetOperatorInfo,
  /// <summary>
  /// ثبت درخواست سفر توسط مسافر
  /// </summary>
  RequestServiceCreate,
  RequestServiceUpdate,
  DriverNotExistForThisRequest,
  /// <summary>
  /// پیشنهاد یک سفر به راننده
  /// </summary>
  ProposedRequestServive,
  FinishedProposedRequestServive,
  FinishedBroadCastRequestServive,
  AcceptProposedRequest,
  RejectProposedRequest,
  ServiceRequestAssigned,
  RequestServiceCancelByPassanger,
  ServiceGetSubmitedSuccessfully,
  ServieState_GotoOrigin ,
  ServiceState_OriginReceived,
  ServiceState_PassangerArrived,
  SuccessfullAlertOnCallCenter=16
  


}

import { Component, Inject, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageboxComponent } from '../../messagebox/messagebox.component';
import { ServiceRequest } from '../../model/ServiceRequest';
import { StationQueueInfo } from '../../model/stationQueueInfo';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { MatListOption, MatSelectionList } from '@angular/material/list'
import { SetpriorityComponent } from '../../manage/setpriority/setpriority.component';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Station } from '../../model/station';
import { StationMemberComponent } from '../station-member/station-member.component';

@Component({
  selector: 'app-station-member-select',
  templateUrl: './station-member-select.component.html',
  styleUrls: ['./station-member-select.component.scss']
})
export class StationMemberSelectComponent implements OnInit {
  selectedqueueList: StationQueueInfo[];
  queueList: StationQueueInfo[];
  queueList_Filter: StationQueueInfo[];
  searchvalue = '';
  @ViewChild("driverCodes", { static: false })
  public driverCodes: MatSelectionList;

  @Output() onSelectChange = new EventEmitter<StationQueueInfo[]>();
  @Output() onSelectButtonChange = new EventEmitter<StationQueueInfo>();
  @Input() selectable: boolean = true;
  constructor(private stationSerivce: StationserviceService,
    private dialog: MatDialog,
    private currentServiceService: ServiceserviceService,

  ) {

    //console.warn(this.queueList);
  }

  public ontextChange(event): void {
    this.search(event.target.value);
  }
  search(value) {
    console.log('value is search is ');
    console.log(value);
    console.log(this.currentStation);

    this.queueList_Filter = [];
    this.queueList.forEach((data) => {
      if (
        (!this.currentStation  || this.currentStation.id <= 0 || data.stationID == this.currentStation.id) &&
        (!value || value == '' || data.fName.includes(value) || data.driverCode.includes(value) || data.carName.includes(value) || data.workingMobile.includes(value))
      )
        this.queueList_Filter.push(data);
      else {
        console.log('search null');

      }
    })
  }
  ngOnInit() {
    this.currentStation = null;
    this.refreshData();
    this.loadStation();
    //this.passangerFormGroup.get('destAC').valueChanges.subscribe((value: string) => {

    //});
  }
  showStationDetailClick() {
    this.showStationMember();
    //if (this.passangerFormGroup.get('stationCode')?.value)
    //  this.showStationDetails();

  }
  currentStation:Station;
  showStationMember() {
    //console.log('this.currentStation.id');
    //console.log(this.currentStation.id);
    this.stationSerivce.getStationQueue(this.currentStation?.id).subscribe(_res => {


      let dialogStationMemberRef = this.dialog.open(StationMemberComponent, {
        width: '400px',
        hasBackdrop: true,
        data: {
          stationID: this.currentStation?.id,
          queue: _res

        }
      });



    });


  }
  loadData(sid: any) {
    this.stationSerivce.getStationQueueWithOther(sid).subscribe(_res => {
      console.log(_res);
      this.queueList = _res;
      this.search('');
    });
  }
  showStation(station) {
    console.log(station);
  }
  close() {
  }
  onGroupsChange(options: MatListOption[]) {
    this.selectedqueueList = options.map(o => o.value);
    this.onSelectChange.emit(options.map(o => o.value));
  }
  SetPriority(dCode: any, stationID: any) {
    var dialogSetPriority = this.dialog.open(SetpriorityComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        driverCode: dCode,
        stationID: stationID
      }
    });

  }
  exitDriver(driverCode: any) {
    this.currentServiceService.exitDriver(driverCode).subscribe(_res => {

    });
  }
  clear() {

    this.selectedqueueList = [];
    this.onSelectChange.emit([]);
    this.driverCodes.options.forEach((data) => { data._setSelected(false); });


  }
  refreshData() {
    this.loadData(-1);
  }
  select(info: StationQueueInfo) {
    if (this.onSelectButtonChange)
      this.onSelectButtonChange.emit(info);
  }
  lstStation: Station[];
  loadStation() {
    this.stationSerivce.getAllStationList().subscribe(_res => {
      console.log('station list');

      this.lstStation = _res;
      var st = new Station();
      st.id = -1;
      st.name = '---';
      st.code = '-1';
      this.lstStation.unshift(st)
      console.log(this.lstStation);
      //_res.forEach(station => {
      //  this.map.addStationMarker(station);

      //});
    });
  }
  stationChanged(e) {
    console.log('selectedStation is ' + e.target.value)
    this.loadStationList(e.target.value);
    this.search(this.searchvalue);
    //this.loadStationStatics(e.target.value);
  }
  loadStationList(stationID: any) {
    if (stationID && stationID > 0 ) {
      this.stationSerivce.getStation(stationID).subscribe(_res => {
        this.currentStation = _res;
      });
    }
    else {

      this.currentStation= null;
    }

  }
}

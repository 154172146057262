
import { Injectable } from '@angular/core';
import { HttpClient,  HttpParams } from '@angular/common/http';

import { GlobalVariable } from '../../globalVariable';
import { Observable} from 'rxjs';
import { Passanger } from '../../model/passanger';

@Injectable({
  providedIn: 'root'
})
export class PassangerserviceService {
  constructor(
    private httpClient: HttpClient

  ) { }


  public insertPassanger(fName :string,lName :string, mobile: string,nationalCode: string,id : string): Observable<boolean> {
  
    let params = new HttpParams().set('fName', fName).set('lName', lName).set('mobile', mobile).set('id', id)
      .set('nationalCode', nationalCode)      ;

    return this.httpClient.post<boolean>(GlobalVariable.PassangerBaseServiceURL + "/InsertPassanger", params);
  }
  public reportAll(): Observable<Passanger[]> {
  
    let params = new HttpParams()
    return this.httpClient.post<Passanger[]>(GlobalVariable.PassangerBaseServiceURL + "/ReportAll", params);
  }
  public get(id :string): Observable<Passanger> {
  
    let params = new HttpParams().set('id', id);
    return this.httpClient.post<Passanger>(GlobalVariable.PassangerBaseServiceURL + "/Get", params);
  }

  public getListDynamic(whereCondition: string, orderByExpression: string): Observable<Passanger[]> {
    let params = new HttpParams().set('whereCondition', whereCondition).set('orderByExpression', orderByExpression);
    return this.httpClient.post<Passanger[]>(GlobalVariable.PassangerBaseServiceURL + "/getListDynamic", params);
  }


  public reportAllPassanger(fName: any, lName: any, mobile: any, nationalCode: any): Observable<Passanger[]> {
    if (typeof fName == 'undefined' || !fName) {
      fName = '';
    }
    if (typeof lName == 'undefined' || !lName) {
      lName = '';
    }
    if (typeof mobile == 'undefined' || !mobile) {
      mobile = '';
    }
    if (typeof nationalCode == 'undefined' || !nationalCode) {
      nationalCode = '';
    }
 
    let params = new HttpParams().set('fName', fName).set('lName', lName).set('mobile', mobile).set('nationalCode', nationalCode);
    return this.httpClient.post<Passanger[]>(GlobalVariable.PassangerBaseServiceURL + "/ReportAllPassanger", params);
  }
}


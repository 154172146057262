import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { OperatorTable } from '../../model/operatorTable';
import Swal from "sweetalert2";


@Component({
  selector: 'app-register-station',
  templateUrl: './register-station.component.html',
})
export class RegisterStationComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() dataItem: any = {};
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSendToServer = new EventEmitter<any>();
  stationFormGroup = new FormGroup({
    name: new FormControl(),
    code: new FormControl(),
    capacity: new FormControl(),
    lat: new FormControl(),
    lng: new FormControl(),
    radios: new FormControl()

  });
  operatorguid: string;
  stationID: any;

  operatorInfo: OperatorTable;
  constructor(
    private stationServiceService: StationserviceService,
    private callCenterService: CallCenterService,

    private route: ActivatedRoute,
    private router: Router
  ) {


  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.stationID = params['id'];
      this.stationFormGroup.patchValue({ lat: params['lat'] }) ;
      this.stationFormGroup.patchValue({ lng: params['lng'] });

      if (this.stationID) {
        this.stationServiceService.getStation(this.stationID).subscribe(res => {
          if (res) {
            this.stationFormGroup.patchValue({ name: res.name });
            this.stationFormGroup.patchValue({ lat: res.lat });
            this.stationFormGroup.patchValue({ lng: res.lng });
            this.stationFormGroup.patchValue({ capacity: res.capacity });
            this.stationFormGroup.patchValue({ radios: res.radios });
            this.stationFormGroup.patchValue({ code: res.code });

          }
        })
      }
    });
  }


  registerStation() {

    this.stationServiceService.insertStation(this.stationFormGroup.get('name')?.value, this.stationFormGroup.get('code')?.value, this.stationFormGroup.get('capacity')?.value, this.stationFormGroup.get('lat')?.value, this.stationFormGroup.get('lng')?.value, this.stationFormGroup.get('radios')?.value,this.stationID).subscribe(res => {

      if (res) {
        this.router.navigate(['cc/stations'], { queryParams: { guid: this.operatorguid } });
        if (this.stationID) {
          Swal.fire('به روزرسانی شد', 'ایستگاه با موفقیت به روزرسانی شد');

        }
        else {
          Swal.fire('ثبت شد', 'ایستگاه با موفقیت ثبت شد');

        }
      }
      else {
        Swal.fire('خطا', 'خطا در ثبت ایستگاه');

      }

    });;


  }
  resetForm() {
    this.stationFormGroup.reset();
  }

}

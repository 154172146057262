import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from '../../model/service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';

@Component({
  selector: 'app-serviceruning',
  templateUrl: './serviceruning.component.html',
  styleUrls: ['./serviceruning.component.css']
})
export class ServiceruningComponent implements OnInit {
  guidService = ''
  currentService = new Service();
  constructor(private route: ActivatedRoute, private taxiSerivce: ServiceserviceService) { }
  loadService(service: Service) {
    //console.warn(service);
    this.currentService = service; 
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.guidService = params['guid'];
     
      this.taxiSerivce.getService(this.guidService).subscribe(_res => {
        this.loadService(_res);
      });
    });
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-map',
  templateUrl: './test-map.component.html',
  styleUrls: ['./test-map.component.scss']
})
export class TestMapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  moveEnd(e: any) {
    //console.log(e);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { DashboardService } from './lib/serviceLib/dashboardservice.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //  styles: [`
  //div.loading{
  //    height: 100px;
  //    background-color: red;
  //    display: none;
  //}
  //router-outlet + div.loading{
  //    display: block;
  //}

  //`]
})
export class AppComponent implements OnInit {
  constructor(private router: Router,
    private srv: DashboardService

  ) { }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      try {

        let logInfo = JSON.stringify({ event });
        console.log(logInfo)
        this.srv.writeLog(logInfo).subscribe(res => { })
      }
      catch (e) {
        console.log(event)

      }
    }
    );
  }

}

import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {OperatorService} from '../../lib/serviceLib/operatorservice.service';
import {IOperator} from '../../lib/interfaces/operator';
import {MatDialog} from '@angular/material/dialog';
import { FormMode } from '../../lib/enums/form-mode.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ConfirmDialogComponent} from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent implements OnInit {
  @ViewChild('operatorDialog', {static: false}) operatorDialogTemplateRef: TemplateRef<any>;
  operatorDialogRef: MatDialogRef<any>;
  currentFormMode: FormMode;
  operatorFromGroup: FormGroup;
  operators: Partial<IOperator>[];
  currentEditingOperator: Partial<IOperator>;

  constructor(
    private operatorService: OperatorService,
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.operatorService.getAll().subscribe(_res => {
      //console.log(_res);
      this.operators = _res;
    });

    this.operatorFromGroup = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
      operatorName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      voipNo: new FormControl('', [Validators.required])
    });
  }

  get FormMode() {
    return FormMode;
  }

  openOperatorDialog(_mode: FormMode, _data?: Partial<IOperator>) {
    console.log('openOperatorDialog');
    this.currentFormMode = _mode;
    this.operatorFromGroup.reset();

    if (_mode === FormMode.Edit && _data) {
      this.currentEditingOperator = _data;
      this.operatorFromGroup.patchValue(this.currentEditingOperator);
    }

    this.operatorDialogRef = this.matDialog.open(this.operatorDialogTemplateRef, {
      hasBackdrop: true,
      width: '360px'
    });
  }

  delete(_data: Partial<IOperator>) {
    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید کاربر «' + _data.operatorName + '» حذف شود؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });

    confirmRef.afterClosed().subscribe(_confirmed => {
      if (_confirmed) {
        this.operatorService.delete(_data.id).subscribe(_res => {
          const index = this.operators.findIndex(_item => _item.id === _data.id);
          if (index > -1) {
            this.operators.splice(index, 1);
          }
          this.matSnackbar.open('اپراتور با شناسه ' + _data.id + ' حذف شد.', null, {duration: 5000});
        });
      }
    });
  }

  submit() {
    if (this.operatorFromGroup.valid) {
      const formValues: Partial<IOperator> = this.operatorFromGroup.value;

      if (this.currentFormMode === FormMode.Add) {

        this.operatorService.insert(formValues).subscribe(_id => {
          formValues.id = _id;
          this.operators.push(formValues);
          this.matSnackbar.open('اپراتور جدید با موفقیت افزوده شد.', null, {duration: 5000});
        });

      } else if (this.currentFormMode === FormMode.Edit) {

        this.currentEditingOperator.mobile = formValues.mobile;
        this.currentEditingOperator.operatorName = formValues.operatorName;
        this.currentEditingOperator.password = formValues.password;
        this.currentEditingOperator.username = formValues.username;
        this.currentEditingOperator.voipNo = formValues.voipNo;

        this.operatorService.update(this.currentEditingOperator).subscribe(_id => {
          this.matSnackbar.open('ویرایش اپراتور با موفقیت انجام شد.', null, {duration: 5000});
        });
      }

      this.operatorDialogRef.close();
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Swal from "sweetalert2";
import { AllServiceInfo } from '../../model/serviceInfo';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forms-list',
  templateUrl: './forms-list.component.html',
  styles: [`
    ::ng-deep .p-dialog-content {
      width: 100%;
    }
  `]
})
export class FormsListComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() data: any = [];
  @Output() visibleChange = new EventEmitter();
  allserviceList: AllServiceInfo[] = [];
  passangerMobile = '';
  driverCode = '';
  passangerName = '';
  originAddr = '';
  stationID = '';
  filteredList: AllServiceInfo[] = [];
  recordCount = 0;
  pageSize = 30;



  constructor(
    private currentServiceService: ServiceserviceService,
    private route: ActivatedRoute,
    private router: Router

  ) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      const guidDriver = params['guid'];
      this.passangerName = params['passangerName'] ?? '';
      this.passangerMobile = params['passangerMobile'] ?? '';
      this.originAddr = params['originAddr']  ?? '';
      this.driverCode = params['driverCode'] ?? '';
      this.stationID = params['stationID'] ?? '';
      if (guidDriver) {
        this.loadPassangerReport();
      }
    })
  }

  loadPassangerReport() {
    //console.log('loadPassangerReport');
    this.currentServiceService.reportAll().subscribe(res => {
      if (res.length > 0) {

        //console.log(res);
        this.allserviceList = res;
        this.filter();

      } else {
        Swal.fire('یافت نشد', 'موردی جهت جستجو یافت نشد!');

      }

    });
  }

  filter() {
 


    this.filteredList = [];
    //console.log(this.showwaiting);
    this.allserviceList.forEach((data) => {

      this.recordCount = this.filteredList.length;
      if (this.recordCount > this.pageSize )
       return;

      if ((this.driverCode && data.driverCode == this.driverCode)
        ||
        (this.passangerName && data.passangerName.includes(this.passangerName))
        ||
        (this.originAddr && data.passangerAddress.includes(this.originAddr))
        ||
        (this.stationID && data.stationID.toString() == (this.stationID))
        ||
        (this.passangerMobile && data.passangerMobile.includes(this.passangerMobile))
      ) {
        this.filteredList.push(data);
      }
      else


        if ((this.driverCode === '' || data.driverCode == this.driverCode)
          &&
          (this.passangerName === '' || data.passangerName.includes(this.passangerName))
          &&
          (this.originAddr === '' || data.passangerAddress.includes(this.originAddr))
          &&
          (this.stationID === '' || data.stationID.toString() == (this.stationID))
          &&
          (this.passangerMobile === '' || data.passangerMobile.toString() == (this.passangerMobile))
        ) {

 
            this.filteredList.push(data);

          //console.log('record count is ' + this.recordCount);
        }
    });
    this.recordCount = this.filteredList.length;
    this.allserviceList = this.filteredList;

  }

  editForm() {

  }

  newComplaint() {

  }

  newFounded() {

  }

}


import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceInfo } from '../../model/serviceInfo';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IShift } from '../../model/shiftTable';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { Driver } from '../../model/driver';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-shiftlisting',
  templateUrl: './shiftlisting.component.html',
  styleUrls: ['./shiftlisting.component.css']
})
export class ShiftlistingComponent implements OnInit {
  list: IShift[] = [];
  driverID = 0;
  myInfo: Driver;
  constructor(
    private dialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private sanitizer: DomSanitizer,

    public serviceService: DriverserviceService,
    public dialogRef: MatDialogRef<ShiftlistingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      driverID: number,
      myInfo: Driver
    }
  ) {
    this.driverID = data.driverID;
    this.myInfo = data.myInfo;
    console.log('this.driverID is' + this.driverID);
    this.serviceService.getAllShiftTable().subscribe(res => {
      this.list = res;
    });
  }

  ngOnInit(): void {

  }
  close() {
    this.dialogRef.close('closse');

  }
  getAvatar(avatar) {
    return this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + avatar)
  }
}

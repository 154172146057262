import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getDriverInfo } from '../../model/driver';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
@Component({
  selector: 'app-save-driver-info',
  templateUrl: './save-driver-info.component.html',
  styleUrls: ['./save-driver-info.component.scss']
})
export class SaveDriverInfoComponent implements OnInit {

  constructor(private driverService: DriverserviceService,) { }

  ngOnInit() {
    this.msg = '';
    this.callFormGroup = new FormGroup({
      nCode: new FormControl()
      
    });
  }
  submitCallForm() {
    
  }
  callFormGroup: FormGroup;
  driverInfo: getDriverInfo;
  driverFullName = new FormControl();
  driverMobile = new FormControl();
  getCall() {
    this.msg = '';
    this.driverService.getDriverByNCode(this.callFormGroup.value.nCode).subscribe(_res => {
      //console.log(_res);
      this.loadInfo(_res);
      }
    );
  }
  loadInfo(info: getDriverInfo) {
    this.msg = '';
    //console.log('loadinfo');
    //console.log(info);
    this.driverMobile.setValue(info.info.mobile);
    this.driverFullName.setValue(info.info.fName);
  }
  msg = '';
  saveInfo() {
    this.driverService.saveManualInfo(this.callFormGroup.value.nCode, this.driverMobile.value).subscribe(res => {
      //console.log(res);
      this.msg = res;
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StationQueueInfo } from '../../model/stationQueueInfo';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WebSocketService } from '../../lib/web-socket.service';
import { NotificationModel, SocketMessageType } from '../../model/socketmodels';
import { ProposedRequestComponent } from '../../driver/proposed-request/proposed-request.component';
import { Station } from '../../model/station';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { map } from 'rxjs/operators';
import { TaximapComponent } from '../../taximap/taximap.component';
import { driverStatusinStation } from '../../model/enumtype';
import { StationMemberComponent } from '../station-member/station-member.component';
import { DomSanitizer } from '@angular/platform-browser';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
@Component({
  selector: 'app-viewstation',
  templateUrl: './viewstation.component.html',
  styleUrls: ['./viewstation.component.css']
})
export class ViewstationComponent implements OnInit {
  @ViewChild("map", { static: false })
  public map: TaximapComponent;

  currentStatus = driverStatusinStation.None;
  currentPriority ='---';
  constructor(
    private socketService: WebSocketService,
    private route: ActivatedRoute,
    private stationSerivce: StationserviceService,
    private dialog: MatDialog,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {

    this.socketService.meesageList$.subscribe(msg => {
      if (msg) {
        this.processMessage(msg);
      }
    });

  }
  
  acceptRequest(guidRequestParam: any) {
    this.socketService.sendMessage(guidRequestParam, SocketMessageType.AcceptProposedRequest);
  }

  rejectRequest(guidRequestParam: any) {
    this.socketService.sendMessage(guidRequestParam, SocketMessageType.RejectProposedRequest);
  }
  gotoRunService(guidService: any) {
    //console.warn('service subbmited by :' + guidService);
    this.router.navigate(['service/origin'], { queryParams: { guid: guidService } });
  }
  dialogProposedServiceRef: MatDialogRef<ProposedRequestComponent, any>;
  dialogStationMemberRef: MatDialogRef<StationMemberComponent, any>;
  
  processMessage(messageBox: NotificationModel) {
    //console.warn(messageBox);
    switch (messageBox.msgType) {
      case SocketMessageType.GetClientInfo:
        this.socketService.sendMessage(this.driverGuid, SocketMessageType.SetDriverInfo);
        break;
      case SocketMessageType.ProposedRequestServive:
        this.showProposedRequest(messageBox.guidCode);
        break;
      case SocketMessageType.FinishedProposedRequestServive:
        this.dialogProposedServiceRef.close('NotAnswered');
        break;
      case SocketMessageType.RequestServiceCancelByPassanger:
        this.dialogProposedServiceRef.close('CancelByUser');
        break;
      case SocketMessageType.ServiceGetSubmitedSuccessfully:
        this.gotoRunService(messageBox.guidCode);
        break;


      default:
        break;
    }
  }

  driverGuid: string;
  stationID: number;
  queuelist: StationQueueInfo[];
  currentStation = new Station();

  ngOnInit() {
    this.map = new TaximapComponent();
    
    this.route.queryParams.subscribe(params => {
      this.driverGuid = params['guid'];
      this.stationID = params['id'];

      this.loadStation(this.stationID);
      if (params['showProposed']) {
        this.showProposedRequest('231');
      }
    });
    this.socketService.startSocket();
  }
  getLocation(): void {
    //console.warn("geolocation started")

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        //console.warn("getCurrentPosition is run")
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        this.map.addMarker(latitude, longitude);
        //console.warn(latitude);
        //console.warn(longitude);
      },
        ((err) => {
          //console.warn("error in get pos")
          //console.warn(err)

        })
      );
    } else {
      //console.warn("No support for geolocation")
    }
  }
  loadStation(stationID: any) {
    this.stationSerivce.getStationQueue(this.stationID).subscribe(_res => {
      this.queuelist = _res;
      this.queuelist.forEach((data) => {
        data.safeAvatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + data.avatar);
      })
      this.getLocation();
    });
    this.stationSerivce.getStation(this.stationID).subscribe(_res => {
      this.currentStation = _res;
      this.map.addStationMarkerWithInfo(this.currentStation);
    });

  }

  addToQueue() {
    this.stationSerivce.addToQueue(this.stationID, this.driverGuid).subscribe(_res => {
      //console.warn('priority :' + _res);
      this.currentStatus = driverStatusinStation.InQueue;
      this.currentPriority = _res;
    });
  }

  removeFromQueue() {
    this.stationSerivce.removeFromQueue(this.stationID, this.driverGuid).subscribe(_res => {
      this.currentStatus = driverStatusinStation.None;
    });
  }

  showProposedRequest(guidRequestParam: any) {
    this.dialogProposedServiceRef = this.dialog.open(ProposedRequestComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        guidRequest: guidRequestParam
      }
    });
    this.dialogProposedServiceRef.afterClosed().subscribe((result : any) => {
      if (result == 'Accept') {
        this.acceptRequest(guidRequestParam);
      } else if (result == 'Reject') {
        this.rejectRequest(guidRequestParam);
      }
      else if (result == 'NULL') {

        this.rejectRequest(guidRequestParam);
      }
    });

    //this.taxiSerivce.getRequestByPropsedCode(guidRequestParam).subscribe(_res => {
    //  console.warn(_res);
    //});

  }
  showStationMember() {
    this.dialogStationMemberRef = this.dialog.open(StationMemberComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        stationID: this.currentStation.id,
        queue: this.queuelist
        
      }
    });
  }
}

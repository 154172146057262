export class Dashboard {
  constructor(
  ) {
    this.todayCount = 0;
    this.monthCount = 0;

  }
  todayCount:number;
  monthCount:number;


}

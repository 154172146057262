import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveservicresultType } from '../../model/saveserviceresult';
import { AllServiceInfo } from '../../model/serviceInfo';
import { StationNeighbor } from '../../model/station';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';

@Component({
  selector: 'app-changestation',
  templateUrl: './changestation.component.html',
  styleUrls: ['./changestation.component.scss']
})
export class ChangestationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChangestationComponent>,
    private currentStationService: StationserviceService,
    private currentServiceService: ServiceserviceService,
    private taxiSerivce: ServiceRequestService,
    private matSnackbar: MatSnackBar,

    @Inject(MAT_DIALOG_DATA) public data: { info: AllServiceInfo }) {
    this.currentInfo = data.info;
  }
  currentInfo: AllServiceInfo;
  ngOnInit() {

    this.currentStationService.getPredictStation(this.data.info.requestID).subscribe(res => { this.currentList = res; });
    this.dialogRef.keydownEvents().subscribe((event: any) => {
      if (event.key === "Escape") {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event: any) => {
      this.onCancel();
    });

  }
  onCancel(): void {
    this.dialogRef.close('');
  }
  close(driverCode: any) {
    //console.log(driverCode);
    //console.log(this.data.info.serviceGuid);


    this.currentServiceService.saveServiceManualByAdmin(this.data.info.serviceGuid, driverCode,2).subscribe(res => {
      if (res.result == saveservicresultType.driverNotFound) {
        this.matSnackbar.open('سفر دیگری دارد.', null, { duration: 3000 });
      }
      else
        this.dialogRef.close('Accept');


      //console.log('accept finish' + res);
    });

    this.dialogRef.close(driverCode);

  }

  currentList: StationNeighbor[];
}

import { Component, EventEmitter, Input, OnInit, Output,AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { Complaint } from '../../model/complaint';
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from '@angular/router';
import { ComplaintserviceService } from '../../lib/serviceLib/complaintservice.service';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html'
})
export class ComplaintsComponent implements OnInit, AfterViewInit {
  @Input() visible: boolean = true;
  @Input() data: any = [];
  @Output() visibleChange = new EventEmitter();
  allComplaintList: Complaint[] = [];
  @ViewChild('android')
  public android: ElementRef;
  isLoading: boolean = false;
  detailsVisible: boolean = false;
  public result: any[] = [];

  recordCount = 0;
  pageSize = 30;



  constructor(
    private complaintServiceService: ComplaintserviceService,
    private route: ActivatedRoute,
    private router: Router

  ) {
  }

  operatorguid: string;

  complaintForm: FormGroup;

  ngOnInit(): void {
    this.complaintForm = new FormGroup({
      passangerFName: new FormControl(),
      passangerMobile: new FormControl(),
      originAddr: new FormControl(),
      driverCode: new FormControl(),
      description: new FormControl(),
      destinationAddr: new FormControl()
    });

    this.allComplaintList = [];
    this.loadComplaintReport();
  }
  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
  }

  async submitFormAndSend() {

  
    this.loadComplaintReport();
  }
  loadComplaintReport() {
    this.complaintServiceService.reportAllComplaint(this.complaintForm.get('passangerFName')?.value, this.complaintForm.get('passangerMobile')?.value, this.complaintForm.get('driverCode')?.value, this.complaintForm.get('originAddr')?.value, this.complaintForm.get('destinationAddr')?.value, this.complaintForm.get('description')?.value).subscribe(res => {
      if (res.length > 0) {
        //console.log(res);

        this.allComplaintList = res;
        //this.filter();
      } else {
        this.allComplaintList = [];

        Swal.fire('یافت نشد', 'موردی جهت جستجو یافت نشد!');
      }
    });
  }

  resetForm() {
    this.complaintForm.reset();
    this.allComplaintList = [];

  }

}


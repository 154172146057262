import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { FormMode } from '../../lib/enums/form-mode.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ConfirmDialogComponent} from '../../shared/confirm-dialog/confirm-dialog.component';
import { BaseInfoService } from '../../lib/serviceLib/baseinfo.service';
import { IBaseInfo, IBaseInfoMaster } from '../../lib/interfaces/Ibaseinfo';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-operators',
  templateUrl: './baseinfo.component.html',
  styleUrls: ['./baseinfo.component.scss']
})
export class BaseInfoComponent implements OnInit {

  currentGroupID: number=2;
  currentMasterInfo: Partial<IBaseInfoMaster> = {} ;
  @ViewChild('operatorDialog', {static: false}) operatorDialogTemplateRef: TemplateRef<any>;
  operatorDialogRef: MatDialogRef<any>;
  currentFormMode: FormMode;
  operatorFromGroup: FormGroup;
  operators: Partial<IBaseInfo>[];
  currentEditingOperator: Partial<IBaseInfo>;

  constructor(
    private baseIService: BaseInfoService,
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
  }
  loadMaster() {
    this.baseIService.getMasterInfo(this.currentGroupID).subscribe(_res => {
      this.currentMasterInfo = _res;
      console.log('master info details');
      console.log(_res);
    });
  }
  loadData() {
    this.loadMaster();

    this.baseIService.getList(this.currentGroupID).subscribe(_res => {
      this.operators = _res;
      console.log('baseinfo details');
      console.log(_res);
    });
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['gid']) {
        if (this.currentGroupID != params['gid']) {
          this.currentGroupID = params['gid'];
          this.loadData();
        }
        this.currentGroupID = params['gid'];
      }
    });
    this.loadMaster();
    this.operatorFromGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      comment: new FormControl('', []),
    });
  }

  get FormMode() {
    return FormMode;
  }

  openOperatorDialog(_mode: FormMode, _data?: Partial<IBaseInfo>) {
    console.log('openOperatorDialog');
    this.currentFormMode = _mode;
    this.operatorFromGroup.reset();

    if (_mode === FormMode.Edit && _data) {
      this.currentEditingOperator = _data;
      this.operatorFromGroup.patchValue(this.currentEditingOperator);
    }

    this.operatorDialogRef = this.matDialog.open(this.operatorDialogTemplateRef, {
      hasBackdrop: true,
      width: '360px'
    });
  }

  delete(_data: Partial<IBaseInfo>) {
    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید  «' + _data.name + '» حذف شود؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });

    confirmRef.afterClosed().subscribe(_confirmed => {
      if (_confirmed) {
        this.baseIService.delete(_data.id).subscribe(_res => {
          const index = this.operators.findIndex(_item => _item.id === _data.id);
          if (index > -1) {
            this.operators.splice(index, 1);
          }
          this.matSnackbar.open('رکورد با شناسه ' + _data.id + ' حذف شد.', null, {duration: 5000});
        });
      }
    });
  }

  submit() {
    if (this.operatorFromGroup.valid) {
      const formValues: Partial<IBaseInfo> = this.operatorFromGroup.value;
      formValues.groupId = this.currentGroupID;
      if (this.currentFormMode === FormMode.Add) {
        this.baseIService.insert(formValues).subscribe(_id => {
          formValues.id = _id;
          this.operators.push(formValues);
          this.matSnackbar.open('رکورد جدید با موفقیت افزوده شد.', null, {duration: 5000});
        });

      }
      else if (this.currentFormMode === FormMode.Edit) {
        this.currentEditingOperator.name = formValues.name;
        this.currentEditingOperator.comment = formValues.comment;

        this.baseIService.update(this.currentEditingOperator).subscribe(_id => {
          this.matSnackbar.open('ویرایش سطر با موفقیت انجام شد.', null, {duration: 5000});
        });
      }

      this.operatorDialogRef.close();
    }
  }
}

export class routingInfo {
  distanceValue: string;
  distanceText: string;
  durationValue: string;
  durationText: string;
  polylines: string[];
  summaryTotal: string;
}


 
export class searchResult {
  category: string;
  type: string;
  region: string;
  neighbourhood: string;
  x: number;
  y: number;
  title: string;
  address: string;
}




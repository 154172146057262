import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Subject} from "rxjs";

@Component({
  selector: 'app-time-picker',
  template: `
<div class="float-input-container">
<span class="p-float-label">
      <p-calendar
        class="w-100 p-mr-1 "
        [(ngModel)]="model"
        [appendTo]="appendTo"
        (onSelect)="onChangeListener.next($event)"
        [timeOnly]="true" inputId="timeonly"></p-calendar>
      <label for="timonly">{{label}}</label>
</span>
</div>
  `,
  styles: [`


    ::ng-deep .p-timepicker {
      direction: ltr !important;
    }

    label {
      font-family: IranYekan;
    }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true,
  }]
})
export class TimePickerComponent implements ControlValueAccessor {
  @Input() label: any = '';
  @Input() appendTo: any = 'body';

  model: any;

  onChangeListener = new Subject();

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChangeListener.subscribe(x => {
      fn(x);
    });
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(val: any): void {
    this.model = val;
  }

}

import { MatDatepickerToggleIcon } from "@angular/material/datepicker";
import { LocationInfo } from "../globalVariable";

export class ServiceRequest {
  constructor(
  ) {
    this.id = 0;
    this.requestGUI = '';
    this.destinationLat = 0;
    this.destinationLat2 = 0;
    this.destinationLng = 0;
    this.destinationLng2 = 0;
    this.originLat = 0;
    this.originLng = 0;
    this.passangerID = 0;
    this.maeloolin = false;
    this.amanati = false;
    this.khadamat = false;
    this.darEkhtiar = false;
    this.pos = false;
    this.typeCars = '';
    this.passangerFName = "";
    this.passangerMobile = "";
    this.driverCode = '';
    this.fixedComment = '';
    this.passangerServiceCount = 0;
    this.requestComment = '';
    this.price = 0;
    this.operatorID = 0;
    this.typePardakht = '';
    this.typePardakhtName = 'پس پرداخت';
    this.dateTimeForDisplay = '';
    
  }
  id:number;
  requestGUI :string;
  originLat:number;
  originLng: number;
  destinationLat: number;
  destinationLng: number;
  destinationLat2: number;
  destinationLng2: number;
  createDateTime:any;
  passangerID: number;
  result: number;
  originAddr: string;
  destinationAddr: string;
  destination2Addr: string;
  daleyTime: number;
  distanceKM: number;
  distanceMinute: number;
  originMeter: number;
  passangerFName: string;
  passangerMobile: string;
  typeCars: string;
  pos: boolean;
  maeloolin: boolean;
  darEkhtiar: boolean;
  amanati: boolean;
  khadamat: boolean;
  driverCode: string;
  fixedComment: string;
  requestComment: string;
  price: number;
  maleDriver: boolean;
  femaleDriver: boolean;
  telIDCaller: string;
  passangerServiceCount: number;
  selectedStationID: number;
  createTime: string;
  acceptTime: string;
  operatorID: number;
  typePardakht: string;
  typeService: string;
  moarefID: string;
  typePardakhtName: string;
  dateTimeForDisplay: string;
}


export class ManualServiceRequest {
  
  id: number=0;
  requestGUID: string='';
  originLat: number=0;
  originLng: number = 0;
  destinationLat: number = 0;
  destinationLng: number = 0;
  destinationLat2: number=0;
  destinationLng2: number=0;
  createDateTime: any;
  passangerID: number = 0;
  result: number = 0;
  originAddr: string='';
  destinationAddr: string = '';
  destination2Addr: string='';
  daleyTime: number=0;
  distanceKM: number = 0;
  distanceMinute: number = 0;
  originMeter: number = 0;
  passangerName: string='';
  passangerMobile: string = '';
  comment: string = '';
  darEkhtiar: boolean = false;



}

export class PropsoedReport {
  id: number;
  dateAndTime: string;
  driverID: number;
  responseText: string;
  proposedID: number;
  requestID: number;
  driverCode: string;
  result: number;
  stationCode: string;

  timeText: string;
  typeName: string;
  resultName: string;


}

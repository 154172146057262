import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { Direction } from "@angular/cdk/bidi";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: 'app-date-picker',
  template: `
    <div class="d-flex float-input-container mt-2">
      <span class="p-float-label">
        <input
          [matDatepicker]="picker"
          (click)="picker.open()"
          [(ngModel)]="model"
          (ngModelChange)="onChangeListener.next($event)"
          id="float-input" type="text" pInputText>
        <label for="float-input">{{label}}</label>
    </span>
      <mat-datepicker
        #picker></mat-datepicker>
    </div>
  `,
  styles: [`
    label {
      font-family: IranYekan;
    }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }]
})
export class DatePickerComponent implements ControlValueAccessor {
  registerOnChange(fn: any): void {
    this.onChangeListener.subscribe(x => {
      fn(this.model);
    })
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(val: any): void {
    this.model = val;
  }

  model: any = new Date();
  @Input() label: any = '';
  @Input() textAlign: CanvasTextAlign = 'left';
  @Input() inputDirection: Direction = 'ltr';
  @Output() onClick = new EventEmitter<any>();
  onChangeListener = new Subject();

  constructor() {
  }


}

//import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { OperatorService } from '../../lib/serviceLib/operatorservice.service';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginFormGroup: FormGroup;
  @ViewChild('android')
  public android: ElementRef;

  errorText = 'اطلاعات وارد شده صحیح نمی باشد';
  hasError = false;

  constructor(private opService: OperatorService, private router: Router) {

    //var cur_url = localStorage.getItem(environment.cur_url);
    //if (cur_url && cur_url.includes("guid")) {
    //  let regexT = '/' + window.location.origin.replace("https://", "") + '([\\w/]+)';
    //  let guidCache = cur_url.match(/guid=([\w-]+)/)[1];
    //  let routeCache = cur_url.match(regexT)[1];
    //  this.router.navigate([routeCache], { queryParams: { guid: guidCache } });
    //}
    //else {
      localStorage.removeItem(environment.cur_url);

      this.loginFormGroup = new FormGroup({
        username: new FormControl(''),
        password: new FormControl('')
      });
    //}
     
  }

  ngOnInit() {



  }

  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
  }

  submit() {
    this.hasError = false;
    var cur_fbsToken = localStorage.getItem(environment.cur_fbsToken);

    this.opService.loginAll(this.loginFormGroup.value.username, this.loginFormGroup.value.password, cur_fbsToken).subscribe(res => {
      console.log(res);
        if (res) {
            if (res.errorString && res.errorString != "") {
              this.hasError = true;
              this.errorText = res.errorString;
            }
            else {
              if (res.isOperator && res.operatorData)
                this.router.navigate(['cc/dashboard'], { queryParams: { guid: res.operatorData.guidCode } });
              else if (res.isDriver && res.driverData) {
                localStorage.setItem(environment.cur_u_tkn, res.driverData.tk);
                localStorage.setItem(environment.cur_u_name, res.driverData.info.guid);
                console.log('environment.cur_u_name is ');
                console.log(res.driverData.info.guid);
                console.log('res.driverData.tk is ');
                console.log(res.driverData.tk);


                console.log('cur_u_tkn is ');
                console.log(localStorage.getItem(environment.cur_u_tkn));


                this.router.navigate(['driver/test'], { queryParams: { guid: res.driverData.info.guid } });

              }
            }
        }

    });
     
  }

}

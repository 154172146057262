import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../../lib/serviceLib/dashboardservice.service'
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service'
import { Dashboard } from '../../model/dashboard';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [`
    .count-number {
      font-family: IranSans;
      font-weight: bold;
      font-size: 1.8rem;
    }

    .title {
      font-family: IranYekan;
    }
  `]
})
export class DashboardComponent implements OnInit, AfterViewInit {
  operatorguid: any;
  telID = new FormControl('123');
  dashboard = new Dashboard();
  @ViewChild('android')
  public android: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private callCenterService: CallCenterService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    localStorage.setItem(environment.cur_url, window.location.href);

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];

      this.dashboardService.getDashboard(this.operatorguid).subscribe(t => {
        this.dashboard.todayCount = t.todayCount;
        this.dashboard.monthCount = t.monthCount;

      });

    });





  }

  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
  }

  getCallClick() {
    this.router.navigate(['cc/new'], { queryParams: { guid: this.operatorguid, telID: this.telID.value } });

  }

}

import { Component, OnInit } from '@angular/core';
import { ICommissionReport, IMonthbyMonthReport } from '../../lib/interfaces/iServiceReport'
import { ReportService } from '../../lib/serviceLib/report.service'
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-monthbymonth',
  templateUrl: './monthbymonth.component.html',
  styleUrls: ['./monthbymonth.component.css']
})
export class MonthbymonthComponent implements OnInit {

  constructor(private repSrv: ReportService,
    private route: ActivatedRoute
  ) {
    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.driverID = params['did'];

    });
    this.report();
    
  }
  driverID = 11;
  totalprice = 0;
  totalcommission = 0;
  totalmande = 0;
  totalEtebari = 0;
  totaldebitCreditMande = 0;
  reports: IMonthbyMonthReport[];
  driveracc: { [id: number]: number; } = {};
  count = 0;

  calctotal() {
    this.totalprice = 0;
    this.totalcommission = 0;
    this.totalmande = 0;

    this.count = this.reports?.length ?? 0;
    this.reports.forEach(t => {

      if (!t.debitCreditMande) {
        t.debitCreditMande = 0;
      }


      if (t.sumPrice) {
        
        this.totalprice += t.sumPrice;

        this.totalcommission += t.commissionValue;
        this.totalmande += t.mande;
        this.totalEtebari += t.sumEtebari;
        this.totaldebitCreditMande += t.debitCreditMande;

      }
      else
        t.sumPrice = 0;


    })
  }
  calcMande() {
    var first = true;
    var MandeKol = this.commissionInfo.mande;
    this.reports.reverse().forEach(t => {

      if (first) {
        t.mandeMain = MandeKol;
        MandeKol = t.mandeMain + t.mande;
        first = false;
      }
      else {
        t.mandeMain = MandeKol;
        MandeKol = t.mandeMain + t.mande;

      }
    })
  }
  commissionInfo: ICommissionReport;
  report() {
   

    this.repSrv.MonthByMonthReport(this.driverID).subscribe(t => {
      this.reports = t;
      console.log(t);
      this.calctotal();
      this.repSrv.CommissionReport('', this.driverID).subscribe(t => {
        this.commissionInfo = t[0];
        this.calcMande();
      });

      
    });
  }
}

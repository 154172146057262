
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import { GlobalVariable } from './../../globalVariable';
import { Driver, DriverLog, DriverStatusInfo, getDriverInfo, NationalCodeCheckResult } from './../../model/driver';
import { Car } from './../../model/car';
import { Observable, Subscriber } from 'rxjs';
import { VerificationOutput } from './../../model/verificationOutput';
import { DriverPositionHist } from '../../model/driver';
import { DebitCredit } from '../../model/debitcredit';

@Injectable({
  providedIn: 'root'
})
export class DriverserviceService {
  public moke = GlobalVariable.mokeFLAG;
  private DriverBaseServiceURL = GlobalVariable.DriverBaseServiceURL;
  constructor(private httpClient: HttpClient) { }

  public getDriverByNationalCode(nCode: any, mobile: any): Observable<NationalCodeCheckResult> {
    let params = new HttpParams().set('nCode', nCode).set('mobile', mobile);
    return this.httpClient.post<NationalCodeCheckResult>(this.DriverBaseServiceURL + "/GetInfo", params);
  }
  public seenAlarm(fbsToken: any): Observable<any> {
    let params = new HttpParams().set('FBSToken', fbsToken);
    return this.httpClient.post(this.DriverBaseServiceURL + "/SeenAlarm", params);
  }
  
  public getDriverBySuccessfuGUID(guid: any): Observable<getDriverInfo> {
   
    let body = new HttpParams().set('guid', guid);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/GetInfoBySuccessfullGUID",body );
  }
  public getDriverSuccessfullGUIDWithToken(newtoken: any, oldToken: any, version: any): Observable<string> {

    let body = new HttpParams().set('newtoken', newtoken).set('oldToken', oldToken).set('Version', version);
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/getDriverSuccessfullGUIDWithToken", body,{ responseType: 'text' as 'json' });
  }
  public saveLauncherInfo(newtoken: any, oldToken: any, version: any): Observable<string> {

    let body = new HttpParams().set('newtoken', newtoken).set('oldToken', oldToken).set('Version', version);
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/saveLauncherInfo", body, { responseType: 'text' as 'json' });
  }
  public setPriority(driverCode: any, stationID: any, newPriority: any): Observable<string> {
    let body = new HttpParams().set('driverCode', driverCode).set('stationID', stationID).set('newPriority', newPriority);
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/SetPriority", body, { responseType: 'text' as 'json' });
  }
  public readDefaultStation(driverID: number) {
    let params = new HttpParams().set('DriverID', driverID.toString());
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/GetDefaultStation", params, { responseType: 'text' as 'json' });
  }
  public updateDefaultStation(driverID, stationID): Observable<number> {

    let params = new HttpParams().set('DriverID', driverID);
    params = params.set('StationID', stationID);


    return this.httpClient.post<number>(`${this.DriverBaseServiceURL}/UpdateDefaultStation`, params);
  }
  public resetVanQueue(): Observable<number> {

    return this.httpClient.post<number>(`${this.DriverBaseServiceURL}/ResetVanQueue`, {});
  }

  

  public startPay(driverID): Observable<string> {

    let params = new HttpParams().set('DriverID', driverID);
     


    return this.httpClient.post<string>(`${this.DriverBaseServiceURL}/StartPay`, params, { responseType: 'text' as 'json' });
  }

  

  postFile(fileToUpload: File, driverID): Observable<boolean> {


    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    formData.append('driverID', JSON.stringify(driverID));
    

    
    return this.httpClient
      .post<boolean>(`${this.DriverBaseServiceURL}/UploadImage`, formData);
      //.map(() => { return true; })
      //.catch((e) => console.log(e));
  }

  public getDriverFBSToken(token: any): Observable<getDriverInfo> {

    let body = new HttpParams().set('token', token);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/GetInfoByFBSToken", body);
  }
  public getDriverWithoutFBSToken(driverID: any): Observable<getDriverInfo> {

    let body = new HttpParams().set('DriverID', driverID);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/GetInfoWithoutFBSToken", body);
  }
  public verifyToken(guidD: any, guidT: any): Observable<getDriverInfo> {
    let params = new HttpParams().set('guidD', guidD).set('guidT', guidT);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/VerifyToken", params);
  }

  public getDriverByGUID(guid: any): Observable<getDriverInfo> {
    let params = new HttpParams().set('guid', guid);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/GetInfoByGUID", params);
  }

  public getDriverByID(ID: any): Observable<getDriverInfo> {
    let params = new HttpParams().set('ID', ID);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/GetDriverInfoByID", params);
  }

  public getDriverByFBSToken_OnlyInfo(fbsToken: any): Observable<getDriverInfo> {
    let params = new HttpParams().set('FbsToken', fbsToken);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/GetOnlyInfoByFBS", params);
  }
  public getDriverByNCode(nCode: any): Observable<getDriverInfo> {
   
    let params = new HttpParams().set('nCode', nCode);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/getDriverByNCode", params);
  }

  public sendVerification(guid: any):Observable<string> {
   
    let params = new HttpParams().set('guid', guid);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/SendVerification", params,{ responseType: 'text' as 'json' });
  }
  public GetSuccesfullService(guidCode: any, actionstring: any): Observable<string> {
    let params = new HttpParams().set('guidCode', guidCode).set('acttionName', actionstring);;
    //console.log(params);
    //console.log(actionstring);

    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/GetSuccesfullService", params,{ responseType: 'text' as 'json' });
  }
  public SetRejectService(guidCode: any): Observable<string> {

    let params = new HttpParams().set('guidCode', guidCode);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/GetSuccesfullService", params,{ responseType: 'text' as 'json' });
  }

  
  public checkVerification(code: any, guidCode: any, guidDriver: any): Observable<VerificationOutput> {
    
    let params = new HttpParams().set('code', code);
    params = params.set('guidCode', guidCode);
    params = params.set('guidDriver', guidDriver);
    return this.httpClient.post<VerificationOutput>(this.DriverBaseServiceURL + "/CheckVerification", params);
  }
  public setSuccessfullResult(guid: any, result: any): Observable<any> {
    if (this.moke) {
      return new Observable<void>((subscriber: Subscriber<void>) => subscriber.next());

    }
    else {
      let params = new HttpParams().set('guid', guid);
      params = params.set('result', result);
      return this.httpClient.post(this.DriverBaseServiceURL + "/setSuccessfullResult", params);
    }
  }

  public logOutFToken(fbsToken: any): Observable<any> {
    let params = new HttpParams().set('fbsToken', fbsToken);
    return this.httpClient.post(this.DriverBaseServiceURL + "/LogOutFToken", params);
  }
  public getAndFetch_StatusInfo(driverGUID: any, Status: any, lat: any, lng: any, stationID: any): Observable<DriverStatusInfo> {
    if (!stationID || stationID == 'undefined')
      stationID = 0;
    let params = new HttpParams().set('driverGUID', driverGUID);
    params = params.set('Status', Status);
    params = params.set('lat', lat);
    params = params.set('lng', lng);
    params = params.set('stationID', stationID);

    return this.httpClient.post <DriverStatusInfo>(this.DriverBaseServiceURL + "/getAndFetch_StatusInfo", params);

  }
  public getLastStatus(driverGUID: any): Observable<DriverStatusInfo> {
    let params = new HttpParams().set('driverGUID', driverGUID);
    return this.httpClient.post<DriverStatusInfo>(this.DriverBaseServiceURL + "/GetLastStatus", params);
  }
  public getCarByDriverID(driverID: any): Observable<Car> {
    let params = new HttpParams().set('DriverID', driverID);
    return this.httpClient.post<DriverStatusInfo>(this.DriverBaseServiceURL + "/GetCarInfo", params);
  }

  public updateDriverStatus(driverGUID: any, Status: any, goOnline : any): Observable<any> {
    let params = new HttpParams().set('driverGUID', driverGUID);
    params = params.set('Status', Status);
    params = params.set('GoOnline', goOnline == true ? "1" : "0");
    return this.httpClient.post(this.DriverBaseServiceURL + "/UpdateDriverStatus", params);
  }

  public SaveNotifInfo(driverGUID: any, endpoint2: any, auth2: any, p256dh: any): Observable<any> {
    let params = new HttpParams().set('driverGUID', driverGUID);
    params = params.set('endpoint', endpoint2);
    params = params.set('auth', auth2);
    params = params.set('p256dh', p256dh);
    

    return this.httpClient.post(this.DriverBaseServiceURL + "/SaveNotifInfo", params);
  }
 
  public handleShahremanToken(token: any): Observable<NationalCodeCheckResult> {
    let params = new HttpParams().set('Token', token);
    return this.httpClient.post<NationalCodeCheckResult>(this.DriverBaseServiceURL + "/HandelToken", params);
  }
  public saveManualInfo(ncode: any, mobile: any): Observable<string> {
    let params = new HttpParams().set('ncode', ncode).set('mobile', mobile);
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/saveManualInfo", params,{ responseType: 'text' as 'json' });

    
  }
  public getDriverByCode(driverCode: any): Observable<string> {
    let params = new HttpParams().set('DriverCode', driverCode);
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/getDriverFromCode", params,{ responseType: 'text' as 'json' });
  }
  public saveDriverFBS(driverID: any, fbsToken: any): Observable<string> {
    let params = new HttpParams().set('driverID', driverID).set('fbsToken', fbsToken);
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/saveDriverFBS", params,{ responseType: 'text' as 'json' });
  }
  
  public getAllLog(driverID: any): Observable<DriverLog[]> {
    let params = new HttpParams().set('DriverID', driverID);
    return this.httpClient.post<DriverLog[]>(`${this.DriverBaseServiceURL}/ListLog`, params);
  }
  public getAllDriver(): Observable<Driver[]> {
    let params = new HttpParams();
    return this.httpClient.post<Driver[]>(`${this.DriverBaseServiceURL}/GetAllDriver`, params);
  }

  public getAll(mobile: any, fName: any, driverCode: any, nationalCode: any, statuses: any): Observable<Driver[]> {
    let params = new HttpParams().set('Mobile', mobile);
    params = params.set('FName', fName);
    params = params.set('DriverCode', driverCode);
    params = params.set('NationalCode', nationalCode);
    if (typeof statuses == 'undefined' || !statuses) {
      statuses = '';
    }
    params = params.set('statuses', statuses);

    return this.httpClient.post<Driver[]>(`${this.DriverBaseServiceURL}/GetAll`, params);
  }
  public getAllDebit(refID: number, refType: number): Observable<DebitCredit[]> {
    let params = new HttpParams().set('RefID', refID.toString());
    params = params.set('RefType', refType.toString());

    return this.httpClient.post<DebitCredit[]>(`${this.DriverBaseServiceURL}/GetAllDebit`, params);
  }

  public abonmanList(year: string, month: string): Observable<Driver[]> {
    let params = new HttpParams().set('Year', year );
    params = params.set('Month', month.toString());

    return this.httpClient.post<DebitCredit[]>(`${this.DriverBaseServiceURL}/AbonmanList`, params);
  }



  public getAllForMonitor(mobile: any, fName: any, driverCode: any, nationalCode: any, statuses: any): Observable<Driver[]> {
    let params = new HttpParams().set('Mobile', mobile);
    params = params.set('FName', fName);
    params = params.set('DriverCode', driverCode);
    params = params.set('NationalCode', nationalCode);
    if (typeof statuses == 'undefined' || !statuses) {
      statuses = '';
    }
    params = params.set('statuses', statuses);

    return this.httpClient.post<Driver[]>(`${this.DriverBaseServiceURL}/GetAllForMonitor`, params);
  }

  public getAllDriverPosition(mobile: any, fName: any, driverCode: any, passangerName: any, passangerMobile: any, tarikhAz: any, tarikhTa: any, timeAz: any, timeTa: any): Observable<DriverPositionHist[]> {
    let params = new HttpParams().set('Mobile', mobile);
    params = params.set('FName', fName);
    params = params.set('DriverCode', driverCode);
    params = params.set('PassangerName', passangerName);
    params = params.set('PassangerMobile', passangerMobile);
    params = params.set('TarikhAz', tarikhAz);
    params = params.set('TarikhTa', tarikhTa);
    params = params.set('TimeAz', timeAz);
    params = params.set('TimeTa', timeTa);


    return this.httpClient.post<DriverPositionHist[]>(`${this.DriverBaseServiceURL}/GetAllDriverPosition`, params);
  }
  public insert(value: Partial<Driver>): Observable<number> {
    console.log('insert')
    console.log(value)

    let params = new HttpParams().set('Mobile', value.mobile);
    params = params.set('FName', value.fName);
    params = params.set('WorkingMobile', value.workingMobile);
    params = params.set('DriverCode', value.driverCode);
    params = params.set('Mobile', value.mobile);
    params = params.set('NationalCode', value.nationalCode);
    params = params.set('Color', value.color.toString());
    params = params.set('TypeCar', value.typeCar.toString());
    params = params.set('Pelaque2', value.pelaque2.toString());
    params = params.set('Pelaque3', value.pelaque3.toString());
    params = params.set('PelaqueIR', value.pelaqueIR);
    params = params.set('PelaqueLetter', value.pelaqueLetter);
    params = params.set('Username', value.username);
    params = params.set('Password', value.password);
    return this.httpClient.post<number>(this.DriverBaseServiceURL + '/InsertNew', params);
  }

  public insertdebit(value: Partial<DebitCredit>, refID: number, IsCredit: boolean, operatorguid:string): Observable<number> {
    console.log('insert')
    console.log(value)

    let params = new HttpParams().set('Price', value.price.toString());
    params = params.set('Comment', value.comment);
    params = params.set('RefID', refID.toString());
    params = params.set('IsCredit', IsCredit.toString());
    params = params.set('OperatorGUID', operatorguid);
    
    return this.httpClient.post<number>(this.DriverBaseServiceURL + '/InsertNewDebit', params);
  }

  public lock(driverID: any, lockComment: any, date: any, hour: any, minute: any): Observable<number> {
    let params = new HttpParams().set('driverID', driverID);
    params = params.set('LockComment', lockComment);
    params = params.set('date', date);
    params = params.set('hour', hour);
    params = params.set('minute', minute);
    return this.httpClient.post<number>(this.DriverBaseServiceURL + '/LockDriver', params);
  }
  public unlock(driverID: any): Observable<number> {
    let params = new HttpParams().set('driverID', driverID);
    return this.httpClient.post<number>(this.DriverBaseServiceURL + '/UnLockDriver', params);
  }
  public update(value: Partial<Driver>): Observable<number> {
    console.log('update ');
    console.log(value);
    let params = new HttpParams().set('Mobile', value.mobile);
    params = params.set('FName', value.fName);
    params = params.set('DriverCode', value.driverCode);
    params = params.set('NationalCode', value.nationalCode);
    params = params.set('WorkingMobile', value.workingMobile);
    params = params.set('typeCar', value.typeCar.toString());
    params = params.set('color', value.color.toString());
    params = params.set('pelaqueIR', value.pelaqueIR);
    params = params.set('pelaqueLetter', value.pelaqueLetter);
    params = params.set('pelaque2', value.pelaque2.toString());
    params = params.set('pelaque3', value.pelaque3.toString());
    params = params.set('Username', value.username);
    params = params.set('Password', value.password);
    params = params.set('NationalCode', value.nationalCode);
    params = params.set('ID', value.id.toString());
    return this.httpClient.post<number>(`${this.DriverBaseServiceURL}/Update`, params);
  }
  public updateComission(driverID , comission): Observable<number> {
    
    let params = new HttpParams().set('DriverID', driverID);
    params = params.set('Comission', comission);
    
    
    return this.httpClient.post<number>(`${this.DriverBaseServiceURL}/UpdateComission`, params);
  }
  public updateAbonman(driverID, abonman): Observable<number> {

    let params = new HttpParams().set('DriverID', driverID);
    params = params.set('Abonman', abonman);


    return this.httpClient.post<number>(`${this.DriverBaseServiceURL}/UpdateAbonman`, params);
  }

  public delete(opID: any): Observable<any> {
    const params = new HttpParams().set('ID', opID);
    return this.httpClient.post(`${this.DriverBaseServiceURL}/Delete`, params);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorOutput } from '../../model/enumtype';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
 
@Component({
  selector: 'app-waitforconfirm',
  templateUrl: './waitforconfirm.component.html',
  styleUrls: ['./waitforconfirm.component.scss']
})
export class WaitforconfirmComponent implements OnInit {

  constructor(private router: Router, private driverService: DriverserviceService, private route: ActivatedRoute) { }
  
  guid: string;
  ngOnInit() {
    
    this.route.queryParams.subscribe(params => {
      this.guid = params['guid'];
      this.loadDriverInfo(this.guid);
    });
  }

  base64Image: any;
  loadDriverInfo(guid: any) {
    this.driverService.getDriverBySuccessfuGUID(guid).subscribe(_res => {
      //console.warn('loadDriverInfo guid ' + guid);
      //console.warn('loadDriverInfo');
      //console.warn(_res);
      if (_res.errorCode != ErrorOutput.None) {
        //console.warn('_res.errorCode != ErrorOutput.None');
        //console.warn(_res.errorCode);
        //console.warn(guid);
        this.router.navigate(['driver/new'], {});
        //console.warn('goto home page 4');
      }
      if (_res.info != null ) {
        //console.warn('navigagte to station with ' + _res.info.guid);
        this.router.navigate(['driver/test'], { queryParams: { guid: _res.info.guid } });
      }
      else {
        
      }
    });
  }

  
  notconfirm() {
    this.router.navigate(['dirver/new'], {});
    //console.warn('goto home page 5');
  }
  logout() {
    this.router.navigate(['driver/logout'], {  });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalVariable } from './../../globalVariable';

import { Observable} from 'rxjs';
import { PassangerSavedPlace } from '../../model/passangerSavedPlace';

@Injectable({
  providedIn: 'root'
})
export class SavedPlaceService {
  public moke = GlobalVariable.mokeFLAG;
  private PassangerSavedPlaceBaseServiceURL = GlobalVariable.PassangerSavedPlaceBaseServiceURL;

  constructor(private httpClient: HttpClient) {
  }

  public getSavedPlace(passangerID: any): Observable<string[]> {
    let params = new HttpParams().set('passangerID', passangerID);
    return this.httpClient.post<string[]>(this.PassangerSavedPlaceBaseServiceURL + '/GetSavedPlace', params);
  }
  public savePlace(value: PassangerSavedPlace): Observable<number> {
    let params = new HttpParams().set('passangerID', value.passangerID.toString());
    params = params.set('lat', value.lat.toString());
    params = params.set('lng', value.lng.toString());
    params = params.set('PlaceName', value.placeName);
    params = params.set('PlaceAddr', value.placeAddr);

    return this.httpClient.post<number>(this.PassangerSavedPlaceBaseServiceURL + '/SavePlace', params,{ responseType: 'text' as 'json'  });
  }
  public listAll(passangerID): Observable<PassangerSavedPlace[]> {
    let params = new HttpParams().set('passangerID', passangerID);

    return this.httpClient.post<PassangerSavedPlace[]>(this.PassangerSavedPlaceBaseServiceURL + '/AllPlace', params, {});
  }
  



}

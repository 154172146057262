import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatTableDataSource } from '@angular/material/table';
import { DriverLog } from '../../model/driver';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';

@Component({
  selector: 'app-driver-log',
  templateUrl: './driver-log.component.html',
  styleUrls: ['./driver-log.component.scss']
})
export class DriverLogComponent implements OnInit {
  
  filteredList: DriverLog[] = [];
  dataSource = new MatTableDataSource(this.filteredList);
  driverID = 0;
    
  constructor(
    private driverService: DriverserviceService,
    public dialogRef: MatDialogRef<DriverLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { driverID: number },
  )
  {
    this.driverID = this.data.driverID;
    this.driverService.getAllLog(this.driverID).subscribe(res => {
      //console.log(res);
      //console.log(this.driverID);
      this.filteredList = res;
      this.dataSource = new MatTableDataSource(this.filteredList);
    });
  }

  ngOnInit() {
    
  }
  displayedColumns: string[] = ['position', 'persianDate', 'eventTime', 'logTypeName', 'logMessage'];
  selectedRowIndex = -1;
  selectedElement: DriverLog = null;
  highlight(row : any) {
    //console.log('highlight' + row.id);
    this.selectedRowIndex = row.id;
    this.selectedElement = row;

  }


}

import {Component, OnInit} from '@angular/core';
import { DriverStatus, IDriverStatusMeta } from '../../lib/enums/driver-status.enum';

@Component({
  selector: 'app-driver-profile',
  templateUrl: './driver-profile.component.html',
  styleUrls: ['./driver-profile.component.scss']
})
export class DriverProfileComponent implements OnInit {

  currentDriverStatus: IDriverStatusMeta;

  constructor() {
  }

  ngOnInit() {
    this.currentDriverStatus = IDriverStatusMeta.getStatus(DriverStatus.Traveling);
  }

  

}

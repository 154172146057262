import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { GlobalVariable } from '../../globalVariable';
import { Observable } from 'rxjs';
import { IAllLoginOutput, IOperator, IOperatorLoginOutput } from '../interfaces/operator';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {
  private OperatorBaseServiceURL = GlobalVariable.OperatorBaseServiceURL;

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public login(userName: any, passWord: any): Observable<IOperatorLoginOutput> {
    const params = new HttpParams().set('UserName', userName).set('Password', passWord);
    return this.httpClient.post<IOperatorLoginOutput>(this.OperatorBaseServiceURL + '/Login', params);
  }
  public loginAll(userName: any, passWord: any, fbsToken): Observable<IAllLoginOutput> {
    const params = new HttpParams().set('UserName', userName).set('Password', passWord).set('FBSToken', fbsToken);
    return this.httpClient.post<IAllLoginOutput>(this.OperatorBaseServiceURL + '/LoginAll', params);
  }


  public isLogin(guidCode: string): Observable<boolean> {
    const params = new HttpParams().set('guidCode', guidCode);
    return this.httpClient.post<boolean>(this.OperatorBaseServiceURL + '/IsLogin', params);
  }

  public logout(id: any, needSocket: any): Observable<boolean> {
    const params = new HttpParams().set('id', id).set('needSocket', needSocket);
    return this.httpClient.post<boolean>(this.OperatorBaseServiceURL + '/Logout', params);
  }

  public insert(value: Partial<IOperator>): Observable<number> {
    let params = new HttpParams().set('Mobile', value.mobile);
    params = params.set('OperatorName', value.operatorName);
    params = params.set('Password', value.password);
    params = params.set('Username', value.username);
    params = params.set('VOIPNo', value.voipNo.toString());
    return this.httpClient.post<number>(this.OperatorBaseServiceURL + '/InsertNew', params);
  }

  public update(value: Partial<IOperator>): Observable<number> {
    const params = new HttpParams()
      .set('Mobile', value.mobile)
      .set('OperatorName', value.operatorName)
      .set('Password', value.password)
      .set('Username', value.username)
      .set('AdminLevel', value.adminLevel.toString())
      .set('IsActive', value.isActive.toString())
      .set('VOIPNo', value.voipNo.toString())
      .set('ID', value.id.toString());
    return this.httpClient.post<number>(`${this.OperatorBaseServiceURL}/Update`, params);
  }

  public delete(opID: any): Observable<any> {
    const params = new HttpParams().set('ID', opID);
    return this.httpClient.post(`${this.OperatorBaseServiceURL}/Delete`, params);
  }

  public getAll(): Observable<IOperator[]> {
    return this.httpClient.post<IOperator[]>(`${this.OperatorBaseServiceURL}/GetAll`, {});
  }
  public getAllMoaref(): Observable<IOperator[]> {
    return this.httpClient.post<IOperator[]>(`${this.OperatorBaseServiceURL}/GetAllMoaref`, {});
  }
  

}

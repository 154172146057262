import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NotifalertserviceService {

  constructor() { }
  private paramSource = new BehaviorSubject("");

  sharedData: Subject<string>  = new Subject();
setParam(param: string) { this.sharedData.next(param) }    
}

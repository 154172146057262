export class Profile {
  id: number;
  todayPrice: number;
  totalPrice: number;
  monthPrice: number;
  todayCount: number;
  totalCount: number;
  monthCount: number;
  serviceTime: number;
  totalServiceTime: number;
  serviceTimeHour: string;
  serviceTimeMinute: string;
  serviceTimeMonth: number;
  serviceTimeHourMonth: string;
  serviceTimeMinuteMonth: string;
  workTimeHour: string;
  workTimeMinute: string;
  workTime: number;
  workTimeHourMonth: string;
  workTimeMinuteMonth: string;
  workTimeMonth: number;
  serviceTimes: string;
  monthCounts: string;
  workTimes: string;
  prices: string;
  sumEtebariToday: number;
  sumEtebariMonth: number;
  sumEtebariTotal: number;
  sumDebit: number;
  sumCredit: number;
  mande: number;

}
 

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SaveServiceResult } from '../../model/saveserviceresult';

@Component({
  selector: 'app-showcreatedservice',
  templateUrl: './showcreatedservice.component.html',
  styleUrls: ['./showcreatedservice.component.scss']
})
export class ShowcreatedserviceComponent implements OnInit {
  curerntinfo = new SaveServiceResult();
  constructor(public dialogRef: MatDialogRef<ShowcreatedserviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { info: SaveServiceResult }) {
    this.curerntinfo = data.info;
  }
  loaddata()
  {
      
  }
  ngOnInit() {
    this.loaddata();
  }
  close() {
    this.dialogRef.close('close');
  }
}

import { Component, OnInit } from '@angular/core';
import { DriverserviceService } from '../lib/serviceLib/driverservice.service';
import { StationserviceService } from '../lib/serviceLib/stationservice.service';


@Component({
  selector: 'app-testapi',
  templateUrl: './testapi.component.html',
  styleUrls: ['./testapi.component.css']
})
export class TestapiComponent implements OnInit {

  constructor(private taxiSerivce: DriverserviceService, private stationService:StationserviceService) { }

  ngOnInit() {
  }
  verifyCode() {
    var code = 'code', guidCode = 'guidCode', guidDriver = 'guidDriver';
    this.taxiSerivce.checkVerification(code, guidCode, guidDriver).subscribe(_res => {
      //console.warn(_res);
    });
  }

  stationList() {
    var guidDriver = '123123', lat = 36.2545, lng= 51.2454;
    this.stationService.getStationList(guidDriver, lat , lng).subscribe(_res => {
      //console.warn(_res);
    });
  }

}


import { Injectable } from '@angular/core';
import { HttpClient,  HttpParams } from '@angular/common/http';

import { GlobalVariable } from '../../globalVariable';
import { Observable, Subscriber } from 'rxjs';
import { Station, StationNeighbor } from '../../model/station';
import { StationQueueInfo } from '../../model/stationQueueInfo';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class StationserviceService {
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) { }
  public moke = GlobalVariable.mokeFLAG;
  
  
  private StationBaseServiceURL = GlobalVariable.StationBaseServiceURL;
  private DriverBaseServiceURL = GlobalVariable.DriverBaseServiceURL;
  private getMokeStationInf(index = 1) {
    var res = new StationQueueInfo();
    if (index == 1) {
      res.carName = 'پراید'
      res.colorName = 'زرد'
      res.fName = 'محمد'
      res.lName = 'محمد زده'
      res.pelaque2 = '12'
      res.pelaque3 = '123'
      res.pelaqueIR = '23';
      res.pelaqueLetter = 'ج'
    }
    else {
      res.carName = '405'
      res.colorName = 'زرد'
      res.fName = 'محسن'
      res.lName = 'حسین زده'
      res.pelaque2 = '54'
      res.pelaque3 = '345'
      res.pelaqueIR = '23';
      res.pelaqueLetter = 'د'
    }
    return res;
  }
  private getMokeStation(index = 1) {
    if (index == 1) {
      var res = new Station();
      res.distanceKM = 23;
      res.distanceMinute = 43;
      res.guidDriver = '4e35cddd-c390-471b-9ae2-211f42170339'
      res.lat = 36.331701;
      res.length = 23;
      res.lng = 59.493137;
      res.name = 'ایستگاه صدف';
      return res;
    }
    else {
      var res = new Station();
      res.distanceKM = 2;
      res.distanceMinute = 12;
      res.guidDriver = '4e35cddd-c290-471b-9ae2-2re1f42170339'
      res.lat = 36.333361;
      res.length = 23;
      res.lng = 59.485584;
      res.name = 'ایستگاه قانع';
      return res;
    }
  }
  public getStation(stationID: any): Observable<Station> {
    if (this.moke) {
      return new Observable<Station>((subscriber: Subscriber<Station>) => subscriber.next(this.getMokeStation()));
    }
    let params = new HttpParams().set('stationID', stationID);
    return this.httpClient.post<Station>(this.StationBaseServiceURL + "/getStationInfo", params);
  }

  public getNeighbors(stationID: any): Observable<Station[]> {
    let params = new HttpParams().set('stationID', stationID);
    return this.httpClient.post<Station[]>(this.StationBaseServiceURL + "/ListSelectNeighbors", params);
  }
  public getPredictStation(RequestID: any): Observable<StationNeighbor[]> {
    let params = new HttpParams().set('RequestID', RequestID);
    return this.httpClient.post<StationNeighbor[]>(this.StationBaseServiceURL + "/getNeighbor", params);
  }


  public addToQueue(stationID: any, guidDriver: any):Observable<string> {
    if (this.moke) {
      return new Observable<string>((subscriber: Subscriber<string>) => subscriber.next('ff072be1-7999-415f-a5c5-199d70017db8'));
    }
    let params = new HttpParams().set('stationID', stationID).set('guidDriver', guidDriver);
    return this.httpClient.post<string>(this.StationBaseServiceURL + "/addToQueue", params,{ responseType: 'text' as 'json' });
  }
  public getStationList(guidDriver: any, lat: any, lng: any): Observable<Station[]> {
    //if (this.moke) {
    //  let stationList: Station[] = [
    //   this.getMokeStation(1),
    //    this.getMokeStation(2)
    //  ];
    //  return new Observable<Station[]>((subscriber: Subscriber<Station[]>) => subscriber.next(stationList));
    //}
    let params = new HttpParams().set('guidDriver', guidDriver).set('lat', lat).set('lng', lng);
    return this.httpClient.post<Station[]>(this.DriverBaseServiceURL + "/StationList", params);

  }
  public getAllStationList(): Observable<Station[]> {
    return this.httpClient.post<Station[]>(this.DriverBaseServiceURL + "/StationList", {  });
  }
  public removeFromQueue(stationID: any, guidDriver: any): Observable<any> {
    if (this.moke) {
      return new Observable<void>((subscriber: Subscriber<void>) => subscriber.next());
    }
    else {
      let params = new HttpParams().set('stationID', stationID).set('guidDriver', guidDriver);
      return this.httpClient.post(this.StationBaseServiceURL + "/removeFromQueue", params);
    }
  }
  
  public getStationQueue(stationID: any): Observable<StationQueueInfo[]> {
     
    let params = new HttpParams().set('stationID', stationID);
    return this.httpClient.post<StationQueueInfo[]>(this.StationBaseServiceURL + "/getStationQueue", params);
    

    //return StationQueueInfo.getStationQueue(stationID, this.httpClient);
  }
  
  public getStationQueueWithOther(stationID: any): Observable<StationQueueInfo[]> {
    //این سرویس علاوه بر اعضای صف بقیه را هم نشان میدهد
    let params = new HttpParams().set('stationID', stationID);
    return this.httpClient.post<StationQueueInfo[]>(this.StationBaseServiceURL + "/getStationQueueWithOther", params);
    //return StationQueueInfo.getStationQueue(stationID, this.httpClient);
  }

  

  public getAllStations(): Observable<Station[]> {
    return this.httpClient.post<Station[]>(this.StationBaseServiceURL + "/StationListAll", {});
  }

  public insertStation(name: string, code: string, capacity: any,lat: any,lng: any,radios: any,id: any): Observable<boolean> {

    let params = new HttpParams().set('name', name).set('code', code).set('capacity', capacity).set('lat', lat).set('lng', lng).set('radios', radios).set('id', id);
    if (id) {
      return this.httpClient.post<boolean>(this.StationBaseServiceURL + "/UpdateStation", params);

    } else {
      return this.httpClient.post<boolean>(this.StationBaseServiceURL + "/InsertStation", params);

    }
  }


  public delete(id: any): Observable<any> {
    const params = new HttpParams().set('ID', id);
    return this.httpClient.post(this.StationBaseServiceURL + "/Delete", params);
  }

  public sendExitSocketMessage(token: string,content: string,id: any,type: any): Observable<any> {
    const params = new HttpParams().set('token', token).set('content', content).set('id', id).set('type',type);
    return this.httpClient.post(this.StationBaseServiceURL + "/SendExitSocketMessage", params);
  }
}

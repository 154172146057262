import {Component, OnInit,ViewChild,ElementRef,AfterViewInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {HttpClient} from "@angular/common/http";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { AllServiceInfo } from '../../model/serviceInfo';
import { environment } from '../../../environments/environment.prod';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FinishserviceComponent } from '../../driver/finishservice/finishservice.component';
import { MessageboxComponent } from '../../messagebox/messagebox.component';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';

@Component({
  selector: 'app-search-forms',
  templateUrl: './search-forms.component.html',
  styles: [`
*{
font-size: 20px;
}


    p-multiSelect {
      width: 100%;
    }

    ::ng-deep p-multiSelect .p-component {
      font-family: iransans !important;
    }
`]
})
export class SearchFormsComponent implements OnInit, AfterViewInit {
  @ViewChild('android')
  public android: ElementRef;

  isLoading: boolean = false;
  detailsVisible: boolean = false;
  public result: any[] = [];
  allServiceList: AllServiceInfo[] = [];
  constructor(
    
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private serviceService: ServiceserviceService,
    private requestService: ServiceRequestService,
    private dialog: MatDialog,

  ) {
    this.serviceStates = [{ name: 'در مرحله درخواست', stateNumber: 0 },
      { name: 'راننده اختصاص یافته', stateNumber: 1 },
      { name: 'در حال رفتن به مبدا', stateNumber:2 },
      { name: 'رسیدن به مبدا', stateNumber:3 },
      { name: 'مسافر سوار شد', stateNumber: 4 },
      { name: 'در حال رفتن به مقصد', stateNumber: 5 },
      { name: 'خاتمه یافته', stateNumber: 6 },
      { name: 'کنسل شده توسط اپراتور', stateNumber: 7 },
      { name: 'کنسل شده توسط اپراتور و تایید راننده', stateNumber: 8 },
      { name: 'کنسل شدن بعد از 8 دقیقه', stateNumber: 9 },
      { name: 'کنسلی توسط راننده بعد از تماس مسافر', stateNumber: 10 },
      { name: 'کنسل توسط مسافر', stateNumber: 11 },
      { name: 'کنسل راننده به دلیل عدم امکان سرویس ', stateNumber: 12 },
    ];



  }

  dateAz = new FormControl(new Date(), []);
  dateTa = new FormControl(new Date(), []);

  operatorguid: string;
  whereString: string = '';

  searchForm: FormGroup;
  serviceStates: ServiceState[];
  selectedStateNumbers: ServiceState[];
  recordCount = 0;
  pageSize = 10;

  ngOnInit() {
    this.searchForm = new FormGroup({
      passangerFName: new FormControl(''),
      passangerMobile: new FormControl(''),
      searchKeyWord: new FormControl(''),
      originAddr: new FormControl(''),
      driverCode: new FormControl(''),
      stationID: new FormControl(''),
      serviceState: new FormControl()
      //tarikhStart: new FormControl(),
      //saatStartFrom : new FormControl(),
      //saatStartTo : new FormControl(),
    });
    this.selectedStateNumbers = [];

    this.allServiceList = [];
    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      if (params['passangerMobile']) { 
        this.searchForm.patchValue({ passangerMobile: params['passangerMobile'] });
      }
    });
    this.loadPassangerReport(this.operatorguid);

  }
  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
  }
  async submitFormAndSend() {

    this.allServiceList = [];
    this.loadPassangerReport('');
  }
  loadPassangerReport(guidCode: any) {
    let formState: any;
    formState = this.searchForm.get('serviceState')?.value;

    var dataz = this.dateAz.value?.toString()
    var datTa = this.dateTa.value?.toString()



    this.serviceService.reportAllService(guidCode,
      this.searchForm.get('passangerMobile')?.value,
      this.searchForm.get('driverCode')?.value,
      this.searchForm.get('stationID')?.value,
      this.searchForm.get('passangerFName')?.value,
      this.searchForm.get('originAddr')?.value,
      this.selectedStateNumbers, dataz, datTa,
      this.searchForm.get('searchKeyWord')?.value,
    ).subscribe(res => {
        if (res.length > 0) {
          //console.log(res);
          this.allServiceList = res;
        } else {
          this.allServiceList = [];

          //Swal.fire('یافت نشد', 'موردی یافت نشد!');
      }

    });
  }

  editForm(id: any) {
    this.router.navigate(['cc/new'], { queryParams: { guid: this.operatorguid, valp: id } });
    }

    newComplaint(id: any) {
      this.router.navigate(['cc/regComplaint'], { queryParams: { guid: this.operatorguid, valp: id } });

    }

    newFounded(id: any) {
      this.router.navigate(['cc/regFounded'], { queryParams: { guid: this.operatorguid, valp: id } });

       

  }
  updatePrice(row, price): void {
    console.log(row?.id);
    const dialogRef = this.dialog.open(InputtextComponent, {
      data: { title: 'ویرایش کرایه', msg: 'مقدار کرایه را وارد نمایید', fieldName: 'کرایه', value: price, maxValue: 40000 },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result)
        this.serviceService.UpdatePrice(row.id, result).subscribe(_res => {
          row.price = result;
        });
      console.log('The dialog was closed');
      console.log(result);
    });
  }
  finishedService(guidService) {

    this.serviceService.finishService(guidService).subscribe(_res => {



    ////console.log('finishedService');
    //this.router.navigate(['service/finish'], { queryParams: { guid: this.guidService } });
    var dialogfinishRef = this.dialog.open(FinishserviceComponent, {
      width: '400px',
      data: {
        guid: guidService
      }
    });

      dialogfinishRef.afterClosed().subscribe((result: any) => {
        this.loadPassangerReport(this.operatorguid);
      });
    });

  }
  deleteForm(requestID) {
    this.requestService.Delete(requestID).subscribe(res => {
      this.loadPassangerReport(this.operatorguid);
    });
  }
  cancelTrip(requestID: any ) {
    //console.log('cancelTrip');
    //console.log(requestID);
    //console.log(trip);

    var dialogmsgBoxRef = this.dialog.open(MessageboxComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        msgText: 'آیا از کنسل کردن سفر اطمینان دارید؟',
        msgTitle: 'انصراف'
      }
    });
    dialogmsgBoxRef.afterClosed().subscribe((result: any) => {

      if (result == 'ok') {
        this.serviceService.cancelServiceByRequest(requestID).subscribe(res => {
          this.loadPassangerReport(this.operatorguid);
        });
      }

    });

    //console.warn(trip);
  }
  resetForm() {
    this.searchForm.reset();
    this.allServiceList = [];

  }
  
}

interface ServiceState {
  name: string,
  stateNumber: number
}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IServiceReport } from '../../lib/interfaces/iServiceReport';
import { ReportService } from '../../lib/serviceLib/report.service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';
import { InputselectItemComponent } from '../../shared/inputselectItem/inputselectItem.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-report-trip',
  templateUrl: './report-trip.component.html',
  styleUrls: ['./report-trip.component.css']
})
export class ReportTripComponent implements OnInit {
  totalprice = 0;
  totalcommission = 0;
  totalbedehkari = 0;
  totalbestankari = 0;
  driveracc: { [id: number]: number; } = {};

  count = 0;
  @Input() driverID = '';
  @Input() dateStart = '';
  @Input() dateFinish = '';
  @Input() operatorID = '';
  @Input() keyword = '';
  operatorguid = '';
  constructor(private repSrv: ReportService, public dialog: MatDialog,
    private serviceService: ServiceserviceService,
    private route: ActivatedRoute,

    private router: Router,

  ) {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];

    });
  }
  reports: IServiceReport[];
  ngOnInit(
  ): void {
    this.report();
  }
  

 

  calctotal() {
    this.totalprice = 0;
    this.totalcommission = 0;
    this.totalbedehkari = 0;
    this.totalbestankari = 0;
    this.driveracc = [];
    this.count = this.reports?.length ?? 0;
    this.reports.forEach(t => {
      if (!this.driveracc[t.driverId])
      {
        this.driveracc[t.driverId] = 0;
      }

      this.driveracc[t.driverId] = this.driveracc[t.driverId] - t.bedehkari;
      this.driveracc[t.driverId] = this.driveracc[t.driverId] + t.bestankari;
      //t.mande = this.driveracc[t.driverId];


      if (!t.anam) {
        t.anam = 0;
      } 
        

      if (t.price) {
        let prc: number = parseInt(t.price);
        if (prc > 0)
          this.totalprice += prc;

        this.totalcommission += t.commission;
        this.totalbedehkari += t.bedehkari;
        this.totalbestankari += t.bestankari;


      }
      else
        t.price = '0';
      if (!t.typePardakhtName)
        t.typePardakhtName = '---';

    })
  }
  report() {
    this.repSrv.ServiceReport(this.driverID, this.dateStart, this.dateFinish, this.operatorID, this.keyword).subscribe(t => {
      this.reports = t;
      console.log(t);
      this.calctotal();
    });
  }
  openDialog(row, defValue, mode): void {
    console.log(row?.serviceID);
    if (mode == 'destination') {
      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش مقصد', msg: 'مقصد  را وارد نمایید', fieldName: 'مقصد', value: defValue },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.serviceService.UpdateDestination(row.serviceID, result).subscribe(_res => {
            row.destinationAddr = result;
          });
        console.log('The dialog was closed');
        console.log(result);
      });
    }
    else if (mode == 'requestComment') {
      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش توضیحات', msg: 'توضیحات  را وارد نمایید', fieldName: 'توضیحات', value: defValue },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.serviceService.UpdateRequestComment(row.serviceID, result).subscribe(_res => {
            row.requestComment = result;
          });
        console.log('The dialog was closed');
        console.log(result);
      });
    }
    else if (mode == 'typePardakhtName') {
      const dialogRef = this.dialog.open(InputselectItemComponent, {
        data: {
          title: 'نوع پرداخت', msg: 'نوع پرداخت را انتخاب نمایید', fieldName: 'نوع پرداخت', value: defValue,
          lst:[
            { id: 1019, name: 'نقدی' },
            { id: 1020, name: 'پیش پرداخت' },
            { id: 1021, name: 'اعتباری' }
            ]
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.serviceService.UpdateTypePardakht(row.serviceID, result).subscribe(_res => {
            if (result == '1019')
              row.typePardakhtName = 'نقدی';
            else if (result == '1020')
              row.typePardakhtName = 'پیش پرداخت';
            else if (result == '1021')
              row.typePardakhtName = 'اعتباری';

          });
        console.log('The dialog was closed');
        console.log(result);
      });
    }
    else if (mode == 'anam') 
    {
      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش انعام', msg: 'مقدار انعام را وارد نمایید', fieldName: 'انعام', value: defValue},
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.serviceService.UpdateAnam(row.serviceID, result).subscribe(_res => {
            row.anam = result;
            
            this.report();
          });
        console.log('The dialog was closed');
        console.log(result);
      });
    }
    else
    {
  const dialogRef = this.dialog.open(InputtextComponent, {
    data: { title: 'ویرایش کرایه', msg: 'مقدار کرایه را وارد نمایید', fieldName: 'کرایه', value: defValue, maxValue: 40000 },
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
    if (result)
      this.serviceService.UpdatePrice(row.serviceID, result).subscribe(_res => {
        row.price = result;

        this.report();
      });
    console.log('The dialog was closed');
    console.log(result);
  });
}



    


  }
  updateDebit(drvID) {
    this.router.navigate(['cc/debits'], { queryParams: { guid: this.operatorguid, refID: drvID } });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SwPush } from '@angular/service-worker';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.scss']
})
export class PushComponent implements OnInit {
  loginFormGroup: FormGroup;

  constructor(
   private swPush: SwPush,
    private driverService: DriverserviceService,
    private callService: CallCenterService
    
  ) { }

  ngOnInit() {

    this.loginFormGroup = new FormGroup({
      title: new FormControl(''),
      body: new FormControl(''),
      driverID: new FormControl('')
    });

  }
  readonly VAPID_PUBLIC_KEY = "BJZtBCC9_bsTqetN7tcSq8HVYjd4qpvSmFgv0RcWfuz-ebeasPnpGphIDhngz-LYpLaeZLrK1WTPP1CGtjEvMfM";
  subscribeToNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    }).then(pushSubscription => {
      var s = JSON.stringify(pushSubscription);
      this.driverService.SaveNotifInfo('','','','')
    })
      .catch(err => {
        console.error(err);
      });
  }
 
  Send() {
    //console.log('send');
    this.callService.sendNotif(this.loginFormGroup.value.title, this.loginFormGroup.value.body, this.loginFormGroup.value.driverID).subscribe(res => { console.log});
  }
  
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequest } from '../../model/ServiceRequest';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';

@Component({
  selector: 'app-freeservice',
  templateUrl: './freeservice.component.html',
  styleUrls: ['./freeservice.component.scss']
})
export class FreeserviceComponent implements OnInit, OnDestroy {
  list: ServiceRequest[];
  constructor(
    public dialogRef: MatDialogRef<FreeserviceComponent>,
    private route: ActivatedRoute,
    private taxiSerivce: ServiceRequestService,
    @Inject(MAT_DIALOG_DATA) public data: { guidRequest: string }) {

  }
  ngOnDestroy() {
    if (this.schedulJon) {
      clearInterval(this.schedulJon);
    }
  }

  ngOnInit() {
    this.loadRequestInfo(this.data.guidRequest);
  }
  msg = 'در حال بروزرسانی سفرهای آزاد';
  schedulJon: any;
  loadRequestInfo(guid: any) {
    this.msg = 'در حال بروزرسانی سفرهای آزاد';
    this.taxiSerivce.getFreeService(guid).subscribe(_res => {
      
      this.list = _res;
      if (this.list && this.list.length > 0)
        this.msg = '';
      else
        this.msg = 'سفر آزادی وجود ندارد';
      this.schedulJon = setTimeout(() => { this.loadRequestInfo(guid) }, 10000);
    });
  }
  

  accept() {
    this.dialogRef.close('');
  }

  reject() {
    this.dialogRef.close('');
  }
  closeform() {
    this.dialogRef.close('');
  }
  showproposed(q: any) {
    clearInterval(this.schedulJon);
    this.dialogRef.close(q.requestGUID);
    
    
  }
}

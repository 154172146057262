import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Dropdown} from "primeng/dropdown";
import {Subject} from "rxjs";

@Component({
  selector: 'custom-dropdown',
  template: `
    <div class="full-width">
      <div class="d-inline-flex mt-2 w-100">
        <label for="float-input" style="height:4vh;font-family: iranyekan_regular,iransans !important;">{{label}}</label>
        <p-dropdown
          [options]="items"
          [(ngModel)]="model"
          (onChange)="onChangeListener.next(model)"
          [editable]="editable"
          [placeholder]="placeHolder"
          [optionLabel]="optionLabel"
          [showClear]="showClear">
        </p-dropdown>
      </div>
    </div>
  `,
  styles: [`
    h5 {
      font-family: IranYekan;
    }

    p-dropdown {
      width: 100%;
    }

    ::ng-deep p-dropdown .p-dropdown {
      width: 100%;
    }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true,
  }]
})
export class DropdownComponent implements ControlValueAccessor {
  model: any = null;
  onChangeListener = new Subject();

  @Input() items: any[] = [];
  @Input() label: any = null;
  @Input() editable: boolean = false;
  @Input() placeHolder: any = null;
  @Input() optionLabel: any = undefined;
  @Input() showClear: boolean = false;

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChangeListener.subscribe(x => {
      fn(x);
    })
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(val: any): void {
    this.model = val;
  }

}

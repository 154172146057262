import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-bulkabonman',
  templateUrl: './bulkabonman.component.html',
  styleUrls: ['./bulkabonman.component.css']
})
export class BulkabonmanComponent implements OnInit {


  ngOnInit() { }
  constructor(
    private matSnackbar: MatSnackBar,

    public dialogRef: MatDialogRef<BulkabonmanComponent   >,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      msg: string,
      fieldName: string,
      value: number,
      maxValue: number

    },) { }


  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
    if (this.data.maxValue > 0) {
      if (this.data.value < this.data.maxValue) {
        this.matSnackbar.open('مبلغ صحیح نمی باشد', null, { duration: 2000 });

        return;

      }
      else
        this.dialogRef.close(this.data.value);

    }
    this.dialogRef.close(this.data.value);
  }



}

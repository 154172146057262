import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalVariable } from '../../globalVariable';
import { Observable} from 'rxjs';
import { Log } from '../../model/log';
import { ConfigurationAll } from '../../model/ConfigurationAll';

@Injectable({
  providedIn: 'root'
})
export class LogserviceService {
  constructor(
    private httpClient: HttpClient

  ) { }



  public reportAllLog(types: any, deviceID: any, deviceModel: any, tarikhAz: any, tarikhTa: any, timeAz: any, timeTa: any): Observable<Log[]> {
  
    let params = new HttpParams();

    if (typeof types == 'undefined' || !types) {
      types = '';
    }
    params = params.set('types', types);
    if (typeof deviceID == 'undefined' || !deviceID) {
      deviceID = '';
    }
    params = params.set('deviceID', deviceID);
    if (typeof deviceModel == 'undefined' || !deviceModel) {
      deviceModel = '';
    }
    params = params.set('deviceModel', deviceModel);
    if (typeof tarikhAz == 'undefined' || !tarikhAz) {
      tarikhAz = '';
    }
    params = params.set('TarikhAz', tarikhAz);
    if (typeof tarikhTa == 'undefined' || !tarikhTa) {
      tarikhTa = '';
    }
    params = params.set('TarikhTa', tarikhTa);
    if (typeof timeAz == 'undefined' || !timeAz) {
      timeAz = '';
    }
    params = params.set('TimeAz', timeAz);
    if (typeof timeTa == 'undefined' || !timeTa) {
      timeTa = '';
    }
    params = params.set('TimeTa', timeTa);

    return this.httpClient.post<Log[]>(GlobalVariable.DashboardBaseServiceURL + "/ReportAllLog", params);
  }

  public getConfig(): Observable<ConfigurationAll> {
 
      let params = new HttpParams();
    return this.httpClient.post<ConfigurationAll>(GlobalVariable.DashboardBaseServiceURL + "/GetConfig", params);
   }

  public updateConfig(gpsLogTime : string,logSocketServer: any,logSocketClient : any,gpsLogDistance : any,serverOffTime : any): Observable<boolean> {

    let params = new HttpParams().set('gpsLogTime', gpsLogTime).set('logSocketServer', logSocketServer).set('logSocketClient', logSocketClient).set('gpsLogDistance', gpsLogDistance).set('serverOffTime', serverOffTime);
    return this.httpClient.post<boolean>(GlobalVariable.DashboardBaseServiceURL + "/UpdateConfig", params);
  }


  public deleteLogs(): Observable<boolean> {

    let params = new HttpParams();
    return this.httpClient.post<boolean>(GlobalVariable.DashboardBaseServiceURL + "/DeleteAllLogs", params);
  }
  public deleteLogsList(ids : any): Observable<boolean> {

    let params = new HttpParams().set('ids', ids);
    return this.httpClient.post<boolean>(GlobalVariable.DashboardBaseServiceURL + "/DeleteLogsList", params);
  }
  public deleteLog(id: any): Observable<boolean> {

    let params = new HttpParams().set('id',id);
    return this.httpClient.post<boolean>(GlobalVariable.DashboardBaseServiceURL + "/DeleteLog", params);
  }
  public deleteLogByType(type: any): Observable<boolean> {

    let params = new HttpParams().set('type', type);
    return this.httpClient.post<boolean>(GlobalVariable.DashboardBaseServiceURL + "/DeleteLogByType", params);
  }

}


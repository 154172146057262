import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GlobalVariable} from './../../globalVariable';
import {Driver, getDriverInfo} from './../../model/driver';
import {Observable, Subscriber} from 'rxjs';
import {ErrorOutput} from '../../model/enumtype';
import {Car} from '../../model/car';
import { LastCallInfo } from '../../model/lastCallInfo';
import { OperatorTable } from '../../model/operatorTable';

@Injectable({
  providedIn: 'root'
})
export class CallCenterService {
  public moke = GlobalVariable.mokeFLAG;
  private CallCenterBaseServiceURL = GlobalVariable.CallCenterBaseServiceURL;

  constructor(private httpClient: HttpClient) {
  }

  public getDriverByNationalCode(nCode: any, mobile: any): Observable<string> {
    if (this.moke) {
      return new Observable<string>((subscriber: Subscriber<string>) => subscriber.next('f7f571ff-49d1-4e78-802b-80d4ab49a28e'));

    }
    let params = new HttpParams().set('nCode', nCode).set('mobile', mobile);
    return this.httpClient.post<string>(this.CallCenterBaseServiceURL + '/GetInfo', params,{ responseType: 'text' as 'json'});
  }

  private getMokeInfo() {
    var result = new getDriverInfo();
    result.car = new Car();
    result.errorCode = ErrorOutput.None;
    result.info = new Driver();
    result.car.carName = 'پراید';
    result.car.carType = 1;
    result.car.color = 2;
    result.car.colorName = 'زرد';
    result.car.pelaque2 = '12';
    result.car.pelaque3 = '123';
    result.car.pelaqueLetter = 'ج';
    result.car.pelaqueIR = '23';
    result.car.pelaque = 'ایران 12 32 ج 28';
    result.info.currentCarID = 1;
    result.info.currentStatus = 1;
    result.info.fName = 'محمد';
    result.info.lName = 'زاده محمد';
    result.info.mobile = '09155213180';

    result.info.nationalCode = '0946854254';
    return result;
  }

  public getCurrentCallInfo(operatorID: any): Observable<LastCallInfo> {
    let params = new HttpParams().set('operatorID', operatorID);
    return this.httpClient.post<LastCallInfo>(this.CallCenterBaseServiceURL + '/GetCurrentCall', params);
  }
  public getCallerInfoByMobile(mobile: any): Observable<LastCallInfo> {
    let params = new HttpParams().set('Mobile', mobile);
    return this.httpClient.post<LastCallInfo>(this.CallCenterBaseServiceURL + '/GetCallerInfo', params);
  }
  public getOperatorInfo(guidCode: any): Observable<OperatorTable> {
    let params = new HttpParams().set('guidCode', guidCode);
    return this.httpClient.post<OperatorTable>(this.CallCenterBaseServiceURL + '/GetByGUID', params);
  }
  public testWS(driverID: any): Observable<OperatorTable> {
    let params = new HttpParams().set('file', driverID);
    return this.httpClient.post<OperatorTable>(this.CallCenterBaseServiceURL + '/testws', params);
  }
  public saveLogCall(line: any, no: any): Observable<string> {
    
    let params = new HttpParams().set('LineNo', line).set('No', no);
    return this.httpClient.post<string>(this.CallCenterBaseServiceURL + '/LogCallWS', params,{ responseType: 'text' as 'json' });
  }  
  public sendNotif(Title: any, Body: any, DriverID: any): Observable<string> {
    //console.log('sendNotif');
    let params = new HttpParams().set('DriverID', DriverID).set('Body', Body).set('Title', Title);
    return this.httpClient.post<string>(this.CallCenterBaseServiceURL + '/sendNotif', params,{ responseType: 'text' as 'json' });

  }
  public setFinish(guid: any): Observable<string> {
    //console.log('setFinish2');
    let params = new HttpParams().set('guid',guid);
    return this.httpClient.post<string>(this.CallCenterBaseServiceURL + '/setFinish', params,{ responseType: 'text' as 'json' });

  }
  

  public finishLog(guid: any): Observable<string> {
    //console.log('finishLog');
    let params = new HttpParams().set('guid', guid);
    return this.httpClient.post<string>(this.CallCenterBaseServiceURL + '/setFinish', params,{ responseType: 'text' as 'json' });

  }
}

export class Service {
  
  carTypeName: string;
  colorTypeName: string;
  pelaqueIR: string;
  pelaqueLetter: string;
  pelaque2: string;
  pelaque3: string;
  passangerFName: string;
  passangerLName: string;
  passangerMobile: string;
  driverFName: string;
  driverLName: string;
  driverMobile: string;
  driverAvatar: string;
  safedriverAvatar: any;

  requestID: number;
  delayTimeMinute: number;
  serviceType: number;
  serviceState: number;
  predictTimeMinute: number;
  predictServiceLenghtMetter: number;
  serviceGUID: string;
  createDateTime: Date;
  originAddr: string;
  destinationAddr: string;
  
  
}

/*
 * protected int _iD;
        protected int _driverID = -1;
        protected int _passangerID = -1;
        protected int _serviceType = -1;
        protected int _carID = -1;
        protected DateTime _createDateTime;
        protected DateTime _finishDateTime;
        protected double _originLat;
        protected double _originLng;
        protected double _destination1Lat;
        protected double _destination1Lng;
        protected double _destination2Lat;
        protected double _destination2Lng;
        protected int _serviceState = -1;
        protected int _price;
        protected int _countOfPassangers;
        protected int _driverVoteToPassanger;
        protected int _delayTimeMinute;
        protected int _predictTimeMinute;
        protected decimal _predictServiceLenghtMetter;
        protected decimal _realServiceLenghtMetter;
        protected string _serviceGUID = String.Empty;
        protected int _requestID ;
        */
 

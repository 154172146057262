import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Direction} from "@angular/cdk/bidi";
import {Subject} from "rxjs";

@Component({
  selector: 'custom-text-area',
  template: `
    <div class="d-flex float-input-container mt-2 w-100">
    <span class="p-float-label">
        <textarea
          pInputTextarea
          [(ngModel)]="model"
          (ngModelChange)="onChangeListener.next(model)" type="text" style="height:8vh" pInputText>
        </textarea>
        <label for="float-input">{{label}}</label>
    </span>
    </div>
  `,
  styles:[`
    label {
      font-family: IranYekan;
    }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextAreaComponent),
    multi: true,
  }]
})
export class TextAreaComponent implements ControlValueAccessor {
  @Input() label: any = '';
  model: string = '';
  @Input() textAlign: CanvasTextAlign = 'right';
  @Input() inputDirection: Direction = 'rtl';
  onChangeListener = new Subject();


  registerOnChange(fn: any): void {
    this.onChangeListener.subscribe(x => {
      fn(x);
    });
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(val: any): void {
    this.model = val;
  }


  constructor() {
  }


}

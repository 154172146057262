export class OperatorTable {
  id: number;
  operatorName: string;
  mobile: string;
  voipno: number;
  username: string;
  password: string;
  guidCode: string;
  countThisOperator: number;
  countAll: number;
  adminLevel: number;
  isActive: boolean;
}

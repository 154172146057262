import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Log } from '../../model/log';
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from '@angular/router';
import { LogserviceService } from '../../lib/serviceLib/logservice.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {  throwError } from 'rxjs';


@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html'
})
export class LogsComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() data: any = [];
  @Output() visibleChange = new EventEmitter();
  allLogList: Log[] = [];

  isLoading: boolean = false;
  detailsVisible: boolean = false;
  public result: any[] = [];

  recordCount = 0;
  pageSize = 30;
  typesString: string[] = ['1', '2', '3', '20', '99', '100', '0'];

  tarikhAz : FormControl;
  tarikhTa: FormControl;
  timeAz: FormControl;
  timeTa: FormControl;

  gpsLogTime: FormControl;
  gpsLogDistance: FormControl;
  logSocketServer  : FormControl;
  logSocketClient: FormControl;
  managerFormGroup: FormGroup;
  serverOffTime: FormControl;

  type1: FormControl;
  type2: FormControl;
  type3: FormControl;
  type20: FormControl;
  type99: FormControl;
  type100: FormControl;
  type0: FormControl;

  typeInput: FormControl;
  deviceID: FormControl;
  deviceModel: FormControl;


  constructor(
    private logServiceService: LogserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private httpClient: HttpClient

  ) {
  }

  operatorguid: string;

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
  setConfigControls() {
    this.logServiceService.getConfig().subscribe(res => {

      console.log(res);

      this.gpsLogTime.setValue(res.gpsLogTime);
      this.gpsLogDistance.setValue(res.gpsLogDistance);
      this.logSocketServer.setValue(res.logSocketServer);
      this.logSocketClient.setValue(res.logSocketClient);
      this.serverOffTime.setValue(res.serverOffTime);

    });
  }

  ngOnInit(): void {

    //var result = this.httpClient.post<ConfigurationAll>(GlobalVariable.DashboardBaseServiceURL + "/GetConfig", new HttpParams()).pipe(
    //  catchError(this.handleError)
    //);;

    //result.subscribe(res => { console.log(res) });

    this.tarikhAz = new FormControl('');
    this.tarikhTa = new FormControl('');
    this.timeAz = new FormControl('');
    this.timeTa = new FormControl('');
    this.gpsLogTime = new FormControl('');
    this.gpsLogDistance = new FormControl('');
    this.logSocketServer = new FormControl(false);
    this.logSocketClient = new FormControl(false);
    this.serverOffTime = new FormControl('');

    this.setConfigControls();


    this.type1 = new FormControl(true);
    this.type2 = new FormControl(true);
    this.type3 = new FormControl(true);
    this.type20 = new FormControl(true);
    this.type99 = new FormControl(true);
    this.type100 = new FormControl(true);
    this.type0 = new FormControl(true);

    this.typeInput = new FormControl(20);
    this.deviceID = new FormControl();
    this.deviceModel = new FormControl();

    this.managerFormGroup = new FormGroup({
      gpsLogTime: this.gpsLogTime,
      gpsLogDistance: this.gpsLogDistance,
      logSocketServer: this.logSocketServer,
      logSocketClient: this.logSocketClient,
      serverOffTime: this.serverOffTime
    })

    this.allLogList = [];
    this.loadLogReport();



    this.type1.valueChanges.subscribe(() => {
      if (!this.type1.value) {
        this.typesString.splice(this.typesString.indexOf('1', 0), 1);
      }
      else if (this.type1.value) {
        if (!this.typesString.includes('1'))
          this.typesString.push('1');
      }
      this.loadLogReport();
    }
    );

    this.type2.valueChanges.subscribe(() => {
      if (!this.type2.value) {
        this.typesString.splice(this.typesString.indexOf('2', 0), 1);
      }
      else if (this.type2.value) {
        if (!this.typesString.includes('2'))
          this.typesString.push('2');
      }
      this.loadLogReport();
    }
    );

    this.type3.valueChanges.subscribe(() => {
      if (!this.type3.value) {
        this.typesString.splice(this.typesString.indexOf('3', 0), 1);
      }
      else if (this.type3.value) {
        if (!this.typesString.includes('3'))
          this.typesString.push('3');
      }
      this.loadLogReport();
    }
    );

    this.type20.valueChanges.subscribe(() => {
      if (!this.type20.value) {
        this.typesString.splice(this.typesString.indexOf('20', 0), 1);
      }
      else if (this.type20.value) {
        if (!this.typesString.includes('20'))
          this.typesString.push('20');
      }
      this.loadLogReport();
    }
    );

    this.type99.valueChanges.subscribe(() => {
      if (!this.type99.value) {
        this.typesString.splice(this.typesString.indexOf('99', 0), 1);
      }
      else if (this.type99.value) {
        if (!this.typesString.includes('99'))
          this.typesString.push('99');
      }
      this.loadLogReport();
    }
    );
    this.type100.valueChanges.subscribe(() => {
      if (!this.type100.value) {
        this.typesString.splice(this.typesString.indexOf('100', 0), 1);
      }
      else if (this.type100.value) {
        if (!this.typesString.includes('100'))
          this.typesString.push('100');
      }
      this.loadLogReport();
    }
    );
    this.type0.valueChanges.subscribe(() => {
      if (!this.type0.value) {
        this.typesString.splice(this.typesString.indexOf('0', 0), 1);
      }
      else if (this.type0.value) {
        if (!this.typesString.includes('0'))
          this.typesString.push('0');
      }
      this.loadLogReport();
    }
    );




  }

  async submitFormAndSend() {


    this.loadLogReport();
  }
  loadLogReport() {


    if (!this.type1.value && !this.type2.value && !this.type3.value && !this.type20.value && !this.type99.value && !this.type100.value && !this.type0.value) {
      this.typesString = ['888'];
    }

    this.logServiceService.reportAllLog(this.typesString, this.deviceID.value, this.deviceModel.value, this.tarikhAz.value, this.tarikhTa.value, this.timeAz.value, this.timeTa.value).subscribe(res => {
      if (res.length > 0) {
        //console.log(res);

        this.allLogList = res;
        //this.filter();
      } else {
        this.allLogList = [];

        Swal.fire('یافت نشد', 'موردی جهت جستجو یافت نشد!');
      }


    });
  }

  saveConfig() {
    this.logServiceService.updateConfig(this.gpsLogTime.value, this.logSocketServer.value, this.logSocketClient.value, this.gpsLogDistance.value, this.serverOffTime.value).subscribe(res => {
      if (res) {
        this.setConfigControls();
        Swal.fire('به روزرسانی شد', 'کانفیگ ها به روزرسانی شدند!');
      } else {

      }
    });
  }

  deletelogs() {

    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید لاگ ها حذف شوند؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });

    confirmRef.afterClosed().subscribe(_confirmed => {
      if (_confirmed) {
        this.logServiceService.deleteLogs().subscribe(res => {
          if (res) {
            Swal.fire('حذف شدند', 'لاگ ها حذف شدند!');
          } else {

          }
        });
        this.loadLogReport();
      }
    });


  }

  deletelogsList() {

    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید لاگ های فعلی حذف شوند؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });

    confirmRef.afterClosed().subscribe(_confirmed => {
      if (_confirmed) {
        var ids = this.allLogList.map(x => x.id);
        this.logServiceService.deleteLogsList(ids).subscribe(res => {
          if (res) {
            Swal.fire('حذف شدند', 'لاگ های فعلی حذف شدند!');
          } else {

          }
        });
        this.loadLogReport();
      }
    });


  }

  resetForm() {
    this.tarikhAz.reset();
    this.tarikhTa.reset();
    this.timeAz.reset();
    this.timeTa.reset();
    this.deviceID.reset();
    this.deviceModel.reset();
    this.allLogList = [];
    this.loadLogReport();

  }

  delete(log: any) {


    this.logServiceService.deleteLog(log.id).subscribe(res => {
      if (res) {
        //Swal.fire('حذف شد', 'لاگ موردنظر حذف شد!');
        this.allLogList = [];

        this.loadLogReport();
      } else {

      }
    });

  }

  deletelogsByType() {

    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید لاگ ها حذف شوند؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });

    confirmRef.afterClosed().subscribe(_confirmed => {
      if (_confirmed) {
        this.logServiceService.deleteLogByType(this.typeInput.value).subscribe(res => {
          if (res) {
            //Swal.fire('حذف شد', 'لاگ موردنظر حذف شد!');
            this.allLogList = [];

            this.loadLogReport();
          } else {

          }
        });
      }
    });


  }

}


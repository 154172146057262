import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Service } from '../../model/service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
@Component({
  selector: 'app-cancelbydriver',
  templateUrl: './cancelbydriver.component.html',
  styleUrls: ['./cancelbydriver.component.scss']
})
export class CancelbydriverComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CancelbydriverComponent>, @Inject(MAT_DIALOG_DATA) public data: { guidService: string, serviceInfo: Service },
    private router: Router,
    private serviceutil: ServiceserviceService
  ) { }

  ngOnInit() {
    this.loadData();
  }
  favoriteSeason: string;
  seasons = [{ name: 'درخواست تلفنی مسافر', value: 10 }];
  loadData() {
    //console.log(this.data.serviceInfo.createDateTime);

    var today = new Date();
    var serviceDate = new Date(this.data.serviceInfo.createDateTime);
    var diffMs = (today.getTime() - serviceDate.getTime() ); // milliseconds between now & Christmas
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffMins > 8) {
      this.seasons.push({ name: 'عدم مراجعه بعد از مهلت مقرر', value: 9 });
    }
    //console.log('diffMins is ' + diffMins);
    this.seasons.push({ name: 'عدم امکان سرویس دهی از سوی راننده', value: 12 });

  }
  closeform() {
    this.dialogRef.close('Close');
  }
  cancel() {
    this.serviceutil.cancelService(this.data.guidService, this.favoriteSeason).subscribe(res => {
      
      this.dialogRef.close('CancelTrip');
    });


  }
}

import { Component, EventEmitter, Input, OnInit, Output,AfterViewInit,ElementRef,ViewChild } from '@angular/core';

import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';


import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { Observable } from 'rxjs';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { OperatorTable } from '../../model/operatorTable';
import Swal from "sweetalert2";
import { ComplaintserviceService } from '../../lib/serviceLib/complaintservice.service';


@Component({
  selector: 'app-register-complaint',
  templateUrl: './register-complaint.component.html',
})
export class RegisterComplaintComponent implements OnInit, AfterViewInit {
  @Input() visible: boolean = false;
  @Input() dataItem: any = {};
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSendToServer = new EventEmitter<any>();
  description = new FormControl();
  operatorguid: string;
  requestID: number;
  @ViewChild('android')
  public android: ElementRef;
  passangerFName: string;
  originAddr: string;
  destinationAddr: string;
  passangerMobile: string;
  driverCode: string;
  serviceDateTime: Date;

  operatorInfo: OperatorTable;
  constructor(
    private complaintServiceService: ComplaintserviceService,
    private callCenterService: CallCenterService,

    private requestService: ServiceRequestService,
    private route: ActivatedRoute,
    private router: Router
  ) {


  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.requestID = params['valp'];
      this.requestService.getRequestServiceByID(this.requestID).subscribe(res => {
        this.passangerFName = res.passangerFName;
        this.originAddr = res.originAddr;
        this.destinationAddr = res.destinationAddr;
        this.passangerMobile = res.passangerMobile;
        this.driverCode = res.driverCode;
        this.serviceDateTime = res.createDateTime
      });

      this.loadOperatorInfo(this.operatorguid);

    });
  }
  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
  }

  loadOperatorInfo(guidCode: any): Observable<any> {
    return new Observable((observer) => {
      this.callCenterService.getOperatorInfo(guidCode).subscribe(res => {
        this.operatorInfo = res;

      });
    });
  }

  registerComplaint() {

    this.complaintServiceService.insertComplaint(this.requestID, this.passangerFName, this.passangerMobile, this.originAddr, this.destinationAddr, this.driverCode, this.serviceDateTime.toString(), this.description?.value, this.operatorInfo?.id).subscribe(res => {

      if (res) {
        this.router.navigate(['cc/complaints'], { queryParams: { guid: this.operatorguid } });
        Swal.fire('ثبت شد', 'شکایت با موفقیت ثبت شد');
      }
      else {
        Swal.fire('خطا', 'خطا در ثبت شکایت');

      }

    });;


  }
  resetForm() {
    this.description.setValue('');
  }
}

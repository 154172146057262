import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from '@angular/router';
import { Station } from '../../model/station';
import { TaximapComponent } from '../../taximap/taximap.component';
import { LatLng, Marker } from 'leaflet';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { LocationInfo } from '../../globalVariable';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { DriverPositionHist } from '../../model/driver';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { IDriverStatusMeta } from '../../lib/enums/driver-status.enum';


@Component({
  selector: 'app-traffic-monitor',
  templateUrl: './traffic-monitor.component.html',
  styleUrls: ['./traffic-monitor.component.scss']

})
export class TrafficmonitorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() visible: boolean = true;
  @Input() data: any = [];
  @Output() visibleChange = new EventEmitter();
  lstStation: Station[];
  @ViewChild("map", { static: false })
  public map: TaximapComponent;
  operatorguid: string;
  originPoint = new LocationInfo();
  originMarker: any;
  initMarker: Marker;
  destinationMarker: any;
  recordCount = 0;
  pageSize = 30;
  mobileSearch = '';
  driverFNameSearch = '';
  driverCodeSearch = '';
  passangerFNameSearch = '';
  passangerMobileSearch = '';
  tarikhAz = '';
  tarikhTa = '';
  timeAz = '';
  timeTa = '';


  lstDriverPosMarker: Marker[] = [];
  constructor(
    private driverService: DriverserviceService,
    private route: ActivatedRoute,
    private taxiSerivce: TaxiserviceService,
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private serviceService: ServiceserviceService,

    private router: Router

  ) {


  }


  ngOnInit(): void {
    if (!this.map) { 
    this.map = new TaximapComponent();
    }

    this.tarikhAz = new Date().toString();
  }

  showPoint()/*: Observable<any>*/ {
    //return new Observable((observer) => {
    this.clearPoints();

    if (!this.mobileSearch && !this.driverFNameSearch && !this.driverCodeSearch && !this.passangerFNameSearch && !this.passangerMobileSearch  && !this.tarikhTa && !this.timeAz && !this.timeTa)
      Swal.fire('فیلد اجباری', 'یکی از موارد جستجو پر شود');

    else {
           this.driverService.getAllDriverPosition(this.mobileSearch, this.driverFNameSearch, this.driverCodeSearch, this.passangerFNameSearch, this.passangerMobileSearch, this.tarikhAz, this.tarikhTa, this.timeAz, this.timeTa).subscribe(_res => {
        if (_res?.length > 0) {
          _res.forEach(pos => {
            let markerPos = this.addPositionMarker(pos);
            this.lstDriverPosMarker.push(markerPos);
          });

        }
        else {
          Swal.fire('یافت نشد', 'موردی یافت نشد!');
        }
        //  observer.next(_res);
      });

    }


    //  });
  }





  addPositionMarker(position: DriverPositionHist): Marker {
    let marker: Marker;
    let currentDriverStatus = IDriverStatusMeta.getStatus(1004);
    try {
      marker = this.map.createPositionMarker(position);
      if (position.currentStatus > 1000) {
        currentDriverStatus = IDriverStatusMeta.getStatus(position.currentStatus);

      }

      if (position.relatedServiceID) {
        this.serviceService.getServiceByID(position.relatedServiceID).subscribe(_res => {
          marker.bindPopup(
            '<p style="font-family:iranyekan_regular,iransans !important ;   text-align-last: right;"> نام راننده : <span style="font-weight:bold">' + position.fName + ' ' + position.lName + '</span><br/> کد راننده : <span>' + position.driverCode + '</span><br/> وضعیت : <span>' + currentDriverStatus.text + '</span> <br/> موبایل : <span>' + position.mobile + '</span> <br/> کد ایستگاه : <span>' + position.currentStation + '</span><br/> زمان : <span>' + position.shamsiCreateDateTime + '</span> <br/> سرعت : <span>' + (position.speed * 3.6).toFixed(2) + '</span><br/> نام مسافر : <span>' + _res.passangerFName + ' ' + _res.passangerLName + '</span><br/> موبایل مسافر : <span>' + _res.passangerMobile + '</span><br/> مبدا : <span>' + _res.originAddr + '</span><br/> مقصد : <span>' + _res.destinationAddr + '</span></p>');
        });

      }
      else {
        marker.bindPopup(
          '<p style="font-family:iranyekan_regular,iransans !important ;   text-align-last: right;"> نام راننده : <span style="font-weight:bold">' + position.fName + ' ' + position.lName + '</span><br/> کد راننده : <span>' + position.driverCode + '</span><br/> وضعیت : <span>' + currentDriverStatus.text + '</span> <br/> موبایل : <span>' + position.mobile + '</span> <br/> کد ایستگاه : <span>' + position.currentStation + '</span><br/> زمان : <span>' + position.shamsiCreateDateTime + '</span><br/> سرعت : <span>' + (position.speed * 3.6).toFixed(2) + '</span> </p>');
      }


      marker.addTo(this.map.map);
    }
    catch (e)
    {
      console.log(e);
    }
   

    return marker;
  }


  resetForm() {
    this.mobileSearch = '';
    this.passangerMobileSearch = '';
    this.driverFNameSearch = '';
    this.driverCodeSearch = '';
    this.passangerFNameSearch = '';
    this.tarikhTa = '';
    this.timeAz = '';
    this.timeTa = '';
    this.clearPoints();
    this.lstDriverPosMarker = [];
  }

  clearPoints() {
    this.lstDriverPosMarker?.forEach(m => {
      try {
        this.map.removemarker(m);

      }
      catch (e) {
        console.log(e);
      }
    })
   
  }

  ngOnDestroy() {
    this.map.removeMap();
  }

  getOriginAddressDesc(lat: any, lng: any) {
    var s = this.taxiSerivce.getAddressFromLocation(lat, lng);
    s.subscribe(_res => {
      this.originPoint.desc = _res;
    });
  }



  map_Click(cordinates: any) {



  }

  gotoOrigin() {

  }


  moveMapEvent(evt: any) {
    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }

  moveEndMapEvent(evt: any) {
    //if (this.CurrentState == PageState.OriginDefine) {
    //  this.getOriginAddressDesc(evt.location[0], evt.location[1]);
    //}
    //else if (this.CurrentState == PageState.DestinationDefine) {
    //  var s = this.taxiSerivce.getAddressFromLocation(evt.location[0], evt.location[1]);
    //  s.subscribe(_res => {
    //    this.destinationPoint.desc = _res;
    //  });
    //}

    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }

  setEmptyStation() {
  }

  ngAfterViewInit() {
    //console.log('ngAfterViewInit');



  }

}


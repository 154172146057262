import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  SwUpdate } from '@angular/service-worker';
import { environment } from '../../../environments/environment';
import { Car } from '../../model/car';
import { Driver } from '../../model/driver';
import { DomSanitizer } from '@angular/platform-browser';

import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { ErrorOutput } from '../../model/enumtype';

@Component({
  selector: 'app-login-app-new',
  templateUrl: './login-app-new.component.html',
  styleUrls: ['./login-app-new.component.scss']
})
export class LoginAppNewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private driverService: DriverserviceService, private router: Router, private swUpdate: SwUpdate,

    private sanitizer: DomSanitizer
  )
  {
    this.driverInfo = new Driver();
    this.carInfo = new Car();
  }
    
  driverInfo: Driver;
  carInfo: Car;
  avatar: any;
  base64Image: any;
  fbsToken = ''
  url=''
  ngOnInit() {
    //this.checkForUpdat();

    //var button = document.getElementById('btnClose');
    //button.onclick = () => this.TaxiOnPhone.resume();



    this.url = this.router.url;


    localStorage.removeItem(environment.is_created);
    this.route.queryParams.subscribe(params => {
      // save fbs hist
      this.driverService.saveLauncherInfo(params['oldid'], params['oldid'], params['version'] ).subscribe(_res => {});
      if (params['version'] && params['version'] != '') {
        localStorage.setItem(environment.currentversion, params['version']);
      }

      if (params['oldid'] && params['oldid'] != '') {      
        localStorage.setItem(environment.old_fbsToken, params['oldid']);
      }
      if (params['uid'] && params['uid'] != '') {
        this.fbsToken = params['uid'];
        localStorage.setItem(environment.cur_fbsToken, params['uid']);
        var old_fbsToken = localStorage.getItem(environment.old_fbsToken);
        var cur_fbsToken = localStorage.getItem(environment.cur_fbsToken);
        var version = localStorage.getItem(environment.currentversion);
        //console.log('old' + old_fbsToken);
        //console.log('new' + cur_fbsToken);

        this.driverService.getDriverSuccessfullGUIDWithToken(cur_fbsToken, old_fbsToken, version).subscribe(_res => {
          console.log('this.driverService.getDriverSuccessfullGUIDWithToke');
          console.log(_res);
          if (_res) {
            localStorage.setItem(environment.cur_u_name, _res);
            this.loadDriverByCacheID(_res, true);
          }
          else {
            //var cachID = (localStorage.getItem(environment.cur_u_name));
            ////console.log('cachID');
            ////console.log(cachID);

            //if (cachID && cachID != '') {
            //  //console.log('cachID exists' + cachID);
            //  this.loadDriverByCacheID(cachID, false);
            //}
            //else {
              //console.log('cachID null');
              this.router.navigate(['driver/new'], {});
          }
        });
      }
      else {
        this.router.navigate(['driver/new'], {});
      }
    });
  }
  msg ='در حال بررسی مجوز ورود...'
  loadDriverByCacheID(cachID: any, set: boolean) {
    console.log('loadDriverByCacheID cachID:' + cachID + '  ,  set:' + set)
    this.driverService.getDriverBySuccessfuGUID(cachID).subscribe(_res => {
      console.log('this.driverService.getDriverBySuccessfuGUID');
      console.log(_res);
      if (_res.errorCode == ErrorOutput.None && _res.info) {
        if (set) {
          localStorage.setItem(environment.cur_u_tkn, _res.tk);
          console.log('set tk is '+_res.tk);
        }
        var fbsToken = (localStorage.getItem(environment.cur_fbsToken));
        var goConfirm = true;
        //console.log('fbsToken' + fbsToken);
        if (fbsToken) {

          this.driverService.getDriverFBSToken(fbsToken).subscribe(_res => {

            this.driverInfo = _res.info;
            this.carInfo = _res.car;

            let url = "data:image/png;base64, " + this.driverInfo.avatar;
            this.base64Image = this.sanitizer.bypassSecurityTrustHtml(url);

            this.avatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + this.driverInfo.avatar);
            this.msg = 'در حال ورود به برنامه...'
            console.log(this.avatar)
            if (_res.errorCode == ErrorOutput.None && _res.info) {
              goConfirm = false;
              setTimeout(() => {
                this.router.navigate(['driver/test'], { queryParams: { guidfbs: fbsToken, fbs: '1' } })
              }, 500);

              
            }
            else {
              this.router.navigate(['driver/new'], { queryParams: { guid: cachID } });
            }
          });
        }
        else {
          this.router.navigate(['driver/confirm'], { queryParams: { guid: cachID } });
        }
      }
      else {
        this.router.navigate(['driver/new'], {});
      }
    }, error => {
      this.router.navigate(['driver/new'], {});
    });

  }

  
  checkForUpdat() {
    this.swUpdate.available.subscribe(event => {
      location.reload();
    });
  }
  loadData(uid : any) {
    localStorage.setItem('cache_id_n', uid);
    //this.router.navigate(['driver/new'], { queryParams: { } });

  }
  close() {
 
    //console.log('close run 1');

    
    //this.libN.close();
  }
  
  //play() {
    
  //  this.taxiOnPhone.resume();

     

  //  console.log('play run 1');
  //  //this.libN.resume();
  //}
  //stop() {
    
  //  this.taxiOnPhone.cancel();

  //  console.log('stop run 1');

  //  //this.libN.cancel();
  //}


}

import { Driver } from "./driver";

 export class IShift {
  id: number;
  date: Date | null;
  shiftAsrDriverID: number | null;
  shiftNightDriverID: number | null;
   datePersian: string;

   date_dayNumber1: number;
   date_dayNumber2: number;
   driver1: Driver;
   driver2: Driver;
   driver1Name: string;
   driver1Code: string;
   driver2Code: string;
   driver2Name: string;
   monthNumber1: number;
   monthNumber2: number;
   dayWeekName: string;
   avatar1: string;
   avatar2: string;

}

 

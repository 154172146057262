import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-info',
  templateUrl: './call-info.component.html',
  styleUrls: ['./call-info.component.scss']
})
export class CallInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {AfterViewInit, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ui-loader',
  template: `
    <div id="overlay" class="overlay hide">
      <div class="d-flex h-100 align-items-center justify-content-center">
        <div class="d-inline-block">
          <div class="d-flex justify-content-center">
            <mat-spinner
              class="my-2"
              color="#fff"
              diameter="50">
            </mat-spinner>
          </div>
          <div class="mt-2">
            <span class="noselect">درحال تبادل اطلاعات</span>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .overlay {
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 99999;
      left: 0;
      top: 0;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.9);
      overflow-x: hidden;
    }

    span {
      font-family: IranSans;
      font-size: 1.5rem;
      color: white;
    }

    ::ng-deep .mat-progress-spinner circle {
      stroke: white !important;
    }

    .hide {
      display: none;
    }

    .fade-in {
      animation: 0.25s fadeIn;
    }


    .fade-out {
      animation: 0.5s fadeOut;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }


    @keyframes fadeOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
  `]
})
export class UiLoaderComponent implements AfterViewInit {

  // @ts-ignore
  private loaderElement: HTMLElements;
  @Input() animateTime: number = 500;

  constructor() {
  }


  hide() {
    this.loaderElement.classList.add('fade-out');
    setTimeout(() => {
      this.loaderElement.classList.add('hide');
    }, this.animateTime);
  }

  show() {
    setTimeout(() => {
      this.loaderElement.classList.remove('hide');
      this.loaderElement.classList.add('fade-in');
      this.loaderElement.classList.remove('fade-out');
    }, this.animateTime);

  }

  ngAfterViewInit(): void {
    this.loaderElement = document.getElementById('overlay');
  }

}

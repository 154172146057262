import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-messgaenotif',
  templateUrl: './messgaenotif.component.html',
  styleUrls: ['./messgaenotif.component.scss']
})
export class MessgaenotifComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,) { }
  msg = 'درخواست شما توسط اپراتور لفو گردید'
  title='لغو سفر'
  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if (params['code'] ) {
        var code = params['code'];
        if (code == 1) {
          this.msg = 'درخواست شما توسط اپراتور لفو گردید'
          this.title = 'لغو سفر'

        }
        else if (code == 2) {
          this.msg = 'اطلاعات سفر شما ویرایش گردید'
          this.title = 'ویرایش  سفر'

        }
        else if (code == 3) {
          this.msg = 'مسافر در حال پیگیری سفر خود می باشد. با مسافر تماس گرفته شود'
          this.title = 'پیگیری سفر'

        }
        else if (code == 4) {
          this.msg = 'سفری توسط اپراتور به شما اختصاص داده شد'
          this.title = 'سفر جدید'

        }
        else {
          this.msg = 'درخواست شما توسط اپراتور لفو گردید'
          this.title = 'لغو سفر'

        }
        
      }
      //this.loadData(params['uid']);

    });


  }
  ok() {


    this.router.navigate(['/lauchercall/setact'], {
      queryParams: {
        act: 'close'
      }
    });
  }

}

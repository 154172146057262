import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICommissionReport } from '../../lib/interfaces/iServiceReport';
import { ReportService } from '../../lib/serviceLib/report.service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-report-commission',
  templateUrl: './report-commission.component.html',
  styleUrls: ['./report-commission.component.css']
})
export class ReportCommissionComponent implements OnInit {
  totalprice = 0;
  totalcommission = 0;
  totalbedehkari = 0;
  totalbestankari = 0;

  dayPrefix = new FormControl('02');
  yearPrefix = new FormControl('1403');

  count = 0;
 
  
  constructor(private repSrv: ReportService, public dialog: MatDialog,
    private serviceService: ServiceserviceService,

  ) { }
  reports: ICommissionReport[];
  ngOnInit(
  ): void {
    this.getCurrentDate();
  }
  getCurrentDate() {
    this.repSrv.GetCurrentActiveDate().subscribe(t => {
      console.log(t);
      this.yearPrefix.setValue(t[0]);
      this.dayPrefix.setValue(t[1]);
    });
  }
  calctotal() {
    this.totalprice = 0;
    this.totalcommission = 0;
    this.totalbedehkari = 0;
    this.totalbestankari = 0;

    this.count = this.reports?.length ?? 0;
    this.reports.forEach(t => {
      if (t.sumPrice) {
        let prc: number = t.sumPrice;
        if (prc > 0) this.totalprice += prc;

        this.totalcommission += t.commissionValue;
        //this.totalbedehkari += t.bedehkari;
        //this.totalbestankari += t.bestankari;


      }
      
    })
  }
  datePrefix = '1403/02';
  report()
  {
    this.datePrefix = this.yearPrefix.value + '/' + this.dayPrefix.value;
    console.log(this.datePrefix);
    this.repSrv.CommissionReport(this.datePrefix).subscribe(t => {
      this.reports = t;
      console.log(t);
      //this.calctotal();
    });
  }
}

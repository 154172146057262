import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drv-master',
  templateUrl: './drv-master.component.html',
  styleUrls: ['./drv-master.component.css']
})
export class DrvMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

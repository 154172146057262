import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { markerHelper, MarkerType } from '../lib/markerHelper';

import { Map, map as LeafletMap, tileLayer, CRS, marker, icon, LeafletMouseEvent, Icon, DivIcon, Marker, LatLng, circleMarker, circle, control, Control, DomUtil } from 'leaflet';
import { Station } from '../model/station';
import { Observable } from 'rxjs';
import { DriverPositionHist } from '../model/driver';

@Component({
  selector: 'app-taximap',
  templateUrl: './taximap.component.html',
  styleUrls: ['./taximap.component.css']
})
export class TaximapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('taximap')
  public mapContainer: ElementRef;

  map: Map;
  markerLayer: any;
  public custom: Control;
  @Input() width: string = '100% !important';
  top: string;
  left: string;

  @Output() mapClick = new EventEmitter<number[]>();
  @Output() moveMap = new EventEmitter<{ location: number[], evt: any }>();
  @Output() moveEnd = new EventEmitter<{ location: number[], evt: any }>();
  @Output() stationClick = new EventEmitter<Station>();

  constructor(
  ) {

  }
  ngOnInit() {


  }

  ngAfterViewInit() {

    this.bindMap();
    this.initilizeMapEvent();

  }

  findTop() {
    return (document.getElementsByClassName("leaflet-control-zoom-out")[0]?.getBoundingClientRect()?.top + 30 + 4)?.toString() + 'px';

  }

  findLeft() {
    return (document.getElementsByClassName("leaflet-control-zoom-out")[0]?.getBoundingClientRect()?.left)?.toString() + 'px';

  }

  getCenter() {
    //console.log('getCenter');
    return this.map.getCenter();
  }
  initilizeMapEvent() {

    //console.log('initilizeMapEvent');
    this.map.on("move", ((evt: any) => {
      //console.log('move fire');
      var loc = this.map.getCenter();
      var cord = [loc.lat, loc.lng]
      this.moveMap.emit({ location: cord, evt: evt })
    }));
    this.map.on('moveend', ((evt: any) => {
      var loc = this.map.getCenter();
      var cord = [loc.lat, loc.lng]
      this.moveEnd.emit({ location: cord, evt: evt });

    }));
    /*
    this.map.on('pointermove', ((evt) => {

      this.moveMarkerOnCenter();
    }));



    this.map.addEventListener('singleclick', (evt) => {
      //console.warn('singleclick');

      const latLon = toLonLat(evt.coordinate);
      this.markerSource.forEachFeatureAtCoordinateDirect([latLon[0], latLon[1]], arg=> {
        //console.warn(arg);
      })

      this.mapClick.emit([latLon[1], latLon[0]]);




    });
    */
    //control.locate().addTo(this.map);
  }
  gpsLat = 0
  gpsLng = 0
  gpsCirle: any;
  locateobservable(setViewparam = true, typeM: MarkerType = MarkerType.currentGPS): Observable<any> {
    return new Observable((observer) => {
      this.map.locate({ setView: setViewparam, maxZoom: 12, enableHighAccuracy: true }).on('locationfound', (e: any) => {

        observer.next(e);
      });
    });
  }
  moveCurrentGPS(lat: any, lng: any) {
    if (this.gpsCirle) {
      this.map.removeControl(this.gpsCirle);
    }
    this.gpsCirle = this.addMarker(lat, lng, MarkerType.taxi);

  }
  locateManual(lat: any, lng: any, setViewparam = true, typeM: MarkerType = MarkerType.currentGPS) {

    this.gpsLat = lat;
    this.gpsLng = lng;
    //console.log(this.gpsLng);
    //console.log(this.gpsLat);
    if (setViewparam) {
      //console.log('inja 7 locate manual');
      this.map.setView([lat, lng], 12)
    }
    if (typeM == MarkerType.currentGPS) {
      if (this.gpsCirle) {
        this.map.removeControl(this.gpsCirle);
      }
      this.gpsCirle = this.addMarker(this.gpsLat, this.gpsLng, MarkerType.taxi);

      //this.gpsCirle = circle([this.gps_lat, this.gps_lng], 1, {
      //  weight: 10,
      //  color: 'blue',
      //  fillColor: '#cacaca',
      //  fillOpacity: 0.9
      //}).addTo(this.map);

      //this.map.setZoom(15);
    }
    else {
      this.addMarker(this.gpsLat, this.gpsLng, typeM);
    }
  }
  locate(setViewparam = true, typeM: MarkerType = MarkerType.currentGPS): Observable<any> {
    return new Observable((observer) => {

      //console.log('inja 1 locate');
      this.map.locate({ setView: setViewparam, enableHighAccuracy: true }).on('locationfound', (e: any) => {
        //console.log('locationfound');
        //console.log(e);

        this.gpsLat = e.latlng.lat;
        this.gpsLng = e.latlng.lng;
        //console.log(this.gpsLng);
        //console.log(this.gpsLat);
        if (setViewparam) {
          this.map.setView([e.latlng.lat, e.latlng.lng], 12)
        }
        if (typeM == MarkerType.currentGPS) {
          if (this.gpsCirle) {
            this.map.removeControl(this.gpsCirle);
          }
          this.gpsCirle = this.addMarker(this.gpsLat, this.gpsLng, MarkerType.taxi);

          //this.gpsCirle = circle([this.gps_lat, this.gps_lng], 1, {
          //  weight: 10,
          //  color: 'blue',
          //  fillColor: '#cacaca',
          //  fillOpacity: 0.9
          //}).addTo(this.map);

          //this.map.setZoom(15);
        }
        else {
          this.addMarker(this.gpsLat, this.gpsLng, typeM);
        }
        observer.next(e);
      })
      this.map.on('locationerror', this.onLocationError);
      observer.next('');
    });
  }
  onLocationError(e: any) {
    //console.log('location error');
    //console.log(e);
  }
  gotogps() {
    console.log('gotogps');
    console.log('this.gpsLat' + this.gpsLat);
    console.log('this.gpsLng' + this.gpsLng);
    var lat = this.gpsCirle.getLatLng().lat;
    var lng = this.gpsCirle.getLatLng().lng;
    this.map.setView([lat, lng], 12)
  }
  bindMap() {

    console.log('bindMap');
    if (typeof this.map == 'undefined' || !this.map) {

      this.map = LeafletMap(this.mapContainer.nativeElement, {
        attributionControl: false
      });


      
tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
//            tileLayer('https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
      }).addTo(this.map);
      //console.log('inja 3 goto 36 59');
      this.map.setView([36.329616, 59.56543], 10);

      //let Custom = Control.extend({
      //  onAdd(map: Map) {
      //    return DomUtil.get('custom');
      //  },
      //  onRemove(map: Map) { }
      //});
      //this.custom = new Custom({
      //  position: 'topleft'
      //}).addTo(this.map);
    }

  }
  setView(lat: any, lng: any, zoomLevel = 12) {
    //console.log('inja 4 setView');
    this.map.setView([lat, lng], zoomLevel);
  }
  addMarker(lat: any, lon: any, markerType?: MarkerType, txt?: string, isdragable: boolean = false) {
    var cord = new LatLng(lat, lon);
    var markerOject = marker(cord, {
      icon: markerHelper.getMarker(markerType),
      draggable: false,
      title: markerHelper.getMarkerText(txt, markerType)

    }).addTo(this.map);

    markerOject.on('click', (e: any) => { });
    return markerOject;
  }
  removemarker(marker: any) {
    this.map.removeControl(marker);
  }
  StationMarkerList: { [stationCode: number]: Marker; } = {};
  addStationMarker(station: Station) {
    const originLatLng = new LatLng(station.lat, station.lng);

    let htmlMarker = '';
    if (station.requestCount > 0) {
      //htmlMarker = '<div class="row"><div class="column" style="position:absolute; margin-right:15px; margin-top:-11px; "><img style=" margin-right:20px;" src="assets/passanger.png"><span style="margin-right:6px; margin-top:1px; position:absolute; width: 32px !important;height: 24px !important;border-radius: 18px;text-align: center;color: #fff;background-color: blue;font-size: 16px;"><strong>' + station.requestCount + '</strong></span></div><div  class="column"><div style="position: relative;"><span >' + station.id + '</span></div></div></div>';
      htmlMarker = '<div class="row"><div class="column" style="position:absolute; margin-right:15px; margin-top:-11px; "><img style=" margin-right:20px;" src="assets/passanger.png"></div><div  class="column"><div style="position: relative;"><span >' + station.code + '</span></div></div></div>';
    }
    else {
      htmlMarker = '<div style="position: relative;"><span >' + station.code + '</span></div>';
    }
    const customIcon = new DivIcon({
      className: 'my-custom-icon',
      html: htmlMarker,
      popupAnchor: [0, 0]
    });

    let stationMarker = this.StationMarkerList[station.id];
    //if (stationMarker)
    //  this.removemarker(stationMarker);
    if (!stationMarker) {
      stationMarker = marker(originLatLng, {
        icon: customIcon,
        draggable: false,
        title: station.name
      }).addTo(this.map);
      this.StationMarkerList[station.id] = stationMarker;
      stationMarker.on('click', (e: any) => { this.onStationClick(e, station); });
    }
    stationMarker.bindPopup(
      '<p> نام ایستگاه : <span style="font-weight:bold">' + station.name + '</span><br/> حاضرین : <span>' + station.length + '</span> <be/> مسافر : <span>' + station.requestCount + '</span> </p>');
    stationMarker.getPopup().on('remove', (p: any) => {
      if (this.currentCircle) {
        //console.warn(this.currentCircle);
        this.map.removeLayer(this.currentCircle);
      }
    });
    return stationMarker;
  }
  updateStationMarker(station: Station) {
    let stationMarker = this.StationMarkerList[station.id];
    if (stationMarker) {

      this.removemarker(stationMarker);
      let htmlMarker = '';
      if (station.requestCount > 0) {
        //htmlMarker = '<div class="row"><div class="column" style="position:absolute; margin-right:15px; margin-top:-11px; "><img style=" margin-right:20px;" src="assets/passanger.png"><span style="margin-right:6px; margin-top:1px; position:absolute; width: 32px !important;height: 24px !important;border-radius: 18px;text-align: center;color: #fff;background-color: blue;font-size: 16px;"><strong>' + station.requestCount + '</strong></span></div><div  class="column"><div style="position: relative;"><span >' + station.id + '</span></div></div></div>';
        htmlMarker = '<div class="row"><div class="column" style="position:absolute; margin-right:15px; margin-top:-11px; "><img style=" margin-right:20px;" src="assets/passanger.png"></div><div  class="column"><div style="position: relative;"><span >' + station.code + '</span></div></div></div>';
      }
      else {
        htmlMarker = '<div style="position: relative;"><span >' + station.code + '</span></div>';
      }
      stationMarker = marker(new LatLng(station.lat, station.lng), {
        icon: new DivIcon({
          className: 'my-custom-icon',
          html: htmlMarker,
          popupAnchor: [0, 0]
        }),
        draggable: false,
        title: station.name
      }).addTo(this.map);

      stationMarker.on('click', (e: any) => { this.onStationClick(e, station); });


      stationMarker.bindPopup(
        '<p> نام ایستگاه : <span style="font-weight:bold">' + station.name + '</span><br/> حاضرین : <span>' + station.length + '</span> <be/> مسافر : <span>' + station.requestCount + '</span> </p>');
      stationMarker.getPopup().on('remove', (p: any) => {
        if (this.currentCircle) {
          this.map.removeLayer(this.currentCircle);
        }
      });

      this.StationMarkerList[station.id] = stationMarker;

    }
  }


  createPositionMarker(position: DriverPositionHist) {
    const positionLatLng = new LatLng(position.lat, position.lng);
    let colorName = 'gray';
    if (position.currentStatus == 1004 || position.currentStatus == 1005)
      colorName = 'gray';
    else if (position.currentStatus == 1006)
      colorName = 'green';
    else if (position.currentStatus == 1007)
      colorName = 'yellow';
    else if (position.currentStatus == 1008)
      colorName = 'red';
    else
      colorName = 'gray';


    const customIcon = new DivIcon({
      className: 'my-custom-positionicon',
      html: '<img src="assets/position-' + colorName + '.png" style="width: 15px; height:15px; transform: rotate(' + position.bearing + 'deg)">',
      popupAnchor: [0, 0]
    });
    const positionMarker = marker(positionLatLng, {
      icon: customIcon,
      draggable: false,
    });

    positionMarker.on('click', (e: any) => { });


    return positionMarker;
  }



  addDriverMarker(lat: any, lng: any, code: any) {
    const originLatLng = new LatLng(lat, lng);
    const customIcon = new DivIcon({
      className: 'my-custom-drivericon',
      html: '<div style="position: relative;"><span >' + code + '</span></div>',
      popupAnchor: [0, 0]
    });
    const originMarker = marker(originLatLng, {
      icon: customIcon,
      draggable: false,
      title: code
    }).addTo(this.map).bindPopup('<p> نام ایستگاه : <span style="font-weight:bold">' + code + '</span><br/> حاضرین : <span>' + code + '</span></p>');
    originMarker.getPopup().on('remove', (p: any) => {
      //console.warn(p)
      //console.warn(this.currentCircle);
      if (this.currentCircle) {
        //console.warn(this.currentCircle);
        this.map.removeLayer(this.currentCircle);
      }
    });

    originMarker.on('click', (e: any) => { });
    //this.map.setView([lat, lng], 13);
    return originMarker;
  }


  addStationMarkerWithInfo(station: Station) {

    var originLatLng = new LatLng(station.lat, station.lng);
    var customIcon = new DivIcon({
      className: 'my-custom-icon',
      html: '<div><span>' + station.length + '</span></div> <br> <div><span style="color:black; font-size:20px;    font-family: \'Vazir\', \'Helvetica Neue\', \'sans-serif\';}"> ' + station.name + '</span></div>'
    });
    var originMarker = marker(originLatLng, {
      icon: customIcon,
      draggable: false,
      title: station.name
    }).addTo(this.map);
    originMarker.on('click', (e: any) => { this.onStationClick(e, station); });
    if (station.id != 100) {
      //console.log('inja 5 ' + station);
      this.map.setView([station.lat, station.lng], 13);
    }
  }
  currentCircle: any;
  passangerMarker: any;
  onStationClick(e: any, i: any) {
    this.stationClick.emit(i);
    //console.warn('marker click');
    //console.warn(e);
    //console.warn(i);
    if (this.currentCircle) {
      //console.warn(this.currentCircle);
      this.map.removeLayer(this.currentCircle);
    }
    this.currentCircle = circle([i.lat, i.lng], i.radios).addTo(this.map);


  }

  getDistanceFromLatLonInKm(lat1: any, lon1: any, lat2: any, lon2: any) {
    var R = 6371; // Radius of the earth in kilometers
    var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in KM
    return d;
  }

  deg2rad(deg: any) {
    return deg * (Math.PI / 180)
  }

  removeMap() {
    this.map?.off();
    this.map?.remove();
  }

  ngOnDestroy() {


  }
  openPopup(t) {
    this.map.openPopup(t)
  }

}

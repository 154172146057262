import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';

@Component({
  selector: 'app-okmessage',
  templateUrl: './okmessage.component.html',
  styleUrls: ['./okmessage.component.scss']
})
export class OkmessageComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<OkmessageComponent>,
    private route: ActivatedRoute,
    private taxiSerivce: ServiceRequestService,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, messageText: string }) { }

  ngOnInit() {

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exitalarm',
  templateUrl: './exitalarm.component.html',
  styleUrls: ['./exitalarm.component.scss']
})
export class ExitalarmComponent implements OnInit, OnDestroy {

  constructor(private router: Router,) {

  }
  ngOnDestroy(): void {
    if (this.schedulJon) {
      clearInterval(this.schedulJon);
    }
  }
  untilSecond = 0;
  dtOutOfRange: any;
  schedulJon: any;
  ngOnInit() {
    this.dtOutOfRange = new Date()
    this.schedulJon = setInterval(() => {
      this.check();
    }, 1000);

  }
  check() {
    
    const diff = Math.abs((new Date()).getTime() - this.dtOutOfRange);
    //console.log(diff);
    if (diff > 3 * 60 * 1000) {
      this.ok();
    }
    else {
      this.untilSecond = parseInt(((3 * 60 * 1000 - diff) / 1000).toString());
    }
  }


  ok() {
    this.router.navigate(['/lauchercall/setact'], {
      queryParams: {
        act: 'close'
      }
    });
  }
}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import { GlobalVariable } from './../../globalVariable';
import { Observable, Subscriber } from 'rxjs';
import { Acc_Acc } from '../../model/accModels';

@Injectable({
  providedIn: 'root'
})
export class AccServiceService {

  private AccBaseServiceURL = GlobalVariable.AccBaseServiceURL;
  constructor(private httpClient: HttpClient) { }

  public getAccList(nCode: any): Observable<Acc_Acc> {
    let params = new HttpParams().set('ParentID', nCode);
    return this.httpClient.post<Acc_Acc>(this.AccBaseServiceURL + "/GetInfo", params);
  }

  public insert(value: Partial<Acc_Acc>): Observable<number> {
    console.log('insert')
    console.log(value)

    let params = new HttpParams().set('AccName', value.accName);
    params = params.set('Comment', value.comment);
    params = params.set('ParentID', value.parentID.toString());
    
    return this.httpClient.post<number>(this.AccBaseServiceURL + '/InsertNew', params);
  }

  public update(value: Partial<Acc_Acc>): Observable<number> {
    console.log('update')
    console.log(value)

    let params = new HttpParams().set('AccName', value.accName);
    params = params.set('Comment', value.comment);
    //params = params.set('ParentID', value.parentID.toString());
    params = params.set('ID', value.id.toString());

    return this.httpClient.post<number>(this.AccBaseServiceURL + '/Update', params);
  }
   
}

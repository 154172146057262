import { Component, OnInit, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Car } from '../../model/car';
import { Driver } from '../../model/driver';
import { ErrorOutput } from '../../model/enumtype';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-confirm-info',
  templateUrl: './confirm-info.component.html',
  styleUrls: ['./confirm-info.component.css']
})
export class ConfirmInfoComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, private driverService: DriverserviceService) {
    this.driverInfo = new Driver();
    this.carInfo = new Car();
    this.route.queryParams.subscribe(params => {
      this.guid = params['guid'];
      //console.warn('receive guid is :' + this.guid);
      this.loadDriverInfo(this.guid);
    });
  }
  driverInfo: Driver;
  carInfo: Car;
  guid: string;
  avatar: any;
  ngOnInit() {


  }
  currentguid = '';
   base64Image : any ;
  loadDriverInfo(guid : any) {
    this.currentguid = guid;
    this.driverService.getDriverBySuccessfuGUID(guid).subscribe(_res => {
      if (_res) {
        //console.log('loadDriverInfo guid ' + guid);
        this.driverInfo = _res.info;
        this.carInfo = _res.car;
        //const token = _res.headers.get('cur-token')

        localStorage.setItem(environment.cur_u_tkn, _res.tk);
        console.log('localStorage.setItem(environment.cur_u_tkn, _res.tk);')
        console.log('cur_u_tkn is ');
        console.log(localStorage.getItem(environment.cur_u_tkn));

        localStorage.setItem(environment.cur_u_name, guid);


        //console.log(this.driverInfo.currentStatus);
        //console.log(this.driverInfo.guid);




        let url = "data:image/png;base64, " + this.driverInfo.avatar;
        this.base64Image = this.sanitizer.bypassSecurityTrustHtml(url);

        this.avatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + this.driverInfo.avatar);
        //console.log('deviceId is ' + localStorage.getItem(environment.cur_fbsToken));
        //console.log('after avatar');
        //console.log(_res.errorCode?.toString());

        //this.avatar = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(url)) ;
        //console.warn(this.avatar);
        if (_res.errorCode != ErrorOutput.None) {
          //console.log('goto home page 1');

          this.router.navigate(['driver/new'], {});
        }
        //else if (this.driverInfo != null && this.driverInfo.confirmInfoByOperator) {
        else if (this.driverInfo != null) {
          //console.log('go to station');

          this.router.navigate(['driver/test'], { queryParams: { guid: guid } });
        }
        //else if (this.driverInfo != null ) {
        //  //console.warn('navigagte to station with ' + this.driverInfo.guid);
        //  this.router.navigate(['driver/waitconfirm'], { queryParams: { guid: guid} });
        //}
      }
      else {
        //console.log('result is null');

        this.router.navigate(['driver/new'], {});
      }
    });
  }
  notconfirm() {
    this.driverService.setSuccessfullResult(this.guid, false).subscribe(_res => { });
    this.router.navigate(['driver/mismatch'], {});
  }
  confirm() {
    this.driverService.setSuccessfullResult(this.guid, true).subscribe(_res => { })
    this.router.navigate(['driver/waitconfirm'], { queryParams: { guid: this.currentguid  } });

  }
 

}

import {Component, OnInit,ViewChild,ElementRef,AfterViewInit, Inject} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../lib/serviceLib/profileservice.service'
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service'
import { Profile } from '../../model/profile';

import { ComplaintserviceService } from '../../lib/serviceLib/complaintservice.service';
import { Complaint } from '../../model/complaint';

import { Driver } from '../../model/driver';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';

import { GlobalVariable } from '../../globalVariable';
import { environment } from '../../../environments/environment.prod';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  operatorguid: any;
  driverInfo = new Driver();
  guidDriver: string;
  telID = new FormControl('');
  allComplaintList: Complaint[] = [];
  driverCode = '';
  profile = new Profile();
  profileToday:Profile;
  @ViewChild('android')
  dataChart: any;
  thisMonth: any;
  public android: ElementRef;
  constructor(
    private complaintServiceService: ComplaintserviceService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private driverSerivce: DriverserviceService,
    private callCenterService: CallCenterService,
    public dialogRef: MatDialogRef<ProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { guidDriver: string },
    private router: Router
  ) {
    this.guidDriver = data.guidDriver;


 

  }
  currentFBSToken = '';
  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];



    });
    
    this.currentFBSToken = localStorage.getItem(environment.cur_fbsToken);
    this.loadDriverInfo().subscribe(_res => {
      this.driverInfo = _res.info;
      this.profileService.getProfileToday(this.driverInfo.id).subscribe(t => {
        this.profileToday = t;
      });
      this.profileService.getProfile(this.driverInfo.id).subscribe(t => {
        this.profile.todayPrice = t.todayPrice;
        this.profile.todayCount = t.todayCount;
        this.profile.workTimeHour = ('0' + (t.workTime / 3600).toFixed(0)).slice(-2);
        this.profile.workTimeMinute = ('0' + ((t.workTime % 3600) / 60).toFixed(0)).slice(-2);
        this.profile.serviceTimeHour = ('0' + (t.serviceTime / 3600).toFixed(0)).slice(-2);
        this.profile.serviceTimeMinute = ('0' + ((t.serviceTime % 3600) / 60).toFixed(0)).slice(-2);

        let nowDate = new Date();
        this.thisMonth = GlobalVariable.toShamsi(nowDate).monthTitle;


        this.profile.monthPrice = t.monthPrice;
        this.profile.monthCount = t.monthCount;
        this.profile.workTimeHourMonth = ('0' + (t.workTimeMonth / 3600).toFixed(0)).slice(-2);
        this.profile.workTimeMinuteMonth = ('0' + ((t.workTimeMonth % 3600) / 60).toFixed(0)).slice(-2);
        this.profile.serviceTimeHourMonth = ('0' + (t.serviceTimeMonth / 3600).toFixed(0)).slice(-2);
        this.profile.serviceTimeMinuteMonth = ('0' + ((t.serviceTimeMonth % 3600) / 60).toFixed(0)).slice(-2);

        let farsiLables = [];
        let prices = t.prices?.split(',').map(x => x == null || x == '' ? 0 : Number(x));
        let workTimes = t.workTimes?.split(',').map(x => x == null || x == '' ? 0 : (Number(x) / 3600).toFixed(2));
        let serviceTimes = t.serviceTimes?.split(',').map(x => x == null || x == '' ? 0 : (Number(x) / 3600).toFixed(2));
        let monthCounts = t.monthCounts?.split(',').map(x => x == null || x == '' ? 0 : Number(x));

        for (let i = 6; i >=0 ; i--) {
          let farsiDate = GlobalVariable.toShamsi(new Date(nowDate.getFullYear(), nowDate.getMonth() - i, 1));
          let currentMonth = farsiDate.monthTitle;
          farsiLables.push(currentMonth);
        }
      

        this.dataChart = {
          labels: farsiLables,
          datasets: [    
            {
              label: 'درآمد',
              data: prices
            },
            {
              label: 'تعداد سفر',
              data: monthCounts
            },       
            {
              label: 'ساعات کارکرد',
              data: workTimes
            },
            {
              label: 'ساعات سفر',
              data: serviceTimes
            },
          ]
        }



        this.loadComplaintReport();

      });


    });






  }

  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
  }


  loadComplaintReport() {
    this.complaintServiceService.reportDriverComplaints(this.driverInfo.driverCode).subscribe(res => {
      if (res.length > 0) {
        //console.log(res);

        this.allComplaintList = res;
        //this.filter();
      } else {
        this.allComplaintList = [];

        //Swal.fire('یافت نشد', 'موردی جهت جستجو یافت نشد!');
      }
    });
  }

  loadDriverInfo(): Observable<any> {
    return new Observable((observer) => {
      if (this.currentFBSToken) {
        this.driverSerivce.getDriverByFBSToken_OnlyInfo(this.currentFBSToken).subscribe(_res => {

          //localStorage.setItem(environment.cur_u_tkn, _res.tk);
          //console.log('localStorage.setItem(environment.cur_u_tkn, _res.tk);');
          //console.log('environment.cur_u_tkn is ;');
          //console.log(localStorage.getItem(environment.cur_u_tkn));
          observer.next(_res);
          //console.log('getDriverBySuccessfuGUID');
          //console.log(_res);
        })
      }
      else {
        observer.error('guid is not correct');


      }
      return {
        unsubscribe() {
          //navigator.geolocation.clearWatch(watchId);
        }
      };
    });
  }

  close() {
    this.dialogRef.close('closse');

  }


}

import { Component, EventEmitter, Input, OnInit, Output,ElementRef,ViewChild,AfterViewInit } from '@angular/core';
import { FoundedObject } from '../../model/foundedObject';
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from '@angular/router';
import { FoundedObjectserviceService } from '../../lib/serviceLib/foundedObjectservice.service';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-founded-objects',
  templateUrl: './founded-objects.component.html'
})
export class FoundedObjectsComponent implements OnInit, AfterViewInit {
  @Input() visible: boolean = true;
  @Input() data: any = [];
  @Output() visibleChange = new EventEmitter();
  allFoundedObjectList: FoundedObject[] = [];
  @ViewChild('android')
  public android: ElementRef;
  isLoading: boolean = false;
  detailsVisible: boolean = false;
  public result: any[] = [];

  recordCount = 0;
  pageSize = 30;



  constructor(
    private foundedObjectServiceService: FoundedObjectserviceService,
    private route: ActivatedRoute,
    private router: Router

  ) {
  }

  operatorguid: string;
  foundedObjectForm: FormGroup;

  ngOnInit(): void {
    this.foundedObjectForm = new FormGroup({
      passangerFName: new FormControl(),
      passangerMobile: new FormControl(),
      originAddr: new FormControl(),
      driverCode: new FormControl(),
      description: new FormControl(),
      destinationAddr: new FormControl()
    });


    this.allFoundedObjectList = [];
    this.loadFoundedObjectReport();
  }

  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
  }

  async submitFormAndSend() {


    this.loadFoundedObjectReport();
  }
  loadFoundedObjectReport() {
    this.foundedObjectServiceService.reportAllFoundedObject(this.foundedObjectForm.get('passangerFName')?.value, this.foundedObjectForm.get('passangerMobile')?.value, this.foundedObjectForm.get('driverCode')?.value, this.foundedObjectForm.get('originAddr')?.value, this.foundedObjectForm.get('destinationAddr')?.value, this.foundedObjectForm.get('description')?.value).subscribe(res => {
      if (res.length > 0) {
        //console.log(res);
        this.allFoundedObjectList = res;
        //this.filter();
      } else {
        this.allFoundedObjectList = [];

        Swal.fire('یافت نشد', 'موردی جهت جستجو یافت نشد!');
      }
    });
  }

  resetForm() {
    this.foundedObjectForm.reset();
    this.allFoundedObjectList = [];

  }
}


import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { ProposedRequestComponent } from '../proposed-request/proposed-request.component';
import {  SwUpdate } from '@angular/service-worker';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
@Component({
  selector: 'app-unlocknotification',
  templateUrl: './unlocknotification.component.html',
  styleUrls: ['./unlocknotification.component.scss']
})
export class UnlocknotificationComponent implements OnInit {
  dialogProposedServiceRef: MatDialogRef<ProposedRequestComponent, any>;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private ccService: CallCenterService,
    private driverService: DriverserviceService,
    private taxiSerivce: ServiceRequestService,
    private matSnackbar: MatSnackBar,
    private dialog: MatDialog,
    private swUpdate: SwUpdate) { }
  code=''
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.taxiSerivce.checkActive(this.code).subscribe(_res => {
        if (_res) {
          this.loadData(this.code);
        }
        else {
          this.closePopupdialog();
          
        }


      });
    });
  }
  ngAfterViewInit() {
    this.checkForUpdat();

  }
  checkForUpdat() {
    this.swUpdate.available.subscribe(event => {

      //console.log('update version ');
      location.reload();


    });
  }
  schedulJon: any;
  loadData(code: any) {
    //this.ccService.finishLog(code).subscribe(res => { });

    //this.driverService.setFinish(code).subscribe(res => { console.log });

    //this.driverService.sendNotif('', '', 1).subscribe(res => { console.log });




    this.dialogProposedServiceRef = this.dialog.open(ProposedRequestComponent, {
      width: '400px',
      hasBackdrop: true,
      disableClose: true,
      data: {
        guidRequest: code
      }
    });
    this.dialogProposedServiceRef.afterClosed().subscribe((result : any) => {
      if (result == 'Accept') {
        this.acceptRequest(code);

      } else if (result == 'Reject') {

        this.rejectRequest(code);
      }
    });
    this.schedulJon = setTimeout(() => {
      this.dialogProposedServiceRef.close();
      this.closePopupdialog();
    }, 30 * 1000);

  }
  closePopupdialog() {
    this.taxiSerivce.proposedDialogClosed(this.code).subscribe(_res => {
      this.router.navigate(['/lauchercall/setact'], {
        queryParams: {
          act: 'close'
        }
      });
    });
   
  }
  acceptRequest(guidRequestParam: any) {
    this.driverService.GetSuccesfullService(guidRequestParam, 'accept').subscribe(res => {
      if (res == 'duplicate') {
        var refsnack = this.matSnackbar.open('سرویس توسط شخص دیگری قبول گردید', null, { duration: 1000 });
        refsnack.afterDismissed().subscribe(() => {
          this.closePopupdialog();
        });

        
      }
      else
      this.closePopupdialog();
      //this.router.navigate(['service/origin'], { queryParams: { guid: res } });
    });
  }

  rejectRequest(guidRequestParam: any) {
    this.driverService.GetSuccesfullService(guidRequestParam, 'reject').subscribe(res => {
      this.closePopupdialog();
      //this.router.navigate(['driver/new'], { queryParams: { guid: res } });
    });
  }
 


}



import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from '../globalVariable';

export class StationQueueInfo {
  fName: string;
  lName: string;
  pelaque2: string;
  pelaque3: string;
  pelaqueIR: string;
  pelaqueLetter: string;
  carName: string;
  colorName: string;
  avatar: string;
  safeAvatar: any;
  driverCode: string;
  lat: number;
  lng: number;
  pos: boolean;
  mobile: string;
  workingMobile: string;
  priority: string;
  driverID: number;
  stationID: number;
}
 

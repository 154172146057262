import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSidenav } from '@angular/material/sidenav';
import { DriverStatus } from '../../lib/enums/driver-status.enum';
import { Car } from '../../model/car';
import { Driver } from '../../model/driver';
import { Station } from '../../model/station';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';

@Component({
  selector: 'app-master-driver',
  templateUrl: './master-driver.component.html',
  styleUrls: ['./master-driver.component.scss']
})
export class MasterDriverComponent implements OnInit {
  driverInfo = new Driver();
  avatar: any;
  carInfo = new Car();
  lstStation: Station[];
  currentStation = new Station();
  currentPriority = 0;
  constructor(
    private stationSerivce: StationserviceService,
    private _bottomSheet: MatBottomSheet,
  ) { }
  @ViewChild("drawer", { static: false })
  public drawer: MatSidenav;

  @ViewChild("drawer2", { static: false })
  public drawer2: MatSidenav;
  ngOnInit() {
  }
  toggleMenu() {
    //console.warn('toggleMenu');
    this.drawer.toggle();
  }
  showMenu2() {
    this.drawer2.open();
    this.drawer.close();
  }
  closeMenu2() {
    this.drawer2.close();
    this.drawer.open();
  }
  _currentDriverState: DriverStatus = DriverStatus.Off;
  showStation(stationInput: Station) {
    this.stationSerivce.getStation(stationInput.id).subscribe(item => {

      

    });
  }

}

import { Component, OnInit } from '@angular/core';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { Abonman } from '../../model/abonman';

@Component({
  selector: 'app-abonmanview',
  templateUrl: './abonmanview.component.html',
  styleUrls: ['./abonmanview.component.css']
})
export class AbonmanviewComponent implements OnInit {

    
  constructor(private drivService: DriverserviceService,) { }

  ngOnInit(): void {
    this.filter();
  }
  year = '1402';
  month = '';
  abonmanList: Partial<Abonman>[];

  filter() {
    console.log('filter');
    this.drivService.abonmanList(this.year, this.month).subscribe(_res => {
      console.log(_res);
      this.abonmanList = _res;
      this.calcTotal();

    });
  }
  calcTotal() { }

}

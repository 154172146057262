import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {ControlValueAccessor} from "@angular/forms";

@Component({
  selector: 'custom-checkbox',
  template: `
    <div class="d-flex clickable"
         dir="ltr"
         (click)="model= !model"
    >
      <div class="col">
        <mat-checkbox
          (change)="onChange"
          [(ngModel)]="model"
          [color]="color">
        </mat-checkbox>
      </div>
      <div class="col-auto">
        <span>{{label}}</span>
      </div>

    </div>
  `,
  styles: [`
    .clickable {
      cursor: pointer;
    }

    span {
      font-family: IranYekan;
    }
  `]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() color: ThemePalette = 'primary';
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  onChange: any = () => {
  }
  model: boolean = false;

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(val: any): void {
    this.model = val;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { Driver } from '../../model/driver';
import { ReportTripComponent } from '../report-trip/report-trip.component';

@Component({
  selector: 'app-report-bydriver',
  templateUrl: './report-bydriver.component.html',
  styleUrls: ['./report-bydriver.component.css']
})
export class ReportBydriverComponent implements OnInit {

  @ViewChild(ReportTripComponent) child: ReportTripComponent;

  

  constructor(private drvService: DriverserviceService,
    
  ) {
    
  }

  selectedDriver = new FormControl('');
  keyword= new FormControl('');
  options: string[] = [];
  driverList: Driver[] = [];
  filteredOptions: Observable<string[]>;


  dateAz = new FormControl(new Date(), []);
  dateTa = new FormControl(new Date(), []);

  ngOnInit() {
    this.options = [];
    this.drvService.getAllDriver().subscribe(res => {
      this.driverList = res;
      res.forEach(t => {
        this.options.push(this.displayDriver(t));
      });
    });

    this.filteredOptions = this.selectedDriver.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value.toString() || '')),
    );

  }
  displayDriver(drv: Driver) {

    return drv.driverCode + ' - ' + drv.fName;
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  selectOption(e: MatAutocompleteSelectedEvent) {
    console.log(e);
  }


  driverID = 0;
  dateStart = '';
  dateFinish = '';
  kw = '';
  report() {
    console.log(this.dateAz.value?.toString());
    console.log(this.dateTa.value?.toString());
    console.log(this.selectedDriver.value);
    var driverObject: Driver;
    this.driverList.forEach(t => {
      console.log(this.displayDriver(t));
      if (this.selectedDriver.value == this.displayDriver(t)) {
        driverObject = t;
      }
    }
    );
    this.driverID = driverObject?.id;
    this.dateStart = this.dateAz.value?.toString();
    this.dateFinish = this.dateTa.value?.toString();
    this.kw = this.keyword.value;
    if (driverObject)
      console.log(driverObject);
    this.child.report();

  }

}

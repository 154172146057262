export class ServiceInfo {
  // این کلاس برای نمایش و گزارش لیست صسفرهای مسافر استفاده شده است
  tarikhStart: string;
  saatStart: string;
  originAddr: string;
  destinationAddr: string;
  driverName: string;
  driverCode: string;
  serviceStateName: string;
  serviceState: number;
  id: number;
  avatar: string;
  safeAvatar: any;
  serviceGuid: string;
  price: string;
  saatForm: string;
  saatAccept: string;
  requestID: number;
  stationID: number;
  reservedDate: string;
  typePardakhtName: string;
  updatablePrice: boolean;
  anam: string;
  requestComment: string;
  darEkhtiar: boolean;
}

export class AllServiceInfo {
  
  tarikhStart: string;
  saatStart: string;
  saatModify: string;
  originAddr: string;
  destinationAddr: string;
  driverName: string;
  driverCode: string;
  serviceStateName: string;
  id: number;
  avatar: string;
  safeAvatar: any;
  serviceGuid: string;
  price: string;
  saatForm: string;
  saatAccept: string;
  requestID: number;
  stationID: number;
  passangerName: string;
  passangerMobile: string;
  passangerAddress: string;
  driverMobile: string;
  timedConsume: string;
  operatorName: string;
  modifyOperatorName: string;
  free: string;
  driverCount: number;
  tripComment: string;
  driverID: number;

  serviceState: number;
  requestResult: number;
  typePardakhtName:string

}


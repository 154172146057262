import { Component, OnInit, Renderer2, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Map, map as LeafletMap, tileLayer, CRS, marker, icon, LeafletMouseEvent, Icon, Marker, LatLng } from 'leaflet';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OperatorTable } from '../../model/operatorTable';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { ServiceInfo } from '../../model/serviceInfo';
import { searchResult } from '../../model/routingInfo';
import { SavedPlaceService } from '../../lib/serviceLib/savedplace.service';
import { Passanger } from '../../model/passanger';
import { ServiceRequest } from '../../model/ServiceRequest';
import { SaveServiceResult, saveservicresultType } from '../../model/saveserviceresult';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';

import { LastCallInfo } from '../../model/lastCallInfo';
import { Station } from '../../model/station';
import { StationQueueInfo } from '../../model/stationQueueInfo';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { StationMemberComponent } from '../../station/station-member/station-member.component';
import { ShowcreatedserviceComponent } from '../../driver/showcreatedservice/showcreatedservice.component';
import { TaximapComponent } from '../../taximap/taximap.component';
import { WebSocketService } from '../../lib/web-socket.service';
import { NotificationModel, SocketMessageType } from '../../model/socketmodels';
import { MessageboxComponent } from '../../messagebox/messagebox.component';
import { DomSanitizer } from '@angular/platform-browser';

import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { InpDateTimeComponent } from '../inp-date-time/inp-date-time.component';
import { StationdetailComponent } from '../../call-center/stationdetail/stationdetail.component';
import { NotifalertserviceService } from '../../shared/notifalertservice.service';
import { CallLog } from '../../model/callLog';
import { ResevedService } from '../../model/reservedService';
import { OkmessageComponent } from '../../shared/okmessage/okmessage.component';
import { environment } from '../../../environments/environment.prod';
import { SetorigindesComponent } from '../../shared/setorigindes/setorigindes.component';
import { BaseInfoEnum, BaseInfoService } from '../../lib/serviceLib/baseinfo.service';
import { IBaseInfo } from '../../lib/interfaces/Ibaseinfo';
import { OperatorService } from '../../lib/serviceLib/operatorservice.service';
import { IOperator } from '../../lib/interfaces/operator';
import { StationMemberSelectComponent } from '../../station/station-member-select/station-member-select.component';

@Component({
  selector: 'app-new-call-simple',
  templateUrl: './new-call-simple.component.html',
  styleUrls: ['./new-call-simple.component.scss']
})
export class NewCallSimpleComponent implements OnInit, AfterViewInit {
  //@ViewChild("map", { static: false })
  //public map: TaximapComponent;
  //@ViewChild('android')
  //public android: ElementRef;

  mapDialogRef: MatDialogRef<SetorigindesComponent>;

  @ViewChild("driverList", { static: false })
  public driverList: StationMemberSelectComponent;



  passangerMobiles: string[];
  dialogProposedServiceRef: MatDialogRef<InpDateTimeComponent, any>;
  dialogStationDetailRef: MatDialogRef<StationdetailComponent, any>;

  @ViewChild("drawer", { static: false })
  public drawer: MatSidenav;

  operatorInfo: OperatorTable;
  passangerFormGroup: FormGroup;

  constructor(
    private callCenterService: CallCenterService,
    private taxiService: TaxiserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private currentServiceService: ServiceserviceService,
    private placeService: SavedPlaceService,
    private matSnackbar: MatSnackBar,
    private stationSerivce: StationserviceService,
    private dialog: MatDialog,
    private socketService: WebSocketService,
    private sanitizer: DomSanitizer,
    private renderer2: Renderer2,
    private requestService: ServiceRequestService,
    private notifAlert: NotifalertserviceService,
    private baseIService: BaseInfoService,
    private operatorService: OperatorService,

  ) {
    // هر جا مثه این با سوکت کار کرد اضافه است
    this.socketService.meesageList$.subscribe(msg => {
      if (msg) {
        this.processMessage(msg);
      }
    });
  }
  // متد مربوط به زمان دریافت یه پیام سوکت بوده
  processMessage(messageBox: NotificationModel) {
    switch (messageBox.msgType) {
      case SocketMessageType.GetClientInfo:
        break;
    }

  }
  callInfo: LastCallInfo;
  stationready: Station[] = [];
  passangerServicesCount: any = '0';
  isExpanded: boolean = false;
  callFormInsertMode = false;

  logs: ServiceInfo[] = [];
  driverStation: StationQueueInfo[] = [];
  selectedDriver: StationQueueInfo;
  telIDCaller = new FormControl('', [Validators.required]);
  passangerFullName = new FormControl('', [Validators.required]);
  originAC = new FormControl('', [Validators.required]);
  stationCode = new FormControl('', [Validators.required]);
  moarefID = new FormControl('', [Validators.required]);
  typePardakht = new FormControl('1019', [Validators.required]);

  typeService = new FormControl('', [Validators.required]);

  destAC = new FormControl('');
  passangerMobile = new FormControl();
  passangerComment = new FormControl();
  serviceRequestComment = new FormControl();
  price = new FormControl();
 
  serviceCountValue=1;

  pos = new FormControl(false);
  maeloolin = new FormControl(false);
  darEkhtiar = new FormControl(false);
  amanati = new FormControl(false);
  khadamat = new FormControl(false);
  maledriver = new FormControl(true);
  femaledriver = new FormControl(true);

  reserveMode = new FormControl(false);

  ngOnInit() {
    this.fillBaseInfo();
    this.loadStation();
    this.passangerFormGroup = new FormGroup({
      telIDCaller: this.telIDCaller,
      passangerFullName: this.passangerFullName,
      originAC: this.originAC,
      stationCode: this.stationCode,
      destAC: this.destAC,
      passangerMobile: this.passangerMobile,
      passangerComment: this.passangerComment,
      serviceRequestComment: this.serviceRequestComment,
      price: this.price,
      //serviceCount: this.serviceCountValue,
      pos: this.pos,
      maeloolin: this.maeloolin,
      darEkhtiar: this.darEkhtiar,
      amanati: this.amanati,
      khadamat: this.khadamat,
      maledriver: this.maledriver,
      femaledriver: this.femaledriver,
      reserveMode: this.reserveMode,
      typePardakht: this.typePardakht,
      typeService: this.typeService,
      moarefID: this.moarefID,
    });
    this.gpsTest();
    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      if (params['telID']) {
        this.passangerFormGroup.patchValue({ telIDCaller: params['telID'] });
        this.getIDCaller();
      }
      this.mode = 'insert';
      this.currentIDForUpdate = -1;

      this.loadOperatorInfo(this.operatorguid).subscribe(res => {
        const mode = params['valp'];
        //console.log('mode' + mode);
        if (mode) {
          // ازین پارامتر برای زمانی که طرف میاد فرم رو برای ویرایش باز می کنه استفاده مییشه
          // توی فرم بی سیم چک کنی ازونجا مقدار میگیره
          this.BackMode = 'manage';
          //this.getCall();
          this.gotoupdate(mode);
        }

      });


    });

    //this.originLatLng = new LatLng(36.297182, 59.604767);
    //this.originOptions = this.originAC.valueChanges.pipe(
    //  // startWith(''),
    //  debounceTime(500),
    //  distinctUntilChanged(),
    //  switchMap(_val => this._getPlaces(_val))
    //);
    //this.destinationOptions = this.destAC.valueChanges.pipe(
    //  // startWith(''),
    //  debounceTime(500),
    //  distinctUntilChanged(),
    //  switchMap(_val => this._getPlaces(_val))
    //);
    this.stationCode.valueChanges.subscribe(val => {
      this.loadStationList(val);
      this.loadStationStatics(val);
    }
    );

    this._initMap();
    this.loadSavedPlace(1);
    //this.map.setView(36.329616, 59.56543);
    //this.socketService.startSocket2();

    this.passangerFormGroup.get('destAC')?.valueChanges.subscribe((value: string) => {
      // با تایپ یه حرف توی مبدا و مقصد بایستی فیلتر بشه لیست مناطق ذخیره شده
      if (!value)
        this.selectedOrigins_Filter = JSON.parse(JSON.stringify(this.selectedOrigins.slice(0, 5)));
      else {
        this.selectedOrigins_Filter = [];
        var count = 0;
        this.selectedOrigins.forEach((data) => {
          if (data.includes(value)) {
            if (this.selectedOrigins_Filter.length < 5)
              this.selectedOrigins_Filter.push(data);

          }
        })
      }

    });
    this.passangerFormGroup.get('destAC').valueChanges.subscribe((value: string) => {
      // مثه بالایی
      if (!value) {
        this.selectedDestination_Filter = JSON.parse(JSON.stringify(this.selectedOrigins.slice(0, 5)));
      }
      else {
        this.selectedDestination_Filter = [];
        this.selectedOrigins.forEach((data) => {
          if (this.selectedOrigins_Filter.length < 5) {
            if (data.includes(value))
              this.selectedDestination_Filter.push(data);
          }
        })
      }
    });


  }
  stationChanged(e) {
    console.log('selectedStation is ' + e.target.value)
    this.loadStationList(e.target.value);
    this.loadStationStatics(e.target.value);
  }
  ngAfterViewInit() {
  }

  lstTypePardakht: Partial<IBaseInfo>[];
  lstTypeService: Partial<IBaseInfo>[];
  lstMoaref: IOperator[];
  fillBaseInfo() {
    this.baseIService.getList(BaseInfoEnum.TypePardakht).subscribe(_res => {
      this.lstTypePardakht = _res;
      console.log(_res);

    });
    this.baseIService.getList(BaseInfoEnum.TypeService).subscribe(_res => {
      this.lstTypeService = _res;

    });

    this.operatorService.getAllMoaref().subscribe(_res => {
      this.lstMoaref = _res;
      console.log(_res);
    });
    console.log('base info ');


  }
  //reservedUntilMinute : new FormControl(),

  stationCapacity = '';
  passangerReservedCount = '';
  lockDriver = '';


  originMarker: Marker;
  destMarker: Marker;
  originOptions: Observable<searchResult[]>;
  destinationOptions: Observable<searchResult[]>;

  selectedOrigins: string[] = [];

  //این دو تا که تهش فیلتر داره برای لیست مبدا و مقصد ها هست - بعد از وارد کردن بخشی از حرف باید فیلتر بشه
  selectedDestination_Filter: string[] = [];
  selectedOrigins_Filter: string[] = [];


  value = 0;
  //این مارکر ها هم اضافه است. یکی مال مبدا بوده یکی مال مقصد الان دیگه نقشه ای نیست و مبدا و مقصد هم نیس
  originMarkerIcon: Icon = icon({
    iconSize: [40, 60],
    iconAnchor: [20, 60],
    iconUrl: 'assets/marker-origin.png',
    shadowUrl: 'assets/marker-shadow.png'
  });

  destMarkerIcon: Icon = icon({
    iconSize: [40, 60],
    iconAnchor: [20, 60],
    iconUrl: 'assets/marker-dest.png',
    shadowUrl: 'assets/marker-shadow.png'
  });

  operatorguid: string;
  carClasses: any = [];
  reserveSelectableDate: boolean = false;
  savedPlaceItems: any = [];
  passengerId: string = '';
  reserveModal = {
    visible: false,
    data: null,
  }
  nearbyStationsModal = {
    visible: false,
    stationId: '',
    data: [],
  }
  waitingPassengers: any = '0';
  capacityCounts: any = '0';
  carInStationCounts: any = '0';



  nearbyStationsModalClick() {
    //this.http.get(`station/getDetails/${this.formGroup.get('station')?.value}`).subscribe((result: any) => {
    //  if (result.length < 1) {
    //    Swal.fire({
    //      icon: 'warning',
    //      text: 'هیچ ایستگاه فعالی در محدوۀ فوق یافت نشد!',
    //      confirmButtonText: 'تأیید'
    //    });
    //    return;
    //  }
    //  this.nearbyStationsModal.data = result;
    //  this.nearbyStationsModal.visible = true;
    //}, error => {
    //});
  }



  // بار گزاری کردن آدرس های ذخیره شده کاربر
  loadSavedPlace(passangerID: any) {

    this.placeService.getSavedPlace(passangerID).subscribe(res => {
      if (res) {
        this.selectedOrigins = res;
        this.selectedDestination_Filter = JSON.parse(JSON.stringify(res.slice(0, 5)));
        this.selectedOrigins_Filter = JSON.parse(JSON.stringify(res.slice(0, 5)));
      }
      else {
        this.selectedOrigins = null;
        this.selectedDestination_Filter = null;
        this.selectedOrigins_Filter = null;
      }


    });
  }
  // وقتی از بین گزینه های مبدا ئو مقضصد انتخابی روی یکی کلیک می کنه این صدا زده می شه - برای مبدا
  gotoPlace(p: any) {
    this.setOrigin(0, 0, p);
  }
  // مثه بالایی برای مقصد
  gotoPlacedest(p: any) {
    this.setDestination(0, 0, p);
  }


  // بعد از لاگین اپراتور کدش رو میگیره و اطلاعاتش رو لود می کنه
  loadOperatorInfo(guidCode: any): Observable<any> {
    return new Observable((observer) => {
      console.log('loadOperatorInfo');
      this.callCenterService.getOperatorInfo(guidCode).subscribe(res => {
        if (res) {
          this.operatorInfo = res;
          observer.next(res);
        } else {

          this.router.navigate(['login'], { queryParams: {} });
        }
      });
    });
  }
  stationMarker: any;
  driverMarker: Marker[] = [];


  loadStationList(stationID: any) {
    if (stationID) {
      // قبلا این شکلی بود که یه ایستگاه کهوارد می شد لیست ایستگاه های همجوارش رو نشون میداد این 99 درصد الان کلا استفاده نمیشه
      this.stationSerivce.getNeighbors(stationID).subscribe(_res => {
        this.stationready = _res;

        //console.log('stationready');
        //console.log(this.stationready);
      });
      // با انتخاب شدن یه ایستگاه ما لیست کسانی که توی صف هستند رو زیرش نشون میدیم این اگر صدا زده میشه که لازمه
      this.stationSerivce.getStationQueue(stationID).subscribe(_res => {

        this.driverStation = _res;
        this.selectedDriverIndex = 0;
        if (this.driverStation) {
          this.driverStation.forEach((data) => {
            data.safeAvatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + data.avatar);
          })

          this.loadDriverByIndex();
        }
      });
      // نمیش جزییات یه ایستگاه بعد از انتخاب آن
      this.stationSerivce.getStation(stationID).subscribe(_res => {
        this.currentStation = _res;

        //console.log('currentStation');
        //console.log(this.currentStation);
        if (_res) {
          this.stationCapacity = this.currentStation.capacity.toString();
          this.passangerReservedCount = this.currentStation.reservedCount.toString();


          this.driverMarker = [];
          this.stationSerivce.getStationQueue(_res.id).subscribe(_res => {
            let queuelist = _res;
            //console.log(queuelist);


          });

        }
        else {
          this.matSnackbar.open('ایستگاه وارد شده صحیح نمی باشد', null, { duration: 250 });
        }



      });
    }

  }
  resetStationDetail() {
    this.driverStation = null;
    this.selectedDriverIndex = -1;
    this.selectDriver(null);
    this.stationready = null;
    this.currentStation = null;
    this.stationCapacity = '';
    this.passangerReservedCount = '';
  }
  currentStation = new Station();
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
  BackMode = '';
  loadStationStatics(stationID: any) {

  }

  loadPassangerReport(passangerID: number) {
    this.currentServiceService.report(passangerID).subscribe(res => {

      this.logs = res;
    });
  }
  CurrentReserved: ResevedService = null;
  // زمانی که مسافر دوباره تماس میگیره اگر سفر رزرو شده ای داشته باشه باید بهش هشدار بدیم
  // چون ممکنه قبلا رزرو کرده باشه بعد دوباره تماس میگیره اپراتور نفهمه و دوباره ثبت کنه وتکراری بشه
  // این تابع چک می کنه اگر سفر رزرو شده ای داره هشدار بده
  checkLastReserved(passangerID: any) {
    this.requestService.getCurrentReserved(passangerID).subscribe(res => {
      //console.log('reserved service is');
      //console.log(res);
      if (res) {
        this.CurrentReserved = res;
      } else
        this.CurrentReserved = null;



    });
  }

  // زمانی که تماس گرفته میشه و اپراتور وصل میشه در صورتی که قبلا سفر یا درخواست بازی داشته باشه هنوز ، یعنی سفری که هنوز راننده نگرفته یا تمام نشده
  // یا نیم ساعت ازش نگذشته همون ابتدا به اپراتور پیام داده میشه و بهش هشدار داده میشه
  checkLastService(passangerID: any) {
    this.requestService.getLastRequest(passangerID).subscribe(res => {
      if (res) {


        const confirmRef = this.dialog.open(ConfirmDialogComponent, {
          hasBackdrop: true,
          data: {
            message: 'آیا مایل به ویرایش سفر اخیر می باشید؟',
            buttonText: {
              ok: '(1) بله',
              cancel: '(2) خیر ثبت سفر جدید',
              extraButtonText: '(3) ثبت پیگیری'
            }
          }
        });

        confirmRef.afterClosed().subscribe((_confirmed: any) => {
          if (_confirmed === 'OK') {
            this.gotoupdate(res.id);
          }
          else if (_confirmed === 'btn3') {
            this.peygiri(res.id);

          }
          else {
            this.CurrentReserved = null;
          }
        });

      }



    });
  }

  // بعد از انتخاب آخرین تماس و برای لود شدن اطالعات مسافر
  loadPassangerInfo(passangerInfo: Passanger) {
    //console.warn(passangerInfo);

    this.checkLastService(passangerInfo.id);
    this.checkLastReserved(passangerInfo.id);

    this.loadPassangerReport(passangerInfo.id);

    //this.callFormGroup.patchValue(passangerInfo);

    //this.callFormGroup.disable();
    this.loadSavedPlace(passangerInfo.id);
    this.passangerFormGroup.patchValue({ passangerFullName: passangerInfo.fName });
    if (passangerInfo.mobile && passangerInfo.mobile.length > 9 && !passangerInfo.mobile.startsWith('051') && !passangerInfo.mobile.startsWith('51')) {
      this.passangerFormGroup.patchValue({ passangerMobile: passangerInfo.mobile });

    }
    this.passangerFormGroup.patchValue({ passangerComment: passangerInfo.fixedComment });
    this.lockDriver = passangerInfo.lockDriver;
    //console.warn(passangerInfo.serviceCount);
    this.passangerServiceCount = passangerInfo.serviceCount;

  }
  selectedCars: any;
  CarList = [{ value: '3', name: 'سمند' }, { name: 'پژو', value: 2 }, { value: '4', name: 'آریو' }, { name: 'برلیانس', value: 1 }, { value: '5', name: 'ون' }];

  // خواندن آخرین شماره تماس گیرنده از آی دی کالر
  getIDCaller() {
    console.log('getIDCaller')
    this.passangerFormGroup.patchValue({ passangerMobile: '' });
    this.fillCall(this.passangerFormGroup.get('telIDCaller').value);
  }
  //شماره مشخص شده و می باید اطلاعات کاربر از روی شماره پر بشه - ممکنه شماره دستی وارد بشه یا با زدن آخرین تماس از ای دی کالر گرفته بشه
  fillCall(mobile: any) {
    this.mode = 'insert';
    this.currentIDForUpdate = -1;
    this.callCenterService.getCallerInfoByMobile(mobile).subscribe(_res => {
      this.fillPassangerInfoFromModel(_res);

    });
  }
  getCallClick() {
    console.log('getCallClick')
    this._reset();
    //console.log('getCallClick');
    this.getCall();
  }
  passangerServiceCount = 0

  fillPassangerInfoFromModel(_res: LastCallInfo) {

    if (_res) {
      //console.warn('استعلام تماس خروجی :');
      //console.warn(_res);
      this.callInfo = _res;
      if (this.callInfo.callInfo) {
        try {
          this.passangerFormGroup.patchValue({ telIDCaller: _res.callInfo.tel });
          //debugger
          if (_res.callInfo.tel.length > 9 && !_res.callInfo.tel.startsWith('051') && !_res.callInfo.tel.startsWith('51')) {
            this.passangerFormGroup.patchValue({ passangerMobile: _res.callInfo.tel });
          }
          else {

            var passMobile = _res.passangerInfo ? _res.passangerInfo.mobile : '';
            if (passMobile.length >= 9 && !passMobile.startsWith('051') && !passMobile.startsWith('51')
            )
              this.passangerFormGroup.patchValue({ passangerMobile: passMobile });
          }
          this.passangerServiceCount = _res.passangerInfo.serviceCount;
          let mobilesString = _res.passangerInfo.mobiles;
          if (mobilesString) {
            let mobilesList = mobilesString?.split('-');
            let mobilesListFiltered = mobilesList.filter(function (el) {
              return el != null && el.trim()?.length == 11;
            });

            this.passangerMobiles = mobilesListFiltered;
          }

          this.callFormInsertMode = !_res.passangerInfo;
          if (!this.callFormInsertMode && this.BackMode != 'manage') {
            this.loadPassangerInfo(_res.passangerInfo);
          }
        }
        catch (e) {

        }


      }
      else {
        this.matSnackbar.open('در حال حاضر شما تماسی ندارید.', null, { duration: 3000 });
        this.callInfo = new LastCallInfo();
        this.callInfo.callInfo = new CallLog();
        this.callInfo.passangerInfo = new Passanger();

      }
    }


  }
  getCall() {
    this.callCenterService.getCurrentCallInfo(this.operatorInfo.id).subscribe(_res => {
      this.fillPassangerInfoFromModel(_res)


    });
  }

  submitCallForm() {

  }

  //displayFn(_searchResult: searchResult): string {
  //  return _searchResult && `${_searchResult.title} - ${_searchResult.address}`;
  //}

  originLatLng: LatLng;
  destLatLng: LatLng;
  // دیگه استفاده نمیشه 99 درصد
  setDestination(x: any, y: any, title: any) {
    this.passangerFormGroup.patchValue({ destAC: title });
    this.destLatLng = new LatLng(x, y);

    //if (this.destMarker) {
    //  this.destMarker.setLatLng(this.destLatLng);
    //  this.map.setView(this.destLatLng.lat, this.destLatLng.lng);
    //} else {
    //  this.destMarker = this.map.addMarker(this.destLatLng.lat, this.destLatLng.lng, MarkerType.destination, '', true);
    //  this.map.setView(this.destLatLng.lat, this.destLatLng.lng);
    //}

  }

  setOrigin(lat: any, lng: any, title: any) {
    this.passangerFormGroup.patchValue({ originAC: title });
    this.originLatLng = new LatLng(lat, lng);
    //////console.log('originLatLng : ');
    //console.log(this.originLatLng);


    //if (this.originMarker) {
    //  //console.log('this.originMarker is not null  ');
    //  this.originMarker.setLatLng(this.originLatLng);
    //  //console.log('then set view');
    //  this.map.setView(lat, lng);
    //} else {
    //  //console.log('else this.originMarker is null  ');
    //  this.originMarker = this.map.addMarker(this.originLatLng.lat, this.originLatLng.lng, MarkerType.origin, '', true);
    //  //console.log('else set view');
    //  this.map.setView(lat, lng);
    //}
  }

  optionSelected(ev: any) {
    //console.log(ev);
    const result: searchResult = ev.option.value;
    this.setOrigin(result.y, result.x, result.title);
    this.renderer2.selectRootElement('#passangerComment').focus();
  }

  optionSelecteddest(ev: any) {
    //console.log(ev);
    const result: searchResult = ev.option.value;
    this.setDestination(result.y, result.x, result.title);
    this.renderer2.selectRootElement('#selectedCars').focus();

  }

  private _getPlaces(value: string): Observable<searchResult[]> {
    ////console.log(value);
    return this.taxiService.searchMap(value, this.originLatLng.lat, this.originLatLng.lng);
  }

  private _getPlacesdes(value: string): Observable<searchResult[]> {
    ////console.log(value);
    return this.taxiService.searchMap(value, this.destLatLng.lat, this.destLatLng.lng);
  }

  private _initMap(): void {
    //this.map = new TaximapComponent();
    //this.map = LeafletMap('map', {
    //  crs: CRS.EPSG4326
    //});

    //tileLayer('https://bpms.mashhad.ir/favageoserver/gwc/service/tms/1.0.0/osm%3Aosm@EPSG:4326@png/{z}/{x}/{y}.png', {
    //  tms: true,
    //}).addTo(this.map);

    //this.map.setView([36.329616, 59.56543], 12);

    // add marker on click
    // this.map.on('click', (ev: LeafletMouseEvent) => {
    //   //console.log(ev);
    //   marker(ev.latlng, {
    //     icon: this.markerIcon
    //   }).addTo(this.map);
    // });
  }



  currentIDForUpdate = -1;

  //این تابع وظیفه اعتبار سنجی قبل از ذخیره رو داره
  isValid(): boolean {



    return true;
  }
  onMatGroupChange(event) {
    this.serviceCountValue = event.value
    
  }
  date: Date = null;
  saveRequest(): Observable<SaveServiceResult> {



    console.log('saveRequest');
    console.log(this.serviceCountValue);
    
    if (!this.isValid()) {
      console.log('if (!this.isValid()) {');
      return null;
    }
    var result: Observable<SaveServiceResult>;
    




    let destinationAddr = this.passangerFormGroup.get('destAC')?.value;

    let typePardakht = this.passangerFormGroup.get('typePardakht')?.value;



    let requestComment = this.passangerFormGroup.get('serviceRequestComment')?.value;
    let price = this.passangerFormGroup.get('price')?.value;

    let operatorID = this.operatorInfo.id;

    let driverCode = '';

    if (this.driverCodes && this.driverCodes.length > 0) {
      this.driverCodes.forEach(p => {
        if (driverCode == '')
          driverCode = p.driverCode;
        else
          driverCode = driverCode + ','+ p.driverCode;

      })
      
    }



    return this.currentServiceService.saveServiceSimple(destinationAddr, operatorID, requestComment, this.serviceCountValue, price, this.darEkhtiar.value, driverCode, typePardakht, this.stationCode.value);
  }
  transactionID = ''
  private _reset(): void {
    this.transactionID = this.makeid(36);
    //console.log(this.transactionID);
    //this.nosave = false;
    this.mode = 'insert';
    /*
     * inja
    this.callFormGroup.reset();
    */
    this.callFormInsertMode = false;
    this.stationready = [];
    this.logs = [];
    this.isExpanded = false;
    this.passangerFormGroup.patchValue({ telIDCaller: '' });
    this.passangerFormGroup.patchValue({ stationCode: '0' });
    this.passangerFormGroup.patchValue({ originAC: '' });
    this.passangerFormGroup.patchValue({ destAC: '' });


    if (this.originMarker) {
      this.originMarker.remove();
      this.originMarker = null;
    }
    if (this.destMarker) {
      this.destMarker.remove();
      this.destMarker = null;
    }
    this.originOptions = null;
    this.destinationOptions = null;

    this.selectedOrigins = [];
    this.selectedDestination_Filter = [];
    this.selectedOrigins_Filter = [];
    this.passangerFormGroup.patchValue({ passangerComment: '' });
    this.passangerFormGroup.patchValue({ serviceRequestComment: '' });
    this.passangerFormGroup.patchValue({ price: '' });
    this.passangerFormGroup.patchValue({ passangerFullName: '' });
    this.passangerFormGroup.patchValue({ passangerMobile: '' });


    this.lockDriver = '';
    this.passangerServiceCount = 0;
    this.passangerFormGroup.patchValue({ pos: false });
    this.passangerFormGroup.patchValue({ maeloolin: false });
    this.passangerFormGroup.patchValue({ darEkhtiar: false });
    this.passangerFormGroup.patchValue({ amanati: false });
    this.passangerFormGroup.patchValue({ khadamat: false });
    this.passangerFormGroup.patchValue({ maledriver: true });
    this.passangerFormGroup.patchValue({ femaledriver: true });
    this.passangerFormGroup.patchValue({ serviceCount: '1' });
    this.passangerFormGroup.patchValue({ reserveMode: false });



    this.resetStationDetail();
    //this.map.setView(36.329616, 59.56543);
    this.checkForBackToCaller(false);
    this.CurrentReserved = null;
    this.currentStation = new Station();
    //console.log(this.pos);

    this.loadSavedPlace(1);
    this.driverList.clear();
    
  }
  // با زدن بعضی تیک ها مثه دراختیار و اینا باید به کامنت اضافه یا کم بشن این تابع ها برای اون کاره
  addTerm(mainvalue: any, addvalue: any) {
    if (!mainvalue)
      return addvalue;
    else {
      if (!mainvalue.includes(addvalue))
        return mainvalue + ' ' + addvalue;
      else
        return mainvalue;
    }
  }
  subTerm(mainvalue: any, addvalue: any) {
    if (mainvalue)
      return mainvalue.replace(addvalue, '');
    else
      return '';
  }

  // مرتبط با دو تا تابع بالا
  // با انتخاب گزینه ها و تیک های دراختیار و ... می بایست توی توضیحات سفر نیز متن مرتبط اضافه یا کم بشه
  valueChange(changedItem: any, $event: any) {
    //console.log('dar ekhtiar before ' + this.darEkhtiar);
    if (changedItem == 'pos') {
      this.pos = $event.checked;
      if (this.pos) {

        this.passangerFormGroup.patchValue({ serviceRequestComment: this.addTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' کارتخوان ') });
      }
      else {
        this.passangerFormGroup.patchValue({ serviceRequestComment: this.subTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' کارتخوان ') });

      }
    }
    else if (changedItem == 'maeloolin') {
      this.maeloolin = $event.checked;
      if (this.maeloolin) {

        this.passangerFormGroup.patchValue({ serviceRequestComment: this.addTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' معلولین ') });
      }
      else {
        this.passangerFormGroup.patchValue({ serviceRequestComment: this.subTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' معلولین ') });

      }
    }
    else if (changedItem == 'darEkhtiar') {
      this.darEkhtiar.setValue($event.checked);
      //if (this.darEkhtiar) {
      //  this.passangerFormGroup.patchValue({ serviceRequestComment: this.addTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' در اختیار ') });
      //}
      //else {
      //  this.passangerFormGroup.patchValue({ serviceRequestComment: this.subTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' در اختیار ') });
      //}
    }
    else if (changedItem == 'amanati') {
      this.amanati = $event.checked;
      if (this.amanati) {
        this.passangerFormGroup.patchValue({ serviceRequestComment: this.addTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' امانتی ') });
      }
      else {
        this.passangerFormGroup.patchValue({ serviceRequestComment: this.subTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' امانتی ') });

      }
    }
    else if (changedItem == 'femaledriver') {
      this.femaledriver = $event.checked;
    }
    else if (changedItem == 'maledriver') {
      {
        this.maledriver = $event.checked;
      }

    }
    else if (changedItem == 'khadamat') {
      this.khadamat = $event.checked;
      if (this.khadamat) {
        this.passangerFormGroup.patchValue({ serviceRequestComment: this.addTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' خدمات ') });
      }
      else {
        this.passangerFormGroup.patchValue({ serviceRequestComment: this.subTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' خدمات ') });

      }

    }
    //console.log('dar ekhtiar after ' + this.darEkhtiar);
  }

  //valueChange(changedItem, $event) {

  //  changedItem= $event.checked;
  //}
  dialogCreatedServiceRef: MatDialogRef<ShowcreatedserviceComponent, any>;
  private showCreatedService(info: SaveServiceResult) {
    //console.warn(info.serviceGUID);

    this.dialogCreatedServiceRef = this.dialog.open(ShowcreatedserviceComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        info: info
      }
    });
  }
  lstStation: Station[];
  loadStation() {
    this.stationSerivce.getAllStationList().subscribe(_res => {
      console.log('station list');
      console.log(this.lstStation);
      this.lstStation = _res;
      //_res.forEach(station => {
      //  this.map.addStationMarker(station);

      //});
    });
  }
  private setStationItemResult(stationID: any, result: saveservicresultType) {
    this.stationready.forEach(async function (station) {
      if (station.id == stationID) {
        station.serviceResult = result;
      }
    })
  }
  testClick() {
    console.log('click');

  }
  //nosave = false;
  saveRequestForQueue(): void {

    console.log('saveRequestForQueue');
    
    


    //if (this.nosave) {

    //  this.matSnackbar.open('ثبت تکراری', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center" });
    //  setTimeout(() => { this.nosave=false }, 25000);
    //  return;
    //}

    if (!this.isValid())
      return;
    if (this.date) {
      var diffMs = this.date.getTime() - new Date().getTime();
      var diff = Math.abs(diffMs) < 6000
      //console.log('diff')
      //console.log(diffMs)
      //console.log(diff)

      if (diff) {
        //console.log('duplicate')

        return;
      }
    }
    this.date = new Date();
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'در حال ثبت',
        buttonText: {
          ok: '(1) متوجه شدم',
          cancel: ' '
        }
      }
    });



    //this.nosave = true;
    this.saveRequest().subscribe(res => {
      if (!res) {
        // چون هر کار می کردیم باز هم این اپراتور ها یه جوری کلیک می کردن پشت سر هم که فرم تکراری ثبت می شد
        // سمت سرور هم یه شرط هایی گذاشتیم و ممکنه ذخیره به علت تکراری بودن انجام نشه
        confirmRef.close('CancelRequest');
        const dupRef = this.dialog.open(ConfirmDialogComponent, {
          hasBackdrop: true,
          data: {
            message: 'فرم تکراری است - در فرم مدیریت سفر چک شود',
            buttonText: {
              ok: '(1) متوجه شدم',
              cancel: ' '
            }
          }
        });
        return;
      }
      confirmRef.close('CancelRequest');



      //this.nosave = false; 
      //console.warn(res);
      this.notifAlert.setParam('savenew');
      //if (this.mode == 'update') {
      //  this.socketService.sendMessage(res.serviceGUID, SocketMessageType.RequestServiceCreate);
      //}
      //else {
      //  this.socketService.sendMessage(res.serviceGUID, SocketMessageType.RequestServiceCreate);
      //}


      //if (this.tarikhReserved) {
      //  this.currentServiceService.reservedService(res.requestID, this.passangerFormGroup.get('stationCode')?.value, this.tarikhReserved, this.timeRserved).subscribe(reservedres => {
      //    //console.log(reservedres)
      //    if (reservedres > 0) {
      //      this.matSnackbar.open('درخواست رزرو شما با موفقیت ثبت گردید', null, { duration: 3000 });
      //      this._reset();
      //    }
      //  });
      //}


      // این حالت هایی که در ادامه امده اینا برداشته شده و دیگه نیازی نیست 
      // چون بهمحض اینکه فرم ثبت میشه فرم بسته باید بشه و بره برای ثبت بعدی = قبلا در حالت انتظار می موند مثه نسخه مسافر اسنپ
      if (res.result == saveservicresultType.driverFound) {
        //console.warn('saveservicresultType.driverFound');
        this.matSnackbar.open('راننده پیدا شده و به سفر اختصاص داده شد', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
        this.showCreatedService(res);
      }
      else if (res.result == saveservicresultType.driverNotFound) {
        this.matSnackbar.open('متاسفانه راننده ای یافت نشد', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      else if (res.result == saveservicresultType.inSearchingDriver) {
        const confirmRef = this.dialog.open(ConfirmDialogComponent, {
          hasBackdrop: true,
          data: {
            message: 'با موفقیت ثبت شد',
            buttonText: {
              ok: '(1) متوجه شدم',
              cancel: ' '
            }
          }
        });



        this.matSnackbar.open('در حال پذیرش از سوی راننده', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      else if (res.result == saveservicresultType.update_lastServiceIsCancel) {
        this.matSnackbar.open('سرویس قبلی کنسل شده و سرویس جدید ثبت گردید', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      else if (res.result == saveservicresultType.update_NoChange) {
        this.matSnackbar.open('تغییرات اعمال گردید', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      else if (res.result == saveservicresultType.update_SentAlarmForDriver) {
        this.matSnackbar.open('تغییرات اعمال گردیده و در انتظار تایید راننده می باشد', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: "center", panelClass: ['blue-snackbar'] });
      }
      //console.warn('station:' + station + ' -> res.result' + res.result)
      //this.setStationItemResult(station.id, res.result);

      this.checkForBackToCaller(true);
    },
      (err) => {
        confirmRef.close('CancelRequest');
        //console.log(err)
      }
    );

  }
  // وقتی از فرم بی سیم یا جای دیگه فرم رو باز می کنی برای ویرایش بعد از ذخیره باید برگردی به همون آدرس . این تابع همین رو چک می کنه
  checkForBackToCaller(resetElse: any) {
    if (this.BackMode == 'manage') {
      this.router.navigate(['cc/manage'], { queryParams: { guid: this.operatorguid } });
    }
    else if (resetElse)
      this._reset();


  }


  dialogmsgBoxRef: MatDialogRef<MessageboxComponent, any>;
  //گزینه ارسال مجدد - این گزینه برای وقتیه که شما یه درخواست زدی ویه نتجیه ای داشته اما می خای دوباره بره تو فرآیند ارسال و دوباره ارسال بشه
  resend(trip: any) {
    //console.log('resend')
    //console.log(trip)
    this.currentServiceService.ReProposedRequest(trip.requestID).subscribe(res => {
      //this.socketService.sendMessage(res.serviceGUID, SocketMessageType.RequestServiceCreate);
      this.matSnackbar.open('فرم دوباره ثبت گردید', null, { duration: 3000 });
      //console.log(res);
    });




  }

  cancelTrip(requestID: any, trip: any) {

    this.dialogmsgBoxRef = this.dialog.open(MessageboxComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        msgText: 'آیا از کنسل کردن سفر اطمینان دارید؟',
        msgTitle: 'انصراف'
      }
    });
    this.dialogmsgBoxRef.afterClosed().subscribe((result: any) => {
      if (result == 'ok') {
        this.currentServiceService.cancelServiceByRequest(requestID).subscribe(res => {

        });
        if (trip)
          trip.serviceStateName = 'کنسل شده';

      }
      this.checkForBackToCaller(true);
    });

    //console.warn(trip);
  }
  peygiri(requestID: any) {
    this.requestService.peygiri(requestID).subscribe(res => {
      this.matSnackbar.open('پیام پیگیری برای راننده صادر گردید', null, { duration: 2000 });
    }
    );
  }
  selectDriver(drv: any) {
    //console.log(drv);
    this.selectedDriver = drv;
    //if (this.selectedDriver) {

    //  this.firstdriverCode.setValue(this.selectedDriver.driverCode);
    //  //this.firstdriverFullName.setValue(this.selectedDriver.fName);
    //  this.firstdriverPelaque.setValue(this.selectedDriver.pelaque2 + this.selectedDriver.pelaqueLetter + this.selectedDriver.pelaque3 + '- ایران ' + this.selectedDriver.pelaqueIR);
    //  this.posStatusControl.setValue(this.selectedDriver.pos);
    //  if (this.selectedDriver.pos == true)
    //    this.posStatusControl.setValue('دارد');
    //  else
    //    this.posStatusControl.setValue('ندارد');
    //  this.personalMobile.setValue(this.selectedDriver.mobile);
    //  this.workingmobilecontrol.setValue(this.selectedDriver.workingMobile);
    //  this.TypeCarOfCurrentDriver.setValue(this.selectedDriver.carName + this.selectedDriver.colorName);

    //}
    //else {
    //  this.firstdriverCode.setValue('');
    //  //this.firstdriverFullName.setValue('');
    //  this.firstdriverPelaque.setValue('');
    //  this.posStatusControl.setValue('');
    //  this.personalMobile.setValue('');
    //  this.workingmobilecontrol.setValue('');
    //  this.TypeCarOfCurrentDriver.setValue('');
    //}
  }
  loadDriverByIndex() {
    this.selectDriver(this.driverStation[this.selectedDriverIndex]);
  }
  selectedDriverIndex = 0;

  // مطابق متد پایینی
  nextDriver() {
    //console.log(this.driverStation.length);
    this.selectedDriverIndex = this.selectedDriverIndex + 1;
    if (this.selectedDriverIndex > this.driverStation.length)
      this.selectedDriverIndex = 0;
    this.loadDriverByIndex();
  }

  // لیست راننده های ایستگاه گزینه بعد و قبل داره این برای قبلیه
  predDriver() {
    //console.log(this.driverStation.length);
    this.selectedDriverIndex = this.selectedDriverIndex - 1;
    if (this.selectedDriverIndex < 0)
      this.selectedDriverIndex = 0;
    this.loadDriverByIndex();
  }

  handleShortCut(event: KeyboardEvent) {

    //console.log(' event key is :');

    //console.log(event);


    if ((event.which == 13 || event.keyCode == 13)) {
      //console.log('enter pressed');
      this.forwardNext(event.srcElement);
      event.preventDefault();
    }
    else if (event.key === 'F1') {
      this.getCall();
      event.preventDefault();
    }
    else if (event.key === 'F2') {
      this.renderer2.selectRootElement('#txttellIDCaller').focus();
      event.preventDefault();
    }
    else if (event.key === 'Escape') {
      //console.log('ESC');
      event.preventDefault();
    }
    else if (event.key === 'F8') {
      //console.log('F8');
      event.preventDefault();
    }
    else if (event.key === 'F5') {
      this.renderer2.selectRootElement('#passangerMobile').focus();
      event.preventDefault();
    }

    else if (event.key === 'F3') {
      this.renderer2.selectRootElement('#originAC').focus();
      event.preventDefault();
    }
    else if (event.key === 'F7') {
      this.renderer2.selectRootElement('#firstdriverCode').focus();
      event.preventDefault();
    }
    else if (event.key === 'F6') {
      this.renderer2.selectRootElement('#stationCode').focus();
      event.preventDefault();
    }

    else if (event.ctrlKey) { // ctrl



      if (event.key === '1') {
        //console.log('ctrl+1');
        event.preventDefault();
      }
      else if (event.key === '5') {
        //console.log('ctrl+5');
        event.preventDefault();
      }
      else if (event.key === '6') {
        //console.log('ctrl+6');
        event.preventDefault();
      }
      else if ((['h', 'H', 'ا'].indexOf(event.key) > -1)) {
        //console.log('ctrl+H');
        event.preventDefault();
      }
      else if (event.key === 'x') {
        //console.log('ctrl+X');
        event.preventDefault();
      }
      else if (event.key === 'q') {
        //console.log('ctrl+Q');
        event.preventDefault();
      }
      else if ((['e', 'E', 'ث'].indexOf(event.key) > -1)) {
        //console.log('ctrl+E');
        event.preventDefault();
        //this.saveRequestClickDebounce();


      }
    }
    else {
      //console.log(event);
    }
  }


  okdialogRef: MatDialogRef<OkmessageComponent, any>;

  showStationDetails() {
    this.dialogStationDetailRef = this.dialog.open(StationdetailComponent, {
      width: '1200px',
      hasBackdrop: true,
      data: {
        stationID: this.passangerFormGroup.get('stationCode')?.value
      },
      autoFocus: true,
    });

    //this.stationdet.loadData(this.passangerFormGroup.get('stationCode')?.value);
    //this.drawer.toggle()

  }
  gpsTest() {
    //console.log('gpstest');

    navigator.geolocation.getCurrentPosition((position) => {
      //console.log(position);
      //console.log(`position in watch getCurrentPosition lat is ${position.coords.latitude} , longitude is ${position.coords.longitude}`);
    });
    navigator.geolocation.watchPosition((position) => {
      //console.log(`position in watch position lat is ${position.coords.latitude} , longitude is ${position.coords.longitude}`);
    });
  }

  onStationKeydown(event: any) {
    //زمانی که در تکست باکس کد ایتسگاه اسپیس می زته لیست جزییات ایستگاه نمایش داده میشه
    if (event.key === " ") {
      event.preventDefault();
      if (this.passangerFormGroup.get('stationCode')?.value)
        this.showStationDetails();
    }
    //console.log('onStationKeydown');
    //console.log(event);
  }
  showStationDetailClick() {
    this.showStationMember();
    //if (this.passangerFormGroup.get('stationCode')?.value)
    //  this.showStationDetails();

  }

  selectedhour = new FormControl('', [Validators.required, Validators.min(0), Validators.max(23)]);
  selectedminute = new FormControl('', [Validators.required, Validators.min(0), Validators.max(59)]);
  dateControl = new FormControl(new Date(), [Validators.required]);
  tarikhReserved = '';
  timeRserved = '';




  // برای وقتی که اینتر می زنه میخاد بره کنترل بعدی استفاده شده
  forwardNext(element: any) {

    var nextControlDic: { [id: string]: string; } = {};
    nextControlDic["txttellIDCaller"] = "passangerFullName";
    nextControlDic["passangerFullName"] = "passangerMobile";
    nextControlDic["passangerMobile"] = "originAC";
    nextControlDic["originAC"] = "passangerComment";
    nextControlDic["passangerComment"] = "serviceRequestComment";


    nextControlDic["serviceRequestComment"] = "price";
    nextControlDic["price"] = "destAC";
    nextControlDic["destAC"] = "selectedCars";
    nextControlDic["selectedCars"] = "stationCode";
    nextControlDic["stationCode"] = "firstdriverCode";
    nextControlDic["firstdriverCode"] = "TypeCarOfCurrentDriver";
    nextControlDic["TypeCarOfCurrentDriver"] = "workingmobilecontrol";
    nextControlDic["workingmobilecontrol"] = "personalMobile";
    nextControlDic["personalMobile"] = "posStatusControl";
    nextControlDic["posStatusControl"] = "firstdriverPelaque";
    nextControlDic["firstdriverPelaque"] = "serviceCount";
    nextControlDic["serviceCount"] = "txttellIDCaller";



    var related = nextControlDic[element.id];
    if (related) {
      //console.log('realted is ' + related)
      this.renderer2.selectRootElement('#' + related).focus();
    }
    else {
      //console.log('relates is null');
      this.renderer2.selectRootElement('#txttellIDCaller').focus();
    }
  }
  mode = '';
  makeid(length: any) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  // یعنی این آی دی رو برای اپدیت باز کن
  gotoupdate(requestID: any) {


    //console.log(requestID);
    this.requestService.getRequestServiceByID(requestID).subscribe(res => {
      //console.log('getRequestServiceByID out');
      //console.log(res);
      this.mode = 'update';
      this.callInfo = new LastCallInfo();
      this.callInfo.callInfo = new CallLog();
      this.callInfo.passangerInfo = new Passanger();
      this.callInfo.callInfo.tel = res.telIDCaller;
      this.callInfo.callInfo.tel = res.passangerMobile;



      this.currentIDForUpdate = requestID;
      this.passangerFormGroup.patchValue({ passangerFullName: res.passangerFName });
      this.passangerFormGroup.patchValue({ telIDCaller: res.telIDCaller });
      this.passangerFormGroup.patchValue({ passangerMobile: res.passangerMobile });
      this.lockDriver = '';
      this.passangerServiceCount = (res.passangerServiceCount);
      this.passangerFormGroup.patchValue({ stationCode: res.selectedStationID.toString() });
      this.stationCapacity = '';
      this.passangerReservedCount = '';
      this.passangerFormGroup.patchValue({ originAC: res.originAddr });
      this.passangerFormGroup.patchValue({ destAC: res.destinationAddr });
      this.passangerFormGroup.patchValue({ passangerComment: res.fixedComment });
      this.passangerFormGroup.patchValue({ serviceRequestComment: res.requestComment });
      this.passangerFormGroup.patchValue({ price: res.price });
      this.passangerFormGroup.patchValue({ pos: res.pos });
      this.passangerFormGroup.patchValue({ maeloolin: res.maeloolin });
      this.passangerFormGroup.patchValue({ darEkhtiar: res.darEkhtiar });
      this.passangerFormGroup.patchValue({ amanati: res.amanati });
      this.passangerFormGroup.patchValue({ khadamat: res.khadamat });
      this.passangerFormGroup.patchValue({ maledriver: res.maleDriver });
      this.passangerFormGroup.patchValue({ femaledriver: res.femaleDriver });

      if (res.typeCars)
        this.selectedCars.setValue(res.typeCars.split(','));
      else
        this.selectedCars.setValue('');

    });



  }
  // ازین برای این استفاده شد که یارو مثه خر روی گزینه ذخیره کلیک می کرد پشت سر هم این میگه توی سیصد میلی ثانیه فقط یه بار فایر بشه
  debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  };
  counter = 0;
  saverequestLog() {
    this.counter++
    this.saveRequestForQueue();
  }
  saveRequestClickDebounce = this.debounce(this.saverequestLog, 1000);

  resetForm() {
    this._reset();
    this.passangerFormGroup.patchValue({ telIDCaller: '' });

  }

  onReserveTimePickerModalClick() {
    this.reserveModal.visible = true;
  }
  showMap() {

    this.mapDialogRef = this.dialog.open(SetorigindesComponent, {
      hasBackdrop: true,
      width: '90%',
      data: {
        origin: this.passangerFormGroup.get('originAC')?.value,
        des: this.passangerFormGroup.get('destAC')?.value,
        originLoc: this.originLatLng,
        destLoc: this.destLatLng
      }

    });
    this.mapDialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      this.originLatLng = result.originLoc;
      this.destLatLng = result.destLoc;
      if (!this.passangerFormGroup.get('originAC')?.value)
        this.passangerFormGroup.get('originAC')?.setValue(result.origin);
      if (!this.passangerFormGroup.get('destAC')?.value)
        this.passangerFormGroup.get('destAC')?.setValue(result.des);

    });

  }
  showPrice: boolean = true
  changeInputHelper(method, e) {
    if (method == 'typePardakht') {
      this.typePardakht.setValue(e.target.value);
      this.showPrice = e.target.value == '1020'
      console.log('this.showPrice ' + this.showPrice + 'e.target.value ' + e.target.value);

    }
    if (method == 'typeService') {
      this.typeService.setValue(e.target.value);
      if (this.typeService.value == '1034') {
        if (this.stationCode.value != '100') {
          this.stationCode.setValue('100');
          this.matSnackbar.open('سرویس ون در ایستگاه آزاد ثبت خواهد شد', null, { duration: 3000 });
        }

      }

    }

    if (method == 'moarefID')
      this.moarefID.setValue(e.target.value);


    console.log(this.moarefID.value);
    console.log(this.typePardakht.value);
    console.log(this.typeService.value);

  }
  showStationMember() {
    //console.log('this.currentStation.id');
    //console.log(this.currentStation.id);
    this.stationSerivce.getStationQueue(this.currentStation.id).subscribe(_res => {


      let dialogStationMemberRef = this.dialog.open(StationMemberComponent, {
        width: '400px',
        hasBackdrop: true,
        data: {
          stationID: this.currentStation.id,
          queue: _res

        }
      });



    });


  }
  driverCodes: StationQueueInfo[] = []
  onSelectDriverChange(selectedqueueList: StationQueueInfo[]) {
    this.driverCodes = selectedqueueList;
    console.log('selected is ');
    console.log(selectedqueueList);


  }
  gotoDarEkhtiar() {
    this.darEkhtiar.setValue(true) ;
    this.setDestination(0, 0, 'در اختیار');
  }
  

}

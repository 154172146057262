import { Component, OnInit, Inject } from '@angular/core';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { Station } from '../../model/station';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.css']
})
export class StationListComponent implements OnInit {
  lstStation: Station[];
 

  constructor(private stationSerivce: StationserviceService,
    public dialogRef: MatDialogRef<StationListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { guidDriver: string, gpsLat: any, gpsLng:any  }

  ) { }

  ngOnInit(): void {
    this.loadStation();
  }
  loadStation() {
    this.stationSerivce.getStationList(this.data.guidDriver, this.data.gpsLat, this.data.gpsLng).subscribe(_res => {
      this.lstStation = _res;
    });
  }
  showStation(station) {
    this.dialogRef.close(station);

  }
  closeform() {
    this.dialogRef.close('');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { GlobalVariable } from './../../globalVariable';
import { Observable, Subscriber } from 'rxjs';
import { routingInfo, searchResult } from '../../model/routingInfo';
import { keyvalueobject } from '../../model/keyvalueobject';
import { configValue } from '../../model/configValue';

@Injectable({
  providedIn: 'root'
})
export class TaxiserviceService {
  private UtilityBaseServiceURL = GlobalVariable.UtilityBaseServiceURL;
  private ServiceBaseServiceURL = GlobalVariable.ServiceBaseServiceURL ;
  public moke = GlobalVariable.mokeFLAG;
  constructor(private httpClient: HttpClient) { }
  public getAddressFromLocation(lat: any, lng: any) {
    if (this.moke) {
      return new Observable<string>((subscriber: Subscriber<string>) => subscriber.next('آدرس گرفته شده از نشان'));

    }
    let params = new HttpParams().set('lat', lat).set('lng', lng);
    return this.httpClient.post<string>(this.UtilityBaseServiceURL + "/loctoadr", params,{ responseType: 'text' as 'json'});
  }
  public routing(lat: any, lng: any, destlat: any, destln: any): Observable<routingInfo> {
    if (this.moke) {
      var val = new routingInfo();
      val.distanceText = '3 کیلومتر'
      val.distanceValue = '3200'
      val.durationText = '25 دقیقه'
      val.durationValue = '650'
      val.polylines = ['efr|EmgsjJg@UyB}@', 'gkr|EajsjJtA}F']
      val.summaryTotal='احمد اباد به پارک'
      return new Observable<routingInfo>((subscriber: Subscriber<routingInfo>) => subscriber.next(val));
    }
    let params = new HttpParams().set('originlat', lat).set('originln', lng).set('destlat', destlat).set('destln', destln);
    return this.httpClient.post<routingInfo>(this.UtilityBaseServiceURL + "/routing", params);
  }

  public searchMap(term: any, lat: any, lng: any): Observable<searchResult[]> {
    let params = new HttpParams().set('term', term).set('lat', lat).set('lng', lng);
    return this.httpClient.post<searchResult[]>(this.UtilityBaseServiceURL + "/search", params);
  }
  public saveHearBit(userid: any, userType: any, lat: any, lng: any, state: any): Observable<searchResult[]> {
    //console.log('saveHearBit service ');
    let params = new HttpParams().set('userType', userType);
    params = params.set('UserID', userid);
    params = params.set('lat', lat);
    params = params.set('lng', lng);
    params = params.set('State', state);
    return this.httpClient.post<searchResult[]>(this.UtilityBaseServiceURL + "/saveHeartBit", params);
  }
  public updateSetting(key:string , value:string): Observable<any> {
    let params = new HttpParams().set('key', key);
    params = params.set('value', value);
    return this.httpClient.post(this.UtilityBaseServiceURL + "/UpdateSetting", params);
  }
  public readConfig(): Observable<configValue[]> {
    let params = new HttpParams();
    return this.httpClient.post<configValue[]>(GlobalVariable.UtilityBaseServiceURL + "/GetAllConfig", params);
  }

  public readConfigValue(key: string ='PoorsantPercent') {
    let params = new HttpParams().set('key', key);
    return this.httpClient.post<string>(this.UtilityBaseServiceURL + "/GetConfigValue", params, { responseType: 'text' as 'json' });
  }
 
   
  public getListKeyValue (group): Observable<keyvalueobject[]> {

    let params = new HttpParams().set('type', group);
    return this.httpClient.post<keyvalueobject[]>(GlobalVariable.UtilityBaseServiceURL + "/getBaseInfoList", params);
  }

}

export class SaveServiceResult {
  id: number;
  result: saveservicresultType;
  driverName: string;
  carName: string;
  carColor: string;
  carPelaque: string;
  serviceGUID: string;
  msg: string;
  requestID: number;

}
export enum saveservicresultType {
  //جالتی که راننده اختصاص داده شد
  driverFound,
  // راننده ای وجود نداردو اتمام فرآیند
  driverNotFound,
  // در حال جستجوی راننده - راننده قطعی وجود نداشته و در حالپیشنهاد به راننده ها می باشد
  inSearchingDriver,
  update_lastServiceIsCancel,
  update_SentAlarmForDriver,
  update_NoChange,

  

}
 

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Passanger } from '../../model/passanger';
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from '@angular/router';
import { PassangerserviceService } from '../../lib/serviceLib/passangerservice.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GlobalVariable } from '../../globalVariable';

@Component({
  selector: 'app-passangers',
  templateUrl: './passangers.component.html'
})
export class PassangersComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() data: any = [];
  @Output() visibleChange = new EventEmitter();
  allPassangerList: Passanger[] = [];
  filteredList: Passanger[] = [];

  isLoading: boolean = false;
  detailsVisible: boolean = false;
  public result: any[] = [];

  recordCount = 0;
  pageSize = 30;



  constructor(
    private passangerServiceService: PassangerserviceService,
    private route: ActivatedRoute,
    private router: Router

  ) {
  }

  operatorguid: string;
  adminLevel: number = 1;
  passangerForm: FormGroup;

  ngOnInit(): void {
    this.adminLevel = GlobalVariable.adminLevel;
    this.allPassangerList = [];
    this.passangerForm = new FormGroup({
      fName: new FormControl(),
      lName: new FormControl(),
      mobile: new FormControl(),
      natinoalCode: new FormControl()
    });
    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];


    });
    this.loadPassangerReport();
  }

  async submitFormAndSend() {

    this.allPassangerList = [];

    this.loadPassangerReport();
  }
  loadPassangerReport() {
    this.passangerServiceService.reportAllPassanger(this.passangerForm.get('fName')?.value, this.passangerForm.get('lName')?.value, this.passangerForm.get('mobile')?.value, this.passangerForm.get('natinoalCode')?.value).subscribe(res => {
      if (res.length > 0) {
        //console.log(res);
        this.allPassangerList = res;
      } else {
        this.allPassangerList = [];

        Swal.fire('یافت نشد', 'موردی جهت جستجو یافت نشد!');
      }
    });
  }


  editForm(id: any) {
    this.router.navigate(['cc/regPassanger'], { queryParams: { guid: this.operatorguid, valp: id } });

  }

  delete(id: any) {

  }


  resetForm() {
    this.passangerForm.reset();
  }
}


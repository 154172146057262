import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AudioService } from '../../lib/serviceLib/audio.service';

@Component({
  selector: 'app-playfile',
  templateUrl: './playfile.component.html',
  styleUrls: ['./playfile.component.scss']
})
export class PlayfileComponent implements OnInit {
  @ViewChild("audioOption", { static: false })
  public audioPlayerRef: ElementRef;

  constructor(private fileutil: AudioService) { }

  ngOnInit() {
    

    this.onAudioPlay();
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }
  loadfile() {

    this.onAudioPlay();
    //console.log('loadfile');
    this.fileutil.playStream('assets/play.mp3');
  }

}

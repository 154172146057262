import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IOperator } from '../../lib/interfaces/operator';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { OperatorService } from '../../lib/serviceLib/operatorservice.service';
import { ReportTripComponent } from '../report-trip/report-trip.component';

@Component({
  selector: 'app-report-byoperator',
  templateUrl: './report-byoperator.component.html',
  styleUrls: ['./report-byoperator.component.css']
})
export class ReportByoperatorComponent implements OnInit {

  @ViewChild(ReportTripComponent) child: ReportTripComponent;


  constructor(private drvService: OperatorService) { }

  selectedDriver= new FormControl('');
  dateAz = new FormControl(new Date(), []);
  dateTa = new FormControl(new Date(), []);
  options: string[] = [];
  operatorList: IOperator[] = [];
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.options = [];
    this.drvService.getAll().subscribe(res => {
      this.operatorList = res;
      res.forEach(t => {
        this.options.push(this.displayOperator(t));
      });
    });

    this.filteredOptions = this.selectedDriver.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value.toString() || '')),
    );

  }
  displayOperator(drv: IOperator) {
    return drv.username + ' - ' + drv.operatorName;
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  selectOption(e: MatAutocompleteSelectedEvent) {
    console.log(e);
  }
  operatorID = 0;
  dateStart = '';
  dateFinish = '';
  report() {
    console.log(this.dateAz.value?.toString());
    console.log(this.dateTa.value?.toString());
    console.log(this.selectedDriver.value);
    var operatorObject: IOperator;
    this.operatorList.forEach(t => {
      console.log(this.displayOperator(t));
      if (this.selectedDriver.value == this.displayOperator(t)) {
        operatorObject = t;
      }
    }
    );
    this.operatorID = operatorObject?.id;
    this.dateStart = this.dateAz.value?.toString();
    this.dateFinish = this.dateTa.value?.toString();
    console.log(operatorObject);
    this.child.report();

  }

}

import { Component, OnDestroy, OnInit, ViewChild, Renderer2, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatorTable } from '../../model/operatorTable';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { OperatorService } from '../../lib/serviceLib/operatorservice.service';
import { NotifalertserviceService } from '../../shared/notifalertservice.service';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '../../../environments/environment.prod';
import { GlobalVariable } from '../../globalVariable';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-master-cc',
  templateUrl: './master-cc.component.html',
  styles: [`
    .mat-toolbar {
      background-color: #00dc6f !important;
      color: #ffffff !important;
    }

    ::ng-deep mat-drawer custom-button {
      width: 100% !important;
    }

    ::ng-deep mat-drawer custom-button button {
      width: 100% !important;
      background-color: white !important;
      color: black !important;
      text-align: right !important;
    }

    ::ng-deep mat-toolbar {
      position: sticky;
      position: -webkit-sticky; /* For macOS/iOS Safari */
      top: 0;
      z-index: 1000;
    }
  `]
})
export class MasterCcComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("drawer", { static: false })
  public drawer: MatSidenav;
  @ViewChild('android', { static: false })
  public android: ElementRef;


  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
     

  }
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document ,
    private callCenterService: CallCenterService,
    private operatorService: OperatorService,
    private route: ActivatedRoute,
    private router: Router,
    private notifAlert: NotifalertserviceService  ) {
    this.notifAlert.sharedData.subscribe(data => { this.changedRecived(data); })

  }
  ngOnDestroy() {
    this.notifAlert.sharedData.next("");
    this.notifAlert.sharedData.complete();
  }


  changedRecived(data: any) {
    if (data == 'savenew') {
      this.loadOperatorInfo(this.operatorguid);
    }
    //console.log(data +' recived as master')
  }
  operatorInfo = new OperatorTable();
  operatorguid: string;
  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.loadOperatorInfo(this.operatorguid);
    });

  }
  loadOperatorInfo(guidCode: any) {
    this.callCenterService.getOperatorInfo(guidCode).subscribe(res => {
      if (res) {
        this.operatorInfo = res;
        GlobalVariable.adminLevel = this.operatorInfo.adminLevel;

      } else {
        localStorage.removeItem(environment.cur_url);

        this.router.navigate(['login'], { queryParams: {} });
      }
    });
  }
  signout() {
    localStorage.removeItem(environment.cur_url);

    try {
      this.operatorService.logout(this.operatorInfo.id, false).subscribe(
        res => {
          this.router.navigate(['login'], {});
        })
    }
    catch (e) {

    }

  }
  menuItemClick(item: any) {


    if (item == 'dashboard') {
      this.router.navigate(['cc/dashboard'], { queryParams: { guid: this.operatorguid } });
    }
    else
      if (item == 'newForm') {
        this.router.navigate(['cc/new'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'searchForms') {
        this.router.navigate(['cc/searchforms'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'formsList') {
        this.router.navigate(['cc/formslist'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'regComplaint') {
        this.router.navigate(['cc/regComplaint'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'complaints') {
        this.router.navigate(['cc/complaints'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'regFounded') {
        this.router.navigate(['cc/regFounded'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'foundeds') {
        this.router.navigate(['cc/foundeds'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'regPassanger') {
        this.router.navigate(['cc/regPassanger'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'passangers') {
        this.router.navigate(['cc/passangers'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'regStation') {
        this.router.navigate(['cc/regStation'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'stations') {
        this.router.navigate(['cc/stations'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'bisim') {
        this.router.navigate(['cc/manage'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'operatosmanage') {
        this.router.navigate(['cc/operators'], { queryParams: { guid: this.operatorguid } });

      }
      else if (item == 'manage') {
        this.router.navigate(['cc/manageall'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'managedrivers') {
        this.router.navigate(['cc/drivers'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'newWebForm') {
        this.router.navigate(['cc/newweb'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'logs') {
        this.router.navigate(['cc/logs'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'drivermonitor') {
        this.router.navigate(['cc/drivermonitor'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'trafficmonitor') {
        this.router.navigate(['cc/trafficmonitor'], { queryParams: { guid: this.operatorguid } });
      }

    this.drawer.toggle()

  }


  powerClick() {
    if (this.operatorInfo.isActive)
      this.operatorInfo.isActive = false;
    else
      this.operatorInfo.isActive = true;
    //let po: Partial<IOperator> = this.operatorInfo;

    this.operatorService.update(this.operatorInfo).subscribe(res => {
      //console.log(res);
    });
  }

}

import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar  } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SetmanualdriverComponent } from '../../call-center/setmanualdriver/setmanualdriver.component';
import { DriverLogComponent } from '../../driver/driver-log/driver-log.component';
import { PropsoedListComponent } from '../../driver/propsoed-list/propsoed-list.component';
import { SetpriorityComponent } from '../../manage/setpriority/setpriority.component';
import { ChangestationComponent } from '../../manage/changestation/changestation.component';
import { MessageboxComponent } from '../../messagebox/messagebox.component';
import { AllServiceInfo } from '../../model/serviceInfo';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';

@Component({
  selector: 'app-callmanager',
  templateUrl: './callmanager.component.html',
  styleUrls: ['./callmanager.component.scss']
})
export class CallmanagerComponent implements OnInit, OnDestroy {
  allserviceList: AllServiceInfo[] = [];
  filteredList: AllServiceInfo[] = [];
  dataSource = new MatTableDataSource(this.filteredList);
  dialogProposedServiceRef: MatDialogRef<SetmanualdriverComponent, any> ;
  dialogSetPriority: MatDialogRef<SetpriorityComponent, any>  ;

  constructor(
    private currentServiceService: ServiceserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private renderer2: Renderer2,
    private matSnackbar: MatSnackBar,
    
  ) {
  }
  @ViewChild("sort", { static: false })
    public sort: MatSort = new MatSort;

  

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  highlight(row : any) {
    //console.log('highlight' + row.id);
    this.selectedRowIndex = row.id;
    this.selectedElement = row;

  }
  handleShortCut(event: KeyboardEvent) {
    if (event.key === 'F2') {
      this.renderer2.selectRootElement('#driverCode').focus();
      event.preventDefault();
    }
    else if (event.key === 'F3') {
      this.filter();
      event.preventDefault();
    }
    else if (event.key === 'F7') {
      // cancelTrip(element.requestID, element)
      if (this.selectedElement) {
        this.cancelTrip(this.selectedElement.requestID, this.selectedElement);
      }
      this.filter();
      event.preventDefault();
    }
    else if (event.key === 'F8') {
      //console.log('F8 is pressed');

      //editService(element.requestID)
      if (this.selectedElement) {
        //console.log('selectedElement is ' + this.selectedElement);
        this.editService(this.selectedElement.requestID);
      }
      else
        //console.log('selectedElement is null');
      event.preventDefault();
    }
    else if (event.key === 'F9') {
      //(click)="setManualDriver(element.serviceGuid)">
      if (this.selectedElement) {
        this.setManualDriver(this.selectedElement.serviceGuid,2);
      }
      event.preventDefault();
    }
    else if (event.key === 'F10') {
      //(click)="setManualDriver(element.serviceGuid)">
      if (this.selectedElement) {
        this.setManualDriver(this.selectedElement.serviceGuid, 1);
      }
      event.preventDefault();
    }


    else if ((event.which == 13 || event.keyCode == 13)) {
      //console.log('enter pressed');

      this.filter();
      this.forwardNext(event.srcElement);

      event.preventDefault();
    }
  }
  forwardNext(element : any) {

    var nextControlDic: { [id: string]: string; } = {};
    nextControlDic["driverCode"] = "telCall";
    nextControlDic["telCall"] = "passangerName";
    nextControlDic["passangerName"] = "address";
    nextControlDic["address"] = "stationCode";
    nextControlDic["stationCode"] = "";




    var related = nextControlDic[element.id];
    if (related) {
      //console.log('realted is ' + related)
      this.renderer2.selectRootElement('#' + related).focus();
    }
    else {
      if (element.id == 'stationCode') {
        this.filter();
      }

    }
  }
  selectedRowIndex = -1;
  selectedElement: AllServiceInfo = new AllServiceInfo();
  displayedColumns: string[] = ['position', 'passangerName', 'passangerMobile', 'originAddr', 'destinationAddr', 'tripComment', 'stationID', 'editCol', 'manualCol', 'manualProposedCol', 'deleteCol', 'resendCol', 'exitCol', 'driverLogCol','finishCol', 'setPriorityCol','queueCol','driverName', 'driverMobile', 'timedConsume', 'serviceStateName', 'operatorName','driverCount'];
  loadPassangerReport() {
    //console.log('loadPassangerReport');
    this.currentServiceService.reportAll().subscribe(res => {
      //console.log(res);
      this.allserviceList = res;
      this.filter();
    });
  }
  telCall = '';
  driverCode = '';
  passangerName = '';
  address = '';
  stationCode = '';
  recordCount = 0;
  showwaiting: Boolean = true;
  showreserved: Boolean = true;
  currentGuid = '';
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const guidDriver = params['guid'];
      if (guidDriver) {
        this.currentGuid = guidDriver;
        this.loadPassangerReport();
      }


    });
    this.schedulJon = setInterval(() => {
      this.schedulingupdatestatus();
    }, 5 * 1000);
  }

  pauseUpdate = false;
  schedulingupdatestatus() {
    if (!this.pauseUpdate) {
      //console.log('update list');
      this.loadPassangerReport();
    }
    else {
      //console.log('update cancel');
    }

  }
  valueChange(changedItem: any, $event: any) {
    if (changedItem == 'showwaiting') {
      this.showwaiting = $event.checked;
      this.filter();
    }
    else if (changedItem == 'pauseUpdate') {
      this.pauseUpdate = $event.checked;
    }
    else if (changedItem == 'showreserved') {
      this.showreserved= $event.checked;
      this.filter();
    }
    
  }

  editService(id: any) {
    this.router.navigate(['cc/new'], { queryParams: { guid: this.currentGuid, valp: id } });
  }
  schedulJon: any;
  ngOnDestroy() {
    if (this.schedulJon) {
      clearInterval(this.schedulJon);
    }
  }
  pageSize = 30;
  filter() {
    //if (this.telCall)
      //console.log(this.telCall);
    

    this.filteredList = [];
    //console.log(this.showwaiting);
    this.allserviceList.forEach((data) => {

      this.recordCount = this.filteredList.length;
      if (this.recordCount > this.pageSize && !this.showwaiting)
        return;

      if ((this.driverCode && data.driverCode == this.driverCode)
        ||
        (this.passangerName && data.passangerName.includes(this.passangerName))
        ||
        (this.address && data.passangerAddress.includes(this.address))
        ||
        (this.stationCode && data.stationID.toString() == (this.stationCode))
        ||
        (this.telCall && data.passangerMobile.includes(this.telCall))
      ) {
        this.filteredList.push(data);
      }
      else


      if ((this.driverCode === '' || data.driverCode == this.driverCode)
        &&
        (this.passangerName === '' || data.passangerName.includes(this.passangerName))
        &&
        (this.address === '' || data.passangerAddress.includes(this.address))
        &&
        (this.stationCode === '' || data.stationID.toString() == (this.stationCode))
      ) {

        if (this.showwaiting) {
          
          if (data.serviceStateName === 'در حال یافتن راننده'  || data.serviceStateName === 'راننده ای یافت نشده' || data.serviceStateName === 'بدون راننده') {
             
            this.filteredList.push(data);
          }
        }
        else
          this.filteredList.push(data);

        //console.log('record count is ' + this.recordCount);
      }
    });
    this.recordCount = this.filteredList.length;
    this.dataSource = new MatTableDataSource(this.filteredList);

  }
  dialogmsgBoxRef: MatDialogRef<MessageboxComponent, any>;
  cancelTrip(requestID: any, trip: any) {
    //console.log('cancelTrip');
    //console.log(requestID);
    //console.log(trip);
    
    this.dialogmsgBoxRef = this.dialog.open(MessageboxComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        msgText: 'آیا از کنسل کردن سفر اطمینان دارید؟',
        msgTitle: 'انصراف'
      }
    });
    this.dialogmsgBoxRef.afterClosed().subscribe((result : any) => {

      if (result == 'ok') {
        this.currentServiceService.cancelServiceByRequest(requestID).subscribe(res => {
          if (trip) {
            trip.serviceStateName = 'کنسل شده';
            this.loadPassangerReport();

          }
        });
      }

    });

    //console.warn(trip);
  }
  resend(requestID: any) {
    //console.log('resend')
    //console.log(trip)
    this.currentServiceService.ReProposedRequest(requestID).subscribe(res => {
      //this.socketService.sendMessage(res.serviceGUID, SocketMessageType.RequestServiceCreate);
      this.matSnackbar.open('فرم دوباره ثبت گردید', null, { duration: 3000 });
      //console.log(res);
    });




  }
  finishTrip(serviceGuid: any) {
    this.currentServiceService.finishService(serviceGuid).subscribe(_res => {

    });
  }
  ListProposed(requestID: any) {

  }
  
  SetPriority(dCode: any, stationID: any) {
    this.dialogSetPriority = this.dialog.open(SetpriorityComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        driverCode: dCode,
        stationID: stationID
      }
    });

  }
  exitDriver(driverCode: any) {
    this.currentServiceService.exitDriver(driverCode).subscribe(_res => {

    });
  }
  setManualDriver(guidRequestParam: any, typeAction: any) {
    //console.log(guidRequestParam);
    this.dialogProposedServiceRef = this.dialog.open(SetmanualdriverComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        guidRequest: guidRequestParam , 
        actionType: typeAction
      },
      autoFocus: true,
    });
    this.dialogProposedServiceRef.afterClosed().subscribe((result : any) => {
      if (result == 'Accept') {



      } else if (result == 'Reject') {

      }
    });

    //this.taxiSerivce.getRequestByPropsedCode(guidRequestParam).subscribe(_res => {
    //  console.warn(_res);
    //});

  }

  getPredict(infoItem: any) {
   
    
    
    this.dialogPredicRef = this.dialog.open(ChangestationComponent, {
      width: '1200px',
      hasBackdrop: true,
      data: {
        info: infoItem
      }
    });
  }
  public dialogPredicRef: MatDialogRef<ChangestationComponent>
  dialogDriverLogRef: MatDialogRef<DriverLogComponent, any>;
  showLog(driverIDParam: any) {
    this.dialogDriverLogRef = this.dialog.open(DriverLogComponent, {
      width: '800px',
      hasBackdrop: true,
      maxHeight: '90vh',
      data: {
        driverID: driverIDParam
      },
      autoFocus: true,
    });
  }
  dialogPropLIstServiceRef: MatDialogRef<PropsoedListComponent, any>;
  showproposed(requestID: any) {
    this.dialogPropLIstServiceRef = this.dialog.open(PropsoedListComponent, {
      width: '800px',
      hasBackdrop: true,
      data: {
        requestID: requestID
      },
      autoFocus: true,
    });
  }
}

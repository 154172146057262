import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'custom-button',
  template: `
    <button
style="height:5vh; font-size:2vh;"
      *ngIf="!sqaure"
      (click)="onClick.emit($event)"
      [disabled]="disabled || loading"
      pButton
      [pTooltip]="tooltip"
      tooltipPosition="bottom"
      [label]="label"
      class="full-width">
      <mat-icon
        *ngIf="icon !== null"
        color="white">
        {{icon}}
      </mat-icon>
      <div class="d-flex justify-content-center align-self-center">
        <mat-spinner
          *ngIf="loading"
          class="my-2"
          [color]="color" diameter="20"></mat-spinner>
      </div>
      <div *ngIf="!loading">
        <ng-content></ng-content>
      </div>
    </button>
    <button
      *ngIf="sqaure"
      (click)="onClick.emit()"
      mat-raised-button
      [color]="color"
      [matTooltip]="tooltip"
      class="sqaure-button d-flex align-items-center justify-content-center mat-primary">
      <mat-icon
        color="white"
        *ngIf="icon !== null">
        {{icon}}
      </mat-icon>
      <span
        *ngIf="icon === null"
        class="text-white">
        {{label}}
        <ng-content></ng-content>
      </span>
    </button>
  `,
  styles: [
    `
      .sqaure-button {
        min-width: 36px !important;
        max-width: 36px !important;
        width: 36px !important;
        cursor: pointer;
        border-radius: 8px;
height: 5vh;
      }
    `
  ]
})
export class ButtonComponent implements OnInit {
  @Input() loading = false;
  @Input() label: string = '';
  @Input() icon: any = null;
  @Input() disabled: boolean = false;
  @Input() sqaure: boolean = false;
  @Input() raised: boolean = false;
  @Input() color: ThemePalette = 'primary';
  @Input() tooltip: any = null;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

}

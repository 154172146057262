import { Injectable } from '@angular/core';
import { NotificationModel,  SocketMessageType } from './../model/socketmodels';
import { BehaviorSubject } from 'rxjs';
import { GlobalVariable } from '../globalVariable';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket: WebSocket;
  meesageList$: BehaviorSubject<NotificationModel> = new BehaviorSubject<NotificationModel>(new NotificationModel());
  constructor() { }
 
  startSocket() {
    
    if (!this.socket) {
      this.socket = new WebSocket(GlobalVariable.socketURL);
      //this.socket.addEventListener("open", (ev => {
      //}));
      this.socket.addEventListener("message", (ev => {
        //console.log(ev);
        try {
          //var messageBox: NotificationModel = JSON.parse(ev.data);
          //this.meesageList$.next(messageBox);
          console.log(ev.data);
        } catch (e) {
          //console.log(e);
        }
      }));
      //this.socket.addEventListener("close", (ev => {
      //  //console.log('web socket is close');
      //  //console.log(ev);
      //}));
    }
  }
  sendMessage(guid: string, type: SocketMessageType) {
    let req= new NotificationModel();
    req.guidCode = guid;
    req.msgType = type;
    var requestAsJson = JSON.stringify(req);
    this.socket.send(requestAsJson);
  }
  sendMessageByType(message: string) {

    this.socket.addEventListener("open", (ev => {
      this.socket.send(message);

    }));
    
  }
 
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationInfo, PageState } from '../globalVariable';
import { MarkerType } from '../lib/markerHelper';
import { WebSocketService } from '../lib/web-socket.service';
import { ServiceRequest } from '../model/ServiceRequest';
import { NotificationModel, SocketMessageType } from '../model/socketmodels';
import { DrivernotfoundComponent } from '../passanger/drivernotfound/drivernotfound.component';
import { WaitforRequestComponent } from '../passanger/waitfor-request/waitfor-request.component';
import { ServiceRequestService } from '../lib/serviceLib/servicerequestservice.service';
import { TaxiserviceService } from '../lib/serviceLib/taxiservice.service';
import { TaximapComponent } from '../taximap/taximap.component';


@Component({
  selector: 'app-newrequest',
  templateUrl: './newrequest.component.html',
  styleUrls: ['./newrequest.component.css']
})

export class NewrequestComponent implements OnInit {
  @ViewChild("map", { static: false })
  public map: TaximapComponent;
  passangerGuid = '38F8ED9A-0DB2-43C7-9A7F-A1564E43A5BE'
  passangerID = 1
  StateName = ''
  guidRequest = ''
  CurrentState = PageState.None;
  originPoint = new LocationInfo();
  destinationPoint = new LocationInfo();
  constructor(
    private requestSerivce: ServiceRequestService,
    private taxiSerivce: TaxiserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: WebSocketService,
    private dialog: MatDialog) {
    
    
    
    this.socketService.meesageList$.subscribe(announcement => {
      if (announcement) {
        this.processReceivedSocketMessage(announcement);
      }
    });

  }
  getService(guidService: any) {
    //console.warn('service accepted by :' + guidService);
    this.router.navigate(['passanger/service'], { queryParams: { guid: guidService } });

  }
  processReceivedSocketMessage(messageBox: NotificationModel) {
    //console.warn('msg is received')
    //console.warn(messageBox)
    switch (messageBox.msgType) {
      case SocketMessageType.GetClientInfo:
        this.socketService.sendMessage(this.passangerGuid, SocketMessageType.SetPassangerInfo);
        break;
      case SocketMessageType.ServiceRequestAssigned:
        //this.socketService.sendMessage(this.passangerGuid, SocketMessageType.SetPassangerInfo);
        this.closeloadingBar();
        this.getService(messageBox.guidCode);
        break;
      case SocketMessageType.DriverNotExistForThisRequest:
        this.closeloadingBar();
        this.openNotFoundError()
        break;


      default:
        break;
    }
  }
  ngOnInit() {
    this.map = new TaximapComponent();
    this.setState(PageState.OriginDefine);
    this.socketService.startSocket();
    this.route.queryParams.subscribe(params => {
       
      if (params['showWaiting']) {
        this.openloadingBar()
      }
      else if (params['showNotFound']) {
        this.openNotFoundError()
      }
      
    });
  }

  setState(newState: any) {

    this.CurrentState = newState;
    switch (this.CurrentState) {
      case PageState.DestinationDefine:
        this.StateName = 'تایید مقصد'
        break;
      case PageState.None:
      case PageState.OriginDefine:
        this.StateName = 'تایید مبدا'
        break;
      case PageState.RequestTrip:
        this.StateName = 'درخواست سفر'
        break;
      default:
        break;
    }
  }
  setOrigin(cordinates: any) {
    this.map.addMarker(cordinates[0], cordinates[1], MarkerType.origin);
    this.originPoint.setFromCordinate(cordinates);
    var s = this.taxiSerivce.getAddressFromLocation(this.originPoint.lat, this.originPoint.ln);
    s.subscribe(_res => {
      this.originPoint.desc = _res;
    });
    this.setState(PageState.DestinationDefine);
  }
  setDestination(cordinates: any) {
    this.map.addMarker(cordinates[0], cordinates[1], MarkerType.destination);
    this.destinationPoint.setFromCordinate(cordinates);
    this.taxiSerivce.getAddressFromLocation(this.destinationPoint.lat, this.destinationPoint.ln).subscribe(_res => {
      this.destinationPoint.desc = _res;
    });
    this.setState(PageState.RequestTrip);
    //this.routing();

  }
  routing() {
    this.taxiSerivce.routing(this.originPoint.lat, this.originPoint.ln, this.destinationPoint.lat, this.destinationPoint.ln).subscribe(_res => {
      //console.warn('ply:' + _res);
      //console.warn(_res.distanceText);
      //console.warn(_res.distanceValue);
      //console.warn(_res.durationText);
      //console.warn(_res.durationValue);
      //console.warn(_res.polylines);
      //console.warn(_res.summaryTotal);

      //this.map.showpoly2(_res.polylines, this.originPoint.ln, this.originPoint.lat, this.destinationPoint.ln, this.destinationPoint.lat);
    });
  }
  currentLat = 0
  currentLng = 0
  map_MoveEnd(data: any) {
    this.currentLat = data.location[0]
    this.currentLng = data.location[1]
    if (this.CurrentState == PageState.OriginDefine) {
      var s = this.taxiSerivce.getAddressFromLocation(this.currentLat, this.currentLng);
      s.subscribe(_res => {
        this.originPoint.desc = _res;
      });

    }
    else if (this.CurrentState == PageState.DestinationDefine) {
      var s = this.taxiSerivce.getAddressFromLocation(data.location[0], data.location[1]);
      s.subscribe(_res => {
        this.destinationPoint.desc = _res;
      });
    }
  }
  map_Click(cordinates: any) {
    //if (this.CurrentState == PageState.OriginDefine) {
    //  this.setOrigin(cordinates);
    //  this.setState(PageState.DestinationDefine);
    //  this.map.showMarkerOnCenter = true;
    //}
    //else if (this.CurrentState == PageState.DestinationDefine) {
    //  this.setDestination(cordinates);
    //  this.setState(PageState.RequestTrip);
    //  this.map.showMarkerOnCenter = false;
    //}
    //this.map.addMarker(cordinates[0], cordinates[1]);
    //console.warn(cordinates);
  }
  openNotFoundError() {
    this.dialogRefDrvNotFound = this.dialog.open(DrivernotfoundComponent, {
      height: '400px',
      width: '600px',
      data: { guidRequest: this.guidRequest }
    });
    this.dialogRefDrvNotFound.afterClosed().subscribe((result : any) => {
      if (result == 'Retry') {
        this.saveRequest();

      }

    });
  }
  openloadingBar() {
    this.dialogRef = this.dialog.open(WaitforRequestComponent, {
      height: '400px',
      width: '600px',
      data: { guidRequest: this.guidRequest }
    });
    this.dialogRef.afterClosed().subscribe((result : any) => {
      if (result == 'CancelRequest') {
        //console.warn('CancelRequest is received');
        this.socketService.sendMessage(this.guidRequest, SocketMessageType.RequestServiceCancelByPassanger);

      }

    });
  }
  closeloadingBar() {
    //console.warn('closeloadingBar')
    this.dialogRef.close('NotAnswered');
  }
  dialogRef: MatDialogRef<WaitforRequestComponent, any>;
  dialogRefDrvNotFound: MatDialogRef<DrivernotfoundComponent, any>;
  saveRequest() {
    //console.warn('saveRequest is click');


    if (this.CurrentState == PageState.OriginDefine) {
      this.setOrigin([this.currentLat, this.currentLng]);
      //this.map.showMarkerOnCenter = true;

    }
    else if (this.CurrentState == PageState.DestinationDefine) {
      this.setDestination([this.currentLat, this.currentLng]);
      //this.map.showMarkerOnCenter = false;

    }
    else if (this.CurrentState == PageState.RequestTrip) {
      //this.map.showMarkerOnCenter = false;

      let req = new ServiceRequest();
      req.id = 1;
      req.destinationLat = this.destinationPoint.lat;
      req.destinationLng = this.destinationPoint.ln;
      req.originLat = this.originPoint.lat;
      req.originLng = this.originPoint.ln;
      req.destinationAddr = this.destinationPoint.desc;
      req.destination2Addr = '';
      req.originAddr = this.originPoint.desc;
      req.passangerID = this.passangerID;
      //console.warn(req);

      this.requestSerivce.saveServiceReuest(req).subscribe(_res => {
        this.guidRequest = _res;
        this.socketService.sendMessage(_res, SocketMessageType.RequestServiceCreate);
        //console.warn(_res);
        this.openloadingBar();
      });
    }
  }

}



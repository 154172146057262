import { ActivatedRoute, Router } from '@angular/router';
import { ServiceState } from '../../globalVariable';
import { WebSocketService } from '../../lib/web-socket.service';
import { Service } from '../../model/service';
import { ServiceRequest } from '../../model/ServiceRequest';
import { NotificationModel, SocketMessageType } from '../../model/socketmodels';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaximapComponent } from '../../taximap/taximap.component';
import { MarkerType } from '../../lib/markerHelper';
import { DomSanitizer } from '@angular/platform-browser';
import { FinishserviceComponent } from '../finishservice/finishservice.component';
import { Location } from '@angular/common';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { TaxiserviceService } from '../../taxiservice.service';
import { CancelbydriverComponent } from '../cancelbydriver/cancelbydriver.component';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-goorigin',
  templateUrl: './goorigin.component.html',
  styleUrls: ['./goorigin.component.scss']
})
export class GooriginComponent implements OnInit, OnDestroy {
  showBottomDiv = true;

  currentService = new Service()
  currentRequest = new ServiceRequest()
  guidService = ''
  CurrentState: ServiceState;
  dialogfinishRef: MatDialogRef<FinishserviceComponent, any>;
  guidDriver = '';
  showDetails = true;
  @ViewChild('qwe', { static: true }) template: TemplateRef<any>;


  @ViewChild("map", { static: false })
  public map: TaximapComponent;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private requestSerivce: ServiceRequestService,
    private serviceService: ServiceserviceService,
    private socketService: WebSocketService,
    readonly bottomSheet: MatBottomSheet,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private _location: Location,
    private stationSerivce: StationserviceService,
    private matSnackbar: MatSnackBar,
    private taxiService: TaxiserviceService,
  ) {


    //this.socketService.meesageList$.subscribe(announcement => {
    //  if (announcement) {
    //    this.processReceivedSocketMessage(announcement);
    //  }
    //});
  }
  ngAfterViewInit() {
    
  }
  ngOnInit() {
    this.open({
      panelClass: 'bottom-sheet-overflow'
    });
    this.CurrentState = ServiceState.GotoOrigin;
    this.route.queryParams.subscribe(params => {
      this.loadData(params['guid']);
      this.guidDriver = localStorage.getItem(environment.cur_u_name);
      console.log("Guid Driver is : " + this.guidDriver);
      const state = params['state'];
      ////console.log('state');
      //console.log(state);

      if (state == 3) {
        this.matSnackbar.open('اطلاعات سفر ویرایش گردید.', null, { duration: 2000 });
        return;
      }




    });
  }
  open(config?: MatBottomSheetConfig) {
    //return this.bottomSheet.open(this.template, config);
  }

  updateCurrentState(state: ServiceState) {
    this.checkState();
    this.CurrentState = state;
    this.serviceService.updateServiceState(this.currentService.serviceGUID, this.CurrentState).subscribe(_res => {
      this.currentService.serviceState = this.CurrentState;
      this.notifyServiceStateChange(this.currentService.serviceGUID, this.CurrentState);
    });
  }
  receiveOrigin() {
    this.updateCurrentState(ServiceState.OriginReceived)
  }
  arrivePassanger() {
    this.updateCurrentState(ServiceState.PassangerArrived)
  }
  finishedService() {
    ////console.log('finishedService');
    this.bottomSheet.dismiss();
    this.showBottomDiv = false;
    //this.router.navigate(['service/finish'], { queryParams: { guid: this.guidService } });
    this.dialogfinishRef = this.dialog.open(FinishserviceComponent, {
      width: '400px',
      hasBackdrop: true,
      disableClose: true,
      data: {
        guid: this.guidService,
        destination: this.currentRequest.destinationAddr,
        price: this.currentRequest.price,
        comment: this.currentRequest.requestComment

      }
    });
    this.dialogfinishRef.afterClosed().subscribe((result : any) => {
      //this.updateCurrentState(ServiceState.Finished);
      this.showBottomDiv = true;
      console.clear();
      console.log("Guid Driver is : " + this.guidDriver);

      this.router.navigate(['driver/test'], { queryParams: { guid: this.guidDriver } });
  
    });

  }
  showState(state: string): boolean {
    return (this.CurrentState == ServiceState.Finished && state == 'Finished') || (this.CurrentState == ServiceState.GotoOrigin && state == 'GotoOrigin') || (this.CurrentState == ServiceState.DriverFound && state == 'GotoOrigin')
      || (this.CurrentState == ServiceState.PassangerArrived && state == 'PassangerArrived') || (this.CurrentState == ServiceState.OriginReceived && state == 'OriginReceived');
  }
  correctPhone(phone:string): string {
    if (!phone || phone === '')
      return 'ثبت نشده';
    else if (phone.indexOf('3') == 0 && phone.length == 8)
      return '051' + phone;
    else if (phone.indexOf('9') == 0 && phone.length == 10)
      return '0' + phone;
    else
      return phone;



      
  }

  callMobile = '';
  loadData(guid : any) {
    
    this.guidService = guid;
    this.serviceService.getService(this.guidService).subscribe(_res => {
      ////console.log('loadData response is : ');
      //console.log(_res);

      this.currentService = _res;
      this.currentService.safedriverAvatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + this.currentService.driverAvatar);

      this.requestSerivce.getRequestServiceByID(this.currentService.requestID).subscribe(_resReq => {
        console.log(this.currentRequest);
        this.currentRequest = _resReq;
        console.log(this.currentRequest);
        if (!this.currentRequest.typePardakhtName || this.currentRequest.typePardakhtName == '')
          this.currentRequest.typePardakhtName = 'نقدی';

        if (this.currentRequest.passangerMobile && this.currentRequest.passangerMobile.length > 2)
          this.callMobile = this.correctPhone(this.currentRequest.passangerMobile);
        else if (this.currentRequest.telIDCaller && this.currentRequest.telIDCaller.length > 2)
          this.callMobile = this.correctPhone(this.currentRequest.telIDCaller);
        this.showmapdetails();
      });
      this.checkState(true);

    });

  }
  public notifyServiceStateChange(guid: any, newState: ServiceState) {
    var typeMsg: SocketMessageType;
    switch (newState) {
      case ServiceState.OriginReceived:
        typeMsg = SocketMessageType.ServiceState_OriginReceived;
        break;
      case ServiceState.PassangerArrived:
        typeMsg = SocketMessageType.ServiceState_PassangerArrived;
        break;
      default:
    }
    //this.socketService.sendMessage(guid, SocketMessageType.ServiceState_OriginReceived);
  }
  openNeshan() {
    window.open("https://neshan.org/", '_blank').focus();

  }
  dialogProposedServiceRef: MatDialogRef<CancelbydriverComponent, any>;
  cancel() {
    const showbot = this.showBottomDiv;
    this.showBottomDiv = false;
    this.dialogProposedServiceRef = this.dialog.open(CancelbydriverComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        guidService: this.guidService,
        serviceInfo : this.currentService
      }
    });
    this.dialogProposedServiceRef.afterClosed().subscribe(_confirmed => {
      this.showBottomDiv = showbot;
      if (_confirmed == 'CancelTrip')
        this.router.navigate(['driver/test'], { queryParams: { guid: this.guidDriver } });
    });
  }
  processReceivedSocketMessage(messageBox: NotificationModel) {
    //console.warn('msg is received')
    //console.warn(messageBox)
    switch (messageBox.msgType) {
      case SocketMessageType.GetClientInfo:
        break;
    }
  }
  checkState(setstate=false) {
    
    if (this.guidService) {
      
      this.taxiService.serviceGetLastState(this.guidService).subscribe(_res => {
        //console.log(_res);
        if (_res == 1001)
        {
          //console.log('اطلاعات سفر ویرایش گردید');
          this.loadData(this.guidService);
          this.matSnackbar.open('اطلاعات سفر ویرایش گردید', null, { duration: 3000 });
            
        }
        else if (_res >= 6) {
          //console.log(' service _res >= 6)');
          this.router.navigate(['driver/test'], { queryParams: { guid: this.guidDriver } });
        }
        else {
          if (setstate)
            this.CurrentState = _res;
        }
        ////console.log('checkState' + this.CurrentState+ ' res is ' + _res);
      });
      
    }
    else {
      ////console.log('checkState false');
    }
  }
  saveServiceInfo() {
    this.checkState();
    //console.log('updateServiceState');
    this.serviceService.updateServiceState(this.currentService.serviceGUID, this.CurrentState).subscribe(_res => {

      //console.log('updateServiceState subscribe ');
      this.currentService.serviceState = this.CurrentState;
      this.notifyServiceStateChange(this.currentService.serviceGUID, this.CurrentState);
    });
  }
  originMarker: any;
  destinationMarker: any;
  schedulJon: any;
  ngOnDestroy() {
    if (this.schedulJon) {
      clearInterval(this.schedulJon);
    }
  }
  showmapdetails() {
    this.checkState();
    //console.log(this.currentRequest);
    //console.log(this.currentService);
    this.originMarker = this.map.addMarker(this.currentRequest.originLat, this.currentRequest.originLng, MarkerType.origin);

    this.destinationMarker = this.map.addMarker(this.currentRequest.destinationLat, this.currentRequest.destinationLng, MarkerType.destination);
    ////console.log('this.destinationMarker');
    //console.log(this.destinationMarker);
    this.map.locate();
    this.schedulJon = setInterval(() => {
      this.schedulingupdatestatus();
    }, 15 * 1000);
  }
  schedulingupdatestatus() {
    //console.log('schedulingupdatestatus');
    this.checkState();
    this.map.locate(false, MarkerType.taxi);
    
  }
  openRouting() {
    this.checkState();
    var url = '';
    window.open(url, "_blank");
  }

}

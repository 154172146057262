import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';


import { PassangerserviceService } from '../../lib/serviceLib/passangerservice.service';
import { Observable } from 'rxjs';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { OperatorTable } from '../../model/operatorTable';
import Swal from "sweetalert2";


@Component({
  selector: 'app-register-passanger',
  templateUrl: './register-passanger.component.html',
})
export class RegisterPassangerComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() dataItem: any = {};
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSendToServer = new EventEmitter<any>();
  passangerFormGroup = new FormGroup({
    fName: new FormControl(),
    lName: new FormControl(),
    mobile: new FormControl(),
    nationalCode: new FormControl()

  });
  operatorguid: string;
  id: string = '';

  operatorInfo: OperatorTable;
  constructor(
    private passangerServiceService: PassangerserviceService,
    private callCenterService: CallCenterService,

    private route: ActivatedRoute,
    private router: Router
  ) {


  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.id = params['valp'];
      if (this.id) {
        this.loadpassangerInfo(this.id);
      }
      this.loadOperatorInfo(this.operatorguid);

    });
  }

  loadOperatorInfo(guidCode: any): Observable<any> {
    return new Observable((observer) => {
      this.callCenterService.getOperatorInfo(guidCode).subscribe(res => {
        this.operatorInfo = res;
      });
    });
  }


  loadpassangerInfo(id: any){
      this.passangerServiceService.get(id).subscribe(res => {
        this.passangerFormGroup.setValue({ fName: res.fName, lName: res.lName, nationalCode: res.nationalCode, mobile: res.mobile });

      });
  }

  registerPassanger() {

    this.passangerServiceService.insertPassanger(this.passangerFormGroup.get('fName')?.value, this.passangerFormGroup.get('lName')?.value, this.passangerFormGroup.get('mobile')?.value, this.passangerFormGroup.get('nationalCode')?.value,this.id).subscribe(res => {

      if (res) {
        this.router.navigate(['cc/passangers'], { queryParams: { guid: this.operatorguid } });
        Swal.fire('ثبت شد', 'مشترک با موفقیت ثبت شد');
      }
      else {
        Swal.fire('خطا', 'خطا در ثبت مشترک');

      }

    });;


  }
  resetForm() {
    this.passangerFormGroup.reset();
  }
}

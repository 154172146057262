import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceInfo } from '../../model/serviceInfo';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IShift } from '../../model/shiftTable';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { Driver } from '../../model/driver';


@Component({
  selector: 'app-myshift',
  templateUrl: './myshift.component.html',
  styleUrls: ['../shiftlisting/shiftlisting.component.css']
})
export class MyshiftComponent implements OnInit {
  list: IShift[] = [];
  driverID = 0;
  myInfo: Driver;
  constructor(
    private dialog: MatDialog,
    private matSnackbar: MatSnackBar,

    public serviceService: DriverserviceService,
    public dialogRef: MatDialogRef<MyshiftComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      driverID: number,
      myInfo: Driver
    }
  ) {
    this.driverID = data.driverID;
    this.myInfo = data.myInfo;
    console.log('this.driverID is' + this.driverID);
    this.serviceService.getShiftTable(this.driverID).subscribe(res => {
      this.list = res;
      

    });
  }

  ngOnInit(): void {

  }
  close() {
    this.dialogRef.close('closse');

  }
}

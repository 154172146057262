import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatorTable } from '../../model/operatorTable';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { NotifalertserviceService } from '../../shared/notifalertservice.service';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-master-cc-web',
  templateUrl: './master-cc-web.component.html',
  styleUrls: ['./master-cc-web.component.scss']
})
export class MasterCcWebComponent implements OnInit, OnDestroy {
  @ViewChild("drawer", { static: false })
  public drawer: MatSidenav;

  constructor(
    private callCenterService: CallCenterService,
    private route: ActivatedRoute,
    private router: Router,
    private notifAlert: NotifalertserviceService
  ) {
    this.notifAlert.sharedData.subscribe(data => { this.changedRecived(data); })

  }
  ngOnDestroy() {
    this.notifAlert.sharedData.next("");
    this.notifAlert.sharedData.complete();
  }


  changedRecived(data: any) {
    if (data == 'savenew') {
      this.loadOperatorInfo(this.operatorguid);
    }
    //console.log(data +' recived as master')
  }
  operatorInfo = new OperatorTable();
  operatorguid: string;
  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.loadOperatorInfo(this.operatorguid);
    });

  }
  loadOperatorInfo(guidCode: any) {
    this.callCenterService.getOperatorInfo(guidCode).subscribe(res => {
      if (res) {
        this.operatorInfo = res;
        //console.log(this.operatorInfo);
      } else {

        this.router.navigate(['login'], { queryParams: {} });
      }
    });
  }
  signout() {

    this.router.navigate(['login'], { queryParams: {} });
  }
  menuItemClick(item: any) {


    if (item == 'dashboard') {
      this.router.navigate(['cc/dashboard'], { queryParams: { guid: this.operatorguid } });
    }
    else
      if (item == 'newForm') {
        this.router.navigate(['cc/new'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'searchForms') {
        this.router.navigate(['cc/searchforms'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'formsList') {
        this.router.navigate(['cc/formslist'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'regComplaint') {
        this.router.navigate(['cc/regComplaint'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'regFounded') {
        this.router.navigate(['cc/regFounded'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'founded') {
        this.router.navigate(['cc/founded'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'bisim') {
        this.router.navigate(['cc/manage'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'operatosmanage') {
        this.router.navigate(['cc/operators'], { queryParams: { guid: this.operatorguid } });

      }
      else if (item == 'manage') {
        this.router.navigate(['cc/manageall'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'managedrivers') {
        this.router.navigate(['cc/drivers'], { queryParams: { guid: this.operatorguid } });
      }
      else if (item == 'newWebForm') {
        this.router.navigate(['cc/newweb'], { queryParams: { guid: this.operatorguid } });
      }


    this.drawer.toggle()

  }

}


import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { GlobalVariable } from '../../globalVariable';
import { Observable } from 'rxjs';
import { FoundedObject } from '../../model/foundedObject';

@Injectable({
  providedIn: 'root'
})
export class FoundedObjectserviceService {
  constructor(
    private httpClient: HttpClient

  ) { }


  public insertFoundedObject(requestID: any,passangerFName :string,passangerMobile :string, originAddr: string,destinationAddr: string,driverCode: string,serviceDateTime:string,description: string, creatorUserID :any): Observable<boolean> {
  
    let params = new HttpParams().set('requestID', requestID).set('description', description).set('creatorUserID', creatorUserID)
      .set('passangerFName', passangerFName).set('passangerMobile', passangerMobile)
      .set('originAddr', originAddr).set('destinationAddr', destinationAddr)
      .set('driverCode', driverCode).set('serviceDateTime', serviceDateTime)
      ;

    return this.httpClient.post<boolean>(GlobalVariable.FoundedObjectBaseServiceURL + "/InsertFoundedObject", params);
  }
  public reportAll(): Observable<FoundedObject[]> {
  
    let params = new HttpParams()
    return this.httpClient.post<FoundedObject[]>(GlobalVariable.FoundedObjectBaseServiceURL + "/ReportAll", params);
  }

  public getTop(): Observable<FoundedObject[]> {

    let params = new HttpParams()
    return this.httpClient.post<FoundedObject[]>(GlobalVariable.ComplaintBaseServiceURL + "/GetTop", params);
  }

  public getListDynamic(whereCondition: string, orderByExpression: string): Observable<FoundedObject[]> {
    let params = new HttpParams().set('whereCondition', whereCondition).set('orderByExpression', orderByExpression);
    return this.httpClient.post<FoundedObject[]>(GlobalVariable.FoundedObjectBaseServiceURL + "/getListDynamic", params);
  }


  public reportAllFoundedObject(passangerFName: any, passangerMobile: any, driverCode: any, originAddr: any, destinationAddr: any, description: any): Observable<FoundedObject[]> {
    if (typeof passangerFName == 'undefined' || !passangerFName) {
      passangerFName = '';
    }
    if (typeof originAddr == 'undefined' || !originAddr) {
      originAddr = '';
    }
    if (typeof passangerMobile == 'undefined' || !passangerMobile) {
      passangerMobile = '';
    }
    if (typeof driverCode == 'undefined' || !driverCode) {
      driverCode = '';
    }
    if (typeof destinationAddr == 'undefined' || !destinationAddr) {
      destinationAddr = '';
    }
    if (typeof description == 'undefined' || !description) {
      description = '';
    }
    let params = new HttpParams().set('passangerFName', passangerFName).set('passangerMobile', passangerMobile).set('driverCode', driverCode).set('originAddr', originAddr).set('destinationAddr', destinationAddr).set('description', description);
    return this.httpClient.post<FoundedObject[]>(GlobalVariable.FoundedObjectBaseServiceURL + "/ReportAllFoundedObject", params);
  }
}


import { Injectable } from '@angular/core';
import { HttpClient,  HttpParams } from '@angular/common/http';

import { GlobalVariable } from '../../globalVariable';
import { Observable, Subscriber } from 'rxjs';
import { Service } from '../../model/service';
import { AllServiceInfo, ServiceInfo } from '../../model/serviceInfo';
import { ServiceRequest } from '../../model/ServiceRequest';
import { Passanger } from '../../model/passanger';
import { SaveServiceResult } from '../../model/saveserviceresult';

@Injectable({
  providedIn: 'root'
})
export class ServiceserviceService {
  constructor(
    private httpClient: HttpClient

  ) { }
  public moke = GlobalVariable.mokeFLAG;

  private ServiceBaseServiceURL = GlobalVariable.ServiceBaseServiceURL;
  private getMokeService(index = 1) {
    var res = new Service();
    res.carTypeName = 'پراید'
    res.colorTypeName = 'زرد'
    res.driverFName = 'محمد'
    res.driverLName = 'محمد زده'
    res.pelaque2 = '12'
    res.pelaque3 = '123'
    res.pelaqueIR = '23';
    res.pelaqueLetter = 'ج'
    res.driverMobile = '09337926212'
    res.passangerFName = 'بهروز'
    res.passangerLName = 'مدیری'
    res.passangerMobile = '09158521458'


    return res;
  }
  public getService(guidService: any): Observable<Service> {
    if (this.moke) {
      return new Observable<Service>((subscriber: Subscriber<Service>) => subscriber.next(this.getMokeService()));
    }
    let params = new HttpParams().set('guidService', guidService);
    return this.httpClient.post<Service>(this.ServiceBaseServiceURL + "/getService", params);
  }

  public getServiceByID(serviceID: any): Observable<Service> {
  
    let params = new HttpParams().set('serviceID', serviceID);
    return this.httpClient.post<Service>(this.ServiceBaseServiceURL + "/getServiceByID", params);
  }

  public updateServiceState(serviceGUID: any, state: any): Observable<any> {
    if (this.moke) {
      return new Observable<void>((subscriber: Subscriber<void>) => subscriber.next());
    }
    //console.log('updateServiceState');
    //console.log('serviceGUID:' + serviceGUID+'state'+state);
    let params = new HttpParams().set('serviceGUID', serviceGUID).set('state', state);
    return this.httpClient.post(this.ServiceBaseServiceURL + "/updateServiceState", params);
  }

  public cancelServiceByRequest(requestID: any): Observable<any> {
    if (this.moke) {
      return new Observable<void>((subscriber: Subscriber<void>) => subscriber.next());
    }
    let params = new HttpParams().set('requestID', requestID);
    return this.httpClient.post(this.ServiceBaseServiceURL + "/cancelRequest", params);
  }

  public report(passangerID: any): Observable<ServiceInfo[]> {
    let params = new HttpParams().set('PassangerID', passangerID);
    return this.httpClient.post<ServiceInfo[]>(this.ServiceBaseServiceURL + "/Report", params);
  }
  public reportAll(): Observable<AllServiceInfo[]> {
    let params = new HttpParams();
    return this.httpClient.post<AllServiceInfo[]>(this.ServiceBaseServiceURL + "/ReportAll", params);
  }
  public reportAllService(guid: any, tel: any, driverCode: any, stationID: any, passangerFName: any, passangerAddress: any, selectedStateNumbers: any,
    dateAz: string, dateTa: string,
    searchKeyWord:string

  ): Observable<AllServiceInfo[]> {
    if (typeof stationID == 'undefined' || !stationID) {
      stationID = 0;
    }
    if (typeof guid == 'undefined' || !guid) {
      guid = '';
    }
    if (typeof tel == 'undefined' || !tel) {
      tel = '';
    }
    if (typeof driverCode == 'undefined' || !driverCode) {
      driverCode = '';
    }
    if (typeof passangerFName == 'undefined' || !passangerFName) {
      passangerFName = '';
    }
    if (typeof passangerAddress == 'undefined' || !passangerAddress) {
      passangerAddress = '';
    }

    if (typeof selectedStateNumbers == 'undefined' || !selectedStateNumbers) {
      selectedStateNumbers = '';
    }
    let params = new HttpParams().set('guid', guid).set('Tel', tel).set('DriverCode', driverCode).set('StationID', stationID).set('PassangerFName', passangerFName).set('PassangerAddress', passangerAddress).set('StateNumbers', selectedStateNumbers.toString()).set('DateAz', dateAz).set('DateTa', dateTa).set('searchKeyWord', searchKeyWord)
    
    return this.httpClient.post<AllServiceInfo[]>(this.ServiceBaseServiceURL + "/ReportAllService", params);
  }

  public reportQueue(queueID: any): Observable<ServiceInfo[]> {
    let params = new HttpParams().set('QueueID', queueID);
    return this.httpClient.post<ServiceInfo[]>(this.ServiceBaseServiceURL + "/ReportQueue", params);
  }
  public reportDriver(driverID: any): Observable<ServiceInfo[]> {
    let params = new HttpParams().set('DriverID', driverID);
    return this.httpClient.post<ServiceInfo[]>(this.ServiceBaseServiceURL + "/ReportDriver", params);
  }
  public UpdateFinish(ServiceGUID: any, Price: any, Anam: any, CountOfPassangers: any, DriverVoteToPassanger: any) {
    let params = new HttpParams().set('ServiceGUID', ServiceGUID);
    params = params.set('Price', Price);
    params = params.set('CountOfPassangers', CountOfPassangers);
    params = params.set('DriverVoteToPassanger', DriverVoteToPassanger);
    params = params.set('Anam', Anam);
    return this.httpClient.post(this.ServiceBaseServiceURL + "/UpdateFinish", params);

  }



  public UpdateFinishWithDestination(ServiceGUID: any, Price: any, Anam: any, Destination: any, Comment) {
    let params = new HttpParams().set('ServiceGUID', ServiceGUID);
    params = params.set('Price', Price);
    params = params.set('Anam', Anam);
    params = params.set('Destination', Destination);
    params = params.set('Comment', Comment);
    return this.httpClient.post(this.ServiceBaseServiceURL + "/UpdateFinishWithDestination", params);
  }

  public UpdatePrice(ServiceID: any, Price: any) {
    //let params = new HttpParams().set('ServiceID', ServiceID);
    //params = params.set('Price', Price);
    
    //return this.httpClient.post(this.ServiceBaseServiceURL + "/UpdatePrice", params);

    let params = new HttpParams().set('sid', ServiceID);
    params = params.set('Price', Price);

    return this.httpClient.post(GlobalVariable.TestBaseServiceURL + "/updateTest_UpdatePrice", params);

  }
  public UpdateAnam(ServiceID: any, Anam: any) {
    let params = new HttpParams().set('ServiceID', ServiceID);
    params = params.set('Anam', Anam);

    return this.httpClient.post(this.ServiceBaseServiceURL + "/UpdateAnam", params);
  }
  public UpdateDestination(ServiceID: any, Destination: any) {
    //let params = new HttpParams().set('ServiceID', ServiceID);
    //params = params.set('Destination', Destination);

    //return this.httpClient.post(this.ServiceBaseServiceURL + "/UpdateDestination", params);

    let params = new HttpParams().set('sid', ServiceID);
    params = params.set('dest', Destination);

    return this.httpClient.post(GlobalVariable.TestBaseServiceURL + "/updateTest_dest", params);


  }
  public UpdateRequestComment(ServiceID: any, RequestComment: any) {
    //let params = new HttpParams().set('ServiceID', ServiceID);
    //params = params.set('RequestComment', RequestComment);

    //return this.httpClient.post(this.ServiceBaseServiceURL + "/UpdateRequestComment", params);

    let params = new HttpParams().set('sid', ServiceID);
    params = params.set('rc', RequestComment);

    return this.httpClient.post(GlobalVariable.TestBaseServiceURL + "/updateTest_reqCom", params);

  }
 






  public UpdateTypePardakht(ServiceID: any, TypePardakht: any) {
    //let params = new HttpParams().set('ServiceID', ServiceID);
    //params = params.set('TypePardakht', TypePardakht);
    //return this.httpClient.post(this.ServiceBaseServiceURL + "/UpdateTypePardakht", params);

    let params = new HttpParams().set('sid', ServiceID);
    params = params.set('tp', TypePardakht);
    return this.httpClient.post(GlobalVariable.TestBaseServiceURL + "/updateTest", params);
  }



  
 
  public saveService(req: ServiceRequest, psng: Passanger, stationID: number, mode: string, serviceCount: any, transID: any,

    reserveSelectableDate = false, tarikhReserved = '', timeRserved = '',
    price=0

  ): Observable<SaveServiceResult> {
    let params = new HttpParams().set('originlat', req.originLat.toString())
    params = params.set('originlng', req.originLng.toString())
    params = params.set('destinationLat', req.destinationLat.toString())
    params = params.set('destinationLng', req.destinationLng.toString())
    params = params.set('destinationLat2', req.destinationLat2.toString())
    params = params.set('destinationLng2', req.destinationLng2.toString())
    
    params = params.set('destination2Addr', req.destination2Addr ? req.destination2Addr.toString() : '')
    params = params.set('destinationAddr', req.destinationAddr ? req.destinationAddr.toString():'')
    params = params.set('originAddr', req.originAddr ? req.originAddr.toString():'');
    params = params.set('originAddr', req.originAddr ? req.originAddr.toString() : '');
    if (psng) {
      params = params.set('passangerFName', psng.fName);
      params = params.set('passangerMobile', psng.mobile);
      if (psng.id)
        params = params.set('passangerID', psng.id.toString())
      else
        params = params.set('passangerID', '-1')

    }
    
    params = params.set('StationID', stationID.toString());
    params = params.set('maeloolin', req.maeloolin.toString());
    params = params.set('amanati', req.amanati.toString());
    params = params.set('khadamat', req.khadamat.toString());
    params = params.set('pos', req.pos.toString());
    params = params.set('telIDCaller', req.telIDCaller.toString());
    params = params.set('operatorID', req.operatorID.toString());
    params = params.set('darEkhtiar', req.darEkhtiar.toString());
    params = params.set('transID', transID);
    
    
    params = params.set('typeCars', req.typeCars&&req.typeCars.toString()||'');
    params = params.set('driverCode', req.driverCode);
    params = params.set('fixedComment', req.fixedComment);
    params = params.set('requestComment', req.requestComment&&req.requestComment||'');
    params = params.set('male', req.maleDriver.toString());
    params = params.set('female', req.femaleDriver.toString());
    params = params.set('mode', mode);
    params = params.set('requestID', req.id ? req.id.toString():'');
    params = params.set('serviceCount', serviceCount);
    params = params.set('serviceCount', serviceCount);
    params = params.set('moarefID', req.moarefID);
    params = params.set('typeService', req.typeService);
    params = params.set('typePardakht', req.typePardakht);
    
    params = params.set('reserveSelectableDate', String(reserveSelectableDate));
    params = params.set('tarikhReserved', tarikhReserved);
    params = params.set('timeRserved', timeRserved);
    params = params.set('price', price.toString());

    
    //return this.httpClient.post<string>(this.RequestServiceBaseServiceURL + "/saverequest", params,{ responseType: 'text' as 'json' });

    return this.httpClient.post<SaveServiceResult>(this.ServiceBaseServiceURL + "/SaveService", params);
  }
  public saveServiceSimple(destinationAddr, operatorID, requestComment, serviceCount, price, darEkhtiar
    , driverCode = '', typePardakht='1019', stationCode='1'
  ): Observable<SaveServiceResult> {
    let params = new HttpParams().set('destinationAddr', destinationAddr)
    params = params.set('operatorID', operatorID);
    params = params.set('requestComment', requestComment && requestComment || '');
    params = params.set('serviceCount', serviceCount);
    params = params.set('price', price);
    params = params.set('driverCode', driverCode);
    params = params.set('typePardakht', typePardakht);
    params = params.set('DarEkhtiar', darEkhtiar);
    params = params.set('StationCode', stationCode);
    

    //return this.httpClient.post<string>(this.RequestServiceBaseServiceURL + "/saverequest", params,{ responseType: 'text' as 'json' });

    return this.httpClient.post<SaveServiceResult>(this.ServiceBaseServiceURL + "/SaveServiceSimple", params);
  }

  public saveServiceManualByAdmin(requestGUID: any, driverCode: any, actionType: any): Observable<SaveServiceResult> {
    let params = new HttpParams().set('RequestGUID', requestGUID)
    params = params.set('DriverCode', driverCode)
    params = params.set('ActionType', actionType)
    return this.httpClient.post<SaveServiceResult>(this.ServiceBaseServiceURL + "/SaveServiceManualByAdmin", params);
  }


  public ReProposedRequest(requestID: any): Observable<SaveServiceResult> {
    let params = new HttpParams().set('requestID', requestID.toString())
    return this.httpClient.post<SaveServiceResult>(this.ServiceBaseServiceURL + "/ReProposedRequest", params);
  }
  public alertWanReady() {
   
    return this.httpClient.post<SaveServiceResult>(this.ServiceBaseServiceURL + "/SendAlaramVanReady", {});
  }
  

  public exitDriver(driverCode: any): Observable<number> {

    let params = new HttpParams().set('driverCode', driverCode);
    return this.httpClient.post<number>(this.ServiceBaseServiceURL + "/ExitDriver", params);
  }
  public finishService(serviceGuid: any ): Observable<number> {

    let params = new HttpParams().set('serviceGuid', serviceGuid);
    return this.httpClient.post<number>(this.ServiceBaseServiceURL + "/FinishService", params);
  }

  public cancelService(guidService: any, newServiceState: any): Observable<number> {

    let params = new HttpParams().set('guidService', guidService);
    params = params.set('newServiceState', newServiceState);
    return this.httpClient.post<number>(this.ServiceBaseServiceURL + "/CancelService", params);
  }

  public reservedService(ServiceRequestID: any, StationID: any, date: any, time: any): Observable<number>{
   
    let params = new HttpParams().set('ServiceRequestID', ServiceRequestID);
    params = params.set('StationID', StationID);
    params = params.set('time', time);
    params = params.set('date', date);
    return this.httpClient.post<number>(this.ServiceBaseServiceURL + "/doreserved", params);
  }


}


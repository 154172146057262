import { Component } from '@angular/core';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { Driver } from '../../model/driver';
import { IShift } from '../../model/shiftTable';

@Component({
  selector: 'app-shift-scheduler',
  templateUrl: './shift-scheduler.component.html',
  styleUrls: ['./shift-scheduler.component.css']
})
export class ShiftSchedulerComponent {
  startNumber: number = 1; // شماره شروع
  startNumber_Showing: number = 1; // از این برای نمایش استفاده می شود
  endNumber: number = 1; // شماره شروع
  skeepFirstCount: number = 0;
  monthNumber: number = 11;   // شماره ماه
  dayInWeekNumber: string = '0';   // روز شروع هفته
  rowCount: number = 17;    // تعداد سطرها
  tableData: IShift[] = []; // داده‌های جدول (می‌تواند شامل اعداد یا null باشد)
  tableGenerated: boolean = false; // آیا جدول ساخته شده است؟

  // آرایه‌ها برای ذخیره کد و نام راننده‌ها
  driverCodes: string[] = [];
  driverNames: string[] = [];
  driverList: Driver[] = [];
  AvalShab :string = 'AvalShab';
  constructor(
    private driverService: DriverserviceService

  ) {

  }
  // تابع برای ایجاد جدول
  generateTable() {
    this.startNumber_Showing = this.startNumber;
    this.tableData = [];
    this.driverCodes = new Array(this.rowCount).fill(''); // مقداردهی اولیه کد راننده‌ها
    this.driverNames = new Array(this.rowCount).fill(''); // مقداردهی اولیه نام راننده‌ها
    let currentNumber = this.startNumber;

    // ساخت جدول با شمارنده‌های عمودی
    for (let i = 0; i < this.rowCount; i++) {
      let row: IShift = new IShift();
      row.date_dayNumber1 = this.startNumber + i;
      if (row.date_dayNumber1 + this.rowCount <= this.endNumber)
          row.date_dayNumber2 = row.date_dayNumber1 + this.rowCount;
      this.tableData.push(row);
    }

    this.tableGenerated = true; // جدول ساخته شد
  }

  // تابع برای زمانی که کاربر از اینپوت کد راننده خارج می‌شود
  onDriverCodeBlur(row: IShift, index: number) {
    const driverCode = index == 1 ? row.driver1Code : row.driver2Code;
    if (driverCode) {
      this.driverService.getDriverByCode(driverCode).subscribe(drv => {
        if (drv) {
          if (index == 1)
            row.driver1Name = drv;
          else
            row.driver2Name = drv;
        }

      })
      // اینجا می‌توانید API را فراخوانی کنید و نام راننده را دریافت کنید
      // برای مثال:
      // this.fetchDriverName(driverCode).then(name => this.driverNames[index] = name);
      console.log(`کد راننده وارد شده: ${driverCode}`);
    }
  }

  // تابع برای چاپ جدول
  printTable() {
    window.print();
  }
  getDayName(shift:IShift ,colIndex:number) {
    if (shift) {
      var rowIndex = shift.date_dayNumber1;
      if (colIndex == 1) {
        rowIndex = shift.date_dayNumber1;
        if (this.monthNumber != shift.monthNumber1)
          rowIndex = rowIndex + this.endNumber;
      }
      else {
        rowIndex = shift.date_dayNumber2;
        if (shift.monthNumber2 != shift.monthNumber1)
          rowIndex = rowIndex + this.endNumber;
      }

      var dIndex = ((rowIndex - this.startNumber_Showing)+ parseInt(this.dayInWeekNumber) ) % 7;
      if (dIndex == 0)
        return 'شنبه';
      if (dIndex == 6)
        return 'جمعه';

      return dIndex + ' شنبه';
    }
    else
      return '';

  }
  autoschedule() {
    debugger;
    this.startNumber_Showing = this.startNumber;
    this.driverService.getAllDriverForSchedule().subscribe(t => {
      if (!this.skeepFirstCount)
        this.skeepFirstCount = 0;
      this.driverList = t.splice(this.skeepFirstCount);
      if (this.monthNumber >= 1 && this.monthNumber <= 6)
        this.endNumber = 31;
      else if (this.monthNumber<12)
        this.endNumber = 30;
      else
        this.endNumber = 29;

      this.tableData = [];
      this.rowCount = this.driverList.length;
      // ساخت جدول با شمارنده‌های عمودی
      for (let i = 0; i < this.rowCount; i++) {
        let row: IShift = new IShift();
        row.date_dayNumber1 = this.startNumber + i;
        
        row.monthNumber1 = this.monthNumber;
        if (row.date_dayNumber1 > this.endNumber) {
          row.date_dayNumber1 = row.date_dayNumber1 - this.endNumber;
          row.monthNumber1 = this.monthNumber + 1;
        }
       
        row.date_dayNumber2 = row.date_dayNumber1 + this.rowCount;
        if (row.date_dayNumber2  >  this.endNumber) {
          row.monthNumber2 = row.monthNumber1 + 1;
        }
        else
          row.monthNumber2 = row.monthNumber1;
        if (row.monthNumber2 >  12)
          row.monthNumber2 = row.monthNumber2  - 12;

        this.tableData.push(row);
      }

      for (let i = 0; i < this.rowCount; i++) {
        var row = this.tableData[i];
        if (this.AvalShab == 'AvalShab') {
          row.driver2Code = this.driverList[i].driverCode;
          row.driver2Name = this.driverList[i].fName
          row.driver2 = this.driverList[i];
        }
        else {
          row.driver1Code = this.driverList[i].driverCode;
          row.driver1Name = this.driverList[i].fName
          row.driver1 = this.driverList[i];
        }
       
      }
      const mid = Math.floor(this.rowCount / 2);
      var counter = 0;
      for (let i = mid; i < this.rowCount; i++) {
        if (this.AvalShab == 'AvalShab') {
          this.tableData[i].driver1 = this.driverList[counter];
          this.tableData[i].driver1Name = this.driverList[counter].fName;
          this.tableData[i].driver1Code = this.driverList[counter].driverCode;
        }
        else {
          this.tableData[i].driver2 = this.driverList[counter];
          this.tableData[i].driver2Name = this.driverList[counter].fName;
          this.tableData[i].driver2Code = this.driverList[counter].driverCode;
        }
        
        counter++;
      }
      counter = 1;
      if (this.rowCount % 2 == 0)
        counter = 0;
      
      for (let i = 0; i < mid; i++) {
        if (this.AvalShab == 'AvalShab') {
          this.tableData[i].driver1 = this.driverList[counter + mid];
          this.tableData[i].driver1Name = this.driverList[counter + mid].fName;
          this.tableData[i].driver1Code = this.driverList[counter + mid].driverCode;
        }
        else {
          this.tableData[i].driver2 = this.driverList[counter + mid];
          this.tableData[i].driver2Name = this.driverList[counter + mid].fName;
          this.tableData[i].driver2Code = this.driverList[counter + mid].driverCode;
        }
        counter++;
      }

      for (let i = 0; i < this.rowCount; i++) {
        row = this.tableData[i];
        if (row.date_dayNumber2 > this.endNumber)
          row.date_dayNumber2 = row.date_dayNumber2 - this.endNumber;
      }



      this.tableGenerated = true; // جدول ساخته شد

    });
  }
  saveShift() {
    this.driverService.saveShiftTable(this.tableData).subscribe(res => {
      console.log(res);
    });

    //
  }
}


import {Component, OnInit,AfterViewInit,ElementRef,ViewChild} from '@angular/core';

import {Router, ActivatedRoute} from '@angular/router';
import {ErrorOutputMessage} from '../../model/enumtype';
import {VerificationOutput} from '../../model/verificationOutput';
import {DriverserviceService} from '../../lib/serviceLib/driverservice.service';
import {SplitInputService} from 'ngx-splitinput';


@Component({
  selector: 'app-verifysms',
  templateUrl: './verifysms.component.html',
  styleUrls: ['./verifysms.component.css']
})
export class VerifysmsComponent implements OnInit, AfterViewInit {
  verificationCode = '';
  guidSms = '';
  guidDriver = '';
  errorMessage = '';
  @ViewChild('android')
  public android: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private driverSerivce: DriverserviceService,
    private splitInputService: SplitInputService
  ) {
  }

  ngOnInit() {
    //localStorage.setItem(environment.cur_url, window.location.href);

    this.route.queryParams.subscribe(params => {
      this.guidSms = params['guidS'];
      this.guidDriver = params['guidD'];
    });
  }

  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());
  }

  confirm() {
    let result: VerificationOutput;
    this.driverSerivce.checkVerification(this.verificationCode, this.guidSms, this.guidDriver).subscribe(_res => {
      result = _res;
      this.processResult(result);
      //console.warn(_res);
    });
  }

  processResult(result: VerificationOutput) {
    //console.log('verify resuylt');
    //console.log(result);
    if (result.result) {
      this.router.navigate(['/driver/confirm'], {queryParams: {guid: result.guidCode}});
    } else {
      this.errorMessage = ErrorOutputMessage.getText(result.error);
      this.guidSms = result.guidCode;
      //console.log(this.guidSms);
    }
  }

  handleCompleted(event: any): void {
    this.verificationCode = event;
    this.confirm();
    this.splitInputService.clearSplitInput();
  }
  backToLast() {

    this.router.navigate(['driver/new']);

  }
}

import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { TaximapComponent } from '../../taximap/taximap.component';
import { LocationInfo, PageState } from '../../globalVariable';
import { MarkerType } from '../../lib/markerHelper';
import { LatLng } from 'leaflet';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { Station } from '../../model/station';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';

@Component({
  selector: 'app-setorigindes',
  templateUrl: './setorigindes.component.html',
  styleUrls: ['./setorigindes.component.css']
})
export class SetorigindesComponent implements OnInit, AfterViewInit {
  currentStep: OriginDestinationStep = OriginDestinationStep.S1_Origin;

  constructor(
    private stationSerivce: StationserviceService,
    private taxiSerivce: TaxiserviceService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SetorigindesComponent>

  ) {
    console.log(data);

    this.dialogRef.backdropClick().subscribe(() => {
      this.returnData();
    });

  }
  @ViewChild("map", { static: false })
  public map: TaximapComponent;

  lstStation: Station[];
  driverCount = 0
  requestCount = 0;
  guidDriver: string = '';
  originLatLng: LatLng;
  destLatLng: LatLng;
  origin = ''
  des = ''

  moveMapEvent(evt: any) {
    if (this.locationMarker) {
      this.locationMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }
  ngOnInit(): void {
    this.map = new TaximapComponent();
  }
  ngAfterViewInit() {
    this.addinitialMarker();
    this.loadStation(true).subscribe(res => { });


  }
  moveEnd(e: any) {
    //console.log(e);
  }
  originPoint = new LocationInfo();
  locationMarker: any;
  originMarker: any;
  destMarker: any;

  addinitialMarker() {
    if (this.data.originLoc && this.data.originLoc.lat > 0) {
      this.currentStep = OriginDestinationStep.S2_Destination;
      this.addOriginMarker(this.data.originLoc);
    }
    if (this.data.destLoc && this.data.destLoc.lat > 0) {
      this.currentStep = OriginDestinationStep.S3_Start;

      this.addDestMarker(this.data.destLoc);
    }
    else {
      this.addLocationMarker();
      if (this.data.originLoc && this.data.originLoc.lat > 0) {
        this.currentStep = OriginDestinationStep.S2_Destination;
      }
      else {
        this.currentStep = OriginDestinationStep.S1_Origin;

      }


    }

    this.origin = this.data.origin;
    this.des = this.data.des;

  }
  removeLocationMarker() {
    this.map.removemarker(this.locationMarker);
    this.locationMarker = null;

  }
  addLocationMarker() {
    var loc = this.map.getCenter();
    var cord = [loc.lat, loc.lng]
    this.locationMarker = this.map.addMarker(loc.lat, loc.lng, MarkerType.initLocation);

  }
  loadStation(withMarker = true): Observable<any> {
    return new Observable((observer) => {
      this.stationSerivce.getStationList('', this.map.gpsLat, this.map.gpsLng).subscribe(_res => {
        console.log('loadStation')
        this.lstStation = _res;
        this.driverCount = 0;
        this.requestCount = 0;
        _res.forEach(station => {
          station.guidDriver = this.guidDriver;
          this.driverCount = this.driverCount + station.length;
          this.requestCount = this.requestCount + station.requestCount;
          if (withMarker) {
            this.map.addStationMarker(station);
          }
          else
            this.map.updateStationMarker(station);
        });
        observer.next(_res)
      });
    });
  }

  returnData() {
    this.dialogRef.close({
      'Action': 'Save',
      'originLoc': this.originLatLng,
      'destLoc': this.destLatLng,
      'origin': this.origin,
      'des': this.des
    });
    /*
     *   origin: this.passangerFormGroup.get('originAC')?.value,
        des: this.passangerFormGroup.get('destAC')?.value,
        originLoc:this.originLatLng,
        destLoc: this.destLatLng*/

  }
  setOrigin() {
    var loc = this.map.getCenter();
    this.addOriginMarker(loc);
    this.currentStep = OriginDestinationStep.S2_Destination;
    this.taxiSerivce.getAddressFromLocation(loc.lat, loc.lng).subscribe(_res => {
      this.origin = _res;
    });

  }
  changeOrigin() {
    this.removeOriginMarker();
    this.currentStep = OriginDestinationStep.S1_Origin;
    this.origin = null;


  }

  addOriginMarker(loc) {
    this.originLatLng = new LatLng(loc.lat, loc.lng);
    var cord = [loc.lat, loc.lng]
    this.originMarker = this.map.addMarker(this.originLatLng.lat, this.originLatLng.lng, MarkerType.origin);
  }
  removeOriginMarker() {
    this.originLatLng = null;
    this.map.removemarker(this.originMarker);
    this.originMarker = null;
  }
  addDestMarker(loc) {
    this.destLatLng = new LatLng(loc.lat, loc.lng);
    this.destMarker = this.map.addMarker(loc.lat, loc.lng, MarkerType.destination);
  }
  removeDestMarker() {
    this.destLatLng = null;
    this.map.removemarker(this.destMarker);
    this.destMarker = null;
  }
  setDest() {
    var loc = this.map.getCenter();
    this.addDestMarker(loc);
    this.currentStep = OriginDestinationStep.S3_Start;
    this.taxiSerivce.getAddressFromLocation(loc.lat, loc.lng).subscribe(_res => {
      this.des = _res;
    });
    this.removeLocationMarker();

  }

  changeDest() {
    this.removeDestMarker();
    this.currentStep = OriginDestinationStep.S2_Destination;
    this.des = null;
    this.addLocationMarker();
  }
  setFinish() {
    this.returnData();
  }
  setBack() {
    console.log('this.currentStep' + this.currentStep)
    if (this.currentStep == OriginDestinationStep.S3_Start) {
      this.changeDest();
    } else if (this.currentStep == OriginDestinationStep.S2_Destination) {
      this.changeOrigin();
    }
    else {
      this.returnData();
    }
  }
}
enum OriginDestinationStep {
  S1_Origin = 1,
  S2_Destination = 2,
  S3_Start = 3
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { Station } from '../../model/station';
import { Observable } from 'rxjs';
import { TaximapComponent } from '../../taximap/taximap.component';
import { LatLng, Marker } from 'leaflet';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { MarkerType } from '../../lib/markerHelper';
import { LocationInfo } from '../../globalVariable';
import { environment } from '../../../environments/environment.prod';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-stations',
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss']

})
export class StationsComponent implements OnInit, OnDestroy {
  @Input() visible: boolean = true;
  @Input() data: any = [];
  @Output() visibleChange = new EventEmitter();
  lstStation: Station[];
  @ViewChild("map", { static: false })
  public map: TaximapComponent;
  operatorguid: string;
  originPoint = new LocationInfo();
  originMarker: any;
  initMarker: Marker;
  destinationMarker: any;
  recordCount = 0;
  pageSize = 30;

  currentFBSToken = '';


  constructor(
    private stationSerivce: StationserviceService,
    private route: ActivatedRoute,
    private taxiSerivce: TaxiserviceService,
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,

    private router: Router

  ) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
    });

    this.map = new TaximapComponent();
    this.setEmptyStation();
    this.currentFBSToken = localStorage.getItem(environment.cur_fbsToken);

    this.loadStation(true).subscribe(res => { });

    //this.addinitialMarker();
  }



  addinitialMarker() {
    var loc = this.map.getCenter();
    this.initMarker = this.map.addMarker(loc.lat, loc.lng, MarkerType.station);
    this.getOriginAddressDesc(loc.lat, loc.lng);
    this.initMarker.on('click', (e: any) => { this.onStationMarkerClick(); });

  }
  getOriginAddressDesc(lat: any, lng: any) {
    var s = this.taxiSerivce.getAddressFromLocation(lat, lng);
    s.subscribe(_res => {
      this.originPoint.desc = _res;
    });
  }

  loadStation(withMarker = true): Observable<any> {
    return new Observable((observer) => {
      this.stationSerivce.getAllStations().subscribe(_res => {
        //console.log(_res);
        this.lstStation = _res;
        _res.forEach(station => {
          if (withMarker)
            this.map.addStationMarker(station);
          else
            this.map.updateStationMarker(station);
        });

        this.addinitialMarker();
        observer.next(_res)
      });
    });
  }



  showStation(stationInput: Station) {
    this.stationSerivce.getStation(stationInput.id).subscribe(res => {

      this.router.navigate(['cc/regStation'], { queryParams: { guid: this.operatorguid, id: stationInput.id } });


    });
  }

  map_Click(cordinates: any) {



  }

  gotoOrigin() {

  }


  moveMapEvent(evt: any) {
    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }

  moveEndMapEvent(evt: any) {
    //if (this.CurrentState == PageState.OriginDefine) {
    //  this.getOriginAddressDesc(evt.location[0], evt.location[1]);
    //}
    //else if (this.CurrentState == PageState.DestinationDefine) {
    //  var s = this.taxiSerivce.getAddressFromLocation(evt.location[0], evt.location[1]);
    //  s.subscribe(_res => {
    //    this.destinationPoint.desc = _res;
    //  });
    //}

    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }

  setEmptyStation() {
  }

  ngAfterViewInit() {
    //console.log('ngAfterViewInit');



  }

  onStationMarkerClick() {
    let loc = this.map.getCenter();
    this.router.navigate(['cc/regStation'], { queryParams: { guid: this.operatorguid, lat: loc.lat, lng: loc.lng } });

  }

  delete(_data: Partial<Station>) {
    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید ایستگاه  «' + _data.name + _data.code + '» حذف شود؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });
    confirmRef.afterClosed().subscribe(_confirmed => {
      if (_confirmed) {
        this.stationSerivce.delete(_data.id).subscribe(_res => {
          this.matSnackbar.open('ایستگاه با کد ' + _data.code + ' حذف شد.', null, { duration: 5000 });


          this.loadStation(true).subscribe(res => { });
        });
      }
    });
  }

  ngOnDestroy() {
    this.map.removeMap();
  }
}


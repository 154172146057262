import { Component, OnInit } from '@angular/core';
import { WebSocketService } from '../lib/web-socket.service';
import { NotificationModel, SocketMessageType, Square, SquareChangeRequest } from '../model/socketmodels';

@Component({
  selector: 'app-testsocket',
  templateUrl: './testsocket.component.html',
  styleUrls: ['./testsocket.component.css']
})
export class TestsocketComponent implements OnInit {


  constructor(private socketService: WebSocketService) {
    this.socketService.meesageList$.subscribe(msg => {
      if (msg) {
        this.processMessage(msg);
      }
    });
  }
  test1() {

  }
  test2() {

  }
  test3() {
  }
  test4() {

  }
  ngOnInit() {

    this.socketService.startSocket();
    //this.schedulJon = setInterval(() => {
    //  this.schedulingupdatestatus();
    //}, 3 * 1000);
  }
  schedulJon: any;

  sendMessage() {
    this.socketService.sendMessage('123123123', 1);
  }
  msg = ''
  processMessage(messageBox: NotificationModel) {
    this.msg = JSON.stringify(messageBox);
    switch (messageBox.msgType) {
      case SocketMessageType.GetClientInfo:

        break;
      case SocketMessageType.ServiceRequestAssigned:

        break;

      default:
        break;
    }
  }
  schedulingupdatestatus() {
    let dateTime = new Date()
  }
  test5() {
    this.sendMessage();
  }
  
}

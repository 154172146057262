
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkerType } from '../../lib/markerHelper';
import { Car } from '../../model/car';
import { Driver } from '../../model/driver';
import { StationSteps } from '../../model/enumtype';
import { FormControl } from '@angular/forms';

import { Station } from '../../model/station';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { TaximapComponent } from '../../taximap/taximap.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomsheetinfoComponent } from '../../station/bottomsheetinfo/bottomsheetinfo.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DriverStatus, IDriverStatusMeta } from '../../lib/enums/driver-status.enum';
import { SavedPlaceService } from '../../lib/serviceLib/savedplace.service';

import { LatLng } from 'leaflet';
import { LocationInfo, PageState } from '../../globalVariable';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';
import { ManualServiceRequest } from '../../model/ServiceRequest';

import { MytripsComponent } from '../mytrips/mytrips.component';
import { ShiftlistingComponent } from '../../manage/shiftlisting/shiftlisting.component';

import { ProfileComponent } from '../profile/profile.component';
import { WebSocketService } from '../../lib/web-socket.service';
import { NotificationModel, SocketMessageType } from '../../model/socketmodels';

import { Observable } from 'rxjs';
import { ProposedRequestComponent } from '../proposed-request/proposed-request.component';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { FreeserviceComponent } from '../freeservice/freeservice.component';
import { environment } from '../../../environments/environment.prod';
import { SnackbarexitdriverComponent } from '../snackbarexitdriver/snackbarexitdriver.component';
import { DriverMenuComponent } from '../driver-menu/driver-menu.component';
import { StationListComponent } from '../../station/station-list/station-list.component';
import { PassangerSavedPlace } from '../../model/passangerSavedPlace';
import { SearchplaceComponent } from './../../shared/searchplace/searchplace.component';
import { MyshiftComponent } from '../../manage/myshift/myshift.component';

@Component({
  selector: 'app-testview',
  templateUrl: './testview.component.html',
  styleUrls: ['./testview.component.scss']
})
export class TestviewComponent implements OnInit, OnDestroy, AfterViewInit {

  //#region variables
  @ViewChild("audioOption", { static: false })
  public audioPlayerRef: ElementRef;
  @ViewChild('android', { static: false })
  public android: ElementRef;
  @ViewChild('logoutAndroid', { static: false })
  public logoutAndroid: ElementRef;
  @ViewChild("map", { static: false })
  public map: TaximapComponent;



  socket: WebSocket;
  isVan = false;
  _currentDriverState: DriverStatus = DriverStatus.Off;
  now: number;
  schedulJon: any;
  guidDriver: string = '';
  driverInfo = new Driver();
  carInfo = new Car();
  avatar: any;
  lstStation: Station[];
  currentStation = new Station();
  currentPriority = 0;
  currentFBSToken = '';
  cuerrentVersion = '';
  dialogStationMemberRef: MatDialogRef<MytripsComponent, any>;
  dialogShiftRef: MatDialogRef<ShiftlistingComponent, any>;
  dialogMyShiftRef: MatDialogRef<MyshiftComponent, any>;
  
  dialogProfileMemberRef: MatDialogRef<ProfileComponent, any>;

  dialogProposedServiceRef: MatDialogRef<ProposedRequestComponent, any>;
  dialogFreeServiceRef: MatDialogRef<FreeserviceComponent, any>;
  dialogStationListRef: MatDialogRef<StationListComponent, any>;
  passangerFullName = 'آقای نجاتی';
  passangerMobile = '09151112914';
  passangerComment = '';
  destinationInput = 'میدان فردوسی';

  CurrentState = PageState.None;
  currentStep: StationSteps = StationSteps.None;
  originPoint = new LocationInfo();
  destinationPoint = new LocationInfo();
  StateName = ''
  originMarker: any;
  initMarker: any;
  destinationMarker: any;
  OutOfRange = false;
  dtOutOfRange: any;
  gpsFailedTry = 0;
  snackBarStationExit: any;
  driverCount = 0
  requestCount = 0;





  constructor(
    private swPush: SwPush,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private driverSerivce: DriverserviceService,
    private stationSerivce: StationserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    private matSnackbar: MatSnackBar,
    private taxiSerivce: TaxiserviceService,
    private requestSerivce: ServiceRequestService,
    private socketService: WebSocketService,
    private swUpdate: SwUpdate,
    private placeService: SavedPlaceService,
  ) {


    //console.log('station init');


    setInterval(() => {
      this.now = Date.now();
    }, 1);

  }

  ngOnInit() {

    //console.log('ngoninit22');
    this.initLoad();
    this.loadSavedPlace();
    //console.log('initLoad finish22');

    this.checkForUpdat();
    //console.log('checkForUpdat finish22');
    //navigator.geolocation.getCurrentPosition((position: Position) => this.getNewPosition(position));
    //navigator.geolocation.getCurrentPosition(this.getNewPosition);
    this.schedulingupdatestatus();
    //navigator.geolocation.watchPosition((position: Position) => this.getNewPosition(position), (err) => {

    //  this.setPosition(36.28988214947892, 59.613681321220305);
    //  this.matSnackbar.open('جی پی اس دستگاه را بررسی نمایید', null, { duration: 2000 }
    //  )
    //    ;
    //}, { enableHighAccuracy: true });
    //this.schedulJon = setInterval(() => {

    //}, 5 * 1000);

    //console.log('schedulingupdatestatus set interall finish22');


    //this.getLocation(true);
    //console.log('this.getLocation(true); finish22');


  }

  initLoad() {
    if (!this.map)
      this.map = new TaximapComponent();
    this.currentFBSToken = localStorage.getItem(environment.cur_fbsToken);
    this.loadDriverInfo().subscribe({
      next: (_res) => {
        this.guidDriver = _res.info.guid;
        this.driverInfo = _res.info;
        this.carInfo = _res.car;
        this.isVan = this.carInfo.isVan;
        if (this.currentFBSToken) {
          this.driverSerivce.saveDriverFBS(this.driverInfo.id, this.currentFBSToken).subscribe(fbsres => { });
        }
        this.UpdateStateFromServer(true).subscribe(res => { });

        this.avatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + this.driverInfo.avatar);

        if (this.driverInfo != null) {
          this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(DriverStatus.Off);

          this.driverInfo.currentStation = 0;
          this.getlastState();

        }
        else {
          //console.log('station to home');
          this.matSnackbar.open('نیاز به لاگین', null, { duration: 2000 });
          this.router.navigate(['driver/new'], {});
        }

        this.loadStation().subscribe(res => { });
        this.UpdateStateFromServer(true).subscribe(res => { });
        //this.socketService.startSocket();
      },
      error: (e) => {
        this.router.navigate(['driver/new'], {});
      }
    });




    this.setEmptyStation();

  }
  schedulingupdatestatus() {
    this.UpdateStateFromServer(false).subscribe(res => {
      this.loadStation(false).subscribe(_r => {
        this.schedulJon = setTimeout(() => { this.schedulingupdatestatus() }, 5000);
      })
    }
    )
    //console.log('schedulingupdatestatus run');
    //this.schedulJon = setTimeout(() => { this.schedulingupdatestatus() }, 10);
    //forkJoin(this.UpdateStateFromServer(), this.loadStation(false)).subscribe(res => {
    //  this.schedulJon = setTimeout(() => { this.schedulingupdatestatus() }, 5000);
    //})
    //forkJoin({
    //  user: this.UpdateStateFromServer();
    //  roles: this.loadStation(false);;
    //}).subscribe(results => {
    //  this.user = results.user;
    //  this.roles = results.roles;
    //  //do whetever has to be done here since both are complete
    //})
    //forkJoin(


    //).subscribe((([posts, tags]: [Post[], Tag[]]) => {

    //}));

    //name$.pipe(
    //  combineLatestWith($document)
    //)
    //  .subscribe(([name, document]) => {
    //    this.name = name;
    //    this.document = pair.document;
    //    this.showForm();
    //  })

    //this.getLocation(false);



  }
  
  lastReset= 0;
  resetGPS() {
    const diff = Math.abs((new Date()).getTime() - this.lastReset);
    if (diff > 2 * 60 * 1000) {
      this.lastReset = Date.now();
      this.android.nativeElement.click();
      this.matSnackbar.open('ارسال جی پی اس ریست شد', null, { duration: 2000 });

    }
  }
  
  Mande = 0;
  MandeString = '';

  lastGPSMinute= 0;
  UpdateStateFromServer(setgps = false): Observable<any> {
    return new Observable((observer) => {
      this.driverSerivce.getAndFetch_StatusInfo(this.driverInfo.guid, this._currentDriverState, this.map.gpsLat, this.map.gpsLng, this.getCurrentStationID()).subscribe(_res => {
        


       
        
        console.warn('getAndFetch_StatusInfo');
        console.warn(_res);
        if (_res) {
          if (_res.lastGPSRecieveDistanceSecond > 0)
            this.lastGPSMinute = Math.floor(_res.lastGPSRecieveDistanceSecond / 60);
          else
            this.lastGPSMinute = 0;
          if (this.lastGPSMinute > 3) {
            this.resetGPS();
          }
          this.Mande = _res.mande;
          this.MandeString= _res.mandeString;

          if (_res.currentStatus == DriverStatus.LogoutForConcurrency) {
            this.matSnackbar.open('خروج اجباری به دلیل همزمانی', null, { duration: 2000 });
            this.signout();
          }
          this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(_res.currentStatus);

          if (_res.currentStatus == DriverStatus.Locked) {
            this.matSnackbar.open('قفل - ' + this.driverInfo.lockComment, null, { duration: 2000 });
            this.driverInfo.currentDriverStatus.text = 'قفل ' + this.driverInfo.lockComment;
          }
            
          this.currentPriority = _res.priority;
          
          this._currentDriverState = _res.currentStatus;
          if (!_res.lat || _res.lat == 0) {
            _res.lat = 36.2568397521973;
          }
          if (!_res.lng || _res.lng == 0) {
            _res.lng = 59.6200141906738;
          }

          this.map.gpsLat = _res.lat;
          this.map.gpsLng = _res.lng;
          this.map.locateManual(_res.lat, _res.lng, false)

          

          //this.checkStationExit();
          if (setgps)
            this.map.gotogps();

          //console.log('this._currentDriverState' + this._currentDriverState);
          //console.log('_res is ');
          //console.log(_res);
          if (this._currentDriverState == DriverStatus.Traveling && _res.guid) {
            //console.log('this.currentState == DriverStatus.Traveling and navigate to service/origin(station)');
            this.router.navigate(['service/origin'], { queryParams: { guid: _res.guid } });
          }

          if (this.getCurrentStationID() != _res.stationID) {
            this.stationSerivce.getStation(_res.stationID).subscribe(_stationInfo => {
              this.currentStation = _stationInfo;
              this.driverInfo.currentStation = _res.stationID;

              //console.warn('_stationInfo res is :');
              //console.warn(_stationInfo);
              if (!_stationInfo) {
                //console.warn('_stationInfo is null :');
              }
            });
          }
        }
        observer.next(_res)
      });
    });
  }

  //#endregion


  inChangeUpdateMode = false;
  saveDriverStateOnDatabase(newStatus: DriverStatus, goOnline: boolean = false): Observable<any> {
    return new Observable((observer) => {
      this.driverSerivce.updateDriverStatus(this.driverInfo.guid, newStatus, goOnline).subscribe(
        _res => {
          observer.next(_res)
        }
      );
    });

  }

  showStationList() {
    this.dialogStationListRef = this.dialog.open(StationListComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {

        guidDriver: this.driverInfo.guid,
        gpsLat: this.map.gpsLat,
        gpsLng: this.map.gpsLng
      }
    });
    this.dialogStationListRef.afterClosed().subscribe((result: any) => {
      if (result) {
        {
          this.showStation(result);
        }
      }
    });
  }
  showFreeServices() {

    //console.log('showFreeServices'+this.driverInfo.guid);
    this.dialogFreeServiceRef = this.dialog.open(FreeserviceComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        guidRequest: this.driverInfo.guid
      }
    });
    this.dialogFreeServiceRef.afterClosed().subscribe((result: any) => {
      if (result.length > 0) {
        {
          this.requestSerivce.getPropsedByDriver_Service(result, this.driverInfo.guid).subscribe(_res => { this.showProposedRequest(_res); });
        }
      }
    });

    //this.taxiSerivce.getRequestByPropsedCode(guidRequestParam).subscribe(_res => {
    //  console.warn(_res);
    //});

  }
  showSearchPlace() {

    console.log('showFreeServices' + this.driverInfo.guid);
    var dialogsearchPlaceRef = this.dialog.open(SearchplaceComponent, {
      width: '99%',
      height: '99%',

      hasBackdrop: true,
      data: {

      }
    });
    dialogsearchPlaceRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      console.log(result)

      if (this.getCurrentStationID() == 2) {
        this.addHomaInit();
      }
      else if (this.getCurrentStationID() == 100 || this.getCurrentStationID() == 0) {
        this.addFreeInit();
      }

      else {
        this.addMadineInit();

      }
      
      //this.setDestination(, false);
      this.CurrentState = PageState.RequestTrip;
      if (result.name == 'در اختیار') {
        this.destinationPoint.setFromCordinate([this.map.gpsLat, this.map.gpsLng]);
        this.destinationMarker = this.map.addMarker(this.map.gpsLat, this.map.gpsLng, MarkerType.destination);
        this.StateName = 'درخواست سفر'
        this.destinationInput = result.name;
        this.darEkhtiar=(true);
        console.log('dar ekhtiar')
      }
      else {
        this.destinationPoint.setFromCordinate([result.lat, result.lng]);
        this.destinationMarker = this.map.addMarker(result.lat, result.lng, MarkerType.destination);
        this.StateName = 'درخواست سفر'
        this.destinationInput = result.name;
      }
      //this.setState(PageState.RequestTrip, false);
      //this.routing();

      if (this.initMarker) {


        this.map.removemarker(this.initMarker);
      }
      this.initMarker = null;

      

    });



  }

  acceptRequest(guidRequestParam: any) {
    //this.socketService.sendMessage(guidRequestParam, SocketMessageType.AcceptProposedRequest);

    this.driverSerivce.GetSuccesfullService(guidRequestParam, 'accept').subscribe(res => {
      this.router.navigate(['service/origin'], { queryParams: { guid: res } });
    });
  }

  rejectRequest(guidRequestParam: any) {
    this.driverSerivce.GetSuccesfullService(guidRequestParam, 'reject').subscribe(res => {

      //this.router.navigate(['driver/new'], { queryParams: { guid: res } });
    });


  }
  gotoRunService(guidService: any) {
    //console.warn('gotoRunService:' + guidService);
    this.getlastState();
    if (this._currentDriverState == DriverStatus.Traveling)
      // با توجه به اینکه ممکن است سفر خاتمه خورده باشد به جای ارسال مستقیم به صفحه سفر ابتدا چک می شود اگر وضعیت در حال سفر باشد منتقل می گردد
      this.router.navigate(['service/origin'], { queryParams: { guid: guidService } });
  }

  gotoOrigin() {
    //if (this._currentDriverState != DriverStatus.WaitingOnStation) {
    //  this.matSnackbar.open('ابتدا می بایست در ایستگاه مربوطه ثبت نوبت نمایید.', null, { duration: 2000 });
    //  return;
    //}
    /*
    this.currentStep = StationSteps.Origin;
    this.setState(PageState.OriginDefine);
    */
    this.addMadineInit();
  }
  addinitialMarker() {

    console.log('addinitialMarker');
    var loc = this.map.getCenter();
    var cord = [loc.lat, loc.lng]
    this.initMarker = this.map.addMarker(loc.lat, loc.lng, MarkerType.initLocation);
    console.log('addinitialMarker + getOriginAddressDesc');

    this.getOriginAddressDesc(loc.lat, loc.lng);
  }
  addOriginMarker() {
    var loc = this.map.getCenter();
    var cord = [loc.lat, loc.lng]
    this.originMarker = this.map.addMarker(loc.lat, loc.lng, MarkerType.origin);
  }
  addDestinationMarker() {
    var loc = this.map.getCenter();
    var cord = [loc.lat, loc.lng]
    this.destinationMarker = this.map.addMarker(loc.lat, loc.lng, MarkerType.destination);
  }
  checkForUpdat() {
    
    
    this.swUpdate.available.subscribe(event => {
      this.swUpdate.activateUpdate().then(() => {
        this.matSnackbar.open('اعمال بروزرسانی به نسخه جدید', null, { duration: 3000 });
        window.location.reload();
      });

      
      location.reload();
    });

  }
  forceUpdate() {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
    window.location.reload();

  }


  loadDriverInfo(): Observable<any> {
    return new Observable((observer) => {
      if (this.currentFBSToken) {
        this.driverSerivce.getDriverByFBSToken_OnlyInfo(this.currentFBSToken).subscribe(_res => {

          //localStorage.setItem(environment.cur_u_tkn, _res.tk);
          //console.log('localStorage.setItem(environment.cur_u_tkn, _res.tk);');
          //console.log('environment.cur_u_tkn is ;');
          //console.log(localStorage.getItem(environment.cur_u_tkn));
          observer.next(_res);
          //console.log('getDriverBySuccessfuGUID');
          //console.log(_res);
        })
      }
      else {
        observer.error('guid is not correct');


      }
      return {
        unsubscribe() {
          //navigator.geolocation.clearWatch(watchId);
        }
      };
    });
  }


  setPosition(lat, lng) {
    this.map.gpsLat = lat;
    this.map.gpsLng = lng;

    this.map.moveCurrentGPS(this.map.gpsLat, this.map.gpsLng);
  }
  getNewPosition(position) {

    console.log('getNewPosition')
    console.log(position.coords.latitude)
    console.log(position.coords.longitude)
    this.setPosition(position.coords.latitude, position.coords.longitude)


    // در صورتی که ثبت نوبت کرده باشد نباید از محدوده خارج شود
    //this.checkStationExit();



  }

  stopAlarmStation() {
    if (this.snackBarStationExit) {
      this.snackBarStationExit.dismiss();
      this.snackBarStationExit = null;
    }
    this.audioPlayerRef.nativeElement.pause();
  }
  stationExit_No_Alarm_PauseClicked = false;
  stationExit_No_Alarm_CloseClicked = false;

  checkStationExit() {
    const currentLat = this.map.gpsLat;
    const currentLng = this.map.gpsLng;

    if (this.currentStation && this.currentStation.id !== 0 && this.currentStation.id !== 100 && this.currentStation.lat != 0) {
      const distKM = this.map.getDistanceFromLatLonInKm(currentLat, currentLng, this.currentStation.lat, this.currentStation.lng);
      const InRange = (distKM * 1000) <= this.currentStation.radios;
      if (InRange) {
        this.OutOfRange = false;
        this.dtOutOfRange = "";
        this.stopAlarmStation();
        this.stationExit_No_Alarm_PauseClicked = false;
        this.stationExit_No_Alarm_CloseClicked = false;
      }
      else {
        if (!this.OutOfRange) {
          //this.audioPlayerRef.nativeElement.play();
          this.OutOfRange = true;
          this.dtOutOfRange = new Date()
          this.stationExit_No_Alarm_CloseClicked = false;
          this.stationExit_No_Alarm_PauseClicked = false;
          this.driverSerivce.seenAlarm(this.guidDriver).subscribe(res => { });

          //this.stationSerivce.sendExitSocketMessage(this.currentFBSToken, "https://boroseir.ir/message/exitq?id=" + this.currentPriority, this.currentPriority,3).subscribe(res => { });



          //this.matSnackbar.open('شما از محدوده مجاز ایستگاه خارج شده اید.در صورت عدم بازگشت ظرف مدت 3 دقیقه از صف و نوبت خارج می شوید', null, { duration: 3000 });
          this.snackBarStationExit = this.matSnackbar.openFromComponent(SnackbarexitdriverComponent, {
            duration: 3 * 60 * 1000,
            data:
            {
              fbsToken: this.guidDriver,
              preClose: (methodname: any) => {
                if (methodname == 'close') {

                  this.stationExit_No_Alarm_CloseClicked = true;
                  this.stopAlarmStation();

                  //console.log('close');
                };

                if (methodname == 'stop') {
                  this.stationExit_No_Alarm_PauseClicked = true;
                  //console.log('stop');
                  this.audioPlayerRef.nativeElement.pause();
                }
              },

            }
          }
          );

        }

        else {



          const diff = Math.abs((new Date()).getTime() - this.dtOutOfRange);

          if (diff > 3 * 60 * 1000) {

            //this.stationSerivce.sendExitSocketMessage(this.currentFBSToken, "به دلیل عدم بازگشت به صف نوبت شما از صف خارج شد", this.currentPriority, 1).subscribe(res => { });

            this.stopAlarmStation();
            this.setdefaultStation().subscribe(res => { });

          }
          else {

            if (!this.stationExit_No_Alarm_PauseClicked && !this.stationExit_No_Alarm_CloseClicked) {
              this.audioPlayerRef.nativeElement.pause();
              //this.audioPlayerRef.nativeElement.play();
              //console.log('play1');
            }
          }
        }

      }
    }
    else {
      this.stopAlarmStation();
    }
  }
  ngAfterViewInit() {
    console.log(this.android.nativeElement.click());

    this.setPosition(36.28988214947892, 59.613681321220305);
    //console.log('ngAfterViewInit');
    this.route.queryParams.subscribe(params => {
      const state = params['state'];
      if (state == 1) {
        this.matSnackbar.open('سفر شما به دلیل ویرایش کاربر کنسل گردید.', null, { duration: 2000 });
      }
      else if (state == 2) {
        this.matSnackbar.open('سفر شما توسط اپراتور کنسل گردید.', null, { duration: 2000 });
      }


    });


  }
  getLocation(setView: any): void {
    this.map.locate(setView).subscribe(res => {
      if (!navigator.geolocation) {
        this.matSnackbar.open('جی پی اس خود را بررسی نمایید', null, { duration: 2500 });
        this.gpsFailedTry++;
        if (this.gpsFailedTry > 5) {
          this.map.gpsLat = 0;
          this.map.gpsLng = 0;
          //this.checkStationExit();
        }
      }
      else {
        this.gpsFailedTry = 0;
        //this.checkStationExit();
      }

    });

  }
  map_Click(cordinates: any) {
    if (this._currentDriverState == DriverStatus.Station100) {
      this._bottomSheet.open(BottomsheetinfoComponent);
    }


  }

  loadStation(withMarker = true): Observable<any> {
    return new Observable((observer) => {
      this.stationSerivce.getStationList(this.guidDriver, this.map.gpsLat, this.map.gpsLng).subscribe(_res => {
        this.lstStation = _res;
        this.driverCount = 0;
        this.requestCount = 0;
        _res.forEach(station => {
          station.guidDriver = this.guidDriver;
          this.driverCount = this.driverCount + station.length;
          this.requestCount = this.requestCount + station.requestCount;
          if (!this.isVan) {
            if (withMarker) {
              this.map.addStationMarker(station);
            }
            else
              this.map.updateStationMarker(station);
          }
        });
        observer.next(_res)
      });
    });
  }
  addtoStation(item: Station) {
    //console.log('addtoStation');
    //console.log(item);
    this.stationSerivce.addToQueue(item.id, this.driverInfo.guid).subscribe(_res => {
      if (_res.startsWith('suspend')) {
        var newstr = _res.replace('suspend', '').replace('#',''); 

        this.matSnackbar.open('سرویس در اختیار امکان ثبت نوبت نمی باشد ' + newstr , null, { duration: 20000 });

      }
      if (_res == '-1000') {
        this.matSnackbar.open('ظرفیت ایستگاه پر می باشد.', null, { duration: 3000 });

      }
      else {
        this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(DriverStatus.WaitingOnStation);
        this.currentStation = item;
        this.driverInfo.currentStation = this.currentStation ? this.currentStation.id : 0;
        this.currentPriority = Number.parseInt(_res);
        if (!item) {
          //console.warn('item is null :');
        }
      }
    });
  }
  showStation(stationInput: Station) {
    console.log(this._currentDriverState);
    if (this._currentDriverState == DriverStatus.Locked) {
      return;
    }
    console.log('this._currentDriverState false called');
    this.stationSerivce.getStation(stationInput.id).subscribe(item => {

      //this.router.navigate(['/station/view'], { queryParams: { guid: this.guidDriver, id: item.id } });
      if (this._currentDriverState >= DriverStatus.Station100) {
        let state = 0;
        if (this._currentDriverState == DriverStatus.WaitingOnStation)
          state = 1;
        else
          state = 0;
        var distKM = this.map.getDistanceFromLatLonInKm(this.map.gpsLat, this.map.gpsLng, item.lat, item.lng);
        //console.warn('distance is ' + distKM);
        var InRange = (distKM * 1000) <= item.radios;
        this._bottomSheet.open(BottomsheetinfoComponent,
          {
            data: { stationInfo: item, driverGuid: this.guidDriver, driverInfo: this.driverInfo, InRange: InRange },
          }
        ).afterDismissed().subscribe((dataFromChild) => {
          if (dataFromChild == 'addToQueue') {
            var distKM = this.map.getDistanceFromLatLonInKm(this.map.gpsLat, this.map.gpsLng, item.lat, item.lng);
            //console.warn('distance is ' + distKM);
            if (!InRange) {
              this.matSnackbar.open('شما در محدوده مجاز ایستگاه قرار ندارید.', null, { duration: 3000 });
            }
            else {
              this.addtoStation(item);
            }

          }
          else if (dataFromChild == 'exitQueue') {
            this.setdefaultStation().subscribe(res => { });
          }
        });

      }
    });
  }
  setdefaultStation(goOnline = false): Observable<any> {

    return new Observable((observer) => {
      this.saveDriverStateOnDatabase(DriverStatus.Station100, goOnline).subscribe(res => {
        if (!this.currentStation)
          this.currentStation = new Station();
        this.currentStation.name = "ایستگاه 100";
        this.currentStation.id = 100;
        this.currentStation.code = "100";

        this.currentPriority = res;

        this.driverInfo.currentStation = 100;
        this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(DriverStatus.Station100);
        this._currentDriverState = DriverStatus.Station100;
        //console.log(res);
        observer.next(this.currentStation.id);
      });
    });

  }
  setEmptyStation() {
    this.saveDriverStateOnDatabase(DriverStatus.Off).subscribe(res => {
      this._currentDriverState = DriverStatus.Off;
      this.currentStation = new Station();
      this.currentStation.name = "---";
      this.currentStation.id = 0;
      this.currentPriority = 0;
      this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(DriverStatus.Off);
      this.driverInfo.currentStation = 0;
    });;
  }
  toggleMenu() {
    //console.warn('toggleMenu');
    //this.drawer.toggle();
    const bottomSheetRef = this._bottomSheet.open(DriverMenuComponent, {
      data: { mande: this.Mande, mandeString : this.MandeString }
    });

    bottomSheetRef.afterDismissed().subscribe((res) => {
      if (res == 'gotoOrigin')
        this.showSearchPlace();
      else if (res == 'mytrip')
        this.mytrip();
      else if (res == 'showFreeServices')
        this.showFreeServices();
      else if (res == 'showStation')
        this.showStationList();

      else if (res == 'turnOff')
        this.turnOff();
      else if (res == 'signout')
        this.signout();
      else if (res == 'forceUpdate')
        this.forceUpdate();
      else if (res == 'myProfile')
        this.myProfile();
      else if (res == 'pay')
        this.gotopay();
      else if (res == 'myShift')
        this.myShift();
      else if (res == 'allShift')
        this.allShift ();




    });
  }
  balebotURL = 'https://ble.ir/borobot?start';
  gotopay() {
    this.driverSerivce.startPay(this.driverInfo.id).subscribe(res => {
      this.balebotURL = 'https://ble.ir/borobot?start='+res;
      window.open(this.balebotURL, '_system');
    });
    

  }
  testLog(msg) {
    console.log(msg);
  }
  goOnline() {
    this.testLog('goOnline');
    if (this._currentDriverState == DriverStatus.Locked) {
      this.testLog('this._currentDriverState == DriverStatus.Locked');
      
      this.matSnackbar.open('قفل- ' + this.driverInfo.lockComment, null, { duration: 2000 });
    } else {
      this.testLog('else this._currentDriverState == DriverStatus.Locked');
      if (this._currentDriverState) {
        this.testLog('this.setdefaultStation');
        this.setdefaultStation(true).subscribe(res => {
          console.log(res);
        });
      }
    }
  }

  saveHeartBit() {
    if (!this.driverInfo.id) {
      return;
    }



    //console.log('saveHearBit ');
    this.taxiSerivce.saveHearBit(this.driverInfo.id, 1, this.map.gpsLat, this.map.gpsLng, this._currentDriverState).subscribe(p => {

      //console.log('saveHearBit subscribe ');
      //console.log(p);
    });
  }
  getlastState() {

    this.driverSerivce.getLastStatus(this.guidDriver).subscribe(_res => {
      this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(_res.currentStatus);

      this._currentDriverState = _res.currentStatus;

      this.driverInfo.currentStation = _res.stationID;
      this.driverInfo.currentDriverStatus = IDriverStatusMeta.getStatus(_res.currentStatus);

      this._currentDriverState = _res.currentStatus;
      if (this._currentDriverState == DriverStatus.LogoutForConcurrency) {

        this.signout();
      }
      if (!this.currentStation)
        this.currentStation = new Station();
      if (this.currentStation.id != _res.stationID || this.currentStation.lat == 0) {
        this.stationSerivce.getStation(_res.stationID).subscribe(_stationInfo => {
          this.currentStation = _stationInfo;
        });
      }



      this.currentPriority = _res.priority;
      if (this._currentDriverState == DriverStatus.Traveling) {
        this.router.navigate(['service/origin'], { queryParams: { guid: _res.guid } });
      }


    }
    );
  }


  getOriginAddressDesc(lat: any, lng: any) {
    var s = this.taxiSerivce.getAddressFromLocation(lat, lng);
    s.subscribe(_res => {
      console.log('this.originPoint.desc = ' + _res)
      this.originPoint.desc = _res;
    });
  }
  moveMapEvent(evt: any) {
    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }
  moveEndMapEvent(evt: any) {
    if (this.CurrentState == PageState.OriginDefine) {
      console.log('moveEndMapEvent')
      this.getOriginAddressDesc(evt.location[0], evt.location[1]);
    }
    else if (this.CurrentState == PageState.DestinationDefine) {
      var s = this.taxiSerivce.getAddressFromLocation(evt.location[0], evt.location[1]);
      s.subscribe(_res => {
        this.destinationPoint.desc = _res;
        //this.destinationInput = _res;
      });
    }

    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }
  addMadineInit() {
    this.currentStep = StationSteps.Origin;
    const lat = 36.2913780212402;
    const lng = 59.6130332946777;
    this.map.setView(lat, lng);
    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(lat, lng));
    }
    else {
      this.initMarker = this.map.addMarker(lat, lng, MarkerType.initLocation);
    }
    this.originPoint.desc = 'هتل مدینه الرضا';
    this.setOrigin([lat, lng], 'هتل مدینه الرضا');
    this.originMarker = this.map.addMarker(lat, lng, MarkerType.origin);
    this.originPoint.desc = 'هتل مدینه الرضا';
    this.setState(PageState.DestinationDefine, false);
  }
  addHomaInit() {
    this.currentStep = StationSteps.Origin;
    	
    const lat = 36.3199234008789;
    const lng = 59.5635948181152;
    this.map.setView(lat, lng);
    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(lat, lng));
    }
    else {
      this.initMarker = this.map.addMarker(lat, lng, MarkerType.initLocation);
    }
    this.originPoint.desc = 'هتل هما';
    this.setOrigin([lat, lng], 'هتل هما');
    this.originMarker = this.map.addMarker(lat, lng, MarkerType.origin);
    this.originPoint.desc = 'هتل هما';
    this.setState(PageState.DestinationDefine, false);
  }

  addFreeInit() {
    this.currentStep = StationSteps.Origin;
    	
    const lat = 36.2635154724121;
    const lng = 59.5273551940918;
    this.map.setView(lat, lng);
    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(lat, lng));
    }
    else {
      this.initMarker = this.map.addMarker(lat, lng, MarkerType.initLocation);
    }
    this.originPoint.desc = 'آزاد';
    this.setOrigin([lat, lng], 'آزاد');
    this.originMarker = this.map.addMarker(lat, lng, MarkerType.origin);
    this.originPoint.desc = 'آزاد';
    this.setState(PageState.DestinationDefine, false);
  }

  setState(newState: any, addmarker = true) {
    console.log('setState' + newState + ',' + addmarker);
    this.CurrentState = newState;
    switch (this.CurrentState) {
      case PageState.DestinationDefine:
        this.StateName = 'تایید مقصد'
        if (addmarker)
          this.addOriginMarker();
        break;
      case PageState.None:
      case PageState.OriginDefine:
        this.StateName = 'تایید مبدا'
        if (addmarker)
          this.addinitialMarker();

        break;
      case PageState.RequestTrip:
        this.StateName = 'درخواست سفر'
        if (addmarker)
          this.addDestinationMarker();
        break;
      default:
        break;
    }
  }
  setOrigin(cordinates: any, addressname: string = '') {
    console.log('setOrigin');
    //this.map.addMarker(cordinates[0], cordinates[1], MarkerType.origin);
    this.originPoint.setFromCordinate(cordinates);
    if (!addressname) {
      var s = this.taxiSerivce.getAddressFromLocation(this.originPoint.lat, this.originPoint.ln);
      s.subscribe(_res => {
        this.originPoint.desc = _res;
      });
      this.setState(PageState.DestinationDefine);
    }
    else
      this.setState(PageState.DestinationDefine, false);

  }
  setDestination(cordinates: any, setText = true) {
    //this.map.addMarker(cordinates[0], cordinates[1], MarkerType.destination);
    this.destinationPoint.setFromCordinate(cordinates);
    if (setText) {
      this.taxiSerivce.getAddressFromLocation(this.destinationPoint.lat, this.destinationPoint.ln).subscribe(_res => {
        this.destinationPoint.desc = _res;
        //this.destinationInput = _res;
      });
    }
    
    this.setState(PageState.RequestTrip);
    this.routing();

  }
  routing() {
    //
    var encoded = "cob|Ewx_kJet@p~@ilEdjEepBc|AYeOaFaG";
    //var coordinates = Polyline.fromEncoded(encoded).getLatLngs();
    ////console.log(coordinates);



    //var polyline = polyline(
    //  coordinates,
    //  {
    //    color: 'red',
    //    weight: 10,
    //    opacity: .7,
    //    dashArray: '20,15',
    //    lineJoin: 'round'
    //  }
    //).addTo(this.map);

    /*this.taxiSerivce.routing(this.originPoint.lat, this.originPoint.ln, this.destinationPoint.lat, this.destinationPoint.ln).subscribe(_res => {
      //console.warn('ply:' + _res);
      //console.warn(_res.distanceText);
      //console.warn(_res.distanceValue);
      //console.warn(_res.durationText);
      //console.warn(_res.durationValue);
      //console.warn(_res.polylines);
      //console.warn(_res.summaryTotal);
      */
    //this.map.showpoly2(_res.polylines, this.originPoint.ln, this.originPoint.lat, this.destinationPoint.ln, this.destinationPoint.lat);

    //});
  }
  getCurrentStationID() {
    if (this.currentStation)
      return this.currentStation.id;
    else
      return 0;

  }
  saveRequest() {
    if (this.CurrentState == PageState.OriginDefine) {
      var latLng = this.initMarker.getLatLng();
      this.setOrigin([latLng.lat, latLng.lng]);
    }
    else if (this.CurrentState == PageState.DestinationDefine) {
      var latLng = this.initMarker.getLatLng();
      this.setDestination([latLng.lat, latLng.lng]);
      this.map.removemarker(this.initMarker);
      this.initMarker = null;
    }
    //else if (this.CurrentState == PageState.RequestTrip) {
    //  this.setState(PageState.PassangerInfo);

    //}
    else if (this.CurrentState == PageState.RequestTrip) {
      if (!this.passangerFullName) {
        this.matSnackbar.open('نام مسافر را به صورت صحیح وارد نمایید', null, { duration: 2000 });
        return;
      }
      else if (!this.passangerMobile) {
        this.matSnackbar.open('شماره همراه را به صورت صحیح وارد نمایید', null, { duration: 2000 });
        return;
      }
      else if (!this.passangerMobile) {
        this.matSnackbar.open('شماره همراه را به صورت صحیح وارد نمایید', null, { duration: 2000 });
        return;
      }
      //else if (!this.passangerComment) {
      //  this.matSnackbar.open('توضیحات را به صورت صحیح وارد نمایید', null, { duration: 2000 });
      //  return;
      //}

      let req = new ManualServiceRequest();
      req.id = 1;
      req.destinationLat = this.destinationPoint.lat;
      req.destinationLng = this.destinationPoint.ln;
      req.originLat = this.originPoint.lat;
      req.originLng = this.originPoint.ln;
      req.destinationAddr = this.destinationInput;
      req.destination2Addr = '';
      req.originAddr = this.originPoint.desc;
      //req.passangerID = this.passangerID;
      req.passangerName = this.passangerFullName;
      req.passangerMobile = this.passangerMobile;
      req.comment = this.passangerComment;
      req.darEkhtiar = this.darEkhtiar;
      
      //console.warn('input req is ');
      //console.warn(req);
      this.requestSerivce.saveManualService(req, this.driverInfo.id, this.getCurrentStationID()).subscribe(_res => {
        //this.guidRequest = _res;
        //console.warn('save manual guid service is ' + _res);
        this.router.navigate(['service/origin'], { queryParams: { guid: _res } });

        //this.router.navigate(['driver/new'], {});
        //this.socketService.sendMessage(_res, SocketMessageType.RequestServiceCreate);
        ////console.warn(_res);
        //this.openloadingBar();
      });

    }
  }
  mainBack() {
    if (this.CurrentState == PageState.OriginDefine) {
      this.setState(PageState.None, false);
      this.currentStep = StationSteps.None;
      this.map.removemarker(this.initMarker);
      this.initMarker = null;
    }
    if (this.CurrentState == PageState.DestinationDefine) {
      this.modiforigin();
    }
    else if (this.CurrentState == PageState.RequestTrip) {
      this.modifyDestination();
    }
  }
  modiforigin() {
    if (this.CurrentState == PageState.DestinationDefine) {
      this.map.setView(this.originPoint.lat, this.originPoint.ln);
      this.setState(PageState.OriginDefine, false);
      this.map.removemarker(this.originMarker);
      this.originMarker = null;
    }
  }
  modifyDestination() {
    if (this.CurrentState == PageState.RequestTrip) {

      this.setState(PageState.DestinationDefine, false);
      this.map.setView(this.destinationPoint.lat, this.destinationPoint.ln);
      this.setState(PageState.DestinationDefine, false);
      this.map.removemarker(this.destinationMarker);
      this.destinationMarker = null;
      this.addinitialMarker();
    }

  }

  //#region Methods

  mytrip() {

    this.dialogStationMemberRef = this.dialog.open(MytripsComponent, {
      width: '100%',
      hasBackdrop: true,
      data: {
        driverID: this.driverInfo.id
      }
    });
  }
  myShift() {

    this.dialogMyShiftRef = this.dialog.open(MyshiftComponent, {
      width: '100%',
      hasBackdrop: true,
      data: {
        driverID: this.driverInfo.id,
        myInfo: this.driverInfo
      },
      panelClass: 'custom-modalbox'
    });
  }
  allShift() {

    this.dialogShiftRef = this.dialog.open(ShiftlistingComponent, {
      width: '100%',
      hasBackdrop: true,
      data: {
        driverID: this.driverInfo.id,
        myInfo: this.driverInfo
      }
      ,
      panelClass: 'custom-modalbox'
    });
  }

  myProfile() {
    this.dialogProfileMemberRef = this.dialog.open(ProfileComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        guidDriver: this.guidDriver
      }
    });
  }

  openNeshan() {
    window.open("https://neshan.org/", '_blank').focus();

  }



  //#endregion

  //#region Firebase Notification
  processMessage(messageBox: NotificationModel) {

    //console.warn('socket is received on driver2');
    //console.warn(messageBox);

    switch (messageBox.msgType) {
      case SocketMessageType.GetClientInfo:
        //console.warn('sent info is : ');
        //console.warn(this.driverInfo);
        this.socketService.sendMessage(this.driverInfo.guid, SocketMessageType.SetDriverInfo);
        break;
      case SocketMessageType.ProposedRequestServive:
        this.showProposedRequest(messageBox.guidCode);
        break;
      case SocketMessageType.FinishedProposedRequestServive:
        //console.warn('case SocketMessageType.FinishedProposedRequestServive');
        this.dialogProposedServiceRef.close('NotAnswered');
        break;
      case SocketMessageType.FinishedBroadCastRequestServive:
        //console.warn('case SocketMessageType.FinishedBroadCastRequestServive');
        this.dialogProposedServiceRef.close('NotAnswered');
        break;
      case SocketMessageType.RequestServiceCancelByPassanger:
        this.dialogProposedServiceRef.close('CancelByUser');
        break;
      case SocketMessageType.ServiceGetSubmitedSuccessfully:

        this.gotoRunService(messageBox.guidCode);
        break;


      default:
        break;
    }
  }
  showProposedRequest(guidRequestParam: any) {
    this.dialogProposedServiceRef = this.dialog.open(ProposedRequestComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        guidRequest: guidRequestParam
      }
    });
    this.dialogProposedServiceRef.afterClosed().subscribe((result: any) => {
      if (result == 'Accept') {
        this.acceptRequest(guidRequestParam);
      } else if (result == 'Reject') {
        this.rejectRequest(guidRequestParam);
      }
      else if (result == 'NULL') {
        this.matSnackbar.open('سرویس توسط شخص دیگری دریافت گردید', null, { duration: 2000 });
      }
    });

    //this.taxiSerivce.getRequestByPropsedCode(guidRequestParam).subscribe(_res => {
    //  console.warn(_res);
    //});

  }

  //#endregion

  //#region Finalize

  ngOnDestroy() {
    if (this.schedulJon) {
      clearInterval(this.schedulJon);
    }
  }

  signout() {
    this.setEmptyStation();
    this.schedulingupdatestatus();
    localStorage.removeItem('cache_id_z');
    localStorage.removeItem(environment.cur_u_name);
    localStorage.removeItem(environment.cur_fbsToken);
    localStorage.removeItem(environment.is_created);
    localStorage.removeItem(environment.cur_u_tkn);


    try {
      this.driverSerivce.logOutFToken(this.guidDriver).subscribe(
        res => {

          this.router.navigate(['driver/new'], {});
        })
    }
    catch (e) {

    }




  }

  //public test(): string{
  //  this.buttonTest.nativeElement.innerHTML = 'test';
  //  return 'test';
  //}



  turnOff() {
    this.setEmptyStation();
  }
  gotogps() {
    this.map.gotogps();
  }
  selectedOrigins: PassangerSavedPlace[] = [];
  loadSavedPlace() {
    /*
    this.placeService.listAll(1).subscribe(res => {
      if (res) {
        this.selectedOrigins = res;
        
      }
      else {
        this.selectedOrigins = null;
        
      }


    });
    */
  }
  gotoPlace(p: PassangerSavedPlace) {
    console.log(p);
    const lat = p.lat;
    const lng = p.lng;
    this.map.setView(lat, lng)
    this.initMarker.setLatLng(new LatLng(lat, lng));
    this.setDestination([lat, lng]);
    this.destinationPoint.desc = p.placeAddr;
    this.destinationInput = p.placeAddr;
    this.map.removemarker(this.initMarker);
    this.initMarker = null;

  }
  fastNewService() {
    if (this._currentDriverState == DriverStatus.Locked) {
      this.matSnackbar.open('قفل - ' + this.driverInfo.lockComment, null, { duration: 2000 });
      return;
    } 

    let req = new ManualServiceRequest();
    req.id = 1;
    req.destinationLat = this.map.gpsLat;
    req.destinationLng = this.map.gpsLng;
    req.originLat = this.map.gpsLat;
    req.originLng = this.map.gpsLng;
    req.destinationAddr = '';
    req.destination2Addr = '';
    req.originAddr = '';
    //req.passangerID = this.passangerID;
    req.passangerName = '';
    req.passangerMobile = '';
    req.comment = '';
    req.darEkhtiar = false;

    //console.warn('input req is ');
    //console.warn(req);
    this.requestSerivce.saveManualService(req, this.driverInfo.id, this.getCurrentStationID()).subscribe(_res => {
      //this.guidRequest = _res;
      //console.warn('save manual guid service is ' + _res);
      this.router.navigate(['service/origin'], { queryParams: { guid: _res } });

      //this.router.navigate(['driver/new'], {});
      //this.socketService.sendMessage(_res, SocketMessageType.RequestServiceCreate);
      ////console.warn(_res);
      //this.openloadingBar();
    });

  }
  //#endregion
  darEkhtiar :boolean=false
  valueChange(changedItem: any, $event: any) {
    //console.log('dar ekhtiar before ' + this.darEkhtiar);
    if (changedItem == 'darEkhtiar') {
      this.darEkhtiar=($event.checked);
      //if (this.darEkhtiar) {
      //  this.passangerFormGroup.patchValue({ serviceRequestComment: this.addTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' در اختیار ') });
      //}
      //else {
      //  this.passangerFormGroup.patchValue({ serviceRequestComment: this.subTerm(this.passangerFormGroup.get('serviceRequestComment')?.value, ' در اختیار ') });
      //}
    }
  
    //console.log('dar ekhtiar after ' + this.darEkhtiar);
  }

}



import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inputselectitem',
  templateUrl: './inputselectItem.component.html',
  styleUrls: ['./inputselectItem.component.css']
})
export class InputselectItemComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InputselectItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      msg: string,
      fieldName: string,
      value: number
      lst: [
        {
        id: number;
        name: string;
      }]

    },) { }


  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  selectedID = '';
  changeInputHelper(e) {
    
    this.selectedID =(e.target.value);
    console.log('this.typePardakht ' + this.selectedID + 'e.target.value ' + e.target.value);
  }
}

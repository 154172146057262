import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GlobalVariable} from './../../globalVariable';
import {Driver, getDriverInfo} from './../../model/driver';
import {Observable, Subscriber} from 'rxjs';
import {ErrorOutput} from '../../model/enumtype';
import {Car} from '../../model/car';
import { LastCallInfo } from '../../model/lastCallInfo';
import { OperatorTable } from '../../model/operatorTable';
import { IBaseInfo, IBaseInfoMaster } from '../interfaces/Ibaseinfo';
import { ICommissionReport, IKarkardReport, IMonthbyMonthReport, IServiceReport } from '../interfaces/iServiceReport';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public moke = GlobalVariable.mokeFLAG;
  private BaseServiceURL = GlobalVariable.BaseServiceURL + 'Report';;
  

  constructor(private httpClient: HttpClient) {

  }

  public ServiceReport(driverID: string, dateStart: string, dateFinish: string, operatorID: string = null, keyword: string =''): Observable<IServiceReport[]> {
    let params = new HttpParams()
      .set('driverID', driverID)
      .set('dateStart', dateStart)
      .set('operatorID', operatorID)
      .set('Keyword', keyword)
      .set('dateFinish', dateFinish);
    return this.httpClient.post<IServiceReport[]>(this.BaseServiceURL + '/ServiceReport', params);
  }
  public KarkardReport(driverID): Observable<IKarkardReport[]> {
    let params = new HttpParams()
      .set('driverID', driverID);
    return this.httpClient.post<IKarkardReport[]>(this.BaseServiceURL + '/ReportKarkard', params);
  }
  public MonthByMonthReport(driverID): Observable<IMonthbyMonthReport[]> {
    let params = new HttpParams()
      .set('driverID', driverID);
    return this.httpClient.post<IMonthbyMonthReport[]>(this.BaseServiceURL + '/ReportMonthByMonth', params);
  }
  public CommissionReport(datePrefix,driverID =-1): Observable<ICommissionReport[]> {
    let params = new HttpParams()
      .set('datePrefix', datePrefix)
      .set('DriverID', driverID.toString())
    return this.httpClient.post<ICommissionReport[]>(this.BaseServiceURL + '/CommissionReport', params);
  }
  public GetCurrentActiveDate(): Observable<string[]> {
    let params = new HttpParams()
    return this.httpClient.post<string[]>(this.BaseServiceURL + '/GetCurrentActiveDate', params);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { OperatorService } from '../../lib/serviceLib/operatorservice.service';
import { OperatorTable } from '../../model/operatorTable';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';
import { GlobalVariable } from '../../globalVariable';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-new-navbar',
  templateUrl: './new-navbar.component.html',
  styleUrls: ['./new-navbar.component.css']
})
export class NewNavbarComponent implements OnInit {

  loadOperatorInfo(guidCode: any) {
    this.callCenterService.getOperatorInfo(guidCode).subscribe(res => {
      if (res) {
        this.operatorInfo = res;
        GlobalVariable.adminLevel = this.operatorInfo.adminLevel;

      } else {

        this.router.navigate(['login'], { queryParams: {} });
      }
    });
  } 
  constructor(private router: Router,
    private route: ActivatedRoute,
    private callCenterService: CallCenterService,

    private operatorService: OperatorService,


  ) { }
  telID = '';
  operatorguid: any;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.loadOperatorInfo(this.operatorguid);

    });
  }

  getCallClick() {
    this.router.navigate(['cc/new'], { queryParams: { guid: this.operatorguid, telID: this.telID} });

  }
  operatorInfo = new OperatorTable();

  signout() {

      try {
        this.operatorService.logout(this.operatorInfo.id,false).subscribe(
          res => {
            this.router.navigate(['login'], {});
          })
      }
      catch (e) {

      }

  }

}

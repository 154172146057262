import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-messagebox',
  templateUrl: './messagebox.component.html',
  styleUrls: ['./messagebox.component.scss']
})
export class MessageboxComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MessageboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { msgText: string, msgTitle: string }) {
    this.title = data.msgTitle;
    this.messageText = data.msgText;
  }
  messageText = 'آیا مطمین هستید؟'
  title=''
  ngOnInit() {

  }
  ok() {
    this.dialogRef.close('ok');

  }
  cancel() {
    this.dialogRef.close('cancel');

  }

}

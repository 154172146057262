import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbarexitdriver',
  templateUrl: './snackbarexitdriver.component.html',
  styleUrls: ['./snackbarexitdriver.component.scss']
})
export class SnackbarexitdriverComponent implements OnInit {
  dtOutOfRange: any;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.fbsToken = this.data.fbsToken;
  }
  forsat = 180;
  fbsToken = '';
  ngOnDestroy() {
    if (this.schedulJon) {
      clearInterval(this.schedulJon);
    }
  }
  schedulJon: any;
  ngOnInit() {
    this.dtOutOfRange = new Date()

    this.schedulJon = setInterval(() => {
      this.check();
    }, 1000);
  }
  ok()
  {
    this.data.preClose('close');
  }
  stop() {
    this.data.preClose('stop');
  }
  check() {
    const diff = Math.abs((new Date()).getTime() - this.dtOutOfRange);
    if (diff > 3 * 60 * 1000) {
      this.data.preClose();
    }
    else {
      this.forsat = parseInt(((3 * 60 * 1000 - diff) / 1000).toString());
    }
  }

}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import { GlobalVariable } from './globalVariable';
import { Driver, getDriverInfo } from './model/driver';
import { Observable } from 'rxjs';
import { VerificationOutput } from './model/verificationOutput';
import { Station } from './model/station';
import { ServiceRequest } from './model/ServiceRequest';
import { StationQueueInfo } from './model/stationQueueInfo';
import { Service } from './model/service';

@Injectable({
  providedIn: 'root'
})
export class TaxiserviceService {
  private DriverBaseServiceURL = GlobalVariable.DriverBaseServiceURL;
  private UtilityBaseServiceURL = GlobalVariable.UtilityBaseServiceURL;
  private RequestServiceBaseServiceURL = GlobalVariable.RequestServiceBaseServiceURL;
  private StationBaseServiceURL = GlobalVariable.StationBaseServiceURL;
  private ServiceBaseServiceURL = GlobalVariable.ServiceBaseServiceURL ;
  
  
  constructor(private httpClient: HttpClient) { }
  getDriverByNationalCode(nCode: any, mobile: any){ 
    let params = new HttpParams().set('nCode', nCode).set('mobile', mobile);
    
    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/GetInfo", params,{ responseType: 'text' as 'json' });
  }
  public getDriverBySuccessfuGUID(guid: any) {
    let body = new HttpParams().set('guid', guid);
  
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/GetInfoBySuccessfullGUID", body);
  }
  public serviceGetLastState(guid: any) {
    const params = new HttpParams().set('serviceGUID', guid);
    return this.httpClient.post<number>(this.ServiceBaseServiceURL + "/Service_GetLastState",params );
  }
  public getDriverByGUID(guid: any) {
    let params = new HttpParams().set('guid', guid);
    return this.httpClient.post<getDriverInfo>(this.DriverBaseServiceURL + "/GetInfoByGUID", params );
  }

  public sendVerification(guid: any) {
    let params = new HttpParams().set('guid', guid);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.httpClient.post<string>(this.DriverBaseServiceURL + "/SendVerification", params,{responseType: 'text' as 'json'});
  }
  public checkVerification(code: any, guidCode: any, guidDriver: any): Observable<VerificationOutput> {
    let params = new HttpParams().set('code', code);
    params=params.set('guidCode', guidCode);
    params=params.set('guidDriver', guidDriver);
    return this.httpClient.post<VerificationOutput>(this.DriverBaseServiceURL + "/CheckVerification", params);
  }
  public setSuccessfullResult(guid: any, result: any): Observable<any> {
    let params = new HttpParams().set('guid', guid);
    params = params.set('result', result);
    return this.httpClient.post(this.DriverBaseServiceURL + "/setSuccessfullResult", params );
  }
  
  public getStationList(guidDriver: any, lat: any, lng: any): Observable<Station[]> {
    let params = new HttpParams().set('guidDriver', guidDriver).set('lat', lat).set('lng', lng);
    return this.httpClient.post<Station[]>(this.DriverBaseServiceURL + "/StationList",  params );
    
  }
  public getStation(stationID: any): Observable<Station> {
    let params = new HttpParams().set('stationID', stationID);
    return this.httpClient.post<Station>(this.StationBaseServiceURL + "/getStationInfo",   params );
  }
  public getAddressFromLocation(lat: any, lng: any) {
    let params = new HttpParams().set('lat', lat).set('lng', lng);
    return this.httpClient.post<string>(this.UtilityBaseServiceURL + "/loctoadr", params,{ responseType: 'text' as 'json'});
  }
  public saveServiceReuest(req: ServiceRequest) {

    let params = new HttpParams().set('originlat', req.originLat.toString()).set('originlng', req.originLng.toString()).set('destinationLat', req.destinationLat.toString()).set('destinationLng', req.destinationLng.toString()).set('destinationLat2', req.destinationLat2.toString()).set('destinationLng2', req.destinationLng2.toString()).set('passangerID', req.passangerID.toString()).set('destination2Addr', req.destination2Addr.toString()).set('destinationAddr', req.destinationAddr.toString()).set('originAddr', req.originAddr.toString());

    //console.warn('taxi servie receiverd');
    //console.warn(req);
    //console.warn(params);
    return this.httpClient.post<string>(this.RequestServiceBaseServiceURL + "/saverequest", params,{ responseType: 'text' as 'json' }); 
  
  }
  public getRequestService(guidRequest: any): Observable<ServiceRequest> {
    let params = new HttpParams().set('guidRequest', guidRequest);
    return this.httpClient.post<ServiceRequest>(this.RequestServiceBaseServiceURL + "/getRequestService", params);
  }
  public getRequestByPropsedCode(guidRequest: any): Observable<ServiceRequest> {
    let params = new HttpParams().set('guidRequest', guidRequest);
    return this.httpClient.post<ServiceRequest>(this.RequestServiceBaseServiceURL + "/getRequestByPropsedCode", params,);
  }
  public addToQueue(stationID: any, guidDriver: any) {
    let params = new HttpParams().set('stationID', stationID).set('guidDriver', guidDriver);
    return this.httpClient.post<string>(this.StationBaseServiceURL + "/addToQueue", params, {responseType: 'text' as 'json' });
  }
  
  public getStationQueue(stationID: any): Observable<StationQueueInfo[]> {
    let params = new HttpParams().set('stationID', stationID);
    return this.httpClient.post<StationQueueInfo[]>(this.StationBaseServiceURL + "/getStationQueue", params,);
    //return StationQueueInfo.getStationQueue(stationID, this.httpClient);
  }
  public getService(guidService: any): Observable<Service> {
    let params = new HttpParams().set('guidService', guidService);
    return this.httpClient.post<Service>(this.ServiceBaseServiceURL  + "/getService", params);
  }
  public getTest(){
    let params = new HttpParams();
    return this.httpClient.post<Service>("https://cidapi.mashhad.ir:7095/Default/getTest", params,{ responseType: 'text' as 'json' });
  }
  //https://cidapi.mashhad.ir:7095/CallCenter/LogCall?No=09337926213&LineNo=6574
  public testCallCenter(no: any, telNo: any) {
      let params = new HttpParams();
      params = params.set('No', no);
      params = params.set('LineNo', telNo);
      return this.httpClient.post<number>("https://localhost:44323/CallCenter/LogCall",params);
    }

}


export enum ErrorOutput {
  None = 0,
  VerificationSms_TryCountMaximumReached,
  VerificationSms_GUIDCodeNotFound,
  VerificationSms_CodeIsMismatch,
  VerificationSms_SentCodeTimeOut,
  /// <summary>
  /// راننده ای با این کد ملی ثبت نشده است
  /// </summary>
  DriverLogin_driverNotFound,
  /// <summary>
  /// شماره موبایل وارد شده با شماره ثبت شده در سیستم متفاوت است - برای لاگین های بار دوم بهب عد
  /// </summary>
  DriverLogin_MobileIsMisMatch,
  /// <summary>
  /// شماره وارد شده متعلق به این راننده نمی باشد
  /// </summary>
  DriverLogin_ShahkarFailed,
  DriverSuccessfull_GUIDMismatch
}
export class ErrorOutputMessage {
  static getText(err: ErrorOutput) {
    switch (err) {
      case ErrorOutput.None:
        return '';
      case ErrorOutput.VerificationSms_CodeIsMismatch:
        return 'کد وارد شده صحیح نمی باشد';
      case ErrorOutput.VerificationSms_GUIDCodeNotFound:
        return 'خطا در اطلاعات ورودی - لطفا صفحه را بسته و دوباره امتحان کنید';
      case ErrorOutput.VerificationSms_SentCodeTimeOut:
        return 'کد ارسالی منقضی شده است';
      case ErrorOutput.VerificationSms_TryCountMaximumReached:
        return 'خطا به دلیل تلاش بیش از حد مجاز';
      case ErrorOutput.DriverLogin_driverNotFound:
        return "رانند ه ای با این کد ملی ثبت نشده است";
      case ErrorOutput.DriverLogin_MobileIsMisMatch:
        return "شماره همراه وارد شده صحیح نیست";
      case ErrorOutput.DriverLogin_ShahkarFailed:
        return "اطلاعات وارد شده صحیح نمی باشد";
      case ErrorOutput.DriverSuccessfull_GUIDMismatch:
        return "خطا در داده های دریافتی";



      default:
        return null;
    }
  }
}
export enum driverStatusinStation {
  None,
  InQueue,
  InTrip
}
export enum StationSteps {
  None,
  Origin ,
}

import { Component, OnInit,AfterViewInit ,ElementRef,ViewChild} from '@angular/core';
import { FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';

import { GlobalVariable } from '../../globalVariable';


@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class NewComponent implements OnInit, AfterViewInit{
  userInfo = this.fb.group({
    mobile: ['', Validators.pattern('09(1[0-9]|3[1-9]|2[1-9])-?[0-9]{3}-?[0-9]{4}')],
    nationalCode: ['', nCodeValidator]
  });
  @ViewChild('android')
  public android: ElementRef;

  constructor(private fb: FormBuilder, private driverSerivce: DriverserviceService, private router: Router, private driverService: DriverserviceService) { }
  ngOnInit() {


  }
  
  hasError = false
  errorText = 'اطلاعات وارد شده صحیح نمی باشد'

  onSubmit() {
    this.hasError = false
    this.driverSerivce.getDriverByNationalCode(this.userInfo.value.nationalCode, this.userInfo.value.mobile).subscribe(_res => {

      //console.warn('subscribe');
      //console.warn(_res);
      //console.warn('guid is ' + _res);
      //console.warn(_res.driverGUID);
      //console.warn(_res.errorCode);
      //console.warn(_res.errorMessage);
      if (_res.driverGUID != '') {
        this.driverSerivce.sendVerification(_res.driverGUID).subscribe(_smsguid => {
          //console.warn('_smsguid' + _smsguid);
          this.router.navigate(['/driver/verify'], { queryParams: { guidS: _smsguid, guidD: _res.driverGUID } });
        });
        this.hasError = false;
      }
      else {
        this.hasError = true;
        this.errorText = _res.errorMessage;
      }
    });


  }
  ngAfterViewInit() {

  }

}


export function nCodeValidator(control: FormControl): ValidatorFn {
  if (!control.value) {
    return null;
  }
  if (GlobalVariable.check(control.value)) {
    return null;
  }
  else {
    //console.warn("کدملی وارد شده صحیح نیست");
    //return {
    //  phone: true
    //};
    return null;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { StationQueueInfo } from '../../model/stationQueueInfo';

@Component({
  selector: 'app-driver-card',
  templateUrl: './driver-card.component.html',
  styleUrls: ['./driver-card.component.scss']
})
export class DriverCardComponent implements OnInit {
  @Input() info: StationQueueInfo ;
  constructor() {

  }

  ngOnInit(): void {

  }

}

export class Car {
  id: number;
  carName: string;
  colorName: string;
  pelaqueIR: string;
  pelaqueLetter: string;
  pelaque2: string;
  pelaque3: string;
  carType: number;
  color: number;
  pelaque: string;
  isVan: boolean;
}
 

import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { DriverserviceService } from './lib/serviceLib/driverservice.service';
import {  Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DriverGuardGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private driverSerivce: DriverserviceService, private router: Router
) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable((observer) => {
      let guidDriver = localStorage.getItem(environment.cur_u_name);
      let guidToken = localStorage.getItem(environment.cur_u_tkn);
      console.log('guidDriver driver from session storage is ' + guidDriver);
      console.log('guidToken driver from session storage is ' + guidToken);

      this.driverSerivce.verifyToken(guidDriver, guidToken).subscribe(_res => {
        //console.log('getDriverBySuccessfuGUID on canActivate' );
        //console.log(_res);
        
        if (_res && _res.info)
          observer.next(true);
        else {
          console.log('can not activate');

          this.router.navigate(['driver/new'], { });
          observer.next(false);

        }
      });
    });
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return false;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProposedRequestComponent } from '../../driver/proposed-request/proposed-request.component';
import { WebSocketService } from '../../lib/web-socket.service';
import { NotificationModel, SocketMessageType } from '../../model/socketmodels';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';

@Component({
  selector: 'app-queueset',
  templateUrl: './queueset.component.html',
  styleUrls: ['./queueset.component.css']
})
export class QueuesetComponent implements OnInit {
  constructor(private socketService: WebSocketService, private taxiSerivce: DriverserviceService, private dialog: MatDialog) {
    this.socketService.meesageList$.subscribe(msg => {
      if (msg) {
        this.processMessage(msg);
      }
    });
  }

  drvguid = '';
  setQueue() {
    this.socketService.startSocket();
  }
  ngOnInit() {

  }
  processMessage(messageBox: NotificationModel) {
    //console.warn(messageBox);
    switch (messageBox.msgType) {
      case SocketMessageType.GetClientInfo:
        this.socketService.sendMessage(this.drvguid, SocketMessageType.SetDriverInfo);
        break;
      case SocketMessageType.ProposedRequestServive:
        this.showProposedRequest(messageBox.guidCode)
        break;
      case SocketMessageType.FinishedProposedRequestServive:
        this.dialogRef.close('NotAnswered');
        break;
      case SocketMessageType.RequestServiceCancelByPassanger:
        this.dialogRef.close('CancelByUser');
        break;
        
        
      default:
        break;
    }
  }
  acceptRequest(guidRequestParam: any) {
    this.socketService.sendMessage(guidRequestParam, SocketMessageType.AcceptProposedRequest);
  }
  rejectRequest(guidRequestParam: any) {
    this.socketService.sendMessage(guidRequestParam, SocketMessageType.RejectProposedRequest);
  }

  dialogRef: MatDialogRef<ProposedRequestComponent , any>;
  showProposedRequest(guidRequestParam: any) {
    this.dialogRef = this.dialog.open(ProposedRequestComponent, {
      height: '400px',
      width: '600px',
      data: { guidRequest: guidRequestParam }
    });
    this.dialogRef.afterClosed().subscribe((result : any) => {
      if (result=='Accept') {
        this.acceptRequest(guidRequestParam);
      }
      else if (result == 'Reject') {
        this.rejectRequest(guidRequestParam);
      }
    });
    
    //this.taxiSerivce.getRequestByPropsedCode(guidRequestParam).subscribe(_res => {
    //  console.warn(_res);
    //});
    
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceInfo } from '../../model/serviceInfo';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit {
  stationID = 0;
  list: ServiceInfo[]=[];
  constructor(
    private sanitizer: DomSanitizer,
    public serviceService: ServiceserviceService,
    public dialogRef: MatDialogRef<TripsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { stationID: number}
  ) {
    this.stationID = data.stationID;
    this.serviceService.reportQueue(this.stationID).subscribe(res => {
     
      this.list = res;
      this.list.forEach((data) => {
        data.safeAvatar = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + data.avatar);
      })

    });
  }

  ngOnInit() {
  }
  closeform() {
    this.dialogRef.close('closse');
  }

}

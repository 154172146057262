import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  message = 'آیا مطمئن هستید؟';
  confirmButtonText = 'بلی';
  cancelButtonText = 'خیر';
  extraButtonText = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        this.extraButtonText = data.buttonText.extraButtonText || this.extraButtonText;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close('cancel');
  }
  onExtrClick(): void {
    this.dialogRef.close('btn3');
  }
  onOKClick(): void {
    this.dialogRef.close('OK');
  }

  doSomething(event: KeyboardEvent) {
    if (event.key === '1') {
      this.onOKClick();
      event.preventDefault();
    }
    if (event.key === '2') {
      this.onNoClick();
      event.preventDefault();
    }
    if (event.key === '3') {
      this.onExtrClick();
      event.preventDefault();
    }
  }


}

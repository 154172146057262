import { StationserviceService } from '../../lib/serviceLib/stationservice.service';
import { Station } from '../../model/station';
import { Observable } from 'rxjs';
import { SavedPlaceService } from '../../lib/serviceLib/savedplace.service';
import { PassangerSavedPlace } from '../../model/passangerSavedPlace';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { searchResult } from '../../model/routingInfo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';


@Component({
  selector: 'app-searchplace',
  templateUrl: './searchplace.component.html',
  styleUrls: ['./searchplace.component.css']
})
export class SearchplaceComponent implements OnInit {
  lstStation: Station[];
  lstStation_Filter: Station[];
  lstPlace: PassangerSavedPlace[];
  lstPlace_Filter: PassangerSavedPlace[];
  lstsearchItem: searchResult[];
  searchvalue = '';
  currentlat = 36.2980006
  currentlng = 59.604234
  constructor(private stationSerivce: StationserviceService,
    private placeService: SavedPlaceService,
    private taxiService: TaxiserviceService,

    public dialogRef: MatDialogRef<SearchplaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { guidRequest: string }

  ) 
  {


  }
 
  ngOnInit(): void {
    this.loadStation();
    this.loadSavedPlace();

  }
  loadStation() {
    this.stationSerivce.getStationList('', 0, 0).subscribe(_res => {
      if (_res) {
        this.lstStation = _res;
        this.lstStation_Filter = JSON.parse(JSON.stringify(_res));
      }
      else {
        this.lstStation = null;
        this.lstStation_Filter = null;

      }
    });
  }
  loadSavedPlace(passangerID = -1) {

    this.placeService.listAll(passangerID).subscribe(res => {
      console.log(res)
      if (res) {
        this.lstPlace = res;
        this.lstPlace_Filter = JSON.parse(JSON.stringify(res));
      }
      else {
        this.lstPlace = null;
        this.lstPlace_Filter = null;
      }


    });
  }
  search(searchvalue) {
    this.taxiService.searchMap(searchvalue, this.currentlat, this.currentlng).subscribe(res => {
      this.lstsearchItem = res;
      console.log(res);

    })
    this.filter(searchvalue);

  }
  public ontextChange(event): void {
    console.log(event);
    this.search(event.target.value);
  }
  
  filter(value) {
    var count = 0;
    this.lstPlace_Filter = [];
    this.lstStation_Filter = [];

    this.lstPlace.forEach((data) => {
      if  ((this.lstPlace_Filter.length < 20) && (data.placeName.includes(value) || data.placeAddr.includes(value))) {
        
        this.lstPlace_Filter.push(data);

      }
    })
    this.lstStation.forEach((data) => {
      if (this.lstStation_Filter.length  <20 && data.name.includes(value)) {
        this.lstStation_Filter.push(data);
      }
    })
  }
  selectstation(st:Station) {
    console.log(st);
    this.selectpoint(st.lat, st.lng, st.name, 'station', st);
  }
  selectplace(plc:PassangerSavedPlace) {
    console.log(plc);
    this.selectpoint(plc.lat, plc.lng, plc.placeName, 'place', plc);

  }
  selectsrch(pl: searchResult) {
    console.log(pl);
    this.selectpoint(pl.x, pl.y, pl.title, 'search', pl);
  }
  selectpoint(lat, lng, name, type, obj) {
    this.dialogRef.close({
      lat: lat, lng: lng, name: name, type: type, obj: obj
    });

  }
  darekheiar() {
    this.dialogRef.close({
      lat: 0, lng: 0, name: 'در اختیار', type: 0, obj: {}
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';

@Component({
  selector: 'app-peygirialert',
  templateUrl: './peygirialert.component.html',
  styleUrls: ['./peygirialert.component.scss']
})
export class PeygirialertComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private requestSerivce: ServiceRequestService
  ) { }
  destination = '';
  origin = '';
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const requestID = params['code'];
      if (requestID) {
        this.requestSerivce.getRequestServiceByID(requestID).subscribe(_res => {
          this.destination = _res.destinationAddr;
          this.origin = _res.originAddr;
          //console.log('getDriverBySuccessfuGUID');
          //console.log(_res);
        });
      }
      else {
      this.router.navigate(['driver/new'], { queryParams: {  } });

      }
    });
  }
  accept() {
    this.router.navigate(['driver/new'], { queryParams: {} });

  }

}

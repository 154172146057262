import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';

@Component({
  selector: 'app-logcall',
  templateUrl: './logcall.component.html',
  styleUrls: ['./logcall.component.scss']
})
export class LogcallComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private callCenterService: CallCenterService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.loadData(params['Line'], params['No']);
    });
  }
  result =''
  loadData(Line: any, No: any) {
    this.callCenterService.saveLogCall(Line, No).subscribe(p => {
      this.result = p;
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';

@Component({
  selector: 'app-drivernotfound',
  templateUrl: './drivernotfound.component.html',
  styleUrls: ['./drivernotfound.component.css']
})
export class DrivernotfoundComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DrivernotfoundComponent>, private route: ActivatedRoute, private taxiSerivce: DriverserviceService, @Inject(MAT_DIALOG_DATA) public data: { guidRequest: string }) { }

  ngOnInit() {
  }
  retry() {
    this.dialogRef.close('Retry')
  }

  cancel() {
    this.dialogRef.close('Cancel')
  }

}

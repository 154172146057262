import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { GlobalVariable } from '../../globalVariable';
import {Observable} from 'rxjs';
import { IStationDetail } from '../interfaces/station-detail';

@Injectable({
  providedIn: 'root'
})
export class StationService {
  private StationBaseServiceURL = GlobalVariable.StationBaseServiceURL;

  constructor(
    private httpClient: HttpClient
  ) {
  }


  public getAll(stationID: any): Observable<IStationDetail[]> {
    const params = new HttpParams().set('StationID', stationID);
    return this.httpClient.post<IStationDetail[]>(`${this.StationBaseServiceURL}/GetDetails`, params);
  }
}

import { saveservicresultType } from "./saveserviceresult";

export class Station {
  id: number;
  name: string;
  length: number;
  distanceKM: number;
  distanceMinute: number;
  lat: number;
  lng: number;
  guidDriver: string;
  serviceResult: saveservicresultType;
  radios: number;
  reservedCount: number;
  capacity: number;
  requestCount: number;
  code: string;

 
}

export class StationNeighbor {
  id: number;
  rowIndex: number;
  driverCodes: string;
  
}



import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { FormMode } from '../../lib/enums/form-mode.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { keyvalueobject } from '../../model/keyvalueobject';
import { Driver,getDriverInfo } from '../../model/driver';
import { DebitCredit } from '../../model/debitcredit';

import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { DriverLogComponent } from '../../driver/driver-log/driver-log.component';
import { MyTel } from '../../shared/materials/plaque.component';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InputtextComponent } from '../../shared/inputtext/inputtext.component';

@Component({
  selector: 'app-operators',
  templateUrl: './manage-debit.component.html',
  styleUrls: ['./manage-debit.component.scss']
})
export class ManageDebitComponent implements OnInit {
  @ViewChild('operatorDialog', { static: false }) operatorDialogTemplateRef: TemplateRef<any>;
  operatorDialogRef: MatDialogRef<any>;


  @ViewChild('lockDialog', { static: false }) lockDialogTemplateRef: TemplateRef<any>;
  lockDialogRef: MatDialogRef<any>;

total=0;
  Mande = 0;
  totalDebit = 0;
  totalCredit = 0;
  currentFormMode: FormMode;
  operatorFromGroup: FormGroup;
  lockFromGroup: FormGroup;
  operators: Partial<DebitCredit>[];
  currentEditingOperator: Partial<Driver>;
  mobileSearch = '';
  nationalCodeSearch = '';
  driverFNameSearch = '';
  driverCodeSearch = '';
  stationCode = '';
  recordCount = 0;
  operatorguid = '';
currentRefID = 0;
  constructor(
    private route: ActivatedRoute,
    private router: Router,

    private drivService: DriverserviceService,
    private taxiservice: TaxiserviceService,

    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private dialog: MatDialog,
    private taxiService: TaxiserviceService,
  ) {
    
    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
      this.currentRefID = params['refID'];

    });
  }
calcTotal()
{
this.total = 0;
  this.totalCredit = 0;
  this.totalDebit= 0;
  this.Mande = 0;
this.operators.forEach(i=>{
  this.total += i.price;
  this.totalCredit += i.credit;
  this.totalDebit+= i.debit;
  this.Mande = this.totalCredit - this.totalDebit;
  i.mande = this.Mande;

})

}

  filter() {
    console.log('filter');
    this.drivService.getAllDebit(this.currentRefID,1).subscribe(_res => {
      console.log(_res);
      this.operators = _res;
this.calcTotal();

    });



     

  }
CurrentDriver:getDriverInfo;
loadDriverInfo()
{
this.drivService.getDriverByID(this.currentRefID).subscribe(_res => {
      this.CurrentDriver = _res;
this.driverCodeSearch=(this.CurrentDriver.info.driverCode )
this.driverFNameSearch=(this.CurrentDriver.info.fName )
    });

}
  ngOnInit() {
    this.filter();
this.loadDriverInfo();
    this.operatorFromGroup = new FormGroup({
      price: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]) 
    });

    
  }
  

  get FormMode() {
    return FormMode;
  }
  currentIsCredit = false;
  openOperatorDialog(_mode: FormMode, isCredit: boolean, _data?: Partial<Driver>) {
    console.log(_data);
    this.currentIsCredit = isCredit;
    this.currentFormMode = _mode;
    this.operatorFromGroup.reset();

    if (_mode === FormMode.Edit && _data) {

      this.currentEditingOperator = _data;
      this.operatorFromGroup.patchValue(this.currentEditingOperator);
    }

    this.operatorDialogRef = this.matDialog.open(this.operatorDialogTemplateRef, {
      hasBackdrop: true,
      width: '360px'
    });
  }

  delete(_data: Partial<Driver>) {
    const confirmRef = this.matDialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      data: {
        message: 'آیا مطمئن هستید تراکنس «' + _data.fName + _data.driverCode + '» حذف شود؟',
        buttonText: {
          ok: 'بله',
          cancel: 'خیر'
        }
      }
    });

    confirmRef.afterClosed().subscribe(_confirmed => {
      if (_confirmed) {
        this.drivService.delete(_data.id).subscribe(_res => {
          const index = this.operators.findIndex(_item => _item.id === _data.id);
          if (index > -1) {
            this.operators.splice(index, 1);
          }
          this.matSnackbar.open('راننده با شناسه ' + _data.id + ' حذف شد.', null, { duration: 5000 });
        });
      }
    });
  }
  selectedhour = new FormControl('', [Validators.required, Validators.min(0), Validators.max(23)]);
  selectedminute = new FormControl('', [Validators.required, Validators.min(0), Validators.max(59)]);
  date = new FormControl('', [Validators.required]);
  submitLock() {
    if (this.lockFromGroup.valid) {
      const formValues: Partial<Driver> = this.lockFromGroup.value;
      this.drivService.lock(this.currentEditingOperator.id, formValues.lockComment, this.date.value, this.selectedhour.value, this.selectedminute.value
      ).subscribe(_id => {
        this.currentEditingOperator.lockComment = formValues.lockComment;
        this.matSnackbar.open('کد' + this.currentEditingOperator.driverCode + 'قفل گردید', null, { duration: 5000 });
      });
      this.lockDialogRef.close();
    }

  }
  unlock(operator: Driver) {
    this.drivService.unlock(operator.id).subscribe(res => {
      operator.lockComment = '';
      this.matSnackbar.open('قفل کد ' + operator.driverCode + 'باز گردید', null, { duration: 5000 });

    }
    );
  }
  submit() {

    if (this.operatorFromGroup.valid) {
      const formValues: Partial<Driver> = this.operatorFromGroup.value;

      if (this.currentFormMode === FormMode.Add) {

        this.drivService.insertdebit(formValues, this.currentRefID, this.currentIsCredit, this.operatorguid ).subscribe(_id => {
          formValues.id = _id;
          
          //this.operators.push(formValues);
          this.matSnackbar.open('ثبت شد.', null, { duration: 5000 });
this.filter();
        });

      } else if (this.currentFormMode === FormMode.Edit) {

        this.currentEditingOperator.mobile = formValues.mobile;
        this.currentEditingOperator.fName = formValues.fName;
        this.currentEditingOperator.workingMobile = formValues.workingMobile;
        this.currentEditingOperator.driverCode = formValues.driverCode;
        this.currentEditingOperator.pelaque2 = formValues.pelaque2;
        this.currentEditingOperator.pelaque3 = formValues.pelaque3;
        this.currentEditingOperator.pelaqueIR = formValues.pelaqueIR;
        this.currentEditingOperator.pelaqueLetter = formValues.pelaqueLetter;
        this.currentEditingOperator.typeCar = formValues.typeCar;
        this.currentEditingOperator.color = formValues.color;
        this.currentEditingOperator.nationalCode = formValues.nationalCode;
        this.currentEditingOperator.username = formValues.username;
        this.currentEditingOperator.password = formValues.password;

        this.drivService.update(this.currentEditingOperator).subscribe(_id => {
          this.matSnackbar.open('ویرایش راننده با موفقیت انجام شد.', null, { duration: 5000 });
        });
      }

      this.operatorDialogRef.close();
    }
  }
  dialogProposedServiceRef: MatDialogRef<DriverLogComponent, any>;
  lockDrv(driver: Driver) {

  }
  showLog(driverIDParam: any) {
    this.dialogProposedServiceRef = this.dialog.open(DriverLogComponent, {
      width: '800px',
      hasBackdrop: true,
      data: {
        driverID: driverIDParam
      },
      autoFocus: true,
    });
  }
   
  updatePriceDefault() {
    this.router.navigate(['cc/setting'], { queryParams: { guid: this.operatorguid } });
  }
  updatePrice(): void {

    this.taxiService.readConfigValue().subscribe(res => {
      console.log(res);


      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش کمیسیون', msg: 'درصد  کمیسیون را وارد نمایید', fieldName: 'کمیسیون', value: res },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.operators.forEach(item => {
            
              this.drivService.updateComission(item.id, result).subscribe(_id => {
                item.price= result;
              });
          })


        console.log('The dialog was closed');
        console.log(result);
      });
    })
  }
  updateSelectedCommission(drv) {

    this.taxiService.readConfigValue().subscribe(res => {
      console.log(res);

      var defaultCommission = drv.commission;
      if (drv.commission && drv.commission > 0) {
        defaultCommission = drv.commission;
      }
      else
        defaultCommission = res;
      const dialogRef = this.dialog.open(InputtextComponent, {
        data: { title: 'ویرایش کمیسیون', msg: 'درصد  کمیسیون را وارد نمایید', fieldName: 'کمیسیون', value: defaultCommission },
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result)
          this.drivService.updateComission(drv.id, result).subscribe(_id => {
            drv.commission = result;
          });
        console.log('The dialog was closed');
        console.log(result);
      });
    })
  }
}

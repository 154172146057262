import { Component, Inject } from '@angular/core';

export class GlobalVariable {

  //public static BaseServiceURL = "https://localhost:44332/";

  //public static socketURL = 'wss://etaxi.mashhad.ir/ws';
  //public static BaseServiceURL = 'https://boroseir.ir/';
  public static BaseServiceURL = location.origin + "/";
  public static socketURL = 'wss://' + GlobalVariable.BaseServiceURL.replace('https://', '') + 'ws';

  //public static BaseServiceURL = 'https://boroseir.ir:8080/';
  //public static socketURL = 'wss://etaxi.mashhad.ir:8080/ws';

  public static AccBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Acc';
  public static DriverBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/driver';
  public static ShiftBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/shift';
  public static UtilityBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/GeneralUtility';
  public static RequestServiceBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/RequestService';
  public static StationBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Station';
  public static TestBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Test';
  public static ServiceBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Service';
  public static DashboardBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Dashboard';
  public static ProfileBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Profile';
  public static ComplaintBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Complaint';
  public static LogBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Log';
  public static FoundedObjectBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/FoundedObject';
  public static PassangerBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Passanger';
  public static CallCenterBaseServiceURL = GlobalVariable.BaseServiceURL + 'CallCenter';
  public static BaseInfoBaseServiceURL = GlobalVariable.BaseServiceURL + 'BaseInfo';
  public static OperatorBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/Operator';
  public static PassangerSavedPlaceBaseServiceURL = GlobalVariable.BaseServiceURL + 'api/PassangerSavedPlace';
  public static mokeFLAG = false;
  public static adminLevel = 1;


  public static check(codemelli: any) {

    var fakeCode = ['0000000000', '1111111111', '2222222222', '3333333333', '4444444444', '5555555555', '6666666666', '7777777777', '8888888888', '9999999999'];
    let Arr = Array.from(codemelli);
    if (fakeCode.some(e => e == codemelli)) {
      return false;
    } else if (Arr.length != 10) {
      return false;
    } else {
      let Sum = 0;
      let Last;

      for (let i = 0; i < 9; i++) {
        Sum += +Arr[i] * (10 - i);
      }
      let divideRemaining = Sum % 11;
      if (divideRemaining < 2) {
        Last = divideRemaining;
      } else {
        Last = 11 - (divideRemaining);
      }
      let n = Arr[9];
      return Last == n;
    }
  }



  public static toShamsi(mdate: Date) {

    const today = mdate;

    const todayFa = {
      "day": this.getDateFormat(today, { "day": "2-digit" }),
      "month": this.getDateFormat(today, { "month": "numeric" }),
      "monthTitle": this.getDateFormat(today, { "month": "long" }),
      "year": this.getDateFormat(today, { "year": "numeric" }),
      "dayWeek": this.getDateFormat(today, { "weekday": "long" }),
    }

    return todayFa;




  }
  public static getDateFormat(uDate, option) {
    let date = new Intl.DateTimeFormat('fa-IR', option).format(uDate);
    return date;
  }
}

export enum PageState {
  None = 0,
  OriginDefine = 1,
  DestinationDefine,
  RequestTrip,
  PassangerInfo
}

export class LocationInfo {
  lat: number;
  ln: number;
  desc: string;

  setFromCordinate(cordinates: any) {
    this.lat = cordinates[0];
    this.ln = cordinates[1];
  }


}

export enum ServiceState {
  DriverFound = 1,
  GotoOrigin = 2,
  OriginReceived = 3,
  PassangerArrived = 4,
  GotoDestination = 5,
  Finished = 6,
  CancelByOperator = 7,
  CancelByOperatorAndConfirmDriver = 8,
  CancelByDriverAfter8Minute = 9,
  CancelByDriverWithCallPassanger = 10,
  CancelByPassanger = 11,
  CancelByDriver = 12
}








import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  audioObj = new Audio();

  constructor() { }
  private stop$ = new Subject();
      
  private streamObservable(url: any) {
    return new Observable(observer => {
      // Play audio
      this.audioObj.src = url;
      this.audioObj.load();
      this.audioObj.play();

      //const handler = (event: Event) => {
      //  this.updateStateEvents(event);
      //  observer.next(event);
      //};

      //this.addEvents(this.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        // remove event listeners
        //this.removeEvents(this.audioObj, this.audioEvents, handler);
        //// reset state
        //this.resetState();
      };
    });
  }
  playStream(url: any) {
    this.stop$.next("");
    return this.streamObservable(url).pipe(takeUntil(this.stop$));
  }
}

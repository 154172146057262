import { Component, OnInit } from '@angular/core';
import { Acc_Acc } from '../../model/accModels';
import { FormMode } from '../../lib/enums/form-mode.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-add-debit',
  templateUrl: './add-debit.component.html',
  styleUrls: ['./add-debit.component.css']
})
export class AddDebitComponent implements OnInit {
  get FormMode() {
    return FormMode;
  }
  constructor() { }
  pageTitle = 'هزینه';
  currentFormMode: FormMode;
  operatorFromGroup: FormGroup;
  currentEditingOperator: Partial<Acc_Acc>;

  ngOnInit(): void {
  this.operatorFromGroup = new FormGroup({
      accName: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
      
    });

  }



}

import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {DriverserviceService} from '../../lib/serviceLib/driverservice.service';

@Component({
  selector: 'app-waitfor-request',
  templateUrl: './waitfor-request.component.html',
  styleUrls: ['./waitfor-request.component.css']
})
export class WaitforRequestComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WaitforRequestComponent>,
    private route: ActivatedRoute,
    private taxiSerivce: DriverserviceService,
    @Inject(MAT_DIALOG_DATA) public data: { guidRequest: string }
  ) {
  }

  ngOnInit() {
  }

  cancelrequest() {
    this.dialogRef.close('CancelRequest');
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styles: [`
  `]
})
export class DataTableComponent implements OnInit {
  @Input() contents: any[] = [];
  @Input() headers: any[] = [];
  @Input() actions: any[] = [];
  @Output() onActionButtonClick: EventEmitter<{ index: number, action: string }> = new EventEmitter<{ index: number; action: string }>();
  columns: any[] = [];


  constructor() {
  }

  ngOnInit(): void {
    this.columns.push('row')
    this.headers.map((f) => {
      return this.columns.push(f.text);
    });
    if (this.actions.length > 0) {
      this.columns.push('action');
    }

  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TaximapComponent } from '../../taximap/taximap.component';
import { LatLng, Marker } from 'leaflet';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { MarkerType } from '../../lib/markerHelper';
import { LocationInfo } from '../../globalVariable';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';
import { Driver } from '../../model/driver';
import { DriverStatus, IDriverStatusMeta } from '../../lib/enums/driver-status.enum';
import { FormControl } from '@angular/forms';
import { ServiceserviceService } from '../../lib/serviceLib/serviceservice.service';
import { StationMemberSelectComponent } from '../../station/station-member-select/station-member-select.component';
import { StationserviceService } from '../../lib/serviceLib/stationservice.service';


@Component({
  selector: 'app-driver-monitor',
  templateUrl: './driver-monitor.component.html',
  styleUrls: ['./driver-monitor.component.scss']

})
export class DrivermonitorComponent implements OnInit, OnDestroy {
  @Input() visible: boolean = true;
  @Input() data: any = [];
  @Output() visibleChange = new EventEmitter();
  
  @ViewChild("map", { static: false })
  public map: any;
  operatorguid: string;
  originPoint = new LocationInfo();
  originMarker: any;
  initMarker: Marker;
  destinationMarker: any;
  recordCount = 0;
  pageSize = 30;
  mobileSearch = '';
  nationalCodeSearch = '';
  driverFNameSearch = '';
  driverCodeSearch = '';
  stationCode = '';
  currentFBSToken = '';
  outOfServiceCount= 0;
  station100Count= 0;
  waitingOnStationCount= 0;
  travelingCount= 0;
  allCount= 0;
  outOfService: FormControl;
  station100: FormControl;
  waitingOnStation: FormControl;
  traveling: FormControl;
  @Output() driverClick = new EventEmitter<Driver>();
  schedulJon: any;
  lstDriver: Driver[];
  updateMap : FormControl;
  
  DriverMarkerList: { [id: number]: Marker; } = {};
  statuesString: string[] = ['1006','1007','1008','1004','1005'];
  constructor(
    private drivService: DriverserviceService,
    private serviceService: ServiceserviceService,
    private route: ActivatedRoute,
    private taxiSerivce: TaxiserviceService,
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private stationSerivce: StationserviceService,
    private router: Router

  ) {

  }
  @ViewChild("driverList", { static: false })
  public driverList: StationMemberSelectComponent;

  ngOnInit(): void {
    if (!this.map) { 
      this.map = new TaximapComponent();
      
    }
    
    //if (!this.driverList) {
    //  this.driverList = new StationMemberSelectComponent();

    //}
    this.outOfService = new FormControl(true);
    this.station100 = new FormControl(true);
    this.waitingOnStation = new FormControl(true);
    this.traveling = new FormControl(true);
    this.updateMap = new FormControl(true);

    this.route.queryParams.subscribe(params => {
      this.operatorguid = params['guid'];
    });





    this.schedulingUpdateMonitoring();

    this.updateMap.valueChanges.subscribe(() => {
      if (this.updateMap.value) {
        this.schedulingUpdateMonitoring();
      }
      else {
        clearTimeout(this.schedulJon);
      }
    }
    );


    this.outOfService.valueChanges.subscribe(() => {
      if (!this.outOfService.value) {
        this.statuesString.splice(this.statuesString.indexOf('1004', 0), 2);
      }
      else if (this.outOfService.value) {
        if (!this.statuesString.includes('1004'))
          this.statuesString.push('1004');
        if (!this.statuesString.includes('1005'))
          this.statuesString.push('1005');
      }
      this.filter().subscribe(() => { });
      }
    );

    this.station100.valueChanges.subscribe(() => {
      if (!this.station100.value) {
        this.statuesString.splice(this.statuesString.indexOf('1006', 0), 1);
      }
      else if (this.station100.value) {
        if (!this.statuesString.includes('1006'))
          this.statuesString.push('1006');
      }
      this.filter().subscribe(() => { });
    }
    );

    this.waitingOnStation.valueChanges.subscribe(() => {
      if (!this.waitingOnStation.value) {
        this.statuesString.splice(this.statuesString.indexOf('1007', 0), 1);
      }
      else if (this.waitingOnStation.value) {
        if (!this.statuesString.includes('1007'))
          this.statuesString.push('1007');
      }
      this.filter().subscribe(() => { });
    }
    );

    this.traveling.valueChanges.subscribe(() => {
      if (!this.traveling.value) {
        this.statuesString.splice(this.statuesString.indexOf('1008', 0), 1);
      }
      else if (this.traveling.value) {
        if (!this.statuesString.includes('1008'))
          this.statuesString.push('1008');
      }
      this.filter().subscribe(() => { });
    }
    );


  }

  filter(): Observable<any>{

    return new Observable((observer) => {
 

    if (!this.outOfService.value && !this.station100.value && !this.waitingOnStation.value && !this.traveling.value) {
      this.statuesString = ['888'];
    }
      this.drivService.getAllForMonitor(this.mobileSearch, this.driverFNameSearch, this.driverCodeSearch, this.nationalCodeSearch, this.statuesString).subscribe(_res => {
        
        for (let key in this.DriverMarkerList) {
          this.map.removemarker(this.DriverMarkerList[key]);
        }

        if (_res && _res.length > 0) {
          this.lstDriver = [];
          this.DriverMarkerList = {};

          this.lstDriver = _res;

          this.outOfServiceCount = _res.filter(x => x.currentStatus == 1005 || x.currentStatus == 1004).length;
          this.station100Count = _res.filter(x => x.currentStatus == 1006).length;
          this.waitingOnStationCount = _res.filter(x => x.currentStatus == 1007).length;
          this.travelingCount = _res.filter(x => x.currentStatus == 1008).length;
          this.allCount = _res.length;

          _res.forEach(driver => {
            this.DriverMarkerList[driver.id] =
              this.addDriverMarker(driver);

          });


        }
        else {
          //Swal.fire('یافت نشد', 'موردی یافت نشد!');

        }
        observer.next(_res);


      });
      if (this.driverList)
        this.driverList.refreshData();

    });

  }


   

  
  addDriverMarker(driver: Driver): Marker  {
    let marker : Marker;
    marker = this.map.addMarker(driver.lat, driver.lng, this.getMarkerTypeForMonitoring(driver.currentStatus));
    marker.on('click', (e: any) => { this.onDriverMarkerClick(e, driver); });
    driver.currentDriverStatus = IDriverStatusMeta.getStatus(driver.currentStatus);
    if (driver.driverStatusInfo.guid) {
      this.serviceService.getService(driver.driverStatusInfo.guid).subscribe(_res => {
        marker.bindPopup(
          '<p style="font-family:iranyekan_regular,iransans !important ;   text-align-last: right;"> نام راننده : <span style="font-weight:bold">' + driver.fName + ' ' + driver.lName + '</span><br/> کد راننده : <span>' + driver.driverCode + '</span><br/> وضعیت : <span>' + driver.currentDriverStatus?.text + '</span> <br/> موبایل : <span>' + driver.mobile + '</span> <br/> کد ایستگاه : <span>' + driver.driverStatusInfo.stationID + '</span><br/> آخرین اتصال : <span>' + driver.lastActivity + '</span> <br/> نام مسافر : <span>' + _res.passangerFName + ' ' + _res.passangerLName + '</span><br/> موبایل مسافر : <span>' + _res.passangerMobile + '</span><br/> مبدا : <span>' + _res.originAddr + '</span><br/> مقصد : <span>' + _res.destinationAddr + '</span></p>');
      });

    }
    else {
      marker.bindPopup(
        '<p style="font-family:iranyekan_regular,iransans !important ;   text-align-last: right;"> نام راننده : <span style="font-weight:bold">' + driver.fName + ' ' + driver.lName + '</span><br/> کد راننده : <span>' + driver.driverCode + '</span><br/> وضعیت : <span>' + driver.currentDriverStatus?.text + '</span> <br/> موبایل : <span>' + driver.mobile + '</span> <br/> کد ایستگاه : <span>' + driver.driverStatusInfo.stationID + '</span><br/> آخرین اتصال : <span>' + driver.lastActivity + '</span> <br/> </p>');
    }

    return marker;
  }


  getMarkerTypeForMonitoring(status: number): MarkerType {
    if (status == DriverStatus.Off || status == DriverStatus.OutOfService)
      return MarkerType.grayDriver;
    if (status == DriverStatus.Station100)
      return MarkerType.greenDriver;
    if (status == DriverStatus.WaitingOnStation)
      return MarkerType.yellowDriver;
    if (status == DriverStatus.Traveling)
      return MarkerType.redDriver;
    else
      return MarkerType.grayDriver;
  }


  onDriverMarkerClick(e: any, i: any) {
    this.driverClick.emit(i);

  }



  schedulingUpdateMonitoring() {
    this.filter().subscribe(res => {
      if (this.updateMap.value) { 
        this.schedulJon = setTimeout(() => { this.schedulingUpdateMonitoring() }, 5000);
      }
    }
    )
  }


  resetForm() {
   this.mobileSearch = '';
    this.nationalCodeSearch = '';
    this.driverFNameSearch = '';
    this.driverCodeSearch = '';
    this.stationCode = '';
    this.filter().subscribe(() => { });
  }




  getOriginAddressDesc(lat: any, lng: any) {
    var s = this.taxiSerivce.getAddressFromLocation(lat, lng);
    s.subscribe(_res => {
      this.originPoint.desc = _res;
    });
  }

  map_Click(cordinates: any) {



  }

  gotoOrigin() {

  }


  moveMapEvent(evt: any) {
    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }

  moveEndMapEvent(evt: any) {
    //if (this.CurrentState == PageState.OriginDefine) {
    //  this.getOriginAddressDesc(evt.location[0], evt.location[1]);
    //}
    //else if (this.CurrentState == PageState.DestinationDefine) {
    //  var s = this.taxiSerivce.getAddressFromLocation(evt.location[0], evt.location[1]);
    //  s.subscribe(_res => {
    //    this.destinationPoint.desc = _res;
    //  });
    //}

    if (this.initMarker) {
      this.initMarker.setLatLng(new LatLng(evt.location[0], evt.location[1]));
    }
  }

  setEmptyStation() {
  }

  ngAfterViewInit() {
    //console.log('ngAfterViewInit');



  }

  ngOnDestroy() {
    if (this.schedulJon) {
      clearInterval(this.schedulJon);
    }

    this.map.removeMap();

   
  }
  onSelectedDriver(q) {
    var loc = this.DriverMarkerList[q.driverID]?.getLatLng();
    if (loc) {
      this.map.setView(loc.lat, loc.lng, 20);
      this.map.openPopup(loc)
    }
    
  }
 

}


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cur_u_name: 'implet_gu',
  cur_u_tkn: 'implet_tkn',
  cur_fbsToken: 'g_fbs',
  old_fbsToken: 'o_fbs',
  currentversion: 'current_versionInfo',
  is_created: 'is_created'


};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

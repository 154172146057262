import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequest } from '../../model/ServiceRequest';
import { ServiceRequestService } from '../../lib/serviceLib/servicerequestservice.service';

@Component({
  selector: 'app-showfreeservicelist',
  templateUrl: './showfreeservicelist.component.html',
  styleUrls: ['./showfreeservicelist.component.scss']
})
export class ShowfreeservicelistComponent implements OnInit {

  list: ServiceRequest[];
  constructor(
    private route: ActivatedRoute,
    private taxiSerivce: ServiceRequestService,
    ) {

  }

  ngOnInit() {
    this.loadRequestInfo('');
  }
  loadRequestInfo(guid: any) {
    this.taxiSerivce.getFreeService(guid).subscribe(_res => {
      //console.log('getFreeService');
      //console.log(_res);
      this.list = _res;
    });
  }


  accept() {
  }

  reject() {
  }
  closeform() {
  }
  showproposed(q: any) {


  }

}

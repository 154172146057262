import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IStationDetail } from '../../lib/interfaces/station-detail';
import { StationService } from '../../lib/serviceLib/stationdetailservice.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-stationdetail',
  templateUrl: './stationdetail.component.html',
  styleUrls: ['./stationdetail.component.scss']
})
export class StationdetailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<StationdetailComponent>,
    private detailService: StationService, private route: ActivatedRoute,
    
    @Inject(MAT_DIALOG_DATA) public data: { stationID: number},
    
  ) {
    this.loadData(this.data.stationID);
  }

  ngOnInit() {
      
  }
  detailsList: Partial<IStationDetail>[] = [];
  loadData(sid : any) {
    this.detailService.getAll(sid).subscribe(res => {
      this.detailsList = res;
    });
  }

  onStationKeydown2(event: KeyboardEvent) {
    //if (event.key === " ") {
    //  this.dialogRef.close();
    //  event.preventDefault();
    //}
    //console.log('onStationKeydown');
    //console.log(event);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient,  HttpParams } from '@angular/common/http';
import { GlobalVariable } from '../../globalVariable';
import { Observable} from 'rxjs';
import { Profile } from '../../model/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private httpClient: HttpClient

  ) { }


  public getProfile(driverID: any): Observable<Profile> {
  
    let params = new HttpParams().set('driverID', driverID);

    return this.httpClient.post<Profile>(GlobalVariable.ProfileBaseServiceURL + "/GetProfile", params);
  }
  public getProfileToday(driverID: any): Observable<Profile> {

    let params = new HttpParams().set('driverID', driverID);

    return this.httpClient.post<Profile>(GlobalVariable.ProfileBaseServiceURL + "/GetProfileToday", params);
  }



  public writeLog(log: string): Observable<boolean> {

    let params = new HttpParams().set('log', log);
    

      return this.httpClient.post<boolean>(GlobalVariable.ProfileBaseServiceURL + "/LogToFile", params);


  }


}


import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StationMemberComponent } from '../../station/station-member/station-member.component';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  dialogRef: MatDialogRef<StationMemberComponent, any>;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }
  showdialog() {
    //console.warn('cklick');
    this.dialogRef = this.dialog.open(StationMemberComponent, {
      width: '400px',
      hasBackdrop: true,
      data: {
        id:1
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-drivermismmatch',
  templateUrl: './drivermismmatch.component.html',
  styleUrls: ['./drivermismmatch.component.scss']
})
export class DrivermismmatchComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  notconfirm() {
    this.router.navigate(['driver/new'], {});
    //console.warn('goto home page 2');
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DriverserviceService } from '../../lib/serviceLib/driverservice.service';

@Component({
  selector: 'app-setpriority',
  templateUrl: './setpriority.component.html',
  styleUrls: ['./setpriority.component.scss']
})
export class SetpriorityComponent implements OnInit {

  stationID = new FormControl('');
  driverCode = new FormControl('');
  priority = new FormControl('');


  constructor(
    private driverService: DriverserviceService,
    public dialogRef: MatDialogRef<SetpriorityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { driverCode: string, stationID:string }
  ) {
    this.driverCode.setValue(data.driverCode);
    this.stationID .setValue(data.stationID);
  }

  ngOnInit() {
  }

  save() {
    this.driverService.setPriority(this.driverCode.value, this.stationID.value, this.priority.value).subscribe(_res => {
      this.cancel()
    });
  }
  cancel() {
    this.dialogRef.close('Reject');
  }

}

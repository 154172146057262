import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CallCenterService } from '../../lib/serviceLib/callcenterservice.service';

@Component({
  selector: 'app-test-socket',
  templateUrl: './test-socket.component.html',
  styleUrls: ['./test-socket.component.scss']
})
export class TestSocketComponent implements OnInit {

  constructor(private callService: CallCenterService, private route: ActivatedRoute,) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.callService.testWS(params['id']).subscribe(res => { console.log });
    });
    
  }

}
